import React, { useState } from 'react';
import {
  Button,
  Container,
  Grid,
  Pagination,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQueryClient, useIsFetching } from '@tanstack/react-query';
import LookupTypeValuesTablePanel from './LookupTypeValues/TablePanel';
import { debug } from 'src/utils/commonUtils';

export interface ILookupsTablePanelPropsInterface {
  username: string;
}

export default function LookupTypesTablePanel(props: ILookupsTablePanelPropsInterface ) {
  debug(`LookupsTablePanel() props is ${JSON.stringify(props)}`);

  const pageSize = 5;

  const queryClient = useQueryClient();

  const isFetchingLookupTypes = useIsFetching(['lookupTypes']);

  const [allItems, setAllItems] = useState<APIt.LookupType[]>(queryClient.getQueryData(['lookupTypes']) || []);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<APIt.LookupType | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.LookupTypes.TablePanel');


  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-lookup-types-found') : 'No Lookup Types Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    const lookupTypesQuery = queryClient.refetchQueries(['lookupTypes']);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <Grid gridDefinition={[{colspan: 4},{colspan: 2}]}>
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-lookup-types')}
              filteringPlaceholder={bundle.getMessage('find-lookup-types')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
          </Grid>
        }
        footer={
          <></>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <Button
                loading={isFetchingLookupTypes > 0}
                key="refreshBtn"
                onClick={refreshBtnClickHandler}
                iconName='refresh'
              >
                {bundle.getMessage('refresh')}
              </Button>
            }
          >
            {bundle.getMessage('manage-lookups')}
          </Header>
        }
        items={items}
        loading={isFetchingLookupTypes > 0}
        loadingText={bundle.getMessage('loading-lookup-types')}
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0])}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItem ? [selectedItem] : []}
        selectionType='single'
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    {
      selectedItem
      &&
      <Container
        key={'LookupTypeDetails'}
        children={
          <LookupTypeValuesTablePanel
            lookupType={selectedItem}
            username={props.username}
          />
        }
      />
    }
    </>
  );
}
