import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery } from '@tanstack/react-query';
import LookupTypeValue from './LookupTypeValue';
import { queryLookupTypeValues } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export interface ILookupTypesTablePanelProps {
  lookupType: APIt.LookupType;
  username: string;
}

export default function LookupTypeValuesTablePanel(props: ILookupTypesTablePanelProps) {
  debug(`LookupTypeValuesTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.LookupTypeValue[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [pagesCount, setPagesCount ] = useState(1);
  const [selectedItem, setSelectedItem] = useState<APIt.LookupTypeValue | undefined>();
  const [showAddFailure, setShowAddFailure] = useState<boolean>(false);
  const [showAddLookupTypeValue, setShowAddLookupTypeValue] = useState<boolean>(false);
  const [showAddSuccess, setShowAddSuccess] = useState<boolean>(false);
  const [showEditLookupTypeValue, setShowEditLookupTypeValue] = useState<boolean>(false);
  const [showUpdateFailure, setShowUpdateFailure] = useState<boolean>(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.LookupTypes.LookupTypeValues.TablePanel');

  const pageSize = 5;

  const lookupTypeValuesQuery = useQuery({
    queryFn: () => queryLookupTypeValues(props.lookupType.name),
    queryKey: ['lookupTypeValues'],
    onSuccess: (data) =>
      {
        setAllItems(data);
        setSelectedItem(undefined);
      },
    refetchOnWindowFocus: false,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading && !lookupTypeValuesQuery.isFetching ? bundle.getMessage('no-lookup-type-values-found') : 'No Lookup Type Values Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const [hideTable, setHideTable] = useState<boolean>(false);

  const refreshBtnClickHandler = async () => {
    setAllItems([]);
    setCurrentPageIndex(1);
    setPagesCount(1);
    await lookupTypeValuesQuery.refetch();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  useEffect(() => {
    if (lookupTypeValuesQuery && lookupTypeValuesQuery.data) setAllItems(lookupTypeValuesQuery.data);
  }, [lookupTypeValuesQuery]);

  useEffect(() => {
    lookupTypeValuesQuery.refetch();
  }, [currentPageIndex]);

  useEffect(() => {
    setAllItems([]);
    setSelectedItem(undefined);
    setCurrentPageIndex(1);
    setPagesCount(1);
    lookupTypeValuesQuery.refetch();
  }, [props.lookupType.name]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-lookup-type-values')}
            filteringPlaceholder={bundle.getMessage('find-lookup-type-values')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        footer={
          <Header
            actions={
              <SpaceBetween size='s' direction='horizontal'>
                <Button
                  disabled={!selectedItem}
                  onClick={() => setShowEditLookupTypeValue(true)}
                  iconName='edit'
                  variant='normal'
                >
                  {bundle.getMessage('edit')}
                </Button>
                <Button
                  onClick={() => setShowAddLookupTypeValue(true)}
                  iconName='add-plus'
                  variant='primary'
                >
                  {bundle.getMessage('add')}
                </Button>
              </SpaceBetween>
            }
          >
          </Header>
        }
        header={
          <>
            {showAddFailure && <Alert header={bundle.getMessage('add-failure')} type='error' dismissible={true} onDismiss={() => setShowAddFailure(false)} />}
            {showAddSuccess && <Alert header={bundle.getMessage('add-success')} type='success' dismissible={true} onDismiss={() => setShowAddSuccess(false)} />}
            {showUpdateFailure && <Alert header={bundle.getMessage('update-failure')} type='error' dismissible={true} onDismiss={() => setShowUpdateFailure(false)} />}
            {showUpdateSuccess && <Alert header={bundle.getMessage('update-success')} type='success' dismissible={true} onDismiss={() => setShowUpdateSuccess(false)} />}
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <Button
                  loading={lookupTypeValuesQuery.isFetching}
                  key="refreshBtn"
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                >
                  {bundle.getMessage('refresh')}
                </Button>
              }
            >
              {`${props.lookupType.name} ${bundle.getMessage('values')}`}
            </Header>
          </>
        }
        items={items}
        loadingText={bundle.getMessage('loading-lookup-type-values')}
        loading={lookupTypeValuesQuery.isFetching}
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0]) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItem ? [selectedItem] : []}
        selectionType='single'
        stickyHeader={true}
        trackBy='id'
      />
      {(showEditLookupTypeValue || showAddLookupTypeValue)
      &&
      <Modal
        header={
          <Header>
            {`${showEditLookupTypeValue ? bundle.getMessage('edit') : bundle.getMessage('add')} ${props.lookupType.name}`}
          </Header>
        }
        visible={showEditLookupTypeValue || showAddLookupTypeValue}
      >
        <LookupTypeValue
          closeCallback={() => showEditLookupTypeValue ? setShowEditLookupTypeValue(false) : setShowAddLookupTypeValue(false)}
          lookupType={props.lookupType}
          lookupTypeValue={showEditLookupTypeValue ? selectedItem : undefined}
          addFailureCallback={() => setShowAddFailure(true)}
          addSuccessCallback={() => setShowAddSuccess(true)}
          updateFailureCallback={() => setShowUpdateFailure(true)}
          updateSuccessCallback={() => setShowUpdateSuccess(true)}
          username={props.username}
        />
      </Modal>
      }
    </div>
  );
}
