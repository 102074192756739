import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "../../../API";
import {
  listVisitorAccessLevelApprovalsForVisitorAccessLevel,
  listVisitorAccessLevelsForRequestor,
  SNSPublishAccessLevelRequestSendReminder,
} from 'src/graphql/queries';
import {
  deleteVisitorAccessLevel as deleteVisitorAccessLevelMutation,
  updateRequest as updateRequestMutation,
} from 'src/graphql/mutations';
import { auditDecorator, getLookupTypeValueId } from 'src/components/utils';
import { ApprovalStatus, LookupTypes } from 'src/constants/Constants';
import { debug } from '../../../utils/commonUtils';

export let cancelAccessLevelRequest = async (visitorAccessLevelId: string, updatedBy: string): Promise<APIt.VisitorAccessLevel | null> => {
  debug(`cancelAccessLevelRequest() visitorAccessLevelId is ${visitorAccessLevelId} updatedBy is ${updatedBy}`);

  let visitorAccessLevel: APIt.VisitorAccessLevel | null = null;
  let cancelledStatusCodeId = await getLookupTypeValueId('Access Level Approval Status', 'Cancelled');
  debug(`cancelAccessLevelRequest() cancelledStatusCodeId is ${cancelledStatusCodeId}`);

  if (!visitorAccessLevelId || !updatedBy || !cancelledStatusCodeId) return visitorAccessLevel;

  try {
    const response = await API.graphql(graphqlOperation(deleteVisitorAccessLevelMutation,
      {
        input: {
          id: visitorAccessLevelId,
          updated_by: updatedBy
        }
      })) as GraphQLResult<APIt.DeleteVisitorAccessLevelMutation>;
    debug(`cancelAccessLevelRequest() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.deleteVisitorAccessLevel) {
      visitorAccessLevel= response.data.deleteVisitorAccessLevel as APIt.VisitorAccessLevel;
    }
  } catch (e) {
    console.error(`cancelAccessLevelRequest(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`cancelAccessLevelRequest() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)}`);
  return(visitorAccessLevel);
};
cancelAccessLevelRequest = auditDecorator('cancelAccessLevelRequest', cancelAccessLevelRequest);

export let queryHistoricalVisitorAccessLevels = async (personId: string | undefined, requestorId: string | undefined): Promise<APIt.VisitorAccessLevel[] | null> => {
  debug(`queryHistoricalVisitorAccessLevels() requestorId is ${requestorId}`);

  if (!requestorId || !personId) return null;

  let historicalVisitorAccessLevelsForRequestor: APIt.VisitorAccessLevel[] = [];

  try {
    const requestorSourceSystemId = await getLookupTypeValueId(LookupTypes.RequestorSourceSystem, 'PACS');
    debug(`queryHistoricalVisitorAccessLevels() requestorSourceSystemId is ${requestorSourceSystemId}`);
    const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, 'PACS');
    debug(`queryHistoricalVisitorAccessLevels() personSourceSystemId is ${personSourceSystemId}`);

    const listVisitorAccessLevelsForRequestorPromises: Promise<GraphQLResult<APIt.ListVisitorAccessLevelsForRequestorQuery>>[] =
      Object.values(ApprovalStatus).filter((status) => status != ApprovalStatus.PendingApproval)
        .map((status) => API.graphql(graphqlOperation(listVisitorAccessLevelsForRequestor,
          {
            person_id: personId,
            person_source_system_id: personSourceSystemId,
            requestor_id: requestorId,
            requestor_source_system_id: requestorSourceSystemId,
            status: status,
          })) as Promise<GraphQLResult<APIt.ListVisitorAccessLevelsForRequestorQuery>>);

    const responses = await Promise.all(listVisitorAccessLevelsForRequestorPromises);
    for (const response of responses) {
      if (response.data && response.data.listVisitorAccessLevelsForRequestor) {
        historicalVisitorAccessLevelsForRequestor = historicalVisitorAccessLevelsForRequestor.concat(response.data.listVisitorAccessLevelsForRequestor as APIt.VisitorAccessLevel[]);
      }
    }
  } catch (error) {
    console.error(`queryHistoricalVisitorAccessLevels() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return (historicalVisitorAccessLevelsForRequestor);
};
queryHistoricalVisitorAccessLevels = auditDecorator('queryHistoricalVisitorAccessLevels', queryHistoricalVisitorAccessLevels);

export let queryPendingVisitorAccessLevels = async (personId: string | undefined, requestorId: string | undefined): Promise<APIt.VisitorAccessLevel[] | null> => {
  debug(`queryPendingVisitorAccessLevels() requestorId is ${requestorId}`);

  if (!requestorId || !personId) return null;

  let pendingVisitorAccessLevelsForRequestor: APIt.VisitorAccessLevel[] = [];
  let requestorSourceSystemId;
  let personSourceSystemId;

  try {
    requestorSourceSystemId = await getLookupTypeValueId('Requestor Source System','PACS');
    debug(`queryPendingVisitorAccessLevels() requestorSourceSystemId is ${requestorSourceSystemId}`);
    personSourceSystemId = await getLookupTypeValueId('Person Source System','PACS');
    debug(`queryPendingVisitorAccessLevels() personSourceSystemId is ${personSourceSystemId}`);
  } catch(error) {
    console.error(`queryPendingVisitorAccessLevels() error is ${JSON.stringify(error)}`);
    throw error;
  }

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAccessLevelsForRequestor,
      {
        person_id: personId,
        person_source_system_id: personSourceSystemId,
        requestor_id: requestorId,
        requestor_source_system_id: requestorSourceSystemId,
        status: ApprovalStatus.PendingApproval,
      })) as GraphQLResult<APIt.ListVisitorAccessLevelsForRequestorQuery>;
    if (response.data && response.data.listVisitorAccessLevelsForRequestor) {
      pendingVisitorAccessLevelsForRequestor = response.data.listVisitorAccessLevelsForRequestor as APIt.VisitorAccessLevel[];
    }
  } catch (e) {
    console.error(`queryPendingVisitorAccessLevels(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(pendingVisitorAccessLevelsForRequestor);
};
queryPendingVisitorAccessLevels = auditDecorator('queryPendingVisitorAccessLevels', queryPendingVisitorAccessLevels);

export let queryVisitorAccessLevelApprovals = async (visitorAccessLevelId: string | undefined): Promise<APIt.VisitorAccessLevelApproval[]> => {
  debug(`queryVisitorAccessLevelApprovals() visitorAccessLevelId is ${visitorAccessLevelId}`);

  let visitorAccessLevelApprovals: APIt.VisitorAccessLevelApproval[] = [];

  if (!visitorAccessLevelId) return visitorAccessLevelApprovals;

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAccessLevelApprovalsForVisitorAccessLevel,
      {
        visitor_access_level_id: visitorAccessLevelId
      })) as GraphQLResult<APIt.ListVisitorAccessLevelApprovalsForVisitorAccessLevelQuery>;
    debug(`queryVisitorAccessLevelApprovals() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listVisitorAccessLevelApprovalsForVisitorAccessLevel) {
      visitorAccessLevelApprovals = response.data.listVisitorAccessLevelApprovalsForVisitorAccessLevel as APIt.VisitorAccessLevelApproval[];
    }
  } catch (e) {
    console.error(`queryVisitorAccessLevelApprovals(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryVisitorAccessLevelApprovals() visitorAccessLevelApprovals is ${JSON.stringify(visitorAccessLevelApprovals)}`);
  return(visitorAccessLevelApprovals);
};
queryVisitorAccessLevelApprovals = auditDecorator('queryVisitorAccessLevelApprovals', queryVisitorAccessLevelApprovals);

export let sendAccessLevelRequestReminder = async (requestId: string, username: string): Promise<void> => {
  debug(`sendAccessLevelRequestReminder() requestId is ${requestId} username is ${username}`);

  try {
    const snsPublishAccessLevelRequestSendReminderResponse = await API.graphql(graphqlOperation(SNSPublishAccessLevelRequestSendReminder,
      {
        requestId: requestId,
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestSendReminderQuery>;
    debug(`() snsPublishAccessLevelRequestSendReminderResponse is ${JSON.stringify(snsPublishAccessLevelRequestSendReminderResponse)}`);
    if (snsPublishAccessLevelRequestSendReminderResponse
    && snsPublishAccessLevelRequestSendReminderResponse.data
    && snsPublishAccessLevelRequestSendReminderResponse.data.SNSPublishAccessLevelRequestSendReminder) {
      const updateRequestInput: APIt.UpdateRequestInput = {
        id: requestId,
        last_reminder_date: new Date().toISOString(),
        updated_by: username,
      }
      const updateRequestMutationResponse = await API.graphql(graphqlOperation(updateRequestMutation,
        {
          input: updateRequestInput,
        })) as GraphQLResult<APIt.UpdateRequestMutation>;
      debug(`() updateRequestMutationResponse is ${JSON.stringify(updateRequestMutationResponse)}`);
    }
  } catch(error) {
    console.error(`sendAccessLevelRequestReminder() error is ${JSON.stringify(error)}`);
    throw error;
  }
};
sendAccessLevelRequestReminder = auditDecorator('sendAccessLevelRequestReminder', sendAccessLevelRequestReminder);