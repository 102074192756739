import React, { useEffect, useRef, useState } from 'react';
import {
  DefaultValidFromTime,
  DefaultValidThroughTime,
  LookupTypes,
  Modes, 
  WelcomeApplicationSettings} from 'src/constants/Constants';
import Escorts, { HeaderMessages } from 'src/components/common/Escorts/TablePanel';
import OnboardedVisitors from 'src/components/common/Escorts/TablePanel';
import { IEscort } from 'src/types';
import ExternalVisitors from './Visitors/TablePanel';
import {
  Box,
  Button,
  Container,
  DateRangePicker,
  DateRangePickerProps,
  Flashbar,
  FormField,
  Grid,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Textarea, 
  TimeInput} from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { submitAirSiteVisitorRequest } from './utils';
import { useQueryClient } from '@tanstack/react-query';
import { TVisitor } from 'src/types';
import * as APIt from 'src/API';
import {
  isFromDateValid,
  isThroughDateValid,
  queryLookupTypeValueForTypeAndDescription,
} from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export interface IVisitDetails {
  endDate: string | null;
  endTime: string | null;
  reason: string | null;
  site: SelectProps.Option | null;
  startDate: string | null;
  startTime: string | null;
}

const initialResetVisitDetails = {
  endDate: null,
  endTime: DefaultValidThroughTime,
  reason: null,
  site: null,
  startDate: null,
  startTime: DefaultValidFromTime,
}

interface IRequestVisitorAccessProps {
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  escorts: IEscort[];
  externalVisitors: TVisitor[];
  maxVisitorsPerEscort: number;
  mode: Modes;
  onboardedVisitors: IEscort[];
  setCardholdersQuickLinksCallback: Function;
  setEscortsCallback: Function;
  setExternalVisitorsCallback: Function;
  setOnboardedVisitorsCallback: Function;
  setVisitDateRangeCallback: Function;
  setVisitDetailsCallback: Function;
  username: string;
  visitDateRange: DateRangePickerProps.Value | null;
  visitDetails: IVisitDetails | undefined;
  visitorId: string | undefined;
}

export default function RequestAmazonAirSiteAccess(props: IRequestVisitorAccessProps) {
  debug(`RequestAmazonAirSiteAccess() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAmazonAirSiteAccess.index');

  const queryClient = useQueryClient();

  const [airSites, setAirSites] = useState<APIt.Site[] | undefined>(queryClient.getQueryData(['airSites']));
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [escorts, setEscorts] = useState<IEscort[]>(props.escorts);
  const [escortMissingActiveBadge, setEscortMissingActiveBadge] = useState<boolean | null>(null);
  const [escortMissingSiteAccess, setEscortMissingSiteAccess] = useState<boolean>(false);
  const [externalVisitors, setExternalVisitors] = useState<TVisitor[]>(props.externalVisitors);
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [invalidStartDate, setInvalidStartDate] = useState<boolean>(false);
  const [invalidEndDate, setInvalidEndDate] = useState<boolean>(false);
  const [onboardedVisitors, setOnboardedVisitors] = useState<IEscort[]>(props.onboardedVisitors);
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [showRequestSubmissionFailureFlashbar, setShowRequestSubmissionFailureFlashbar] = useState<boolean>(false);
  const [showRequestSubmittedFlashbar, setShowRequestSubmittedFlashbar] = useState<boolean>(false);
  const [tooManyVisitorsPerEscortFlashbar, setTooManyVisitorsPerEscortFlashbar] = useState<boolean>(false);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(props.visitDateRange);
  const [visitDetails, setVisitDetails] = useState<IVisitDetails>(
    {
      endDate: props.visitDetails?.endDate || null,
      endTime: props.visitDetails?.endTime || DefaultValidThroughTime,
      reason: props.visitDetails?.reason || null,
      site: props.visitDetails?.site || null,
      startDate: props.visitDetails?.startDate || null,
      startTime: props.visitDetails?.endTime || DefaultValidFromTime,
    }
  );
  const [airVisitMaxDays, setAirVisitMaxDays] = React.useState<number>(7);

  const airSitesQueryState = queryClient.getQueryState(['airSites']);

  const reasonForVisitRef = useRef<HTMLInputElement>(null);
  const siteInputRef = useRef<HTMLInputElement>(null);

  const submitRequestButtonOnClickHandler = async () => {
    debug(`RequestAmazonAirSiteAccess() submitRequest()`);
    setShowRequestSubmittedFlashbar(false);
    setShowRequestSubmissionFailureFlashbar(false);
    setRequestProcessing(true);
    let invalidDate = false;
    if (!isFromDateValid(new Date(`${visitDetails.startDate} ${visitDetails.startTime}`), hoursExtFromDate)) {
      setInvalidStartDate(true);
      invalidDate = true;
    }
    if (!isThroughDateValid(
      new Date(`${visitDetails.startDate} ${visitDetails.startTime}`),
      new Date(`${visitDetails.endDate} ${visitDetails.endTime}`),
      airVisitMaxDays))
    {
      setInvalidEndDate(true);
      invalidDate = true;
    }
    if (invalidDate) {
      setRequestProcessing(false);
      return;
    }
    try {
      await submitAirSiteVisitorRequest(
        props.username,
        visitDetails,
        escorts,
        onboardedVisitors,
        externalVisitors);
      setShowRequestSubmittedFlashbar(true);
      clearRequest();
      queryClient.fetchQuery({ queryKey: ['pendingVisitorRequests'] });
    } catch(error) {
      console.error(error);
      debug(`RequestAmazonAirSiteAccess() submitRequest() error is ${JSON.stringify(error)}`);
      setErrorMessage((error as any).message ? (error as any).message : JSON.stringify(error));
      setShowRequestSubmissionFailureFlashbar(true);
    }
    setRequestProcessing(false);
  };

  const dateRangeInputChangeHandler = async (value: DateRangePickerProps.Value | null) => {
    debug(`dateRangeInputChangeHandler() value is ${JSON.stringify(value)}`);
    setVisitDateRange(value);
    await props.setVisitDateRangeCallback(value);
    if (value?.type == 'absolute') {
      const newVisitDetails = 
        {
          ...visitDetails,
          endDate: value.endDate,
          startDate: value.startDate,
        };
      setVisitDetails(newVisitDetails);
      // setInvalidFromDate(!isFromDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), hoursExtFromDate));
      // setInvalidFromDate(!isThroughDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), new Date(`${value.endDate} ${validThroughTimeInput}`)));
      props.setVisitDetailsCallback(newVisitDetails);
    }
    if (value?.type == 'relative') {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      debug(`dateRangeInputChangeHandler() fromDate is ${fromDate}`);
      const fromDateInput = `${fromDate.getFullYear()}-${(fromDate.getMonth()+1).toString().padStart(2, '0')}-${fromDate.getDate().toString().padStart(2, '0')}`;
      debug(`dateRangeInputChangeHandler() fromDateInput is ${fromDateInput}`);
      const throughDate = new Date();
      let factor = 1;
      switch(value.unit) {
        case('day'):
          factor = Math.abs(value.amount)*1;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('month'):
          factor = Math.abs(value.amount);
          debug(`dateRangeInputChangeHandler() ${throughDate.getFullYear()} ${throughDate.getMonth()} ${throughDate.getDate()}`);
          const dateUTC = Date.UTC(throughDate.getFullYear(), throughDate.getMonth() + factor, throughDate.getDate());
          debug(`dateRangeInputChangeHandler() dateUTC is ${dateUTC}`);
          throughDate.setTime(dateUTC);
          break;
        case('week'):
          factor = Math.abs(value.amount)*7;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('year'):
          factor = Math.abs(value.amount)*365;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
      }
      debug(`dateRangeInputChangeHandler() throughDate is ${throughDate}`);
      const throughDateInput = `${throughDate.getFullYear()}-${(throughDate.getMonth()+1).toString().padStart(2, '0')}-${throughDate.getDate().toString().padStart(2, '0')}`;
      debug(`dateRangeInputChangeHandler() throughDateInput is ${throughDateInput}`);
      if (fromDate !== null) setInvalidStartDate(!isFromDateValid(fromDate, hoursExtFromDate));
      if (fromDate !== null && throughDate !== null) setInvalidEndDate(throughDateInput == '' ? false : !isThroughDateValid(fromDate, throughDate));
      const newVisitDetails = 
        {
          ...visitDetails,
          endDate: throughDateInput,
          startDate: fromDateInput,
        };
      setVisitDetails(newVisitDetails);
      props.setVisitDetailsCallback(newVisitDetails);
    }
  };

  const startDateInputChangeHandler = (value: string) => {
    setVisitDetails({...visitDetails, startDate: value});
    setInvalidStartDate(!isFromDateValid(new Date(`${value} ${visitDetails.startTime}`), hoursExtFromDate));
    setInvalidEndDate(
      visitDetails.endDate == null
        ? false 
        : !isThroughDateValid(
          new Date(`${value} ${visitDetails.startTime}`),
          new Date(`${visitDetails.endDate} ${visitDetails.endTime}`),
          airVisitMaxDays));
  };
 
  const startTimeInputChangeHandler = (value: string) => {
    setVisitDetails({...visitDetails, startTime: value});
    setInvalidStartDate(!isFromDateValid(new Date(`${visitDetails.startDate} ${value}`), hoursExtFromDate));
    setInvalidEndDate(
      visitDetails.endDate == null
        ? false 
        : !isThroughDateValid(
          new Date(`${visitDetails.startDate} ${value}`),
          new Date(`${visitDetails.endDate} ${visitDetails.endTime}`),
          airVisitMaxDays));
  };
 
  const endDateInputChangeHandler = (value: string) => {
    setVisitDetails({...visitDetails, endDate: value});
    setInvalidEndDate(
      visitDetails.endDate == null
        ? false 
        : !isThroughDateValid(
          new Date(`${visitDetails.startDate} ${visitDetails.startTime}`),
          new Date(`${value} ${visitDetails.endTime}`),
          airVisitMaxDays));
  };
 
  const endTimeInputChangeHandler = (value: string) => {
    setVisitDetails({...visitDetails, endTime: value});
    setInvalidEndDate(
      visitDetails.endDate == null
        ? false 
        : !isThroughDateValid(
          new Date(`${visitDetails.startDate} ${visitDetails.startTime}`),
          new Date(`${visitDetails.endDate} ${value}`),
          airVisitMaxDays));
  };

  const clearRequest = async () => {
    setVisitDateRange(null);
    setVisitDetails(initialResetVisitDetails);
    setEscorts([]);
    setExternalVisitors([]);
    setOnboardedVisitors([]);
    setInvalidStartDate(false);
    setInvalidEndDate(false);
    await Promise.all(
      [
        props.setExternalVisitorsCallback([]),
        props.setOnboardedVisitorsCallback([]),
        props.setEscortsCallback([]),
        props.setVisitDetailsCallback(initialResetVisitDetails),
      ]);
  };

  useEffect(() => {
    setEscorts(props.escorts);
  }, [props.escorts]);

  useEffect(() => {
    setExternalVisitors(props.externalVisitors);
  }, [props.externalVisitors]);

  useEffect(() => {
    setOnboardedVisitors(props.onboardedVisitors);
  }, [props.onboardedVisitors]);

  useEffect(() => {
    if (props.visitDetails) setVisitDetails(props.visitDetails);
  }, [props.visitDetails]);

  useEffect(() => {
    if (props.escorts.length > 0
      && siteInputRef
      && !visitDetails.site
      && props.externalVisitors.length + props.onboardedVisitors.length == 0
    ) {
      debug(`RequestAmazonAirSiteAccess() useEffect[props.escorts, props.onboardedVisitors, props.externalVisitors] setting focus to siteInputRef`);
      siteInputRef.current?.focus();
    }
  }, [props.escorts, props.externalVisitors, props.onboardedVisitors, siteInputRef]);

  useEffect(() => {
    setAirSites(queryClient.getQueryData(['airSites']));
  }, [queryClient.getQueryData(['airSites'])]);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.AirVisitMaxDays)
    .then((v) => setAirVisitMaxDays(parseInt(v.value)));
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.HoursExtensionFromDateFormValidation)
    .then((v) => setHoursExtFromDate(parseInt(v.value)));
  }, []);

  useEffect(() => {
    if (escorts.length > 0 && externalVisitors.length/escorts.length > props.maxVisitorsPerEscort) setTooManyVisitorsPerEscortFlashbar(true);
    if (escorts.length == 0 || externalVisitors.length/escorts.length <= props.maxVisitorsPerEscort) setTooManyVisitorsPerEscortFlashbar(false);
  }, [escorts, externalVisitors]);

  const FlashMessageRequestSubmitted = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('request-submitted'),
            buttonText: bundle.getMessage('ok'),
            onButtonClick: () => setShowRequestSubmittedFlashbar(false)
          },
        ]
      }
    />
  );

  const FlashMessageRequestSubmissionFailure = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: `${bundle.getMessage('request-submission-failure')}: ${errorMessage}`,
            buttonText: bundle.getMessage('ok'),
            onButtonClick: () => {
              setErrorMessage('');
              setShowRequestSubmissionFailureFlashbar(false);
            },
          },
        ]
      }
    />
  );

  const FlashMessageTooManyVisitorsPerEscort = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: `${bundle.formatMessage('too-many-visitors-per-escorts', { maxVisitorsPerEscort: props.maxVisitorsPerEscort } )}`
          },
        ]
      }
    />
  );

  if (isBundleLoading) return <Spinner/>

  return(
    <div id='RequestAmazonAirSiteAccessTableDiv'>
    <Container
      header={
        <Header
          variant='h2'
        >
         {bundle.getMessage('amazon-air-visitor')}
        </Header>}
      footer={
        <Box float='right'>
          <SpaceBetween key={'RequestAccessBottomButtonsSpaceBetween'} size='s' direction='horizontal'>
            <Button
              disabled={
                escorts.length == 0
                && externalVisitors.length + onboardedVisitors.length == 0
                && !visitDetails.reason
                && !visitDetails.site
                && !visitDetails.startDate
                && !visitDetails.endDate
              }
              onClick={clearRequest}
            >
              {bundle.getMessage('clear')}
            </Button>
            <Button
              data-testid='RequestAmazonAirSiteAccessSubmitRequestButton'
              disabled={
                 escorts.length == 0
                 || !visitDetails.site
                 || externalVisitors.length + onboardedVisitors.length == 0
                 || !visitDetails.reason
                 || escortMissingActiveBadge == null
                 || escortMissingActiveBadge
                 || escortMissingSiteAccess == null
                 || escortMissingSiteAccess
                 || tooManyVisitorsPerEscortFlashbar
                 || !visitDetails.startDate
                 || !visitDetails.startTime
                 || !visitDetails.endDate
                 || !visitDetails.endTime
                 || invalidStartDate
                 || invalidEndDate
                 || !isFromDateValid(new Date(`${visitDetails.startDate} ${visitDetails.startTime}`), hoursExtFromDate)
                 || !isThroughDateValid(new Date(`${visitDetails.startDate} ${visitDetails.startTime}`), new Date(`${visitDetails.endDate} ${visitDetails.endTime}`), airVisitMaxDays)
              }
              variant='primary'
              loading={requestProcessing}
              onClick={submitRequestButtonOnClickHandler}
            >
              {bundle.getMessage('submit-request')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      variant='default'
    >
      {showRequestSubmittedFlashbar && <FlashMessageRequestSubmitted/>}
      {showRequestSubmissionFailureFlashbar && <FlashMessageRequestSubmissionFailure/>}
      <Escorts
        autoFocus={true}
        cardholdersQuickLinks={props.cardholdersQuickLinks}
        checkForMissingSiteAccess={true}
        darkMode={props.darkMode}
        escorts={escorts}
        headerMessage={HeaderMessages.ESCORTS}
        username={props.username}
        setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
        setEscortsCallback={props.setEscortsCallback}
        setEscortMissingActiveBadgeCallback={setEscortMissingActiveBadge}
        setEscortMissingSiteAccessCallback={setEscortMissingSiteAccess}
        siteCode={visitDetails.site?.value || null}
      />
      <Container
        header={
          <>
          <Header>
            {bundle.getMessage('visit-details')}
          </Header>
          <Grid gridDefinition={[{colspan: 12}]}>
            <div>
            <SpaceBetween direction='horizontal' size='m'>
              <FormField label={bundle.getMessage('site')} stretch={true}>
                <div style={{width: 120}}>
                  <Select
                    data-testid='RequestAmazonAirSiteAccessSiteSelect'
                    filteringType='auto'
                    loadingText={bundle.getMessage('loading-sites')}
                    onChange={event => {
                      setVisitDetails({...visitDetails, site: event.detail.selectedOption});
                      props.setVisitDetailsCallback({...visitDetails, site: event.detail.selectedOption});
                    }}
                    options={
                      airSites?.map(s => {
                        return {label: s.SiteCode, value: s.SiteCode};
                      })
                    }
                    placeholder={bundle.getMessage('select-a-site')}
                    ref={siteInputRef}
                    selectedOption={visitDetails.site}
                    statusType={airSitesQueryState?.fetchStatus == 'fetching' ? 'loading' : 'finished'}
                  />
                </div>
              </FormField>
              <FormField label={bundle.getMessage('days-site-time')}>
                <DateRangePicker
                  data-testid='RequestAmazonAirSiteAccessVisitDateRangePicker'
                  dateOnly
                  hideTimeOffset
                  i18nStrings={{
                    todayAriaLabel: bundle.getMessage('today'),
                    nextMonthAriaLabel: bundle.getMessage('next-month'),
                    previousMonthAriaLabel: bundle.getMessage('previous-month'),
                    customRelativeRangeDurationLabel: bundle.getMessage('duration'),
                    customRelativeRangeDurationPlaceholder: bundle.getMessage('enter-duration'),
                    customRelativeRangeOptionLabel: bundle.getMessage('custom-range'),
                    customRelativeRangeOptionDescription: bundle.getMessage('set-a-custom-range-in-the-future'),
                    customRelativeRangeUnitLabel: bundle.getMessage('unit-of-time'),
                    formatRelativeRange: (rr): string => {
                      return(
                        `${bundle.getMessage('next')} ${Math.abs(rr.amount)} ${bundle.formatMessage(rr.unit, { amount: rr.amount.toString() })}`
                      );
                    },
                    formatUnit: (e, t) => (1 === t ? e : `${bundle.formatMessage(e, { amount: t.toString() })}`),
                  dateTimeConstraintText: '',
                    relativeModeTitle: bundle.getMessage('relative-range'),
                    absoluteModeTitle: bundle.getMessage('absolute-range'),
                    relativeRangeSelectionHeading: bundle.getMessage('choose-a-range'),
                    startDateLabel: bundle.getMessage('start-date'),
                    endDateLabel: bundle.getMessage('end-date'),
                    startTimeLabel: bundle.getMessage('start-time'),
                    endTimeLabel: bundle.getMessage('end-time'),
                    clearButtonLabel: bundle.getMessage('clear-and-dismiss'),
                    cancelButtonLabel: bundle.getMessage('cancel'),
                    applyButtonLabel: bundle.getMessage('apply'),
                  }}
                  invalid={invalidStartDate || invalidEndDate}
                  isDateEnabled={date => {
                    const [month, day, year] = [
                      (date.getMonth() + 1).toString().padStart(2, '0'),
                      date.getDate().toString().padStart(2, '0'),
                      date.getFullYear(),
                    ];
                    const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
                    return isFromDateValid(convertedDate, hoursExtFromDate);
                  }}
                  isValidRange={(value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
                    const result: DateRangePickerProps.ValidationResult = { valid: true };
                    return result;
                  }}
                  onChange={({ detail }) => dateRangeInputChangeHandler(detail.value)}
                  placeholder='YYYY/MM/DD'
                  rangeSelectorMode='absolute-only'
                  relativeOptions={[
                    { key: 'tomorrow', amount: -1, unit: 'day', type: 'relative' },
                    { key: 'next-7-days', amount: -7, unit: 'day', type: 'relative' },
                    { key: 'next-14-days', amount: -14, unit: 'day', type: 'relative' },
                    { key: 'next-365-days', amount: -365, unit: 'day', type: 'relative' },
                  ]}
                  value={visitDateRange}
                />
              </FormField>
              <FormField label={bundle.getMessage('from-time')}>
                <div style={{width: 100}}>
                  <TimeInput
                    data-testid='RequestAmazonAirSiteAccessVisitStartTimeInput'
                    format='hh:mm'
                    invalid={invalidStartDate}
                    onChange={event => startTimeInputChangeHandler(event.detail.value)}
                    placeholder='hh:mm'
                    value={visitDetails.startTime || ''}
                  />
                </div>
              </FormField>
              <FormField label={bundle.getMessage('to-time')}>
                <div style={{width: 100}}>
                  <TimeInput
                    data-testid='RequestAmazonAirSiteAccessVisitEndTimeInput'
                    format='hh:mm'
                    onChange={event => {
                      setVisitDetails({...visitDetails, endTime: event.detail.value});
                      props.setVisitDetailsCallback({...visitDetails, endTime: event.detail.value});
                    }}
                    placeholder='hh:mm'
                    value={visitDetails.endTime || ''}
                  />
                </div>
              </FormField>
            </SpaceBetween>
            </div>
          </Grid>
          </>
        }
        >
        <OnboardedVisitors
          autoFocus={false}
          cardholdersQuickLinks={props.cardholdersQuickLinks}
          checkForMissingSiteAccess={false}
          darkMode={props.darkMode}
          escorts={onboardedVisitors}
          headerMessage={HeaderMessages.ONBOARDED_VISITORS}
          username={props.username}
          setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
          setEscortsCallback={props.setOnboardedVisitorsCallback}
          setEscortMissingActiveBadgeCallback={setEscortMissingActiveBadge}
          setEscortMissingSiteAccessCallback={() => null}
          siteCode={visitDetails.site?.value || null}
        />
        <ExternalVisitors
          visitors={externalVisitors}
          setVisitorsCallback={async (visitors: TVisitor[]) => {
            await props.setExternalVisitorsCallback(visitors);
            if (!visitDetails.reason && visitors.length > 0) reasonForVisitRef.current?.focus();
          }}
        />
        {tooManyVisitorsPerEscortFlashbar && <FlashMessageTooManyVisitorsPerEscort />}
        <Container
          header={
            <Header
              variant='h2'
            >
             {bundle.getMessage('justification')}
            </Header>}
          variant='stacked'
        >
          <FormField label={bundle.getMessage('reason-for-visit')}>
            <Textarea
              data-testid='RequestAmazonAirSiteAccessReasonForVisitTextarea'
              onChange={event => {
                setVisitDetails({...visitDetails, reason: event.detail.value});
                props.setVisitDetailsCallback({...visitDetails, reason: event.detail.value});
              }}
              placeholder={bundle.getMessage('reason-for-visit-placeholder')}
              ref={reasonForVisitRef}
              value={visitDetails.reason || ''}
            />
          </FormField>
        </Container>
      </Container>
    </Container>
    </div>
  );
}