import * as APIt from 'src/API';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import {
  createVisitorAsset as createVisitorAssetMutation,
  deleteVisitorAsset as deleteVisitorAssetMutation,
} from "src/graphql/mutations";
import { CreateVisitorAssetMutation } from 'src/API';
import { auditDecorator } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export let createVisitorAsset = async (visitorAsset: APIt.VisitorAsset): Promise<APIt.VisitorAsset | null> => {
  debug(`createVisitorAsset() visitorAsset is ${JSON.stringify(visitorAsset)}`);

  const input = {
    approval_status_id: visitorAsset.approval_status_id,
    asset_type_id: visitorAsset.asset_type_id,
    created_by: visitorAsset.created_by,
    description: visitorAsset.description,
    end_date: visitorAsset.end_date,
    make: visitorAsset.make,
    model: visitorAsset.model,
    permanent_flag: visitorAsset.permanent_flag,
    serial_num: visitorAsset.serial_num,
    site_id: visitorAsset.site_id,
    site_source_system_id: visitorAsset.site_source_system_id,
    start_date: visitorAsset.start_date,
    visitor_id: visitorAsset.visitor_id,
  };
  debug(`createVisitorAsset() input is ${JSON.stringify(input)}`);

  let createdVisitorAsset: APIt.VisitorAsset | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createVisitorAssetMutation,
      {
        input: input
      })) as GraphQLResult<CreateVisitorAssetMutation>;
    if (response && response.data && response.data.createVisitorAsset) {
      createdVisitorAsset = response.data.createVisitorAsset;
      if (!createdVisitorAsset) throw new Error('visitor asset not created');
    }
  } catch(error) {
    console.error(error);
    throw error;
  }

  return createdVisitorAsset;
};
createVisitorAsset = auditDecorator('createVisitorAsset', createVisitorAsset);