import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from 'src/API';
import { auditDecorator, createEscort, getLookupTypeValueId } from 'src/components/utils';
import { ApprovalStatus, LookupTypes } from 'src/constants/Constants';
import { createRequest, createVisitor, deleteRequest } from '../utils';
import { SNSPublishVisitCreated } from 'src/graphql/queries';
import { ICreateVisitorInputWithAssets } from 'src/interfaces';
import { createVisitorAsset } from '../Assets/utils';
import { debug } from 'src/utils/commonUtils';

export interface ISubmitVisitorsRequest {
  escorts: APIt.CreateRequestEscortInput[],
  request: APIt.CreateRequestInput,
  visitors: ICreateVisitorInputWithAssets[],
}

export let submitVisitorRequest = async (visitorRequest: ISubmitVisitorsRequest) => {
  debug(`submitVisitorRequest() visitorRequest is ${JSON.stringify(visitorRequest)}`);

  let createdRequest: APIt.Request | null = null;

  try {
    createdRequest = await createRequest(visitorRequest.request);
    debug(`submitVisitorRequest() createdRequest is ${JSON.stringify(createdRequest)}`);
    const approverSourceSystemId = await getLookupTypeValueId(LookupTypes.ApproverSourceSystem, 'PACS');
    debug(`submitVisitorRequest() approverSourceSystemId is ${approverSourceSystemId}`);
    if (!approverSourceSystemId) throw new Error(`Unable to locate approver source system id value for PACS`);
    const statusCodeId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus, ApprovalStatus.PendingApproval);
    debug(`submitVisitorRequest() statusCodeId is ${statusCodeId}`);
    if (!statusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);
    for (let visitor of visitorRequest.visitors) {
      debug(`submitVisitorRequest() visitor is ${JSON.stringify(visitor)}`);
      const createVisitorInput = {...visitor};
      delete createVisitorInput.assets;
      debug(`submitVisitorRequest() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
      const createdVisitor = await createVisitor(createVisitorInput);
      if (!visitor) throw new Error('Unable to create visitor');
      if (visitor.assets) {
        for (let asset of visitor.assets) {
          const createdVisitorAsset = await createVisitorAsset(asset);
        };
      }
    };
    for (let escort of visitorRequest.escorts) {
      debug(`submitVisitorRequest() escort is ${JSON.stringify(escort)}`);
      const createdEscort = await createEscort(escort);
      if (!createdEscort) throw new Error('Unable to create escort');
    };
  } catch(error) {
    if (createdRequest) await deleteRequest({id: createdRequest.id, updated_by: createdRequest.created_by});
    debug(`submitVisitorRequest() error is ${JSON.stringify(error)}`);
    throw(error);
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishVisitCreated,
      {
        id: createdRequest!.id
      })) as GraphQLResult<APIt.SNSPublishVisitCreatedQuery>;
    if (response && response.data && response.data.SNSPublishVisitCreated) {
      debug(`submitAccessRequest() SNSPublishVisitCreated response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`submitAccessRequest() SNSPublishVisitCreated error is ${JSON.stringify(error)}`);
  }

};
submitVisitorRequest = auditDecorator('submitVisitorRequest', submitVisitorRequest);