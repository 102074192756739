import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { debug } from 'src/utils/commonUtils';

export interface IEditVisitorProps{
  assignStickerNum: Function;
  cancelAssignStickerNum: Function;
  errorText: string;
}

export default function AssignStickerNum(props: IEditVisitorProps) {
  debug(`AssignStickerNum() props is ${JSON.stringify(props)}`);

  const [assigningStickerNum, setAssigningStickerNum] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>(props.errorText);
  const [stickerNum, setStickerNum] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAssets.VisitorSiteAssets.AssignStickerNum');

  const stickerNumRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    stickerNumRef.current?.focus();
  }, [stickerNum]);

  useEffect(() => {
    if (errorText != '') setAssigningStickerNum(false);
    setErrorText(props.errorText);
  }, [props.errorText]);
  
  if (isBundleLoading) return <Spinner/>;

  return(
      <form onSubmit={async e =>
        {
          e.preventDefault();
          setAssigningStickerNum(true);
          await props.assignStickerNum(stickerNum);
          setAssigningStickerNum(false);
        }}
      >
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                data-testid='EditVisitorCancelButton'
                formAction='none'
                variant='normal'
                onClick={() => props.cancelAssignStickerNum()}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='EditVisitorEditVisitorButton'
                loading={assigningStickerNum}
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          <FormField label={bundle.getMessage('asset-sticker-num')}>
            <Input
              autoFocus
              data-testid='VisitorSiteAssetsAssignStickerNumInput'
              onChange={({detail}) => setStickerNum(detail.value)}
              ref={stickerNumRef}
              value={stickerNum}
            />
          </FormField>
        </Form>
      </form>);
}