import React, { useEffect, useState } from 'react';
import {
  Button,
  Cards,
  Container,
  Form,
  ProgressBar,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import * as APIt from '../../../API';
import CheckOut from './CheckOut';
import { debug } from 'src/utils/commonUtils';

export interface IBulkCheckOutProps{
  airSite: boolean;
  bulkCheckOutCallback: Function;
  cancelBulkCheckOutCallback: Function;
  siteCode: string;
  submissionProcessing: boolean;
  username: string;
  visitorRequests: APIt.VisitorRequest[];
}

type TCheckOutCardItem = {
  checkOut?: JSX.Element,
}

export default function BulkCheckOut(props: IBulkCheckOutProps) {
  debug(`BulkCheckOut() props is ${JSON.stringify(props)}`);

  const [checkingOut, setCheckingOut] = useState<boolean>(false);
  const [checkOutProgress, setCheckOutProgress] = useState<number | null>(null);
  const [errorText, setErrorText] = useState<string>('');
  const [visitorRequests, setVisitorRequests] = useState<APIt.VisitorRequest[]>(props.visitorRequests);
  const [visitorRequestsCheckedOut, setVisitorRequestsCheckedOut] = useState<APIt.VisitorRequest[]>([]);
  const [visitorRequestsBadgesVerified, setVisitorRequestsBadgesVerified] = useState<APIt.VisitorRequest[]>([]);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.BulkCheckOut');

  const bulkCheckOut = () => {
    setCheckingOut(true);
    setCheckOutProgress(0);
    setErrorText('');
  };

  const badgeVerified = (visitorRequest: APIt.VisitorRequest) => {
    debug(`BulkCheckOut() badgeVerified() visitorRequest is ${JSON.stringify(visitorRequest)}`);
    setVisitorRequestsBadgesVerified(prev => {
      debug(`BulkCheckOut() badgeVerified() prev is ${JSON.stringify(prev)}`);
      const visitors = [...prev.filter(vr => vr.visitor_id !== visitorRequest.visitor_id), visitorRequest];
      debug(`BulkCheckOut() badgeVerified() visitors is ${JSON.stringify(visitors)}`);
      return visitors;
    });
  };

  const badgeUnVerified = (visitorRequest: APIt.VisitorRequest) => {
    debug(`BulkCheckOut() badgeUnVerified() visitorRequest is ${JSON.stringify(visitorRequest)}`);
    setVisitorRequestsBadgesVerified(prev => {
      debug(`BulkCheckOut() badgeUnVerified() prev is ${JSON.stringify(prev)}`);
      const visitors = [...prev.filter(vr => vr.visitor_id !== visitorRequest.visitor_id)];
      debug(`BulkCheckOut() badgeUnVerified() visitors is ${JSON.stringify(visitors)}`);
      return visitors;
    });
  };

  const checkedOut = (visitorRequest: APIt.VisitorRequest) => {
    debug(`BulkCheckOut() checkedOut() visitorRequest is ${JSON.stringify(visitorRequest)}`);
    setVisitorRequestsCheckedOut(prev => {
      return [...prev, visitorRequest];
    });
  };

  useEffect(() => {
    if (!checkingOut) return;
    const percentComplete = visitorRequestsCheckedOut.length/props.visitorRequests.length*100;
    setCheckOutProgress(percentComplete);
    if (percentComplete >= 100) {
      setCheckingOut(false);
      props.bulkCheckOutCallback();
    }
  }, [visitorRequestsCheckedOut, checkingOut]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {checkOutProgress !== null
    &&
    <ProgressBar
      value={checkOutProgress}
      description={bundle.getMessage('check-out-progress')}
      label={bundle.getMessage('progress')}
    />}
    <form onSubmit={e => e.preventDefault()}>
      <SpaceBetween direction='vertical' size='s'>
        <Form
          actions={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button
                formAction='none'
                variant='normal'
                onClick={() => props.cancelBulkCheckOutCallback()}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                disabled={!(visitorRequestsBadgesVerified.length === visitorRequests.length)}
                variant='primary'
                loading={checkingOut}
                onClick={bulkCheckOut}
              >
                {bundle.getMessage('check-out')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        />
        <SpaceBetween direction='vertical' size='s'>
          <Cards
            items={
              // visitorRequests.filter(vr => !visitorRequestsCheckedOut.find(vrco => vrco.visitor_id === vr.visitor_id)).map((vr) => {
              visitorRequests.map((vr) => {
                return(
                  <Container
                    key={vr.visitor_id}
                  >
                    <CheckOut
                      key={vr.visitor_id}
                      airSite={props.airSite}
                      autoCheckOut={checkOutProgress !== null}
                      badgeVerifiedCallback={badgeVerified}
                      badgeUnVerifiedCallback={badgeUnVerified}
                      bulkCheckOutCallback={checkedOut}
                      cancelCheckOutCallback={undefined}
                      checkOutCallback={undefined}
                      siteCode={props.siteCode}
                      submissionProcessing={false}
                      username={props.username}
                      visitorRequest={vr}
                    />
                  </Container>
                );
              })
            }
           cardDefinition={{
              sections: [
                {
                  content: e => e
                },
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 1000, cards: 2 },
              { minWidth: 1500, cards: 3 },
              { minWidth: 2000, cards: 4 },
            ]}
            loading={false}
          />
        </SpaceBetween>
      </SpaceBetween>
    </form>
    </>
  );
}