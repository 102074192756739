import React, { useEffect, useState } from 'react';
import { Box, Button, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './details-table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { RefreshCallBackInterface } from 'src/interfaces';
import { debug } from 'src/utils/commonUtils';

export interface IPendingAccessRequestDetailsTablePanelProps {
  isLoading: boolean;
  refreshCallback: RefreshCallBackInterface;
  visitorAccessLevelApprovals: APIt.VisitorAccessLevelApproval[];
}

export default function PendingAccessRequestDetailsTablePanel(props: IPendingAccessRequestDetailsTablePanelProps) {
  debug(`PendingAccessRequestDetailsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAccessLevelApproval[]>(props.visitorAccessLevelApprovals);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.PendingAccessRequests.DetailsTablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading && !props.isLoading ? bundle.getMessage('no-access-request-approvers-found') : 'No Access Request Approvals Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const [hideTable, setHideTable] = useState<boolean>(false);

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await props.refreshCallback();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  useEffect(() => {
    setAllItems(props.visitorAccessLevelApprovals);
    return () => {
      setAllItems([]);
    }
  }, [props.visitorAccessLevelApprovals]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-access-request-approvers')}
            filteringPlaceholder={bundle.getMessage('find-access-request-approvers')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button loading={refreshing} key="refreshBtn" onClick={refreshBtnClickHandler} iconName='refresh'>{bundle.getMessage('refresh')}</Button>
              </SpaceBetween>
              </>
            }
            description={
              <Box float='left'>{bundle.getMessage('access-request-approvers-description')}</Box>
            }
          >
            {bundle.getMessage('access-request-approvers')}
          </Header>
        }
        items={items}
        loading={props.isLoading}
        loadingText={bundle.getMessage('loading-access-request-approvers')}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    </>
  );
}
