export enum Actions {
  CheckIn = 'Check In',
  CheckOut = 'Check Out',
};

export enum ActionFromLinkStatus {
  AlreadyApproved = 'already-approved',
  AlreadyCancelled = 'already-approved',
  AlreadyDenied = 'already-denied',
  Approved = 'approved',
  Checking = 'checking',
  Denied = 'denied',
  Error = 'error',
  InProgress = 'in-progress',
  NotFound = 'not-found',
  WaitingForInput = 'waiting-for-input',
};

export const AmazonOrangeColor = '#ec7211';

export enum ApprovalStatus {
  Activated = 'Activated',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Denied = 'Denied',
  Deactivated = 'Deactivated',
  PendingApproval = 'Pending Approval',
  Submitted = 'Submitted',
};

export const BADGE_PHOTO_BY_EMP_ID_URL = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?employeeid=';
export const BADGE_PHOTO_BY_UID_URL = 'https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=';

export enum BadgeStatus {
  Active = 1,
};

export enum BadgeTypes {
  VendorLoanerDayPass = 33,
};

export const BLANK = '&#8203;';

export enum CheckInSteps {
  AssigningBadge = 'assigning-badge',
  AssigningAccessLevels = 'assigning-access-levels',
  HandlingNonWelcomeVisitor = 'handling-non-welcome-visitor-badge-holder',
  Initializing = 'initializing',
  NotifyingOthers = 'notifying-others',
  RecordingAction = 'recording-action',
  UpdatingEscorts = 'updating-escorts',
  UpdatingVisitor = 'updating-visitor',
  VerifyingCardholder = 'verifying-cardholder',
};

export enum CheckOutSteps {
  Initializing = 'initializing',
  NotifyingOthers = 'notifying-others',
  RecordingAction = 'recording-action',
  UnassigningAccessLevels = 'unassigning-access-levels',
  UnassigningBadge = 'unassigning-badge',
  UpdatingVisitor = 'updating-visitor',
};

export const EmailDomains = [
  'amazon.com',
  'amazon.co.jp',
];

export const DEFAULT_LOCALE = navigator.language || 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export const DefaultValidFromTime = '08:00';
export const DefaultValidThroughTime = '17:00';

export const HOW_MANY_TRACKER = {
  WebsiteAccess_URL_BETA: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=5bfcb583-f45c-fda5-ccec-42b4c7d9a11e',
  WebsiteAccess_URL_GAMMA: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=63cf9cb7-e8af-93b2-298d-a7bd7153cdef',
  WebsiteAccess_URL_PROD: 'https://0s62bmu3aj.execute-api.us-east-1.amazonaws.com/PROD/pixel/tracker?PixelID=15fce6bb-6d0a-5f14-52ef-2a50ab2061bf',
};

export enum Images {
  home = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZ cwAACxIAAAsSAdLdfvwAAAAHdElNRQfnCBkOKRZg+NX+AAABIHpUWHRSYXcgcHJvZmlsZSB0eXBl IHhtcAAAKJF1UktyxSAM23OKHoHIxibHSR+w60yXPX5lkr6keS2e/GwjSyLp6+MzvcUSSJKHV9m8 WvNszYqrLcjxbQ/rLlGTBthiasNgRbY9/+weAHI6YZh8jy2laisZWcWGcyOyGLrkeUF63pAjSAEE N9kKVJPabf5eDA7VlZFl48zhc6E7m9DnCMeQRdYIjCRZwAR4bzsIn+IrYUnbK1oMiPLJ5c5I1Upy MWFindJWutDJ8GigL+BsOhEMacQvoGt4TRdRAWR/yZqc+mk33+mfNQo+9oS0ZZo4Xqk/BfwM2DSc Wa0csg8F0unUI52o/zfevcG4urObk67uXI/2bI7DVYvazL78dVFJ30ktmxa/6wauAAAAAW9yTlQB z6J3mgAAAHpJREFUOMvFkEsOgCAMRKfGA/X+l+IIz4VgNYJAonEWpJ95Lan0jnB8zp5IwwhOAhhE sj3lt4ccdo9oyH7PuvYuUm82kfasauf5t5Vu7x4FiQLwfHEcQJJsBySzwMN4rZpJiybVAMxi9hAw veFPYC3B+Zihe3V6w/faAALJpfwQe37YAAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoA BQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAAEgAAAAB AAAASAAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAAARDxiuAAAA JXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTI1VDE0OjQxOjIyKzAwOjAwRTce7gAAACV0RVh0ZGF0 ZTptb2RpZnkAMjAyMy0wOC0yNVQxNDo0MToyMiswMDowMDRqplIAAAAodEVYdGRhdGU6dGltZXN0 YW1wADIwMjMtMDgtMjVUMTQ6NDE6MjIrMDA6MDBjf4eNAAAAEXRFWHRleGlmOkNvbG9yU3BhY2UA MQ+bAkkAAAASdEVYdGV4aWY6RXhpZk9mZnNldAA5MFmM3psAAAAXdEVYdGV4aWY6UGl4ZWxYRGlt ZW5zaW9uADI0eNTzYwAAABd0RVh0ZXhpZjpQaXhlbFlEaW1lbnNpb24AMjSlQirmAAAAEnRFWHR0 aWZmOk9yaWVudGF0aW9uADG3q/w7AAAAAElFTkSuQmCC',
}

export interface ILanguage {
  id: string;
  text: string;
}
export const Languages: ILanguage[] = [
  {
    id: "en-US",
    text: "English",
  },
  {
    id: "fr-FR",
    text: "French",
  },
  {
    id: "de-DE",
    text: "German",
  },
  {
    id: "es-ES",
    text: "Spanish",
  },
  {
    id: "pt-PT",
    text: "Portuguese",
  },
  {
    id: "it-IT",
    text: "Italian",
  },
  {
    id: "hi-IN",
    text: "Hindi",
  },
  {
    id: "ja-JP",
    text: "Japanese",
  },
  {
    id: "ar-AE",
    text: "Arabic (U.A.E.)",
  },
  {
    id: "ar-SA",
    text: "Arabic (Saudi Arabia)",
  },
  {
    id: "cs-CZ",
    text: "Czech (Czech Republic)",
  },
  {
    id: "en-AU",
    text: "English (Australia)",
  },
  {
    id: "en-CA",
    text: "English (Canada)",
  },
  {
    id: "en-GB",
    text: "English (United Kingdom)",
  },
  {
    id: "en-SG",
    text: "English (Singapore)",
  },
  {
    id: "es-MX",
    text: "Spanish (Mexico)",
  },
  {
    id: "fr-CA",
    text: "French (Canada)",
  },
  {
    id: "nl-NL",
    text: "Dutch (Netherlands)",
  },
  {
    id: "pl-PL",
    text: "Polish (Poland)",
  },
  {
    id: "sk-SK",
    text: "Slovak (Slovakia)",
  },
  {
    id: "tr-TR",
    text: "Turkish (Turkey)",
  },
  {
    id: "zh-CN",
    text: "Chinese (S)",
  },
  {
    id: "zh-TW",
    text: "Chinese (T)",
  },
];

import { LocalizationContextBuilder } from "@amzn/arb-tools";

export enum LookupTypes {
  AccessLevelApprovalStatus = 'Access Level Approval Status',
  AccessLevelDenial = 'Access Level Denial',
  AccessLevelSourceSystem = 'Access Level Source System',
  AccessLevels = 'Access Levels',
  Actions = 'Actions',
  ApproverSourceSystem = 'Approver Source System',
  AssetApprovalStatus = 'Asset Approval Status',
  AssetDenial = 'Asset Denial',
  AssetType = 'Asset Type',
  BadgeSourceSystem = 'Badge Source System',
  DelegationType = 'Delegation Type',
  PersonSourceSystem = 'Person Source System',
  RequesStatus = 'Request Status',
  RequestorSourceSystem = 'Requestor Source System',
  SiteSourceSystem = 'Site Source System',
  VisitorRequestStatus = 'Visitor Request Status',
  VisitorType = 'Visitor Type',
  WelcomeApplicationSettings = 'Welcome Application Settings',
};

export enum WelcomeApplicationSettings {
  AccessLevelReminderThrottleHours = 'Access Level Reminder Throttle Hours',
  AdminGroups = 'Admin Groups',
  AirVisitMaxDays = 'Air Visit Max Days',
  AssetAutoApprovalExcludedJobLevels = 'Asset Auto Approval Excluded Job Levels',
  AssetAutoApprovalMinimumJobLevel = 'Asset Auto Approval Minimum Job Level',
  DisableNewVendorDayPassRequests = 'Disable New Vendor Day Pass Requests',
  HoursExtensionAccessLevelActivation = 'Hours Extension - Access Level Activation',
  HoursExtensionAccessLevelDeactivation = 'Hours Extension - Access Level Deactivation',
  HoursExtensionBadgeActivate = 'Hours Extension - Badge Activate',
  HoursExtensionBadgeDeactivation = 'Hours Extension - Badge Deactivation',
  HoursExtensionFromDateFormValidation = 'Hours Extension - From Date Form Validation',
  InvalidVisitorEmailDomainsRegEx = 'Invalid Visitor Email Domains RegEx',
  MaxVisitorsPerEscort = 'Max Visitors Per Escort',
  VendorDayPassMaxDays = 'Vendor Day Pass Max Days',
  VendorPassEliminationDate = 'Vendor Pass Elimination Date',
  VisitMaxDays = 'Visit Max Days',
}

export enum Modes {
  MANAGEMENT = 'management',
  SELF_SERVICE = 'selfService',
}

interface IPage {
  admin?: boolean;
  code: string;
  name: string;
  order: number;
  route: string;
}

export const ManagementPages: IPage[] = [
  {
    admin: true,
    code: 'administration',
    name: 'Administration',
    order: 6,
    route: '/management/administration',
  },
  {
    code: 'home',
    name: 'Home',
    order: 1,
    route: '/management/home',
  },
  {
    code: 'manage-access-requests',
    name: 'Manage Access Requests',
    order: 2,
    route: '/management/manageaccessrequests',
  },
  {
    code: 'manage-assets',
    name: 'Manage Assets',
    order: 4,
    route: '/management/manageassets',
  },
  {
    code: 'manage-visitors',
    name: 'Manage Visitors',
    order: 3,
    route: '/management/managevisitors',
  },
  {
    admin: true,
    code: 'support',
    name: 'Support',
    order: 6,
    route: '/management/support',
  },
];

export enum NavigationTypes {
  TABS = 'tabs',
  SIDE_MENU = 'side-menu',
}

export const ProdDomain = 'welcome.gso.amazon.dev';

export enum RequestStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Denied = 'Denied',
  PendingApproval = 'Pending Approval',
};

export const Regions = ['AMER','APAC','EMEA'];

export const SelfServicePages: IPage[] = [
  {
    code: 'home',
    name: 'Home',
    route: '/selfservice/home',
    order: 1
  },
  {
    code: 'assets',
    name: 'Assets',
    route: '/selfservice/viewmyassets',
    order: 6
  },
  {
    code: 'pending-access-requests',
    name: 'Pending Access Requests',
    route: '/selfservice/pendingaccessrequests',
    order: 3
  },
  {
    code: 'pending-visitor-requests',
    name: 'Pending Visitor Requests',
    route: '/selfservice/pendingvisitorrequests',
    order: 5
  },
  {
    code: 'register-a-visitor',
    name: 'Register A Visitor',
    route: '/selfservice/registeravisitor',
    order: 4
  },
  {
    code: 'request-access',
    name: 'Request Access',
    route: '/selfservice/requestaccess',
    order: 2
  },
  {
    code: 'vendor-day-pass-request',
    name: 'Vendor Day Pass Request',
    route: '/selfservice/vendordaypassrequest',
    order: 7
  },
  {
    code: 'view-access',
    name: 'View Access',
    route: '/selfservice/viewmyaccess',
    order: 8
  },
  {
    code: 'visit-amazon-air-site',
    name: 'Visit Amazon Air Site',
    route: '/selfservice/visitamazonairsite',
    order: 9
  },
  {
    code: 'delegation',
    name: 'Delegation',
    route: '/selfservice/viewership',
    order: 10
  }
];

export enum SIGInfraAPIv2Methods {
  GetADGroupsMembersIncludingNesting = 'getadgroupsmembersncludingnesting',
};

export const Statuses = [
  { label: 'Masked', value: 'Masked' },
  { label: 'Unmasked', value: 'Unmasked' },
];

export enum UnicornPACSAPIv2Priority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
};

export enum UserActionNames {
  AuthenticateUser = "Authenticate User",
  AuthenticateUserError = "Error: Authenticate User",
  CreateUserPreferences = "Create User Preferences",
  CreateUserPreferencesError = "Error: Create User Preferences",
  FetchUserPreferences = "Fetch User Preferences",
  FetchUserPreferencesError = "Error: Fetch User Preferences",
  SelectSite = "Select Site",
  SelectSiteError = "Error: Select Site",
  UpdateUserPreferences = "Update User Preferences",
};

export const URLS = {
  AmazonVisits: "https://visits.acs.amazon.dev/",
  Contact: 'https://w.amazon.com/index.php/SIDE#HContactus',
  ContactUs: 'https://river.amazon.com/?org=AccSys&q0=21f0c4b7-aef7-40a1-bbbb-afc2c82801c6',
  GSOGlobalSecuritySystemStandard: 'https://policy.fintech.amazon.dev/docs/68400/publication#11',
  GSOSecuritySystemsStandardGlobal: 'https://policy.a2z.com/docs/68400/publication',
  GSOVisitorStandardGlobal: 'https://policy.a2z.com/docs/25853/publication',
  GuardianHub: 'https://guardian.gso.amazon.dev/',
  ReportABug: 'https://t.corp.amazon.com/create/templates/5b7b9362-5e38-428a-b0d3-faa1446bc04e',
  UserGuide: 'https://w.amazon.com/bin/view/AccSys/Welcome/',
  VendorDayPassDeprecationWiki: 'https://w.amazon.com/bin/view/AccSys/Welcome/VendorDayPassDeprecation/',
};

export enum AssetApprovalStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Denied = 'Denied',
  PendingApproval = 'Pending Approval',
};

export enum VisitorRequestStatus {
  CheckedIn = 'Checked In',
  CheckedOut = 'Checked Out',
  Denied = 'Denied',
  PendingApproval = 'Pending Approval',
  ScheduledVisit = 'Scheduled Visit',
};

export const visitorStatusFilterStatuses = [
  {
    label: VisitorRequestStatus.CheckedIn,
    value: VisitorRequestStatus.CheckedIn },
  {
    label: VisitorRequestStatus.CheckedOut,
    value: VisitorRequestStatus.CheckedOut
  },
  {
    label: VisitorRequestStatus.PendingApproval,
    value: VisitorRequestStatus.PendingApproval },
  {
    label: VisitorRequestStatus.ScheduledVisit,
    value: VisitorRequestStatus.ScheduledVisit
  },
];

export enum VisitorTypes {
  Employee = 'Employee',
  UnescortedVendor = 'Unescorted Vendor',
  Visitor = 'Visitor',
};

/**
 * Default application IDs for EV3 badges. The app IDs
 * are set by SafeTrust and will differ for iClass vs.
 * Desfire cards.
 * 
 * https://sim.amazon.com/issues/SIDE-44429
*/
export const EV3_DESFIRE_ACCSYS_APP_ID = '560001';
export const EV3_ICLASS_ACCSYS_APP_ID = 'app';