import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from '../../../API';
import { getAccessLevelsForPSID } from 'src/graphql/queries';
import { auditDecorator } from 'src/components/utils';
import { debug } from '../../../utils/commonUtils';

export interface IQueryAccessLevelsParams {
  employeeId: string;
  Orderby: string;
  OrderbyDirection: string;
  PageNumber: number;
  RecordsByPage: number;
}

export let queryAccessLevels = async (params: IQueryAccessLevelsParams): Promise<APIt.AccessLevel[]> => {
  debug(`queryAccessLevels() params is ${JSON.stringify(params)}`);

  let accessLevels: APIt.AccessLevel[] = [];
  let pageNumber = 0;

  while (true) {
    debug(`queryAccessLevels() pageNumber is ${pageNumber}`);
    try {
      const response = await API.graphql(graphqlOperation(getAccessLevelsForPSID,
        {
          input: {
            methodName: 'Welcome_GetAccessLevelsForPSID',
            params: {
              ...params,
              PageNumber: pageNumber++,
            },
          }
        })) as GraphQLResult<APIt.GetAccessLevelsForPSIDQuery>;
      debug(`queryAccessLevels() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.getAccessLevelsForPSID && response.data.getAccessLevelsForPSID.length > 0) {
        accessLevels.push(...(response.data.getAccessLevelsForPSID as APIt.AccessLevel[]));
        debug(`queryAccessLevels() accessLevels.length is ${accessLevels.length}`);
      } else {
        break;
      }
    } catch (e) {
      console.error(`queryAccessLevels(): exception is ${JSON.stringify(e)}`);
      throw e;
    }
  }

  debug(`queryAccessLevels() accessLevels is ${JSON.stringify(accessLevels)}`);
  debug(`queryAccessLevels() accessLevels.length is ${accessLevels.length}`);
  return(accessLevels);
};
queryAccessLevels = auditDecorator('queryAccessLevels', queryAccessLevels);