import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  CollectionPreferences,
  Container,
  FormField,
  Grid,
  Input,
  Modal,
  Pagination,
  RadioGroup,
  RadioGroupProps,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Table,
  TableProps,
  Textarea,
  TextFilter, 
  Toggle,
  ToggleProps} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryVisitorAssetApprovals, submitVisitorAssetActions } from './utils';
import SubmitActions from './SubmitActions';
import ManageVisitorAssetDetailsTablePanel from './DetailsTablePanel';
import { ApprovalStatus } from 'src/constants/Constants';
import VisitorSiteAsssetsTablePanel from './VisitorSiteAssets/TablePanel';
import { debug } from 'src/utils/commonUtils';

export enum Actions {
  approve = 'approve',
  deny = 'deny',
  wait = 'wait',
}

export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export interface VisitorAssetWithAction extends APIt.VisitorAsset {
  action?: RadioGroupProps.RadioButtonDefinition;
}

export interface ManageVisitorAssetsTablePanelPropsInterface {
  admin: boolean;
  isTableLoading: boolean;
  manageVisitorAssets: VisitorAssetWithAction[];
  manageAssetsQueryAdmin: boolean;
  pageSize: number;
  refreshCallback: RefreshCallBackInterface;
  setPageSizePrefCallback: Function;
  setSplitPanelCallback: Function;
  setSplitPanelOpenCallback: Function;
  setStatusFilterCallback: Function;
  siteCode: string;
  userCanManageVisitorsForSite: boolean;
  username: string;
}

export default function ManageVisitorAssetsTablePanel(props: ManageVisitorAssetsTablePanelPropsInterface ) {
  debug(`ManageVisitorAssetsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<VisitorAssetWithAction[]>(props.manageVisitorAssets);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [queryAllRequests, setQueryAllRequests] = useState<boolean>(false);
  const [queryAllPendingAssetsAdmin, setQueryAllPendingAssetsAdmin] = useState<boolean>(props.manageAssetsQueryAdmin);
  const [pageSize, setPageSize ] = useState<number>(props.pageSize);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<VisitorAssetWithAction | null>(null);
  const [showSubmissionFailureMessage, setShowSubmissionFailureMessage] = useState<boolean>(false);
  const [showSubmitActions, setShowSubmitActions] = useState<boolean>(false);
  const [showViewVisitorSiteAssets, setShowViewVisitorSiteAssets] = useState<boolean>(false);
  const [submissionFailureErrorText, setSubmissionFailureErrorText] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAssets.TablePanel');

  const queryClient = useQueryClient();

  const manageVisitorAssetsQueryState = queryClient.getQueryState(['manageAssets']);

  const visitorAssetApprovalsQuery = useQuery({
    queryKey: ['visitorAssetApprovals'],
    queryFn: () => queryVisitorAssetApprovals(selectedItem?.id),
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const visitorAssetSubmitActionsMutation = useMutation({
    mutationFn: submitVisitorAssetActions,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['manageAssets'] })
      queryClient.refetchQueries({ queryKey: ['manageAssets'] })
      setShowSubmitActions(false);
      setSelectedItem(null);
    },
    onError: (error: any) => {
      setShowSubmissionFailureMessage(true);
      debug(`visitorAssetSubmitActionsMutation() error is ${error} ${JSON.stringify(error)} typeof error is ${typeof error} error.message is ${error.message}`);
      (typeof error == 'object') ? setSubmissionFailureErrorText(error.message) : setSubmissionFailureErrorText(error.toString());
      setShowSubmitActions(false);
    }
  });

  const refresh = async () => {
    await visitorAssetApprovalsQuery.refetch();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-assets-found') : 'No Asset Requests Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await props.refreshCallback();
    setRefreshing(false);
  };

  const setAction = (item: VisitorAssetWithAction, value: any) => {
    const newItems = [...allItems];
    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].id == item.id) {
        newItems[i].action = { value, label: bundle.getMessage(value)}
        break;
      }
    };
    setAllItems(newItems);
  };

  const setPreferences = async (details: any) => {
    setPageSize(details.pageSize);
    props.setPageSizePrefCallback(details.pageSize);
  };

  const resetActions = () => {
    const newItems = [...allItems];
    for (let i = 0; i < newItems.length; i++) {
      delete newItems[i].action;
    };
    setAllItems(newItems);
  };

  const submitActions = async (actions: VisitorAssetWithAction[]) => {
    debug(`ManageVisitorAssetsTablePanel() submitActions() actions is ${JSON.stringify(actions)}`);
    visitorAssetSubmitActionsMutation.mutate({ actions, approverId: props.username });
    props.refreshCallback();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const Details = () => {
    return(
      <Grid gridDefinition={[{colspan: 2},{colspan: 2},{colspan: 2},{colspan: 2},{colspan: 4}]}>
        <FormField label={bundle.getMessage('requested-by')} >
          <Input value={selectedItem?.created_by || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('asset-serial-number')} >
          <Input value={selectedItem?.serial_num || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('make')} >
          <Input value={selectedItem?.make || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('model')} >
          <Input value={selectedItem?.model || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('asset-description')} >
          <Textarea value={selectedItem?.description || ''} disabled/>
        </FormField>
      </Grid>
    );
  };

  const columnDefinitions = (): TableProps.ColumnDefinition<VisitorAssetWithAction>[] => {
    return(
      [
        ...ColumnDefinitions,
        {
          id: 'action',
          header: bundle.getMessage('action'),
          cell: (item: VisitorAssetWithAction) =>
            <SpaceBetween size='s' direction='horizontal'>
              <RadioGroup
                items={
                  [ { value: Actions.approve, label: bundle.getMessage(Actions.approve), disabled: item.approval_status != ApprovalStatus.PendingApproval } ]
                }
                onChange={() => setAction(item, Actions.approve) }
                value={item.action?.value || null}
              />
              <RadioGroup
                items={
                  [ { value: Actions.deny, label: bundle.getMessage(Actions.deny), disabled: item.approval_status != ApprovalStatus.PendingApproval } ]
                }
                onChange={() => setAction(item, Actions.deny) }
                value={item.action?.value || null}
              />
              <RadioGroup
                items={
                  [ { value: Actions.wait, label: bundle.getMessage(Actions.wait), disabled: item.approval_status != ApprovalStatus.PendingApproval } ]
                }
                onChange={() => setAction(item, Actions.wait) }
                value={item.action?.value || Actions.wait }
              />
            </SpaceBetween>,
          minWidth: 175,
          sortingField: 'updated'
        },
      ]);
  }
  
  const listAllToggleHandler = async (detail: ToggleProps.ChangeDetail) => {
    setQueryAllPendingAssetsAdmin(false);
    setQueryAllRequests(detail.checked);
    await props.setStatusFilterCallback(detail.checked ? undefined : ApprovalStatus.PendingApproval);
  };
  
  const listAllPendingAssetsAdminToggleHandler = async (detail: ToggleProps.ChangeDetail) => {
    setQueryAllRequests(false);
    setQueryAllPendingAssetsAdmin(detail.checked);
    await props.setStatusFilterCallback(ApprovalStatus.PendingApproval, !queryAllPendingAssetsAdmin);
  };

  useEffect(() => {
    const currentItems = allItems;
    // add chosen action back
    for (let i = 0; i < props.manageVisitorAssets.length; i++) {
      for (let j = 0; j < currentItems.length; j++) {
        if (currentItems[j].action?.value && props.manageVisitorAssets[i].id == currentItems[j].id) {
          props.manageVisitorAssets[i].action =
            {
              value: currentItems[j].action!.value,
              label: !isBundleLoading ? bundle.getMessage(currentItems[j].action!.value) : currentItems[j].action!.value
            };
        }
      }
    }
    setSelectedItem(null);
    setAllItems(props.manageVisitorAssets);
  }, [props.manageVisitorAssets]);

  useEffect(() => {
    setQueryAllPendingAssetsAdmin(props.manageAssetsQueryAdmin);
  }, [props.manageAssetsQueryAdmin]);

  useEffect(() => {
    refresh();
  }, [selectedItem]);

  useEffect(() => {
    console.log(`ManageVisitorAssetsTablePanel() useEffect()[selectedItem] isBundleLoading is ${isBundleLoading} selectedItem is     ${JSON.stringify(selectedItem)}`);
    if (isBundleLoading || selectedItem == null) return;
    console.log(`ManageVisitorAssetsTablePanel() useEffect()[selectedItem] calling setSplitPanelCallack`);
    props.setSplitPanelCallback(
      <SplitPanel
        header={`${bundle.getMessage('asset-details')} ${bundle.getMessage('for')} ${selectedItem.person_id}, ${selectedItem.asset_type}`}
        hidePreferencesButton
        i18nStrings={{
          closeButtonAriaLabel: 'Close panel',
          openButtonAriaLabel: 'Open panel',
          preferencesConfirm: 'Confirm',
          preferencesCancel: 'Cancel',
          preferencesPositionBottom: 'Bottom',
          preferencesPositionDescription: 'Choose the default split panel position for the service.',
          preferencesPositionLabel: 'Split panel position',
          preferencesPositionSide: 'Side',
          preferencesTitle: 'Split panel preferences',
          resizeHandleAriaLabel: 'Resize split panel',
        }}
      >
    <Container
      key={'ManageVisitorAssetDetails'}
      children={
        <>
          <Details/>
          <br/>
          {selectedItem
          && visitorAssetApprovalsQuery.data
          &&
          <ManageVisitorAssetDetailsTablePanel
            isLoading={visitorAssetApprovalsQuery.isLoading || visitorAssetApprovalsQuery.isFetching}
            refreshCallback={refresh}
            visitorAssetApprovals={visitorAssetApprovalsQuery.data}
          />}
        </>
      }
    />
    </SplitPanel>);
    props.setSplitPanelOpenCallback(true);
  }, [visitorAssetApprovalsQuery.data]);

  const SubmissionFailure = () => {
    return(
        <Alert
          dismissible={true}
          onDismiss={() => setShowSubmissionFailureMessage(false)}
          statusIconAriaLabel='error'
          type='error'
          header={bundle.getMessage('submission-failure-message')}
        >
          {submissionFailureErrorText}
        </Alert> 
    );
  }

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {
      showSubmissionFailureMessage
      &&
      <SubmissionFailure/>
    }
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions()}
        filter={
          <Grid gridDefinition={[{colspan: 4},{colspan: 2}]}>
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-manage-assets')}
              filteringPlaceholder={bundle.getMessage('find-manage-assets')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
            <Toggle
              onChange={({ detail }) => listAllToggleHandler(detail)}
              checked={queryAllRequests}
            >
              {bundle.getMessage('list-all-assets')}
            </Toggle>
            {
              props.admin &&
              <Toggle
                onChange={({ detail }) => listAllPendingAssetsAdminToggleHandler(detail)}
                checked={queryAllPendingAssetsAdmin}
              >
                {bundle.getMessage('list-all-pending-assets')}
              </Toggle>
            }
          </Grid>
        }
        footer={
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                {
                  props.userCanManageVisitorsForSite &&
                  <Button
                    onClick={() => setShowViewVisitorSiteAssets(true)}
                    variant='normal'
                  >
                    {bundle.getMessage('view-site-assets')}
                  </Button>
                }
                <Button
                  disabled={!allItems.some(i => i.action?.value == Actions.approve || i.action?.value == Actions.deny)}
                  onClick={resetActions}
                  variant='normal'
                >
                  {bundle.getMessage('reset-actions')}
                </Button>
                <Button
                  disabled={!allItems.some(i => i.action?.value == Actions.approve || i.action?.value == Actions.deny)}
                  onClick={() => setShowSubmitActions(true)}
                  variant='primary'
                >
                  {bundle.getMessage('submit-actions')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <Button
                loading={refreshing}
                key="refreshBtn"
                onClick={refreshBtnClickHandler}
                iconName='refresh'
              >
                {bundle.getMessage('refresh')}
              </Button>
            }
          >
            {bundle.getMessage('manage-assets')}
          </Header>
        }
        items={items}
        loading={manageVisitorAssetsQueryState?.fetchStatus == 'fetching'}
        empty={manageVisitorAssetsQueryState?.status == 'error'
          ?
            <Alert type='error'>
              `${bundle.getMessage('query-failure')} (${typeof manageVisitorAssetsQueryState?.error === 'object'
                 ? JSON.stringify(manageVisitorAssetsQueryState.error)
                 : manageVisitorAssetsQueryState?.error})`
            </Alert>
          : bundle.getMessage('no-assets-found')
        }
        loadingText={bundle.getMessage('loading-manage-assets')}
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0]) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            title={bundle.getMessage('preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              pageSize: pageSize,
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' },
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' }
              ],
            }}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItem ? [selectedItem] : []}
        selectionType='single'
        stickyHeader={true}
        trackBy='id'
        visibleColumns={columnDefinitions().map(cd => {
          if (cd.id && cd.id !== 'requestedBy') return cd.id;
          return '';
        })}
      />
    </div>
    {
      showSubmitActions
      &&
      <SubmitActions
        visitorAssets={allItems.filter(i => i.action) || []}
        cancelSubmitActionsCallback={() => setShowSubmitActions(false)}
        submitActionsCallback={submitActions}
        submissionProcessing={visitorAssetSubmitActionsMutation.isLoading}
      />
    }
    {showViewVisitorSiteAssets
    &&
    <Modal
      header={
        <>
          {props.siteCode} - {bundle.getMessage('view-site-assets')}
        </>
      }
      onDismiss={() => setShowViewVisitorSiteAssets(false)}
      size='max'
      visible={showViewVisitorSiteAssets}
    >
      <VisitorSiteAsssetsTablePanel
        closeViewVisitorSiteAssetsCallback={() => setShowViewVisitorSiteAssets(false)}
        siteCode={props.siteCode}
        username={props.username}
      />
    </Modal>}
    </>
  );
}
