import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";
import EmployeeBadgePhoto from 'src/components/common/EmployeeBadgePhoto';
import { IEmployeeActiveBadges } from 'src/types';

export const ColumnDefinitions: TableProps.ColumnDefinition<IEmployeeActiveBadges>[] = [
  {
    id: 'lastname',
    header: <TranslateHeader value={'last-name'} />,
    cell: item => item.lastName,
    minWidth: 30,
    sortingField: 'lastName'
  },
  {
    id: 'firstname',
    header: <TranslateHeader value={'first-name'} />,
    cell: item => item.firstName,
    minWidth: 30,
    sortingField: 'firstName',
  },
  {
    id: 'username',
    header: <TranslateHeader value={'user-name'} />,
    cell: item =>
      <EmployeeBadgePhoto
        employeeId={item.id}
        username={item.username}
        firstName={item.firstName}
        lastName={item.lastName}
        badgeText={item.missingActiveBadge ? '!' : undefined}
      />,
    minWidth: 30,
    sortingField: 'username'
  },
  {
    id: 'title',
    header: <TranslateHeader value={'title'} />,
    cell: item => item.title,
    minWidth: 15,
    sortingField: 'title'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAccess.CardHolders.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
