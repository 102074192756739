import { IEV3BadgeSwipe } from 'src/types';
import { EV3_DESFIRE_ACCSYS_APP_ID, EV3_ICLASS_ACCSYS_APP_ID, LookupTypes, WelcomeApplicationSettings } from '../constants/Constants';
import { queryLookupTypeValueForTypeAndDescription } from 'src/components/utils';
import { debugLogging } from 'src/components/App';

export function debug(message: string) {
  debugLogging && console.debug(message);
}

export function getTimeString(timestamp: number) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

export async function validEmailAddress(emailAddress: string): Promise<boolean> {
  debug(`validEmailAddress() emailAddress is ${emailAddress}`);
  try {
    const invalidVisitorEmailDomainsLookupTypeValue = await queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.InvalidVisitorEmailDomainsRegEx);
    debug(`validEmailAddress() invalidVisitorEmailDomainsLookupTypeValue is ${JSON.stringify(invalidVisitorEmailDomainsLookupTypeValue)}`);
    if (!invalidVisitorEmailDomainsLookupTypeValue) return true;
    const invalidVisitorEmailDomainsRegEx = new RegExp(invalidVisitorEmailDomainsLookupTypeValue.value);
    debug(`validEmailAddress() invalidVisitorEmailDomainsRegEx is ${invalidVisitorEmailDomainsRegEx}`);
    if (!invalidVisitorEmailDomainsRegEx) return true;
    const regex_result = invalidVisitorEmailDomainsRegEx.test(emailAddress);
    return !regex_result;
  } catch(error) {
    debug(`validEmailAddress() error is ${error}`);
    if (error === 'Unable to find lookup type value') {
      return true;
    }
    throw error;
  }
}

export function validateEV3Input(data: string): string | null {
  try {
    const baseDecode = atob(data);

    if (baseDecode) {
      const badgeData: IEV3BadgeSwipe = JSON.parse(baseDecode);
      
      // Find the primary app badge ID to fill out field
      const primaryBadgeID = badgeData.card.apps.find(app => app[EV3_DESFIRE_ACCSYS_APP_ID] || app[EV3_ICLASS_ACCSYS_APP_ID]);

      if (primaryBadgeID)
        return `${primaryBadgeID[EV3_DESFIRE_ACCSYS_APP_ID] ?? primaryBadgeID[EV3_ICLASS_ACCSYS_APP_ID]}`;
    }
  } catch (e) {} // skip

  return null;
}