import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import { createVisitorAsset } from '../Assets/utils';
import { createRequestEscort as createRequestEscortMutation } from "src/graphql/mutations";
import {
  auditDecorator,
  createVisitorAccessLevel,
  createVisitorAccessLevelApproval,
  getLookupTypeValueId,
} from 'src/components/utils';
import {
  ApprovalStatus,
  AssetApprovalStatus,
  LookupTypes,
  RequestStatus,
  VisitorRequestStatus,
  VisitorTypes
} from 'src/constants/Constants';
import * as uuid from 'uuid';
import { IEscort, TVisitor } from 'src/types';
import { IVisitDetails } from '.';
import { createRequest, createVisitor, deleteRequest } from '../utils';
import { SNSPublishAccessLevelRequestCreated } from 'src/graphql/queries';
import { ICreateVisitorInputWithAssets } from 'src/interfaces';
import { debug } from 'src/utils/commonUtils';

export interface ISubmitVisitorsRequest {
  escorts: APIt.CreateRequestEscortInput[],
  externalVisitors: ICreateVisitorInputWithAssets[],
  onboardedVisitors: APIt.CreateVisitorInput[],
  request: APIt.CreateRequestInput,
}

export let submitAirSiteVisitorRequest = async (
  username: string,
  visitDetails: IVisitDetails,
  escorts: IEscort[],
  onboardedVisitors: IEscort[],
  externalVisitors: TVisitor[]) => 
{
  debug(`submitRequest() username is ${username} visitDetails is ${JSON.stringify(visitDetails)} escorts is ${JSON.stringify(escorts)} \
    onboardedVisitors is ${JSON.stringify(onboardedVisitors)} externalVisitors is ${JSON.stringify(externalVisitors)}`);

  const sourceSystem = 'PACS';
  const requestId = uuid.v4();

  try {
    const siteSourceSystemId = await getLookupTypeValueId(LookupTypes.SiteSourceSystem, sourceSystem);
    if (!siteSourceSystemId) throw `unable to locate site source system id for ${sourceSystem}`;
    const requestorSourceSystemId = await getLookupTypeValueId(LookupTypes.RequestorSourceSystem, sourceSystem);
    if (!requestorSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, sourceSystem);
    if (!personSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    const requestStatusPendingApprovalId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, RequestStatus.PendingApproval);
    if (!requestStatusPendingApprovalId) throw `unable to locate request status id for ${RequestStatus.PendingApproval}`;
    const externalVisitorTypeId = await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.Visitor);
    if (!externalVisitorTypeId) throw `unable to locate visitor type id for ${VisitorTypes.Visitor}`;
    const onboardedVisitorTypeId = await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.Employee);
    if (!onboardedVisitorTypeId) throw `unable to locate employee type id for ${VisitorTypes.Employee}`;
    const assetApprovalStatusApproved = await getLookupTypeValueId(LookupTypes.AssetApprovalStatus, AssetApprovalStatus.Approved);
    if (!assetApprovalStatusApproved) throw `unable to locate asset approval status id for ${AssetApprovalStatus.Approved}`;

    const escortsInput: APIt.CreateRequestEscortInput[] = [];
    const onboardedVisitorsInput: APIt.CreateVisitorInput[] = [];
    const externalVisitorsInput: APIt.CreateVisitorInput[] = [];
    
    escorts.map(e => {
      const escort: APIt.CreateRequestEscortInput =
        {
          created_by: username,
          escort_id: e.username,
          escort_source_system_id: personSourceSystemId,
          id: uuid.v4(),
          request_id: requestId,
        };
      escortsInput.push(escort);
    });
    
    onboardedVisitors.map(ov => {
      const onboardedVisitor: APIt.CreateVisitorInput =
        {
          created_by: username,
          first_name: ov.firstName,
          id: uuid.v4(),
          last_name: ov.lastName,
          person_id: ov.id,
          person_source_system_id: personSourceSystemId,
          request_id: requestId,
          status_id: requestStatusPendingApprovalId,
          visitor_type_id: onboardedVisitorTypeId,
        };
      onboardedVisitorsInput.push(onboardedVisitor);
    });
    
    externalVisitors.map(ev => {
      const visitorId = uuid.v4();
      if (ev.assets) {
        ev.assets = ev.assets.map(a => {
          return {
            ...a,
            approval_status_id: assetApprovalStatusApproved,
            created_by: username,
            end_date: visitDetails.endDate,
            person_id: ev.emailAddress || `${ev.lastName}, ${ev.firstName}`,
            site_id: visitDetails.site!.value!,
            site_source_system_id: siteSourceSystemId,
            start_date: visitDetails.startDate,
            visitor_id: visitorId,
          };
        });
      }
      const externalVisitor: ICreateVisitorInputWithAssets =
        {
          assets: ev.assets,
          company: ev.company,
          created_by: username,
          email: ev.emailAddress,
          first_name: ev.firstName,
          id: visitorId,
          last_name: ev.lastName,
          person_id: ev.emailAddress || `${ev.lastName}, ${ev.firstName}`,
          phone_number: ev.phoneNumber,
          request_id: requestId,
          status_id: requestStatusPendingApprovalId,
          visitor_type_id: externalVisitorTypeId,
        };
      externalVisitorsInput.push(externalVisitor);
    });
  
    const visitorsRequest: ISubmitVisitorsRequest = {
      escorts: escortsInput,
      request: {
        id: requestId,
        site_id: visitDetails.site!.value,
        site_source_system_id: siteSourceSystemId,
        reason: visitDetails.reason!,
        requestor_id: username,
        requestor_source_system_id: requestorSourceSystemId,
        start_date: `${visitDetails.startDate} ${visitDetails.startTime}`,
        end_date: `${visitDetails.endDate} ${visitDetails.endTime}`,
        status: requestStatusPendingApprovalId,
        created_by: username,
      },
      onboardedVisitors: onboardedVisitorsInput,
      externalVisitors: externalVisitorsInput
    };

    try {
      debug(`submitRequest() visitorsRequest is ${JSON.stringify(visitorsRequest)}`);
      await submitAirVisitorRequest(visitorsRequest);
    } catch(error) {
      console.error(error);
      debug(`submitRequest() error is ${JSON.stringify(error)}`);
      throw(error);
    }
  } catch(error) {
    throw(error);
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishAccessLevelRequestCreated,
      {
        requestId: requestId
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestCreatedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestCreated) {
      debug(`submitAirSiteVisitorRequest() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`submitAirSiteVisitorRequest() error is ${JSON.stringify(error)}`);
    throw error;
  }
};
submitAirSiteVisitorRequest = auditDecorator('submitAirSiteVisitorRequest', submitAirSiteVisitorRequest);

export const submitAirVisitorRequest = async (visitorRequest: ISubmitVisitorsRequest) => {
  debug(`submitAirVisitorRequest() visitorRequest is ${JSON.stringify(visitorRequest)}`);

  let createdRequest: APIt.Request | null = null, autoApprove = false;

  try {
    createdRequest = await createRequest(visitorRequest.request);
    if (!createdRequest) throw new Error('Unable to create request');
    debug(`submitAirVisitorRequest() createdRequest is ${JSON.stringify(createdRequest)}`);
    const approverSourceSystemId = await getLookupTypeValueId(LookupTypes.ApproverSourceSystem, 'PACS');
    debug(`submitAirVisitorRequest() approverSourceSystemId is ${approverSourceSystemId}`);
    if (!approverSourceSystemId) throw new Error(`Unable to locate approver source system id value for PACS`);
    const pendingApprovalAccessLevelStatusCodeId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus, ApprovalStatus.PendingApproval);
    debug(`submitAirVisitorRequest() pendingApprovalAccessLevelStatusCodeId is ${pendingApprovalAccessLevelStatusCodeId}`);
    const approvedAccessLevelStatusCodeId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus, ApprovalStatus.Approved);
    debug(`submitAirVisitorRequest() approvedAccessLevelStatusCodeId is ${approvedAccessLevelStatusCodeId}`);
    const scheduledVisitVisitorRequestStatusCodeId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, VisitorRequestStatus.ScheduledVisit);
    debug(`submitAirVisitorRequest() scheduledVisitVisitorRequestStatusCodeId is ${scheduledVisitVisitorRequestStatusCodeId}`);
    const accessLevelSourceSystemId = await getLookupTypeValueId(LookupTypes.AccessLevelSourceSystem, 'PACS');
    debug(`submitAirVisitorRequest() accessLevelSourceSystemId is ${accessLevelSourceSystemId}`);
    if (!accessLevelSourceSystemId) throw new Error('Unable to get accessLevelSourceSystemId');
    const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, 'PACS');
    debug(`submitAirVisitorRequest() personSourceSystemId is ${personSourceSystemId}`);
    if (!personSourceSystemId) throw new Error('Unable to get personSourceSystemId');
    if (!pendingApprovalAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);
    if (!approvedAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.Approved}`);
    for (let escort of visitorRequest.escorts) {
      debug(`submitAirVisitorRequest() escort is ${JSON.stringify(escort)}`);
      const createdEscort = await createEscort(escort);
      if (!createdEscort) throw new Error('Unable to create escort');
      if (escort.escort_id === visitorRequest.request.requestor_id) autoApprove = true;
    }
    debug(`submitAirVisitorRequest() autoApprove is ${autoApprove}`);
    let createVisitorAccessLevelApprovalCalls = [];
    const createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[] = [];
    for (let externalVisitor of visitorRequest.externalVisitors) {
      if (autoApprove) externalVisitor.status_id = scheduledVisitVisitorRequestStatusCodeId;
      debug(`submitAirVisitorRequest() external visitor is ${JSON.stringify(externalVisitor)}`);
      const createExternalVisitorInput = {...externalVisitor};
      delete createExternalVisitorInput.assets;
      debug(`submitAirVisitorRequest() createExternalVisitorInput is ${JSON.stringify(createExternalVisitorInput)}`);
      const createdVisitor = await createVisitor(createExternalVisitorInput);
      if (externalVisitor.assets) {
        for (let asset of externalVisitor.assets) {
          asset.visitor_id = externalVisitor.id;
          const createdVisitorAsset = await createVisitorAsset(asset);
        };
      }
      if (!externalVisitor) throw new Error('Unable to create external visitor');
      // create one request_access_levels for site access
      const createVisitorAccessLevelInput: APIt.CreateVisitorAccessLevelInput = {
        access_level_id: '0', // 0 - site access
        access_level_name: 'Site Access',
        access_level_source_system_id: accessLevelSourceSystemId,
        created_by: externalVisitor.created_by,
        end_date: createdRequest.end_date,
        id: uuid.v4(),
        permanent_flag: false,
        reason: '',
        visitor_id: externalVisitor.id,
        site_id: createdRequest.site_id,
        start_date: createdRequest.start_date,
        status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
      };
      const createdVisitorAccessLevel = await createVisitorAccessLevel(createVisitorAccessLevelInput);
      if (!createdVisitorAccessLevel) throw new Error('Unable to create access level');
      // create request_access_level_approvals for each escort
      for (let escort of visitorRequest.escorts) {
        debug(`submitAirVisitorRequest() escort as approver is ${JSON.stringify(escort)}`);
        if (createdVisitorAccessLevelApprovals.find((a) =>
          (a.approver_id == escort.escort_id
          && a.approver_source_system_id == approverSourceSystemId
          && a.visitor_access_level_id == createdVisitorAccessLevel.id)) == undefined)
        {
          const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
            approver_id: escort.escort_id,
            approver_source_system_id: approverSourceSystemId,
            created_by: escort.created_by,
            id: uuid.v4(),
            visitor_access_level_id: createdVisitorAccessLevel.id,
            status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
          };
          createdVisitorAccessLevelApprovals.push(createVisitorAccessLevelApprovalInput);
          createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
        }
      }
      await Promise.all(createVisitorAccessLevelApprovalCalls);
    }
    for (let onboardedVisitor of visitorRequest.onboardedVisitors) {
      if (autoApprove) onboardedVisitor.status_id = scheduledVisitVisitorRequestStatusCodeId;
      debug(`submitAirVisitorRequest() onboardedVisitor is ${JSON.stringify(onboardedVisitor)}`);
      const createdOnboardedVisitor = await createVisitor(onboardedVisitor);
      if (!createdOnboardedVisitor) throw new Error('Unable to create onboarded visitor');
      // create one request_access_levels for site access
      const createVisitorAccessLevelInput: APIt.CreateVisitorAccessLevelInput = {
        access_level_id: '0', // 0 - site access
        access_level_name: 'Site Access',
        access_level_source_system_id: accessLevelSourceSystemId,
        created_by: onboardedVisitor.created_by,
        end_date: createdRequest.end_date,
        id: uuid.v4(),
        permanent_flag: false,
        reason: '',
        visitor_id: onboardedVisitor.id,
        site_id: createdRequest.site_id,
        start_date: createdRequest.start_date,
        status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
      };
      const createdVisitorAccessLevel = await createVisitorAccessLevel(createVisitorAccessLevelInput);
      if (!createdVisitorAccessLevel) throw new Error('Unable to create access level');
      // create request_access_level_approvals for each escort
      createVisitorAccessLevelApprovalCalls = [];
      for (let escort of visitorRequest.escorts) {
        debug(`submitAirVisitorRequest() escort as approver is ${JSON.stringify(escort)}`);
        const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
          approver_id: escort.escort_id,
          approver_source_system_id: approverSourceSystemId,
          created_by: escort.created_by,
          id: uuid.v4(),
          visitor_access_level_id: createdVisitorAccessLevel.id,
          status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
        };
        createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
      };
      await Promise.all(createVisitorAccessLevelApprovalCalls);
    }
  } catch(error) {
    if (createdRequest) await deleteRequest({id: createdRequest.id, updated_by: createdRequest.created_by});
    debug(`submitAirVisitorRequest() error is ${JSON.stringify(error)}`);
    throw(error);
  }

};

const createEscort = async (escortInput: APIt.CreateRequestEscortInput): Promise<APIt.RequestEscort | null> => {
  debug(`createEscort() escortInput is ${JSON.stringify(escortInput)}`);

  let createdEscort: APIt.RequestEscort | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createRequestEscortMutation,
      {
        input: escortInput
      })) as GraphQLResult<APIt.CreateRequestEscortMutation>;
    if (response && response.data && response.data.createRequestEscort) {
      createdEscort = response.data.createRequestEscort;
    }
  } catch (e) {
    console.error(`createEscort(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return createdEscort;
};