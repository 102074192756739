import * as APIt from 'src/API';
import {
  getLookupTypeValueId,
  queryDuplicateVisitorAssets,
  queryEmployeeDetails,
  querySites,
} from 'src/components/utils';
import { LookupTypes } from 'src/constants/Constants';
import { validEmailAddress } from 'src/utils/commonUtils';
import { z } from 'zod';
import { debug } from 'src/utils/commonUtils';

export const VisitorAssetSchema = z.object(
  {
    description: z.string({description: 'invalid-description'}),
    make: z.string({description: 'invalid-make'}).optional(),
    model: z.string({description: 'invalid-model'}).optional(),
    permanentFlag: z.string({description: 'invalid-permanent-flag'}).regex(/N|Y/, {message: 'invalid-permanent-flag'}),
    serialNum: z.string({description: 'invalid-serial-num'}),
    site: z.string({description: 'invalid-site'})
      .refine(async (site) => {
        const sites = await querySites(false);
        return sites.some(s => s.SiteCode == site);
      },
      {message: 'invalid-site'}),
    type: z.string({description: 'invalid-type'})
      .refine(async (type) => {
        try {
          return (await getLookupTypeValueId(LookupTypes.AssetType, type, true));
        } catch(error) {
          return false;
        }
      },
      {message: 'invalid-type'}),
    username: z.string({description: 'invalid-username'})
      .refine(async (username) => {
        try {
          await queryEmployeeDetails(username);
          return true;
        } catch(error) {
          return false;
        }
      },
      {message: 'invalid-username'}),
    validFromDate: z.string({description: 'invalid-valid-from-date'})
      .refine(validFromDate => {
        try {
          return validFromDate === '' || !isNaN(Date.parse(validFromDate));
        } catch (error) {
          return false; 
        }
      },
      {message: 'invalid-valid-from-date'}),
    validThroughDate: z.string({description: 'invalid-valid-through-date'})
      .refine(validThroughDate => {
        try {
          return validThroughDate === '' || !isNaN(Date.parse(validThroughDate));
        } catch (error) {
          return false; 
        }
      },
      {message: 'invalid-valid-through-date'}),
  }
).refine(input => {
  debug(`VisitorAssetSchema input is ${JSON.stringify(input)}`);
  if (input.permanentFlag !== 'Y') {
    if (input.validFromDate === '' || input.validThroughDate === '') {
      return false;
    }
  }
  if (input.permanentFlag === 'Y') {
    if (input.validFromDate !== '' || input.validThroughDate !== '') {
      return false;
    }
  }
  return true;
},
{message: 'invalid-valid-dates'})
.refine(async (input) => {
  try {
    if (input.site.toLocaleLowerCase() !== 'all') return true;
    const employeeDetails = await queryEmployeeDetails(input.username);
    if (employeeDetails?.jobLevel === '99' && input.site.toLocaleLowerCase() === 'all') return false;
    return true;
  } catch(error) {
    return false;
  }
},
{message: 'invalid-site-all'});

export type TVisitorAsset = z.infer<typeof VisitorAssetSchema>;

export const VisitorAssetWarningSchema = z.object(
  {
    description: z.string(),
    make: z.string().optional(),
    model: z.string().optional(),
    permanentFlag: z.string(),
    serialNum: z.string(),
    site: z.string(),
    type: z.string(),
    username: z.string(),
    validFromDate: z.string(),
    validThroughDate: z.string(),
  }
).transform(async input => {
  debug(`VisitorAssetWarningSchema input is ${JSON.stringify(input)}`);
  const duplicateVisitorAssets = await queryDuplicateVisitorAssets(
    {
      serialNum: input.serialNum,
      type: input.type,
      username: input.username,
    });
  if (duplicateVisitorAssets.length > 0) {
    return [
      {
        warningMessage: 'duplicate-visitor-asset',
        duplicateVisitorAssets: duplicateVisitorAssets,
      },
    ];
  }
  return [];
});

export type TVisitorAssetWarning = z.infer<typeof VisitorAssetWarningSchema>;

export type TVisitorAssetWarningData = { data: {warningMessage: string, duplicateVisitorAssets: any[]}[] };

const phoneRegExp = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/;

export const VisitorSchema = z.object(
  {
    assets: z.custom<APIt.VisitorAsset>().array().optional(),
    company: z.string({description: 'invalid-company'}).nullable(),
    emailAddress: z.string().email({message: 'invalid-email-address'})
      .refine(async (email) => 
        {
          return (await validEmailAddress(email));
        },
        {message: 'invalid-email-address-domain'})
      .nullable(),
    firstName: z.string({required_error: 'first-name-required'}),
    id: z.number({description: 'invalid-id'}),
    lastName: z.string({required_error: 'last-name-required'}),
    phoneNumber: z.string({description: 'invalid-phone-number'}).regex(phoneRegExp, 'invalid-phone-number').nullable(),
  }
);

export type TVisitor = z.infer<typeof VisitorSchema>;

export const VendorSchema = VisitorSchema.extend(
  {
    company: z.string({required_error: 'company-required'}),
  }
);

export type TVendor = z.infer<typeof VendorSchema>;

export interface IEscort extends APIt.EmployeeDetails {
  missingActiveBadge?: boolean;
  missingSiteAccess?: boolean;
}

export interface IEmployeeActiveBadges extends APIt.EmployeeDetails {
  missingActiveBadge?: boolean;
}

export interface IPendingVisitorRequest extends APIt.VisitorRequest {
  nonPendingRequest?: boolean;
}

export interface IPendingVisitorAccessLevel extends APIt.VisitorAccessLevel {
  nonPendingRequest?: boolean;
}

export interface IVisitorRequest {
  endDate: string | null;
  endTime: string | null;
  escorts: IEscort[];
  visitors: TVisitor[];
  reason: string | null;
  siteCode: string | null;
  startDate: string | null;
  startTime: string | null;
}

export interface IEV3CardApp {
	[key: string]: string;
}

export interface IEV3Card {
	UID: string;
	apps: IEV3CardApp[];
}

export interface IEV3BadgeSwipe {
	card: IEV3Card;
	signature: string;
	transactionId: number;
	type: string;
}
