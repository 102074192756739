import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flashbar,
  FormField,
  Input,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { IQueryEmployeeActiveBadgesParams, queryEmployeeActiveBadges, queryEmployeeDetails } from 'src/components/utils';
import AdvancedEmployeeSearch from 'src/components/common/AdvancedEmployeeSearch.tsx/TablePanel';
import { IEmployeeActiveBadges } from 'src/types';
import { Link } from 'react-router-dom';
import { debug } from 'src/utils/commonUtils';

export interface CardHoldersTablePanelPropsInterface {
  cardholders: IEmployeeActiveBadges[];
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  setCardholdersCallback: Function;
  setCardholdersQuickLinksCallback: Function;
  setCardholderMissingActiveBadgeCallback: Function;
  username: string;
}

export default function CardHoldersTablePanel(props: CardHoldersTablePanelPropsInterface ) {
  debug(`CardHoldersTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.EmployeeDetails[]>(props.cardholders);
  const [cardholdersListInput, setCardholdersListInput] = useState<string>('');
  const [cardholderMissingActiveBadge, setCardholderMissingActiveBadge] = useState<boolean>(false);
  const [invalidLoginInput, setInvalidLoginInput] = useState<boolean>(false);
  const [loadingCardholders, setLoadingCardholders] = useState<boolean>(false);
  const [usernamesAutoFocus, setUsernamesAutoFocus] = useState<boolean>(true);
  const [showAdvancedCardholderSearch, setShowAdvancedCardholderSearch] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAccess.CardHolders.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'username' }
    }
  );

  const [selectedItems, setSelectedItems] = useState<APIt.EmployeeDetails[]>([]);

  const [hideTable, setHideTable] = useState<boolean>(false);

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const addCardholdersHandler = async () => {
    debug(`CardHoldersTablePanel() addCardHoldersHandler()`);
    setLoadingCardholders(true);
    setInvalidLoginInput(false);
    const cardholders = cardholdersListInput.split(',');
    debug(`CardHoldersTablePanel() addCardHoldersHandler() cardholders is ${JSON.stringify(cardholders)}`);
    let employees: APIt.EmployeeDetails[] = [];
    const invalidLogins = [];
    for (let cardholder of cardholders) {
      debug(`CardHoldersTablePanel() addCardHoldersHandler() cardholder is ${JSON.stringify(cardholder)}`);
      try {
        const employeeDetails = await queryEmployeeDetails(cardholder.trim());
        debug(`CardHoldersTablePanel() addCardHoldersHandler() employeeDetails is ${JSON.stringify(employeeDetails)}`);
        if (employeeDetails) employees.push(employeeDetails);
      } catch(error) {
        debug(`CardHoldersTablePanel() addCardHoldersHandler() error is ${JSON.stringify(error)}`);
        invalidLogins.push(cardholder);
      }
    }
    if (employees && employees.length > 0) {
      employees = employees.filter(e => !allItems.find(i => i.username == e.username));
      setAllItems([...allItems, ...employees]);
      props.setCardholdersCallback([...allItems, ...employees]);
    }
    if (invalidLogins.length > 0) {
      setInvalidLoginInput(true);
      setCardholdersListInput(invalidLogins.toString());
    } else {
      setCardholdersListInput('');
    }
    setLoadingCardholders(false);
    setUsernamesAutoFocus(false);
  };

  const addCurrentUser = async () => {
    setLoadingCardholders(true);
    const employeeDetails = await queryEmployeeDetails(props.username);
    if (!employeeDetails) return;
    const items = allItems.filter(i => i.id !== employeeDetails.id);
    items.push(employeeDetails);
    setAllItems(items);
    props.setCardholdersCallback(items);
    setLoadingCardholders(false);
  };

  const addCardholderQuickLink = async (username: string) => {
    setLoadingCardholders(true);
    const employeeDetails = await queryEmployeeDetails(username);
    if (!employeeDetails) return;
    const items = allItems.filter(i => i.id !== employeeDetails.id);
    items.push(employeeDetails);
    setAllItems(items);
    props.setCardholdersCallback(items);
    setLoadingCardholders(false);
  };

  const removeSelected = () => {
    const currentItems = allItems;
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.username == al.username));
    setSelectedItems([]);
    setAllItems(newItems);
    props.setCardholdersCallback(newItems);
  };

  const addToQuickLinks = () => {
    const newQuickLinks: string[] = [];
    if (props.cardholdersQuickLinks) newQuickLinks.push(...props.cardholdersQuickLinks);
    let makeCall = false;
    selectedItems.forEach(si => {
      if (props.username !== si.username) {
        newQuickLinks.push(si.username);
        makeCall = true;
      }
    });
    if (makeCall) props.setCardholdersQuickLinksCallback(Array.from(new Set(newQuickLinks)).sort());
  };

  const removefromQuickLinks = () => {
    const newQuickLinks: string[] = props.cardholdersQuickLinks.filter(ch => !selectedItems.find(si => si.username === ch));
    props.setCardholdersQuickLinksCallback(Array.from(new Set(newQuickLinks)).sort());
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as APIt.EmployeeDetails[]);
  };

  const flagCardholdersMissingActiveBadge = async (cardholders: APIt.EmployeeDetails[]): Promise<{ cardholders: IEmployeeActiveBadges[] }> => {
    debug(`CardHoldersTablePanel() flagCardholdersMissingActiveBadge() cardholders is ${JSON.stringify(cardholders)}`);

    if (cardholders.length == 0) return { cardholders: cardholders };

    const updatedCardholders: IEmployeeActiveBadges[] = [];

    for (let cardholder of cardholders) {
      debug(`CardHoldersTablePanel() flagCardholdersMissingActiveBadge() cardholder is ${JSON.stringify(cardholder)}`);
      const employeeDetails = await queryEmployeeDetails(cardholder.username);
      if (!employeeDetails) continue;
      debug(`CardHoldersTablePanel() flagCardholdersMissingActiveBadge() employeeDetails is ${JSON.stringify(employeeDetails)}`);
      let missingActiveBadge = true;
      const queryEmployeeActiveBadgeParams: IQueryEmployeeActiveBadgesParams = {
        emplId: cardholder.id.toString(),
        region: employeeDetails.region || 'AMER'
      };
      const activeBadges = await queryEmployeeActiveBadges(queryEmployeeActiveBadgeParams);
      if (activeBadges.length > 0) missingActiveBadge = false;
      debug(`CardHoldersTablePanel() flagCardholdersMissingActiveBadge() missingActiveBadge is ${JSON.stringify(missingActiveBadge)}`);
      updatedCardholders.push({
        ...cardholder,
        missingActiveBadge: missingActiveBadge
      });
    }
    debug(`CardHoldersTablePanel() flagCardholdersMissingActiveBadge() updatedCardholders is ${JSON.stringify(updatedCardholders)}`);
    return { cardholders: updatedCardholders };
  };

  useEffect(() => {
    setAllItems(props.cardholders);
  }, [props.cardholders]);

  useEffect(() => {
    debug(`CardHoldersTablePanel() useEffect[props.cardholders] props.username is ${props.username}`);
    const checkMissingActiveBadge = async () => {
      debug(`CardHoldersTablePanel() useEffect[props.cardholders] checkMissingActiveBadge()`);
      if (props.username) {
        const { cardholders } = await flagCardholdersMissingActiveBadge(props.cardholders);
        debug(`CardHoldersTablePanel() useEffect[props.cardholders] cardholders is ${JSON.stringify(cardholders)}`);
        if (cardholders.find(c => c.missingActiveBadge)) {
          setCardholderMissingActiveBadge(true);
          props.setCardholderMissingActiveBadgeCallback(true);
        } else {
          setCardholderMissingActiveBadge(false);
          props.setCardholderMissingActiveBadgeCallback(false);
        }
        setAllItems(cardholders);
      } else {
        setCardholderMissingActiveBadge(false);
      }
    };
    checkMissingActiveBadge();
  }, [props.cardholders])

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showAdvancedCardholderSearch
    &&
    <Modal
      header={bundle.getMessage('advanced-search')}
      onDismiss={() => setShowAdvancedCardholderSearch(false)}
      size='large'
      visible={showAdvancedCardholderSearch}
    >
      <AdvancedEmployeeSearch
        addEmployeesCallback={(employees: APIt.EmployeeDetails[]) => {
          props.setCardholdersCallback(Array.from(new Set([...allItems, ...employees])));
        }}
        closeCallback={() => setShowAdvancedCardholderSearch(false)}
      />
    </Modal>
    }
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        data-testid='RequestAccessCardHoldersTable'
        header={
          <>
          <Header
            counter={`(${itemsCount().toString()})`}
          >
            {bundle.getMessage('cardholders')}
          </Header>
          <SpaceBetween direction='horizontal' size='s'>
            <div>
              <FormField label={bundle.getMessage('usernames')} >
                <Input
                  autoFocus={usernamesAutoFocus}
                  data-testid='RequestAccessCardHoldersUsernames'
                  invalid={invalidLoginInput}
                  onChange={event => {
                    setInvalidLoginInput(false);
                    setCardholdersListInput(event.detail.value);
                  } }
                  onKeyUp={event => {
                    if (event.detail.key == 'Enter')
                      addCardholdersHandler();
                  } }
                  placeholder='username, username'
                  value={cardholdersListInput}
                />
              </FormField>
            </div>
            <div style={{position:'relative', bottom: '-25px'}}>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  loading={loadingCardholders}
                  data-testid='RequestAccessCardHoldersAddCardholders'
                  disabled={!cardholdersListInput || loadingCardholders}
                  onClick={addCardholdersHandler}
                  variant='normal'
                >
                  {bundle.getMessage('add')}
                </Button>
                <Button
                  onClick={() => setShowAdvancedCardholderSearch(true)}
                  variant='normal'
                >
                  {bundle.getMessage('advanced-search')}
                </Button>
              </SpaceBetween>
            </div>
          </SpaceBetween>
          <SpaceBetween direction='horizontal' size='xxxs'>
            <Link
              onClick={addCurrentUser}
              style={props.darkMode ? {color: 'white'} : undefined}
              to={''}
            >
              {props.username.trimRight()}{props.cardholdersQuickLinks.length > 0 ? ',' : ''}
            </Link>
            {props.cardholdersQuickLinks.map((ch, idx) => {
              return(
                <Link
                  key={ch}
                  onClick={() => addCardholderQuickLink(ch)}
                  style={props.darkMode ? {color: 'white'} : undefined}
                  to={''}
                >
                  {ch.trimRight()}{idx === props.cardholdersQuickLinks.length-1 ? '' : ','}
                </Link>);
            })}
          </SpaceBetween>
          </>
        }
        footer={
          <>
          <SpaceBetween direction='vertical' size='xs'>
            {cardholderMissingActiveBadge
            &&
            <Flashbar
              items = {
                [
                  {
                    type: 'error',
                    dismissible: false,
                    content: bundle.getMessage('cardholder-missing-active-badge')
                  },
                ]
              }
            />}
            <Box float='right'>
              <SpaceBetween direction='horizontal' size={'s'}>
                 <Button
                  disabled={selectedItems.length == 0 || selectedItems.find(si => si.username !== props.username) === undefined}
                  onClick={removefromQuickLinks}
                  variant='normal'
                >
                  {bundle.getMessage('remove-from-quick-links')}
                </Button>
                 <Button
                  disabled={selectedItems.length == 0 || selectedItems.find(si => si.username !== props.username) === undefined}
                  onClick={addToQuickLinks}
                  variant='normal'
                >
                  {bundle.getMessage('add-to-quick-links')}
                </Button>
                 <Button
                  disabled={selectedItems.length == 0}
                  onClick={removeSelected}
                  variant='primary'
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
          </>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
    </div>
    </>
  );
}
