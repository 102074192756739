import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";
import { TVisitor } from 'src/types';

export const ColumnDefinitions: TableProps.ColumnDefinition<TVisitor>[] = [
  {
    id: 'firstName',
    header: <TranslateHeader value={'first-name'} />,
    cell: item => item.firstName,
    minWidth: 20,
    sortingField: 'firstName'
  },
  {
    id: 'lastName',
    header: <TranslateHeader value={'last-name'} />,
    cell: item => item.lastName,
    minWidth: 20,
    sortingField: 'lastName'
  },
  {
    id: 'company',
    header: <TranslateHeader value={'company'} />,
    cell: item => item.company,
    minWidth: 20,
    sortingField: 'company'
  },
  {
    id: 'emailAddress',
    header: <TranslateHeader value={'email-address'} />,
    cell: item => item.emailAddress,
    minWidth: 20,
    sortingField: 'emailAddress'
  },
  {
    id: 'phoneNumber',
    header: <TranslateHeader value={'phone-number'} />,
    cell: item => item.phoneNumber,
    minWidth: 20,
    sortingField: 'phoneNumber'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVisitorAccess.Visitors.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
