import { Badge } from '@amzn/awsui-components-react';
import React from 'react';


interface IPendingVisitorRequest {
  status?: string | null;
  nonPendingRequest?: boolean;
}

export default function PendingVisitorRequestStatus(props: IPendingVisitorRequest) {

  return(
    props.nonPendingRequest
      ?
        <span>
          <Badge color='red'>!</Badge>
        </span>
      :
        <>
          {props.status}
        </>
    );
    
};
