import React from 'react';
import { UseQueryResult } from '@tanstack/react-query'
import { Modes } from 'src/constants/Constants';
import PendingVisitorRequestsTablePanel from './TablePanel';
import { VisitorRequest } from 'src/API';
import { debug } from '../../../utils/commonUtils';

interface IPendingVisitorRequestsProps {
  mode: Modes;
  username: string;
}

export default function PendingVisitorRequests(props: IPendingVisitorRequestsProps) {
  debug(`PendingVisitorRequests() props is ${JSON.stringify(props)}`);

  return(
    <PendingVisitorRequestsTablePanel
      username={props.username}
    />
  );
}


