import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flashbar,
  FormField,
  Input,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table
} from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { IQueryAccessLevelsParams, queryAccessLevels } from 'src/components/SelfService/AccessLevels/utils';
import { IEscort } from 'src/types';
import { IQueryEmployeeActiveBadgesParams, queryEmployeeActiveBadges, queryEmployeeDetails } from 'src/components/utils';
import AdvancedEmployeeSearch from '../AdvancedEmployeeSearch.tsx/TablePanel';
import { debug } from 'src/utils/commonUtils';

export enum HeaderMessages {
  ESCORTS = 'escorts',
  ONBOARDED_VISITORS = 'onboarded-visitors',
  POINTS_OF_CONTACT = 'amazon-points-of-contact',
}

export interface EscortsTablePanelPropsInterface {
  autoFocus: boolean;
  cardholdersQuickLinks: string[];
  checkForMissingSiteAccess: boolean;
  darkMode: boolean;
  escorts: IEscort[];
  setEscortsTablePanelTouched?: Function;
  headerMessage: HeaderMessages;
  setCardholdersQuickLinksCallback: Function | null;
  setEscortsCallback: Function;
  setEscortMissingActiveBadgeCallback: Function;
  setEscortMissingSiteAccessCallback: Function;
  siteCode: string | null;
  username: string;
}

export default function EscortsTablePanel(props: EscortsTablePanelPropsInterface ) {
  debug(`EscortsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<IEscort[]>(props.escorts);
  const [escortMissingActiveBadge, setEscortMissingActiveBadge] = useState<boolean>(false);
  const [escortMissingSiteAccess, setEscortMissingSiteAccess] = useState<boolean>(false);
  const [escortsListInput, setEscortsListInput] = useState<string>('');
  const [id, setId] = useState<number>(1);
  const [invalidLoginInput, setInvalidLoginInput] = useState<boolean>(false);
  const [loadingEscorts, setLoadingEscorts] = useState<boolean>(false);
  const [showAdvancedCardholderSearch, setShowAdvancedCardholderSearch] = useState<boolean>(false);
  const [usernamesAutoFocus, setUsernamesAutoFocus] = useState<boolean>(props.autoFocus);

  const [bundle, isBundleLoading] = useBundle('components.common.Escorts.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'username' }
    }
  );

  const [selectedItems, setSelectedItems] = useState<APIt.EmployeeDetails[]>([]);

  const [hideTable, setHideTable] = useState<boolean>(false);

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const flagEscortsMissingSiteAccess = async (siteCode: string, escorts: APIt.EmployeeDetails[]): Promise<{ escorts: IEscort[] }> => {
    debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() siteCode is ${siteCode} escorts is ${JSON.stringify(escorts)}`);

    if (escorts.length == 0) return { escorts: escorts };

    const updatedEscorts: IEscort[] = [];
    const skeletonAccessLevelRegex = new RegExp(`}.*${siteCode}`);

    for (let escort of escorts) {
      debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() escort is ${JSON.stringify(escort)}`);
      let missingSiteAccess = true;
      let currentPageIndex = 0;
      const queryAccessLevelsParams: IQueryAccessLevelsParams = {
        employeeId: escort.id.toString(),
        Orderby: 'accesslevelname',
        OrderbyDirection: 'a',
        PageNumber: 0,
        RecordsByPage: 500
      };
      debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() queryAccessLevelsParams is ${JSON.stringify(queryAccessLevelsParams)}`);
      const accessLevels = await queryAccessLevels(queryAccessLevelsParams);
      debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() accessLevels is ${JSON.stringify(accessLevels)}`);
      for (let accessLevel of accessLevels) {
        debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() accessLevel is ${JSON.stringify(accessLevel)}`);
        if (accessLevel.ACCESSLEVEL_NAME.startsWith(`${siteCode}-`) || skeletonAccessLevelRegex.test(accessLevel.ACCESSLEVEL_NAME)) {
          missingSiteAccess = false;
        }
      }
      currentPageIndex = currentPageIndex + 1;
      debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() currentPageIndex is ${currentPageIndex}`);
      queryAccessLevelsParams.PageNumber = currentPageIndex;
      debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() queryAccessLevelsParams is ${JSON.stringify(queryAccessLevelsParams)}`);
      debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() missingSiteAccess is ${JSON.stringify(missingSiteAccess)}`);
      updatedEscorts.push({
        ...escort,
        missingSiteAccess: missingSiteAccess
      });
    }
    debug(`EscortsTablePanel() flagEscortsMissingSiteAccess() updatedEscorts is ${JSON.stringify(updatedEscorts)}`);
    return { escorts: updatedEscorts };
  };

  const addEscortsHandler = async () => {
    props.setEscortMissingSiteAccessCallback(null);
    setLoadingEscorts(true);
    setInvalidLoginInput(false);
    const escorts = escortsListInput.split(',');
    let employees = [];
    const invalidLogins = [];
    let i = id + 1;
    for (let escort of escorts) {
      setId(i++);
      try {
        const employeeDetails = await queryEmployeeDetails(escort.trim());
        if (employeeDetails) employees.push(employeeDetails);
      } catch(error) {
        debug(`addEscortsHandler() error is ${JSON.stringify(error)}`);
        invalidLogins.push(escort);
      }
    }
    if (employees.length > 0) {
      employees = employees.filter(e => !allItems.find(i => i.username == e.username));
      setAllItems([...allItems, ...employees]);
      props.setEscortsCallback([...allItems, ...employees]);
    }
    if (invalidLogins.length > 0) {
      setInvalidLoginInput(true);
      setEscortsListInput(invalidLogins.toString());
    } else {
      setEscortsListInput('');
      if (props.setEscortsTablePanelTouched) props.setEscortsTablePanelTouched(false);
    }
    setLoadingEscorts(false);
    setUsernamesAutoFocus(false);
  };

  const addCurrentUser = async () => {
    setLoadingEscorts(true);
    const employeeDetails = await queryEmployeeDetails(props.username);
    if (!employeeDetails) return;
    const items = allItems.filter(i => i.id !== employeeDetails.id);
    items.push(employeeDetails);
    setAllItems(items);
    props.setEscortsCallback(items);
    setLoadingEscorts(false);
  };

  const addCardholderQuickLink = async (username: string) => {
    setLoadingEscorts(true);
    const employeeDetails = await queryEmployeeDetails(username);
    if (!employeeDetails) return;
    const items = allItems.filter(i => i.id !== employeeDetails.id);
    items.push(employeeDetails);
    setAllItems(items);
    props.setEscortsCallback(items);
    setLoadingEscorts(false);
  };

  const removeSelected = () => {
    const currentItems = allItems;
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.username == al.username));
    setSelectedItems([]);
    setAllItems(newItems);
    props.setEscortsCallback(newItems);
  };

  const addToQuickLinks = () => {
    const newQuickLinks: string[] = [];
    if (props.cardholdersQuickLinks) newQuickLinks.push(...props.cardholdersQuickLinks);
    let makeCall = false;
    selectedItems.forEach(si => {
      if (props.username !== si.username) {
        newQuickLinks.push(si.username);
        makeCall = true;
      }
    });
    if (makeCall && props.setCardholdersQuickLinksCallback) props.setCardholdersQuickLinksCallback(Array.from(new Set(newQuickLinks)).sort());
  };

  const removefromQuickLinks = () => {
    const newQuickLinks: string[] = props.cardholdersQuickLinks.filter(ch => !selectedItems.find(si => si.username === ch));
    if (props.setCardholdersQuickLinksCallback) props.setCardholdersQuickLinksCallback(Array.from(new Set(newQuickLinks)).sort());
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as APIt.EmployeeDetails[]);
  };

  const flagEscortMissingActiveBadge = async (escorts: APIt.EmployeeDetails[]): Promise<{ escorts: IEscort[] }> => {
    debug(`CardHoldersTablePanel() flagEscortMissingActiveBadge() escorts is ${JSON.stringify(escorts)}`);

    if (escorts.length == 0) return { escorts: escorts };

    const updatedEscorts: IEscort[] = [];

    for (let escort of escorts) {
      debug(`CardHoldersTablePanel() flagEscortMissingActiveBadge() cardholder is ${JSON.stringify(escort)}`);
      const employeeDetails = await queryEmployeeDetails(escort.username);
      if (!employeeDetails) continue;
      debug(`CardHoldersTablePanel() flagEscortMissingActiveBadge() employeeDetails is ${JSON.stringify(employeeDetails)}`);
      let missingActiveBadge = true;
      const queryEmployeeActiveBadgeParams: IQueryEmployeeActiveBadgesParams = {
        emplId: escort.id.toString(),
        region: employeeDetails.region || 'AMER'
      };
      const activeBadges = await queryEmployeeActiveBadges(queryEmployeeActiveBadgeParams);
      if (activeBadges.length > 0) missingActiveBadge = false;
      debug(`CardHoldersTablePanel() flagEscortMissingActiveBadge() missingActiveBadge is ${JSON.stringify(missingActiveBadge)}`);
      updatedEscorts.push({
        ...escort,
        missingActiveBadge: missingActiveBadge
      });
    }
    debug(`CardHoldersTablePanel() flagEscortMissingActiveBadge() updatedEscorts is ${JSON.stringify(updatedEscorts)}`);
    return { escorts: updatedEscorts };
  };

  useEffect(() => {
    debug(`EscortsTablePanel() useEffect[props.escorts, props.siteCode] props.escorts is ${JSON.stringify(props.escorts)} props.checkForMissingSiteAccess is ${props.checkForMissingSiteAccess} props.siteCode is ${props.siteCode}`);
    props.setEscortMissingSiteAccessCallback(null);
    const checkMissingEscortSiteAccess = async () => {
      if (props.checkForMissingSiteAccess && props.siteCode) {
        const { escorts } = await flagEscortsMissingSiteAccess(props.siteCode, props.escorts);
        if (escorts.find(e => e.missingSiteAccess)) {
          setEscortMissingSiteAccess(true);
          props.setEscortMissingSiteAccessCallback(true);
        } else {
          setEscortMissingSiteAccess(false);
          props.setEscortMissingSiteAccessCallback(false);
        }
        debug(`EscortsTablePanel() useEffect[props.escorts, props.siteCode] escorts is ${JSON.stringify(escorts)}`);
        setAllItems(escorts);
      } else if (props.checkForMissingSiteAccess) {
        setEscortMissingSiteAccess(false);
      }
    };
    checkMissingEscortSiteAccess();
  }, [props.escorts, props.siteCode]);

  useEffect(() => {
    debug(`EscortsTablePanel() useEffect[props.escorts]`);
    props.setEscortMissingActiveBadgeCallback(null);
    const checkMissingActiveBadge = async () => {
      debug(`EscortsTablePanel() useEffect[props.escorts] checkMissingActiveBadge()`);
      const { escorts } = await flagEscortMissingActiveBadge(props.escorts);
      debug(`EscortsTablePanel() useEffect[props.escorts] escorts is ${JSON.stringify(escorts)}`);
      if (escorts.find(c => c.missingActiveBadge)) {
        setEscortMissingActiveBadge(true);
        props.setEscortMissingActiveBadgeCallback(true);
      } else {
        setEscortMissingActiveBadge(false);
        props.setEscortMissingActiveBadgeCallback(false);
      }
      setAllItems(escorts);
    };
    checkMissingActiveBadge();
  }, [props.escorts]);

  const FlashMessageEscortMissingSiteAccess = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: bundle.getMessage('escort-missing-site-access')
          },
        ]
      }
    />
  );
  
  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showAdvancedCardholderSearch
    &&
    <Modal
      header={bundle.getMessage('advanced-search')}
      onDismiss={() => setShowAdvancedCardholderSearch(false)}
      size='large'
      visible={showAdvancedCardholderSearch}
    >
      <AdvancedEmployeeSearch
        addEmployeesCallback={(employees: APIt.EmployeeDetails[]) => {
          props.setEscortsCallback(Array.from(new Set([...allItems, ...employees])));
        }}
        closeCallback={() => 
        {
          setShowAdvancedCardholderSearch(false);
          if (props.setEscortsTablePanelTouched) props.setEscortsTablePanelTouched(false);
        }
        }
      />
    </Modal>
    }
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        data-testid='EscortsTable'
        header={
          <>
          <Header
            counter={`(${itemsCount().toString()})`}
          >
            {bundle.getMessage(props.headerMessage)}
          </Header>
          <SpaceBetween direction='horizontal' size='s'>
            <div>
              <FormField
                label={
                  (props.headerMessage == HeaderMessages.ESCORTS)
                    ? bundle.getMessage('escort-usernames')
                    : props.headerMessage == HeaderMessages.ONBOARDED_VISITORS
                      ? bundle.getMessage('add-onboarded-visitors')
                      : props.headerMessage == HeaderMessages.POINTS_OF_CONTACT
                        ? bundle.getMessage('amazon-points-of-contact-usernames')
                        : ''
                }
              >
                <Input
                  autoFocus={usernamesAutoFocus}
                  data-testid='EscortsUserNameInput'
                  invalid={invalidLoginInput}
                  onChange={event => {
                    setInvalidLoginInput(false);
                    setEscortsListInput(event.detail.value);
                    if (props.setEscortsTablePanelTouched) props.setEscortsTablePanelTouched(event.detail.value.length > 0);
                  }}
                  onKeyUp={event => {
                    if (event.detail.key == 'Enter')
                      addEscortsHandler();
                  }}
                  placeholder='username, username'
                  value={escortsListInput}
                />
              </FormField>
            </div>
            <div style={{position:'relative', bottom: '-25px'}}>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  data-testid='EscortsAddButton'
                  disabled={!escortsListInput || loadingEscorts}
                  formAction='none'
                  loading={loadingEscorts}
                  onClick={addEscortsHandler}
                  variant='normal'
                >
                  {bundle.getMessage('add')}
                </Button>
                  <Button
                    formAction='none'
                    onClick={() => 
                    {
                      setEscortsListInput('');
                      setShowAdvancedCardholderSearch(true);
                      if (props.setEscortsTablePanelTouched) props.setEscortsTablePanelTouched(true);
                    }
                    }
                    variant='normal'
                  >
                    {bundle.getMessage('advanced-search')}
                </Button>
              </SpaceBetween>
            </div>
          </SpaceBetween>
          {
            props.setCardholdersQuickLinksCallback
            &&
            <SpaceBetween direction='horizontal' size='xxxs'>
              <Link
                onClick={addCurrentUser}
                style={props.darkMode ? {color: 'white'} : undefined}
                to={''}
              >
                {props.username}{props.cardholdersQuickLinks.length > 0 ? ',' : ''}
              </Link>
              {props.cardholdersQuickLinks.map((ch, idx) => {
                return(
                  <Link
                    key={ch}
                    onClick={() => addCardholderQuickLink(ch)}
                    style={props.darkMode ? {color: 'white'} : undefined}
                    to={''}
                  >
                    {ch}{idx === props.cardholdersQuickLinks.length-1 ? '' : ','}
                  </Link>);
              })}
            </SpaceBetween>
          }
          </>
        }
        footer={
          <>
          {escortMissingActiveBadge
          &&
          <Flashbar
            items = {
              [
                {
                  type: 'error',
                  dismissible: false,
                  content: bundle.getMessage('escort-missing-active-badge')
                },
              ]
            }
          />}
          <SpaceBetween direction='vertical' size='xs'>
            {escortMissingSiteAccess && <FlashMessageEscortMissingSiteAccess />}
            <Box float='right'>
              <SpaceBetween direction='horizontal' size={'s'}>
                {
                  props.setCardholdersQuickLinksCallback
                  &&
                  <>
                    <Button
                      disabled={selectedItems.length == 0 || selectedItems.find(si => si.username !== props.username) === undefined}
                      formAction='none'
                      onClick={removefromQuickLinks}
                      variant='normal'
                    >
                      {bundle.getMessage('remove-from-quick-links')}
                    </Button>
                     <Button
                      disabled={selectedItems.length == 0 || selectedItems.find(si => si.username !== props.username) === undefined}
                      formAction='none'
                      onClick={addToQuickLinks}
                      variant='normal'
                    >
                      {bundle.getMessage('add-to-quick-links')}
                    </Button>
                  </>
                }
                <Button
                  formAction='none'
                  disabled={selectedItems.length == 0}
                  onClick={removeSelected}
                  variant='primary'
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
          </>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
    </div>
    </>
  );
}
