import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  Textarea,
  TextFilter, 
  Toggle,
  ToggleProps} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { cancelVisitorRequest, queryHistoricalVisitorRequests, queryPendingVisitorRequests } from './utils';
import { debug } from 'src/utils/commonUtils';
import { useQuery } from '@tanstack/react-query';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import { IPendingVisitorRequest } from 'src/types';

export interface PendingVisitorRequestsTablePanelPropsInterface {
  username: string;
}

export default function PendingVisitorRequestsTablePanel(props: PendingVisitorRequestsTablePanelPropsInterface ) {
  debug(`PendingVisitorRequestsTablePanel() props is ${JSON.stringify(props)}`);

  const pendingVisitorRequestsQuery = useQuery({
    onSuccess: (data) => { setAllItems(data || []); },
    queryKey: ['pendingVisitorRequests'],
    queryFn: () => queryPendingVisitorRequests(props.username),
    retry: 3,
  });

  const [allItems, setAllItems] = useState<IPendingVisitorRequest[]>(pendingVisitorRequestsQuery.data || []);
  const [cancelVisitorRequestsVisible, setCancelVisitorRequestsVisible] = useState<boolean>(false);
  const [cancellingVisitorRequests, setCancellingVisitorRequests] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [queryAllHistorical, setQueryAllHistorical] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<IPendingVisitorRequest[]>([]);
  const [showCancelledVisitorRequestsFlashbar, setShowCancelledVisitorRequestsFlashbar] = useState<boolean>(false);
  const [showNotCancelledVisitorRequestsFlashbar, setShowNotCancelledVisitorRequestsFlashbar] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.PendingVisitorRequests.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={
          queryAllHistorical
            ? !isBundleLoading
              ? bundle.getMessage('no-historical-visitor-requests-found')
              : 'No Historical Visitor Requests Found'
            : !isBundleLoading
              ? bundle.getMessage('no-pending-visitor-requests-found')
              : 'No Pending Visitor Requests Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'visitor_id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const FlashMessageVisitorRequestsCancelled = () => (
    <Flashbar
      items = {
        [
          {
            type: 'success',
            dismissible: true,
            content: bundle.getMessage('visitor-request-cancelled'),
            onDismiss: () => setShowCancelledVisitorRequestsFlashbar(false)
          },
        ]
      }
    />
  );

  const FlashMessageVisitorRequestsNotCancelled = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: true,
            content: bundle.getMessage('visitor-request-not-cancelled'),
            onDismiss: () => setShowNotCancelledVisitorRequestsFlashbar(false)
          },
        ]
      }
    />
  );

  const cancelVisitorRequestsBtnClickHandler = () => {
    setShowCancelledVisitorRequestsFlashbar(false);
    if (selectedItems.length > 0) setCancelVisitorRequestsVisible(true);
  };

  const csvColumns = allPageItems.map(item => {
    return {
      approvals_needed: item.approvals_needed,
      badge_id: item.badge_id,
      company: item.company,
      created: item.created,
      created_by: item.created_by,
      email: item.email,
      end_date: item.end_date,
      escort1: item.escort1,
      escort2: item.escort2,
      escort3: item.escort3,
      escort4: item.escort4,
      escort5: item.escort5,
      first_name: item.first_name,
      last_name: item.last_name,
      person_id: item.person_id,
      phone_number: item.phone_number,
      reason: item.reason,
      request_id: item.request_id,
      requestor_id: item.requestor_id,
      site_id: item.site_id,
      start_date: item.start_date,
      status: item.status,
      title: item.title,
      updated: item.updated,
      updated_by: item.updated_by,
      vendor_day_pass_badge_num: item.vendor_day_pass_badge_num,
      visitor_pass_assginment_id: item.visitor_pass_assignment_id,
      visitor_type: item.visitor_type
    }
  });

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await refreshVisitorRequests();
    setRefreshing(false);
    setSelectedItems([]);
  };

  const cancelVisitorRequestsConfirmedHandler = async () => {
    debug(`cancelVisitorRequestsConfirmedHandler()`);
    setCancellingVisitorRequests(true);
    let filteredSelectedItems: APIt.VisitorRequest[] = selectedItems;
    let itemsNotUpToDate: APIt.VisitorRequest[] = [];
    try {
      const refreshedItems = await queryPendingVisitorRequests(props.username);
      if (!refreshedItems)
        throw new Error('Failed to refresh visitor requests.');
      for (let si of selectedItems) {
        if (!refreshedItems.find((ri) => ri.visitor_id === si.visitor_id)) {
          itemsNotUpToDate.push(si);
        }
      }
      if (itemsNotUpToDate.length === 0) {
        for (let vr of selectedItems) {
          await cancelVisitorRequest(vr.visitor_id, props.username);
          filteredSelectedItems = filteredSelectedItems.filter((si) => si.visitor_id !== vr.visitor_id);
        };
        await refreshVisitorRequests();
        setShowCancelledVisitorRequestsFlashbar(true);
        setSelectedItems(filteredSelectedItems);
      } else {
        for (let vr of itemsNotUpToDate) {
          allItems.find((ai) => {
            if (ai.visitor_id === vr.visitor_id) {
              ai.nonPendingRequest = true;
            }
          });
        }
        setShowNotCancelledVisitorRequestsFlashbar(true);
      }
      setCancelVisitorRequestsVisible(false);
      setCancellingVisitorRequests(false);

    } catch (error) {
      console.error(error);
    }
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const listHistoricalToggleHandler = (detail: ToggleProps.ChangeDetail) => {
    setQueryAllHistorical(detail.checked);
    setSelectedItems([]);
  };

  const historicalVisitorRequestsQuery = useQuery({
    enabled: queryAllHistorical,
    onSuccess: (data) => { setAllItems(data || []); },
    queryKey: ['historicalVisitorRequests'],
    queryFn: () => queryHistoricalVisitorRequests(props.username),
    retry: 3,
  });

  const ReasonField = () => {
    return(
      <>
      <FormField label={bundle.getMessage('reason-for-visitor-request')} >
        <Textarea value={selectedItems[0].reason || ''} disabled/>
      </FormField>
      <br/>
      </>
    );
  };

  const refreshVisitorRequests = async () => {
    if (queryAllHistorical) {
      await historicalVisitorRequestsQuery.refetch();
    } else {
      await pendingVisitorRequestsQuery.refetch();
    }
  };

  useEffect(() => {
    refreshVisitorRequests();
  }, [queryAllHistorical]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showCancelledVisitorRequestsFlashbar && <FlashMessageVisitorRequestsCancelled/>}
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
            <TextFilter
              {...filterProps}
              filteringAriaLabel={queryAllHistorical ? bundle.getMessage('filter-historical-visitor-requests') : bundle.getMessage('filter-pending-visitor-requests')}
              filteringPlaceholder={queryAllHistorical ? bundle.getMessage('find-historical-visitor-requests') : bundle.getMessage('find-pending-visitor-requests')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0 : filteredItemsCount)}
            />
            <Toggle
              onChange={({ detail }) => listHistoricalToggleHandler(detail)}
              checked={queryAllHistorical}
            >
              {bundle.getMessage('list-historical-requests')}
            </Toggle>
          </Grid>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='PendingVisitorRequestsRefreshButton'
                  iconName='refresh'
                  key="refreshBtn"
                  loading={refreshing}
                  onClick={refreshBtnClickHandler}
                >
                  {bundle.getMessage('refresh')}
                </Button>
                <CsvDownloadButton
                  data={csvColumns}
                  delimiter={','}
                  disabled={pendingVisitorRequestsQuery.isFetching || historicalVisitorRequestsQuery.isFetching || items.length < 1}
                  filename={`VisitorRequests-${new Date().toISOString()}`}
                />
              </SpaceBetween>
              </>
            }
          >
            {queryAllHistorical ? bundle.getMessage('historical-visitor-requests') : bundle.getMessage('pending-visitor-requests')}
          </Header>
        }
        footer={
          <>
           {showNotCancelledVisitorRequestsFlashbar && <FlashMessageVisitorRequestsNotCancelled/>}
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='PendingVisitorRequestsCancelVisitorRequestButton'
                  disabled={selectedItems.length == 0 || queryAllHistorical || selectedItems.some(si=>si.nonPendingRequest)}
                  onClick={cancelVisitorRequestsBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('cancel-visitor-request')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
          </>
        }
        items={items}
        loading={pendingVisitorRequestsQuery.isFetching || historicalVisitorRequestsQuery.isFetching}
        loadingText={queryAllHistorical ? bundle.getMessage('loading-historical-visitor-requests') : bundle.getMessage('loading-pending-visitor-requests')}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='visitor_id'
      />
    </div>
    {
      selectedItems.length == 1
      &&
      <Container
        key={'PendingVisitorRequestDetails'}
        header={<Header>{`${bundle.getMessage('visitor-request-details')} for ${selectedItems[0].last_name}, ${selectedItems[0].first_name}`}</Header>}
        children={
          <ReasonField/>
        }
      />
    }
    {cancelVisitorRequestsVisible
    &&
    <Modal
      onDismiss={() => setCancelVisitorRequestsVisible(false)}
      visible={cancelVisitorRequestsVisible}
      closeAriaLabel='Close'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setCancelVisitorRequestsVisible(false)}>{bundle.getMessage('no')}</Button>
            <Button
              variant='primary'
              onClick={() => cancelVisitorRequestsConfirmedHandler() }
              disabled={cancellingVisitorRequests}
              loading={cancellingVisitorRequests}
            >
              {bundle.getMessage('yes')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('confirm-visitor-request-cancel')}
    >
      {bundle.getMessage('please-confirm-visitor-request-cancel')}
    </Modal>
    }
    </>
  );
}
