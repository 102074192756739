import React, { useRef, useState } from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import Papa from 'papaparse';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@amzn/awsui-components-react';
import { TVendor, TVisitor, VendorSchema, VisitorSchema } from 'src/types';
import { debug } from 'src/utils/commonUtils';

interface IVisitorBulkAddParams {
  addBulkVisitorsCallback: Function;
  addVendor: boolean;
  closeCallback: Function;
  id: number;
}

export default function VisitorBulkAdd(props: IVisitorBulkAddParams) {
  debug(`VisitorBulkAdd() props is ${JSON.stringify(props)}`);

  const [csvData, setCSVData] = useState('');
  const [invalidData, setInvalidData] = useState<boolean>(false);
  const [loadingVisitors, setLoadingVisitors] = useState<boolean>(false);
  const [invalidDataInfo, setInvalidDataInfo] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVisitorAccess.Visitors.VisitorBulkAdd');

  const fileChooserRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (result: any) => {
        setCSVData(result.data.join('\n'));
      },
      delimiter: ',',
      error: () => setInvalidData(true),
      escapeChar: '\\',
      quotes: true,
      quoteChar: '"',
      skipEmptyLines: true,
    });
  };

  const addVisitors = async () => {
    setLoadingVisitors(true);
    const visitors: TVisitor[] | TVendor[] = [];
    try {
      const config = {
        delimiter: ',',
        error: () => setInvalidData(true),
        escapeChar: '\\',
        quotes: true,
        quoteChar: '"',
        skipEmptyLines: true,
      };
      const visitorData = Papa.parse(csvData, config).data;
      debug(`VisitorBulkAdd() addVisitors() visitorData is ${JSON.stringify(visitorData)}`);
      let id = props.id;
      for (let i = 0; i < visitorData.length; i++, id++) {
        debug(`VisitorBulkAdd() addVisitors() visitorData[${i}] is ${JSON.stringify(visitorData[i])}`);
        if (JSON.stringify(visitorData[i]) == '["first","last","company","email","phone"]') continue;
        const visitor = 
          {
            company: visitorData[i][2].trim() || null,
            emailAddress: visitorData[i][3].trim() || null,
            firstName: visitorData[i][0].trim(),
            id: id,
            lastName: visitorData[i][1].trim() || null,
            phoneNumber: visitorData[i][4].trim() || null,
          };
        const parseResults = props.addVendor ? await VendorSchema.safeParseAsync(visitor) : await VisitorSchema.safeParseAsync(visitor);
        debug(`VisitorBulkAdd() addVisitors() parseResults is ${JSON.stringify(parseResults)}`);
        if (!parseResults.success) {
          let errors = '';
          debug(`VisitorBulkAdd() addVisitors() parseResults.error.issues is ${JSON.stringify(parseResults.error.issues)}`);
          for (let issue of parseResults.error.issues) {
            debug(`VisitorBulkAdd() addVisitors() issue is ${JSON.stringify(issue)}`);
            errors += `${bundle.getMessage(issue.message)}; `;
          }
          debug(`VisitorBulkAdd() addVisitors() errors is ${errors}`);
          setInvalidDataInfo(`Record ${i+1}: ${errors}`);
          throw new Error('visitor parse error');
        }
        visitors.push(visitor);
      }
    } catch(error) {
      debug(`VisitorBulkAdd() addVisitors() error is ${error} ${JSON.stringify(error)}`);
      setInvalidData(true);
      setLoadingVisitors(false);
      return;
    }
    debug(`VisitorBulkAdd() addVisitors() visitors is ${JSON.stringify(visitors)}`);
    props.addBulkVisitorsCallback(visitors);
    setCSVData('');
    setInvalidData(false);
    setInvalidDataInfo('');
    if (fileChooserRef?.current) fileChooserRef.current.value = '';
    setLoadingVisitors(false);
    props.closeCallback();
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <Container
      header={
        invalidData
        &&
        <Flashbar
          items = {
            [
              {
                content: `${bundle.getMessage('invalid-data')} ${invalidDataInfo}`,
                dismissible: true,
                onDismiss: () => {
                  setInvalidData(false);
                  setInvalidDataInfo('');
                },
                type: 'error',
              },
            ]
          }
        />
      }
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              onClick={() => {
                setCSVData('');
                if (fileChooserRef?.current) fileChooserRef.current.value = '';
                props.closeCallback();
              }}
              variant='normal'
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              disabled={!csvData}
              loading={loadingVisitors}
              onClick={async () => await addVisitors()}
              variant='primary'
            >
              {bundle.getMessage('add')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction='vertical' size='s'>
        <FormField label={bundle.getMessage('upload-csv-file')}>
          <input ref={fileChooserRef} type='file' onChange={handleFileUpload} />
        </FormField>
        <a href="data:text/plain;charset=utf-8,first%2Clast%2Ccompany%2Cemail%2Cphone" download="template.csv">template.csv</a>
        <FormField label='CSV file contents' stretch>
          <Textarea
            onChange={({ detail }) => {
              setCSVData(detail.value);
              setInvalidData(false);
              setInvalidDataInfo('');
            }}
            placeholder='first, last, company, email, phone'
            rows={15}
            value={csvData}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}