import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  FormField,
  Grid,
  Input,
  Select,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import * as APIt from 'src/API';
import * as uuid from 'uuid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createApproverGroup, updateApproverGroup, isApproverGroupValid } from './utils';
import { queryAccessLevelsForSite, querySites } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export interface IApproverGroupProps {
  approverGroup: APIt.ApproverGroup | undefined;
  addFailureCallback: Function;
  addSuccessCallback: Function;
  closeCallback: Function;
  updateFailureCallback: Function;
  updateSuccessCallback: Function;
  username: string;
}

export default function ApproverGroup(props: IApproverGroupProps) {
  debug(`ApproverGroup() props is ${JSON.stringify(props)}`);

  const defaultApproverGroup: APIt.ApproverGroup = 
    {
      __typename: 'ApproverGroup',
      id: uuid.v4(),
      access_level: '',
      approver_group: '',
      created: Date.now().toString(),
      created_by: props.username,
      updated: Date.now().toString(),
      updated_by: props.username,
    };

  const [approverGroup, setApproverGroup] = useState<APIt.ApproverGroup>(props.approverGroup || defaultApproverGroup);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedSiteCode, setSelectedSiteCode] = useState<string>();
  const [validApproverGroup, setValidApproverGroup] = useState<boolean>(true);

  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.ApproverGroups.ApproverGroup');

  const queryClient = useQueryClient();

  const sitesQuery = useQuery({
    queryFn: () => querySites(false),
    queryKey: ['querySites'],
  });

  const accessLevelsForSiteQuery = useQuery({
    queryFn: () => queryAccessLevelsForSite(selectedSiteCode, true),
    queryKey: ['accessLevelsForSiteApproverGroups'],
  });

  const createApproverGroupMutation = useMutation({
    mutationFn: createApproverGroup,
    onSuccess: () => {
      props.addSuccessCallback();
      const newApproverGroups = [
        ...(queryClient.getQueryData(['approverGroups']) as APIt.ApproverGroup[]),
       {
         ...approverGroup,
         created: approverGroup.created,
         updated: approverGroup.updated,
       }];
      queryClient.setQueryData(['approverGroups'], newApproverGroups);
      props.closeCallback();
      setSelectedSiteCode('');
      setApproverGroup(defaultApproverGroup);
      setSaving(false);
    },
    onError: () => {
      setSaving(false);
      props.addFailureCallback();
      props.closeCallback();
    },
  });

  const updateApproverGroupMutation = useMutation({
    mutationFn: updateApproverGroup,
    onSuccess: () => {
      props.updateSuccessCallback();
      const newApproverGroups = [
        ...(queryClient.getQueryData(['approverGroups']) as APIt.ApproverGroup[])
        .filter(ag => ag.id !== approverGroup.id),
        {
          ...approverGroup,
          created: approverGroup.created,
          updated: approverGroup.updated,
        }];
      queryClient.setQueryData(['approverGroups'], newApproverGroups);
      props.closeCallback();
      setSelectedSiteCode('');
      setApproverGroup(defaultApproverGroup);
      setSaving(false);
    },
    onError: () => {
      setSaving(false);
      props.updateFailureCallback();
      props.closeCallback();
    },
  });

  const allowSave = (): boolean => {
    return(
      (
        (JSON.stringify(props.approverGroup) == JSON.stringify(approverGroup)
        ||
        (!props.approverGroup && (!approverGroup.approver_group || !approverGroup.access_level)))
        ||
        (!approverGroup.approver_group || !approverGroup.access_level)
      )
    );
  };

  const saveForm = async () => {
    setSaving(true);
    if (!(await isApproverGroupValid(approverGroup.approver_group))) {
      setValidApproverGroup(false);
      setSaving(false);
      return;
    }
    setValidApproverGroup(true);
    props.approverGroup
      ? updateApproverGroupMutation.mutate({...approverGroup, updated_by: props.username})
      : createApproverGroupMutation.mutate(approverGroup);
  };

  useEffect(() => {
    setApproverGroup(props.approverGroup || defaultApproverGroup);
  }, [props.approverGroup])

  useEffect(() => {
    accessLevelsForSiteQuery.refetch();
  }, [selectedSiteCode])

  if (isBundleLoading) return <Spinner/>;

  return(
    <Container>
      <Form
        actions={
          <SpaceBetween size='s' direction='horizontal'>
            <Button
              key='lookupTypeValueCancelBtn'
              loading={false}
              onClick={() =>
                {
                  setApproverGroup(defaultApproverGroup);
                  setSelectedSiteCode('');
                  props.closeCallback();
                }
              }
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              disabled={allowSave()}
              key='lookupTypeValueSaveBtn'
              loading={saving || updateApproverGroupMutation.isLoading || createApproverGroupMutation.isLoading}
              onClick={saveForm}
              variant='primary'
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        }
      >
        <FormField label={bundle.getMessage('approver-group')}>
          <Input
            invalid={!validApproverGroup}
            onChange={ ({detail}) => setApproverGroup( { ...approverGroup, approver_group: detail.value } ) }
            value={approverGroup?.approver_group || ''}
          />
        </FormField>
        <Grid gridDefinition={[{colspan: 4},{colspan: 8}]}>
          <FormField label={bundle.getMessage('site')}>
            <Select
              filteringType='auto'
              loadingText={bundle.getMessage('loading-sites')}
              onChange={event => setSelectedSiteCode(event.detail.selectedOption.label)}
              options={
                sitesQuery.data?.map(s => {
                  return {label: s.SiteCode, value: s.SiteCode};
                })
              }
              placeholder={bundle.getMessage('select-a-site')}
              selectedOption={{ value: selectedSiteCode, label: selectedSiteCode }}
              statusType={sitesQuery.isLoading || sitesQuery.isFetching? 'loading' : 'finished'}
            />
          </FormField>
          <FormField label={bundle.getMessage('access-level')}>
            <Select
              disabled={!selectedSiteCode}
              filteringType='auto'
              loadingText={bundle.getMessage('loading-access-levels')}
              onChange={event => {
                setApproverGroup(
                  {
                    ...approverGroup,
                    access_level: event.detail.selectedOption.value!
                  });
              }}
              options={
                [
                  ...(accessLevelsForSiteQuery.data?.map(al => {
                    return {
                      label: al.AccessLevelName,
                      value: al.AccessLevelName
                    };
                  })) || [],
                  {label: `${selectedSiteCode}-RC-ASSET-APPROVERS`, value: `${selectedSiteCode}-RC-ASSET-APPROVERS`}
                ]
              }
              placeholder={bundle.getMessage('select-a-site')}
              selectedOption={{ value: approverGroup.access_level, label: approverGroup.access_level }}
              statusType={accessLevelsForSiteQuery.isLoading || accessLevelsForSiteQuery.isFetching? 'loading' : 'finished'}
              />
          </FormField>
        </Grid>
      </Form>
    </Container>
  )

}
