import { Badge, Link, Popover, Spinner } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { BADGE_PHOTO_BY_EMP_ID_URL, BADGE_PHOTO_BY_UID_URL } from 'src/constants/Constants';
import { queryEmployeeDetails } from '../utils';
import { EmployeeDetails } from 'src/API';
import { debug } from 'src/utils/commonUtils';

export interface IEmployeeBadgePhotoProps {
  employeeId?: string;
  firstName?: string;
  lastName?: string;
  badgeText?: string | undefined;
  username: string | null | undefined;
}

export default function EmployeeBadgePhoto(props: IEmployeeBadgePhotoProps) {
  debug(`EmployeeBadgePhoto() props is ${JSON.stringify(props)}`);

  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails | null>();
  const [cannotGetEmployeeDetails, setCannotGetEmployeeDetails] = useState<boolean>(false);

  useEffect(() => {
    if (props.username) {
      queryEmployeeDetails(props.username)
        .then(e => setEmployeeDetails(e))
        .catch(() => setCannotGetEmployeeDetails(true));
    }
    return () => {
      setEmployeeDetails(null);
      setCannotGetEmployeeDetails(false);
    }
  }, [props.username]);

  if (employeeDetails) {
   return(
      <Popover
        content={
          <>
            <img
              alt={'Employee Badge Photo'}
              src={(props.employeeId && props.employeeId != '-1') ? `${BADGE_PHOTO_BY_EMP_ID_URL}${employeeDetails.id}` : `${BADGE_PHOTO_BY_UID_URL}${employeeDetails.username}`}
            />
            <div>
              <div>
                {employeeDetails?.title} L{employeeDetails?.jobLevel}
              </div>
              <div>
                {`${employeeDetails?.locationName ? employeeDetails?.locationName : employeeDetails?.siteCode}`}
              </div>
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={`https://phonetool.amazon.com/users/${employeeDetails.username}`}
              >
                Phone Tool
              </Link>
            </div>
          </>
        }
        header={employeeDetails ? `${employeeDetails?.lastName}, ${employeeDetails?.firstName}` : ''}
        size='medium'
        triggerType='text'
      >
        {employeeDetails.username || props.username}
        {
          props.badgeText
          &&
          <span>
            <Badge color='red'>!</Badge>
          </span>
        }
      </Popover>
    );
  }

  return(<>{props.username}</>);
};