import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQueryClient, useIsFetching, useQuery, useMutation } from '@tanstack/react-query';
import { querySites } from 'src/components/utils';
import ApproverGroup from './ApproverGroup';
import { deleteApproverGroup } from './utils';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import { debug } from 'src/utils/commonUtils';

export interface IApproverGroupsTablePanelPropsInterface {
  username: string;
}

export default function ApproverGroupsTablePanel(props: IApproverGroupsTablePanelPropsInterface ) {
  debug(`ApproverGroupsTablePanel() props is ${JSON.stringify(props)}`);

  const pageSize = 15;

  const queryClient = useQueryClient();

  const isFetchingApproverGroups = useIsFetching(['approverGroups']);

  const [allItems, setAllItems] = useState<APIt.ApproverGroup[]>(queryClient.getQueryData(['approverGroups']) || []);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<APIt.ApproverGroup | undefined>(undefined);
  const [showAddApproverGroup, setShowAddApproverGroup] = useState<boolean>(false);
  const [showEditApproverGroup, setShowEditApproverGroup] = useState<boolean>(false);
  const [showDeleteApproverGroup, setShowDeleteApproverGroup] = useState<boolean>(false);
  const [showAddFailure, setShowAddFailure] = useState<boolean>(false);
  const [showAddSuccess, setShowAddSuccess] = useState<boolean>(false);
  const [showDeleteFailure, setShowDeleteFailure] = useState<boolean>(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);
  const [showUpdateSuccess, setShowUpdateSuccess] = useState<boolean>(false);
  const [showUpdateFailure, setShowUpdateFailure] = useState<boolean>(false);

  const sitesQuery = useQuery({
    queryFn: () => querySites(false),
    queryKey: ['managementSites'],
  });

  const deleteApproverGroupMutation = useMutation({
    mutationFn: deleteApproverGroup,
    onSuccess: async () => {
      setShowDeleteSuccess(true);
      const newApproverGroups = [
        ...(queryClient.getQueryData(['approverGroups']) as APIt.ApproverGroup[]).filter(ag => ag.id !== selectedItem?.id)
      ];
      queryClient.setQueryData(['approverGroups'], newApproverGroups);
    },
    onError: () => setShowDeleteFailure(true),
  });

  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.ApproverGroups.TablePanel');

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-approver-groups-found') : 'No Approver Groups Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
        fields: [
          'access_level',
          'approver_group',
          'created',
          'updated',
        ],
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    queryClient.refetchQueries(['approverGroups']);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const confirmDeleteHandler = () => {
    setSelectedItem(undefined);
    setShowDeleteApproverGroup(false);
    if (selectedItem) deleteApproverGroupMutation.mutate(selectedItem);
  };

  useEffect(() => {
    setAllItems(queryClient.getQueryData(['approverGroups']) || []);
    setSelectedItem(undefined);
  }, [queryClient.getQueryData(['approverGroups'])]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <Grid gridDefinition={[{colspan: 4},{colspan: 2}]}>
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-approver-groups')}
              filteringPlaceholder={bundle.getMessage('find-approver-groups')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
          </Grid>
        }
        footer={
          <Header
            actions={
              <SpaceBetween size='s' direction='horizontal'>
                <Button
                  disabled={!selectedItem}
                  onClick={() => setShowEditApproverGroup(true)}
                  iconName='edit'
                  variant='normal'
                >
                  {bundle.getMessage('edit')}
                </Button>
                <Button
                  disabled={!selectedItem}
                  onClick={() => setShowDeleteApproverGroup(true)}
                  iconName='close'
                  variant='normal'
                >
                  {bundle.getMessage('delete')}
                </Button>
                <Button
                  onClick={() => setShowAddApproverGroup(true)}
                  iconName='add-plus'
                  variant='normal'
                >
                  {bundle.getMessage('add')}
                </Button>
              </SpaceBetween>
            }
          >
          </Header>
        }
        header={
          <>
            {showAddFailure && <Alert header={bundle.getMessage('add-failure')} type='error' dismissible={true} onDismiss={() => setShowAddFailure(false)} />}
            {showAddSuccess && <Alert header={bundle.getMessage('add-success')} type='success' dismissible={true} onDismiss={() => setShowAddSuccess(false)} />}
            {showDeleteFailure && <Alert header={bundle.getMessage('delete-failure')} type='error' dismissible={true} onDismiss={() => setShowDeleteFailure(false)} />}
            {showDeleteSuccess && <Alert header={bundle.getMessage('delete-success')} type='success' dismissible={true} onDismiss={() => setShowDeleteSuccess(false)} />}
            {showUpdateFailure && <Alert header={bundle.getMessage('update-failure')} type='error' dismissible={true} onDismiss={() => setShowUpdateFailure(false)} />}
            {showUpdateSuccess && <Alert header={bundle.getMessage('update-success')} type='success' dismissible={true} onDismiss={() => setShowUpdateSuccess(false)} />}
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <SpaceBetween direction='horizontal' size={'s'}>
                  <CsvDownloadButton
                    data={allPageItems}
                    delimiter={','}
                    disabled={isFetchingApproverGroups > 0}
                    filename={`WelcomeApproverGroups-${new Date().toISOString()}`}
                  />
                  <Button
                    loading={isFetchingApproverGroups > 0}
                    key="refreshBtn"
                    onClick={refreshBtnClickHandler}
                    iconName='refresh'
                  >
                    {bundle.getMessage('refresh')}
                  </Button>
                </SpaceBetween>
              }
            >
              {bundle.getMessage('manage-approver-groups')}
            </Header>
          </>
        }
        items={items}
        loading={isFetchingApproverGroups > 0}
        loadingText={bundle.getMessage('loading-approver-groups')}
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0])}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItem ? [selectedItem] : []}
        selectionType='single'
        stickyHeader={true}
        trackBy='id'
      />
      {(showEditApproverGroup || showAddApproverGroup)
      &&
      <Modal
        header={
          <Header>
            {showEditApproverGroup ? `${bundle.getMessage('edit')} ${selectedItem?.approver_group}` : bundle.getMessage('add')}
          </Header>
        }
        visible={true}
      >
        <ApproverGroup
          addFailureCallback={() => setShowAddFailure(true)}
          addSuccessCallback={() => setShowAddSuccess(true)}
          updateFailureCallback={() => setShowUpdateFailure(true)}
          updateSuccessCallback={() => setShowUpdateSuccess(true)}
          approverGroup={showEditApproverGroup ? selectedItem : undefined}
          closeCallback={() => showEditApproverGroup ? setShowEditApproverGroup(false) : setShowAddApproverGroup(false)}
          username={props.username}
        />
      </Modal>
      }
      {showDeleteApproverGroup
      &&
      <Modal
        onDismiss={() => setShowDeleteApproverGroup(false)}
        header={
          <Header>
            {`${bundle.getMessage('delete')} ${selectedItem?.approver_group}`}
          </Header>
        }
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowDeleteApproverGroup(false)}
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                onClick={() => confirmDeleteHandler()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        visible={showDeleteApproverGroup}
      >
        {bundle.getMessage('confirm-delete-approver-group')}
      </Modal>
      }
    </div>
  );
}
