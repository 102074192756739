import React, { useEffect, useState } from 'react';
import { IVisitorAccessLevelWithAction } from "./TablePanel";
import {
  Box,
  SpaceBetween,
  Form,
  FormField,
  Checkbox,
  DateRangePicker,
  DateRangePickerProps,
  Spinner,
  TimeInput,
  Grid,
  Icon,
  Flashbar,
} from '@amzn/awsui-components-react';
import { isFromDateValid, isThroughDateValid } from 'src/components/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { debug } from 'src/utils/commonUtils';

export interface AccessRequestEditPanelPropsInterface {
  accessRequest: IVisitorAccessLevelWithAction,
  setDatesCallback: Function;
  username: string;
}

export const AccessRequestEditPanel = (props: AccessRequestEditPanelPropsInterface) => {

  const defaultValidFromTime = '08:00';
  const defaultValidThroughTime = '17:00';

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.EditDates');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [invalidFromDate, setInvalidFromDate] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [permanentFlag, setPermanentFlag] = useState<boolean>(props.accessRequest.permanent_flag);
  const [validFromDateInput, setValidFromDateInput] = useState<any>(props.accessRequest.start_date ? props.accessRequest.start_date.slice(0, 10) : null);
  const [validThroughDateInput, setValidThroughDateInput] = useState<any>(props.accessRequest.end_date ? props.accessRequest.end_date.slice(0, 10) : null);
  const [validFromTimeInput, setValidFromTimeInput] = useState<string>(props.accessRequest.start_date ? new Date(props.accessRequest.start_date).toTimeString().slice(0, 5) : defaultValidFromTime);
  const [validThroughTimeInput, setValidThroughTimeInput] = useState<string>(props.accessRequest.end_date ? new Date(props.accessRequest.end_date).toTimeString().slice(0, 5) : defaultValidThroughTime);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(null);

  const datesInvalid = (): boolean => {
    return !permanentFlag && (!validFromDateInput || !validFromTimeInput || !validThroughDateInput || !validThroughTimeInput || invalidFromDate || invalidThroughDate);
  }

  const dateRangeInputChangeHandler = (value: DateRangePickerProps.Value | null) => {
    debug(`AccessRequestCreatePanel() dateRangeInputChangeHandler() value is ${JSON.stringify(value)}`);
    setVisitDateRange(value);
    if (value?.type == 'absolute') {
      setValidFromDateInput(value.startDate);
      setInvalidFromDate(!isFromDateValid(new Date(`${value.startDate} 00:00:00`), hoursExtFromDate));
      setValidThroughDateInput(value.endDate);
      setInvalidFromDate(!isThroughDateValid(new Date(`${value.startDate} 00:00:00`), new Date(`${value.endDate} 00:00:00`)));
    }
    if (value?.type == 'relative') {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      debug(`AccessRequestCreatePanel() dateRangeInputChangeHandler() fromDate is ${fromDate}`);
      setValidFromDateInput(`${fromDate.getFullYear()}-${(fromDate.getMonth() + 1).toString().padStart(2, '0')}-${fromDate.getDate().toString().padStart(2, '0')}`);
      const throughDate = new Date();
      let factor = 1;
      switch (value.unit) {
        case ('day'):
          factor = Math.abs(value.amount) * 1;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case ('month'):
          factor = Math.abs(value.amount);
          debug(`AccessRequestCreatePanel() dateRangeInputChangeHandler() ${throughDate.getFullYear()} ${throughDate.getMonth()} ${throughDate.getDate()}`);
          const dateUTC = Date.UTC(throughDate.getFullYear(), throughDate.getMonth() + factor, throughDate.getDate());
          debug(`AccessRequestCreatePanel() dateRangeInputChangeHandler() dateUTC is ${dateUTC}`);
          throughDate.setTime(dateUTC);
          break;
        case ('week'):
          factor = Math.abs(value.amount) * 7;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case ('year'):
          factor = Math.abs(value.amount) * 365;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
      }
      debug(`AccessRequestCreatePanel() dateRangeInputChangeHandler() throughDate is ${throughDate}`);
      const throughDateInput = `${throughDate.getFullYear()}-${(throughDate.getMonth() + 1).toString().padStart(2, '0')}-${throughDate.getDate().toString().padStart(2, '0')}`;
      setValidThroughDateInput(throughDateInput);
      if (fromDate !== null) setInvalidFromDate(!isFromDateValid(fromDate, hoursExtFromDate));
      if (fromDate !== null && throughDate !== null) setInvalidThroughDate(throughDateInput == '' ? false : !isThroughDateValid(fromDate, throughDate));
    }
  };

  const getDatesLabel = (): string => {
    if (!validFromDateInput || validFromDateInput.length < 10 || !validFromTimeInput || validFromTimeInput.length < 5 || !validThroughDateInput || validThroughDateInput.length < 10 || !validThroughTimeInput || validThroughTimeInput.length < 5)
      return '';
    return validFromDateInput.slice(0, 10) + ' ' + validFromTimeInput.slice(0, 5) + ` ${bundle.getMessage('through')} ` + validThroughDateInput.slice(0, 10) + ' ' + validThroughTimeInput.slice(0, 5);
  }

  const FlashValidationInvalidMessage = () => (
    <Flashbar
      items={
        [
          {
            header: bundle.getMessage('dates-invalid-message'),
            dismissible: false,
            type: 'error',
          },
        ]
      }
    />);

  const fromTimeInputChangeHandler = (value: string) => {
    setValidFromTimeInput(value);
  };

  const throughTimeInputChangeHandler = (value: string) => {
    setValidThroughTimeInput(value);
  };

  const visitorPermanentFieldOnChangeHandler = (detail: any) => {
    setPermanentFlag(detail.checked);
    if (detail.checked) {
      setValidFromDateInput(null);
      setValidThroughDateInput(null);
      setValidFromTimeInput(defaultValidFromTime);
      setValidThroughTimeInput(defaultValidThroughTime);
    }
  };

  useEffect(() => {
    if (!permanentFlag)
      props.setDatesCallback(props.accessRequest.id, validFromDateInput.slice(0, 10) + ' ' + validFromTimeInput.slice(0, 5), validThroughDateInput.slice(0, 10) + ' ' + validThroughTimeInput.slice(0, 5), permanentFlag, true, props.username, datesInvalid());
    else
      props.setDatesCallback(props.accessRequest.id, null, null, permanentFlag, true, props.username, datesInvalid());
  }, [invalidFromDate, invalidThroughDate]);

  useEffect(() => {
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${validThroughDateInput} ${validThroughTimeInput}`)));

    if (!permanentFlag)
      props.setDatesCallback(props.accessRequest.id, validFromDateInput.slice(0, 10) + ' ' + validFromTimeInput.slice(0, 5), validThroughDateInput.slice(0, 10) + ' ' + validThroughTimeInput.slice(0, 5), permanentFlag, true, props.username, datesInvalid());
    else
      props.setDatesCallback(props.accessRequest.id, null, null, permanentFlag, true, props.username, datesInvalid());
  }, [validFromTimeInput, validThroughTimeInput, validFromDateInput, validThroughDateInput]);

  useEffect(() => {
    if (permanentFlag || !validFromDateInput || validFromDateInput.length < 10 || !validFromTimeInput || validFromTimeInput.length < 5 || !validThroughDateInput || validThroughDateInput.length < 10 || !validThroughTimeInput || validThroughTimeInput.length < 5)
      props.setDatesCallback(props.accessRequest.id, null, null, permanentFlag, true, props.username, datesInvalid());
    else
      props.setDatesCallback(props.accessRequest.id, validFromDateInput.slice(0, 10) + ' ' + validFromTimeInput.slice(0, 5), validThroughDateInput.slice(0, 10) + ' ' + validThroughTimeInput.slice(0, 5), permanentFlag, true, props.username, datesInvalid());
  }, [permanentFlag]);

  if (isBundleLoading) return <Spinner />;

  return (

    <div id='AccessRequestsCreateDiv'>
      {!permanentFlag && (invalidFromDate || invalidThroughDate) && <FlashValidationInvalidMessage />}
      <Form
        actions={
          <Box float='right'>
            <SpaceBetween size='xs' direction='horizontal'>
            </SpaceBetween>
          </Box>
        }
        errorText={errorText}
      >
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]} >
          {props.accessRequest.first_name + ' ' + props.accessRequest.last_name}
          {props.accessRequest.access_level_name}
          {permanentFlag ? bundle.getMessage('permanent') : getDatesLabel()}
        </Grid>
        <SpaceBetween size='s' direction='vertical'>
          <SpaceBetween size='xs' direction='horizontal'>
            <FormField label={bundle.getMessage('permanent')}>
              <Checkbox
                onChange={({ detail }) => visitorPermanentFieldOnChangeHandler(detail)}
                checked={permanentFlag}
              />
            </FormField>
            <FormField label={bundle.getMessage('days-site-time')}>
              <DateRangePicker
                dateOnly
                disabled={permanentFlag}
                hideTimeOffset
                i18nStrings={{
                  todayAriaLabel: bundle.getMessage('today'),
                  nextMonthAriaLabel: bundle.getMessage('next-month'),
                  previousMonthAriaLabel: bundle.getMessage('previous-month'),
                  customRelativeRangeDurationLabel: bundle.getMessage('duration'),
                  customRelativeRangeDurationPlaceholder: bundle.getMessage('enter-duration'),
                  customRelativeRangeOptionLabel: bundle.getMessage('custom-range'),
                  customRelativeRangeOptionDescription: bundle.getMessage('set-a-custom-range-in-the-future'),
                  customRelativeRangeUnitLabel: bundle.getMessage('unit-of-time'),
                  formatRelativeRange: (rr): string => {
                    return (
                      `${bundle.getMessage('next')} ${Math.abs(rr.amount)} ${bundle.formatMessage(rr.unit, { amount: rr.amount.toString() })}`
                    );
                  },
                  formatUnit: (e, t) => (1 === t ? e : `${bundle.formatMessage(e, { amount: t.toString() })}`),
                  dateTimeConstraintText: '',
                  relativeModeTitle: bundle.getMessage('relative-range'),
                  absoluteModeTitle: bundle.getMessage('absolute-range'),
                  relativeRangeSelectionHeading: bundle.getMessage('choose-a-range'),
                  startDateLabel: bundle.getMessage('start-date'),
                  endDateLabel: bundle.getMessage('end-date'),
                  startTimeLabel: bundle.getMessage('start-time'),
                  endTimeLabel: bundle.getMessage('end-time'),
                  clearButtonLabel: bundle.getMessage('clear-and-dismiss'),
                  cancelButtonLabel: bundle.getMessage('cancel'),
                  applyButtonLabel: bundle.getMessage('apply'),
                }}
                invalid={!permanentFlag && (invalidFromDate || invalidThroughDate)}
                isDateEnabled={date => {
                  const [month, day, year] = [
                    (date.getMonth() + 1).toString().padStart(2, '0'),
                    date.getDate().toString().padStart(2, '0'),
                    date.getFullYear(),
                  ];
                  const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
                  return isFromDateValid(convertedDate, hoursExtFromDate);
                }}
                isValidRange={(value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
                  const result: DateRangePickerProps.ValidationResult = { valid: true };
                  return result;
                }}
                onChange={({ detail }) => dateRangeInputChangeHandler(detail.value)}
                placeholder='YYYY/MM/DD'
                relativeOptions={[
                  { key: 'tomorrow', amount: -1, unit: 'day', type: 'relative' },
                  { key: 'next-7-days', amount: -7, unit: 'day', type: 'relative' },
                  { key: 'next-14-days', amount: -14, unit: 'day', type: 'relative' },
                  { key: 'next-365-days', amount: -365, unit: 'day', type: 'relative' },
                ]}
                value={visitDateRange}
              />
            </FormField>
            <FormField label={bundle.getMessage('from-time')}>
              <div style={{ width: 100 }}>
                <TimeInput
                  disabled={permanentFlag}
                  format="hh:mm"
                  invalid={!permanentFlag && invalidFromDate}
                  onChange={event => fromTimeInputChangeHandler(event.detail.value)}
                  placeholder="hh:mm"
                  value={validFromTimeInput}
                />
              </div>
            </FormField>
            <FormField label={bundle.getMessage('to-time')}>
              <div style={{ width: 100 }}>
                <TimeInput
                  disabled={permanentFlag}
                  format="hh:mm"
                  invalid={!permanentFlag && invalidThroughDate}
                  onChange={event => throughTimeInputChangeHandler(event.detail.value)}
                  placeholder="hh:mm"
                  value={validThroughTimeInput}
                />
              </div>
            </FormField>
          </SpaceBetween>
        </SpaceBetween>
      </Form>
    </div>
  );
}
