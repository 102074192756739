import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import {
  createLookupTypeValue as createLookupTypeValueMutation,
  updateLookupTypeValue as updateLookupTypeValueMutation,
} from 'src/graphql/mutations';
import { listLookupTypeValuesForTypeName } from 'src/graphql/queries';
import { auditDecorator } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export let createLookupTypeValue = async (lookupTypeValue: APIt.LookupTypeValue): Promise<APIt.LookupTypeValue | null> => {
  debug(`createLookupTypeValue() lookupTypeValue is ${JSON.stringify(lookupTypeValue)}`);

  let createdLookupTypeValue: APIt.LookupTypeValue | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createLookupTypeValueMutation, {
      input: {
	      created_by: lookupTypeValue.created_by,
	      description: lookupTypeValue.description,
	      enabled: lookupTypeValue.enabled,
	      end_date: lookupTypeValue.end_date,
	      lookup_type_id: lookupTypeValue.lookup_type_id,
	      start_date: lookupTypeValue.start_date,
	      value: lookupTypeValue.value,
      }
    })) as GraphQLResult<APIt.CreateLookupTypeValueMutation>;
    debug(`createLookupTypeValue() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.createLookupTypeValue) createdLookupTypeValue = response.data.createLookupTypeValue as APIt.LookupTypeValue;
  } catch (e) {
    console.error(`createLookupTypeValue() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(createdLookupTypeValue);
};
createLookupTypeValue = auditDecorator('createLookupTypeValue', createLookupTypeValue);

export let updateLookupTypeValue = async (lookupTypeValue: APIt.LookupTypeValue): Promise<APIt.LookupTypeValue | null> => {
  debug(`updateLookupTypeValue() lookupTypeValue is ${JSON.stringify(lookupTypeValue)}`);

  let createdLookupTypeValue: APIt.LookupTypeValue | null = null;

  try {
    const response = await API.graphql(graphqlOperation(updateLookupTypeValueMutation, {
      input: {
	      description: lookupTypeValue.description,
	      enabled: lookupTypeValue.enabled,
	      end_date: lookupTypeValue.end_date,
	      id: lookupTypeValue.id,
	      start_date: lookupTypeValue.start_date,
	      updated_by: lookupTypeValue.updated_by,
	      value: lookupTypeValue.value,
      }
    })) as GraphQLResult<APIt.UpdateLookupTypeValueMutation>;
    debug(`updateLookupTypeValue() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateLookupTypeValue) createdLookupTypeValue = response.data.updateLookupTypeValue as APIt.LookupTypeValue;
  } catch (e) {
    console.error(`updateLookupTypeValue() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(createdLookupTypeValue);
};
updateLookupTypeValue = auditDecorator('updateLookupTypeValue', updateLookupTypeValue);