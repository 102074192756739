import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "../../../API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.AccessLevel>[] = [
  {
    id: 'accesslevel_name',
    header: <TranslateHeader value={'access-level-name'} />,
    cell: item => item.ACCESSLEVEL_NAME,
    minWidth: 30,
    sortingField: 'ACCESSLEVEL_NAME'
  },
  {
    id: 'accesslevel_activate',
    header: <TranslateHeader value={'access-level-activate'} />,
    cell: item => item.ACCESSLEVEL_ACTIVATE,
    minWidth: 30,
    sortingField: 'ACCESSLEVEL_ACTIVATE'
  },
  {
    id: 'accesslevel_deactivate',
    header: <TranslateHeader value={'access-level-deactivate'} />,
    cell: item => item.ACCESSLEVEL_DEACTIVATE,
    minWidth: 30,
    sortingField: 'ACCESSLEVEL_DEACTIVATE'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.SelfService.AccessLevels.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
