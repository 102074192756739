import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import { auditDecorator } from 'src/components/utils';
import { getAccessRequestDetails } from 'src/graphql/queries';
import { debug } from 'src/utils/commonUtils';

export let queryAccessRequestDetails = async (requestId: string): Promise<APIt.AccessRequestDetails[]> => {
  debug(`queryAccessRequestDetails() requestId is ${requestId}`);

  let accessRequestDetails: APIt.AccessRequestDetails[] = [];
  const limit = 500;
  let offset = 0;

  while (true) {
    try {
      const response = await API.graphql(graphqlOperation(getAccessRequestDetails,
        {
	        limit: limit,
	        offset: offset,
          request_id: requestId,
        })) as GraphQLResult<APIt.GetAccessRequestDetailsQuery>;
      if (response.data && response.data?.getAccessRequestDetails) {
        accessRequestDetails.push(...(response.data?.getAccessRequestDetails as APIt.AccessRequestDetails[]));
      } else {
        break;
      }
    } catch(error) {
      console.error(`queryAccessRequestDetails() error is ${JSON.stringify(error)}`);
      throw error;
    }
    offset += limit;
  }

  debug(`queryAccessRequestDetails() requestDetails is ${JSON.stringify(accessRequestDetails)}`);
  return accessRequestDetails;
};
queryAccessRequestDetails = auditDecorator('queryAccessRequestDetails', queryAccessRequestDetails);