import React from 'react';
import AccessRequestDetailsTablePanel from './TablePanel';
import { debug } from 'src/utils/commonUtils';

export default function AccessRequestDetails() {
  debug(`AccessRequestDetails()`);

  return(
    <AccessRequestDetailsTablePanel/>
  );
}