import * as APIt from "src/API";
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import { Checkbox } from '@amzn/awsui-components-react';
import EmployeeBadgePhoto from 'src/components/common/EmployeeBadgePhoto';
import React from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBundle } from "@amzn/react-arb-tools";

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.VisitorAccessLevel>[] = [
  {
    id: 'personId',
    header: <TranslateHeader value={'person-id'} />,
    cell: item => <>{`${item.last_name}, ${item.first_name}`} (<EmployeeBadgePhoto username={item.person_id} />)</>,
    minWidth: 50,
    sortingField: 'person_id'
  },
  {
    id: 'company',
    header: <TranslateHeader value={'company'} />,
    cell: item => item.company,
    sortingField: 'company'
  },
  {
    id: 'siteId',
    header: <TranslateHeader value={'site'} />,
    cell: item => item.site_id,
    minWidth: 85,
    sortingField: 'site_id'
  },
  {
    id: 'accessLevelName',
    header: <TranslateHeader value={'access'} />,
    cell: item => item.access_level_name,
    minWidth: 200,
    sortingField: 'access_level_name'
  },
  {
    id: 'permanentFlag',
    header: <TranslateHeader value={'permanent'} />,
    cell: item => <Checkbox checked={item.permanent_flag} disabled onChange={()=>{}}/>,
    sortingField: 'permanent_flag'
  },
  {
    id: 'validFrom',
    header: <TranslateHeader value={'valid-from'} />,
    cell: item => item.start_date?.slice(0,16),
    minWidth: 120,
    sortingField: 'start_date'
  },
  {
    id: 'validThrough',
    header: <TranslateHeader value={'valid-through'} />,
    cell: item => item.end_date?.slice(0,16),
    minWidth: 120,
    sortingField: 'end_date'
  },
  {
    id: 'status',
    header: <TranslateHeader value={'status'} />,
    cell: item => item.status,
    minWidth: 100,
    sortingField: 'status'
  },
  {
    id: 'requestedBy',
    header: <TranslateHeader value={'requested-by'} />,
    cell: item => <EmployeeBadgePhoto username={item.requestor_id} employeeId='-1'/>,
    maxWidth: 0,
    minWidth: 0,
    sortingField: 'requestor_id'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
