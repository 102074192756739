import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Flashbar,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery } from '@tanstack/react-query';
import { IQueryVisitorSiteAssetsParams, queryVisitorSiteAssets } from './utils';
import { deleteVisitorAsset as deleteVisitorAssetMutation } from 'src/graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { VisitorTypes } from 'src/constants/Constants';
import AssignStickerNum from './AssignStickerNum';
import { updateVisitorAsset } from '../utils';
import { queryEmployeeDetails } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export interface VisitorSiteAssetsTablePanelPropsInterface {
  closeViewVisitorSiteAssetsCallback: Function;
  siteCode: string;
  username: string;
}

export default function VisitorSiteAssetsTablePanel(props: VisitorSiteAssetsTablePanelPropsInterface ) {
  debug(`VisitorSiteAssetsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAsset[]>([]);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [removingAssets, setRemovingAssets] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<APIt.VisitorAsset[]>([]);
  const [showAssignStickerNum, setShowAssignStickerNum] = useState<boolean>(false);
  const [visitorAssetDeleteSaved, setVisitorAssetDeleteSaved] = useState<boolean>(false);
  const [visitorEmail, setVisitorEmail] = useState<string>('');
  const [visitorFirstName, setVisitorFirstName] = useState<string>('');
  const [visitorLastName, setVisitorLastName] = useState<string>('');
  const [visitorStickerNum, setVisitorStickerNum] = useState<string>('');
  const [visitorType, setVisitorType] = useState<SelectProps.Option | null>(null);
  const [visitorUsername, setVisitorUsername] = useState<string>('');  


  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAssets.VisitorSiteAssets.TablePanel');

  const textFilterRef = useRef<HTMLTextAreaElement>(null);
  const visitorFirstNameRef = useRef<HTMLInputElement>(null);
  const visitorUsernameRef = useRef<HTMLInputElement>(null);

  const visitorSiteAssetsQuery = useQuery({
    enabled: false,
    queryKey: ['visitorSiteAssets'],
    queryFn: async () => {
      debug(`VisitorSiteAssetsTablePanel() visitorSiteAssetsQuery visitorUsername ${visitorUsername}`);
      let personId = visitorUsername;
      debug(`VisitorSiteAssetsTablePanel() visitorSiteAssetsQuery visitorType ${JSON.stringify(visitorType)}`);
      if (visitorType?.value === VisitorTypes.Employee) personId = (await queryEmployeeDetails(visitorUsername))?.id || visitorUsername;
      const queryVisitorSiteAssetsParams: IQueryVisitorSiteAssetsParams = {
        email: visitorEmail,
        firstName: visitorFirstName,
        lastName: visitorLastName,
        personId: personId,
        siteId: props.siteCode, 
        stickerNum: visitorStickerNum,
        visitorType: visitorType?.value as VisitorTypes        
      }
      return queryVisitorSiteAssets(queryVisitorSiteAssetsParams);
    },
    retry: 3
  });

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-assets-found') : 'No Assets Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const deleteSelectedVisitorAssets = async () => {
    setRemovingAssets(true);
    for (let selectedItem of selectedItems) {
      try {
        await API.graphql(graphqlOperation(deleteVisitorAssetMutation,
          {
            input:
              {
                id: selectedItem.id,
                updated_by: props.username
              }
          }));
      } catch (e) {
        console.error(`deleteVisitorAssets(): exception is ${JSON.stringify(e)}`);
      }
    }
    const newAllItems = allItems!.filter( (item) => !selectedItems.find( si => si.id == item.id));
    setAllItems(newAllItems);
    setDeleteVisible(false);
    setVisitorAssetDeleteSaved(true);
    setSelectedItems([]);
    setRemovingAssets(false);
    visitorSiteAssetsQuery.refetch();
  };

  const deleteBtnClickHandler = () => {
    if (selectedItems.length > 0) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const resetVisitorAssetDeleteSaved = () => {
    setVisitorAssetDeleteSaved(false);
  };

  const assignStickerNum = async (stickerNum: string) => {
    const visitorAsset: APIt.UpdateVisitorAssetInput = {
      approval_status_id: null,
      asset_type_id: null,
      description: null,
      denial_reason_id: null,
      end_date: null,
      id: selectedItems[0].id,
      make: null,
      model: null,
      notes: null,
      permanent_flag: null,
      serial_num: null,
      site_id: selectedItems[0].site_id,
      site_source_system_id: selectedItems[0].site_source_system_id,
      start_date: null,
      sticker_num: stickerNum,
      updated_by: props.username,
      visitor_id: null,
    };
    try {
      await updateVisitorAsset(visitorAsset);
      setShowAssignStickerNum(false);
      visitorSiteAssetsQuery.refetch();
    } catch(error) {
      setErrorText(JSON.stringify(error));
    }
  };

  const FlashMessageDeleteSaved = () => (
    <Flashbar
      items = {
        [
          {
            header: bundle.getMessage('assets-removed'),
            dismissible: true,
            dismissLabel: bundle.getMessage('dismiss'),
            onDismiss: resetVisitorAssetDeleteSaved,
            type: 'success',
          },
        ]
      }
    />);

  useEffect(() => {
    if (visitorSiteAssetsQuery.data) setAllItems(visitorSiteAssetsQuery.data);
    textFilterRef.current?.focus();
  }, [visitorSiteAssetsQuery.data]);

  useEffect(() => {
    if (visitorType?.value === VisitorTypes.Employee) {
      visitorUsernameRef.current?.focus();
    }
    if (visitorType?.value !== VisitorTypes.Employee) {
      visitorFirstNameRef.current?.focus();
    }
    setVisitorEmail('');
    setVisitorFirstName('');
    setVisitorLastName('');
    setVisitorStickerNum('');
    setVisitorUsername('');    
  }, [visitorType]);

    //reset function which invokes setVisitor... methods with empty string as a parameter
    const resetVisitor = () => {
      visitorSiteAssetsQuery.remove();
      setVisitorEmail('');
      setVisitorFirstName('');
      setVisitorLastName('');
      setVisitorStickerNum('');
      setVisitorType(null);
      setVisitorUsername('');      
      setAllItems([]);
    }

  useEffect(() => {
    return () => {
      resetVisitor();
    }
  }, []);

  
  
  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      {visitorAssetDeleteSaved && <FlashMessageDeleteSaved/>}
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter Assets'
            filteringPlaceholder={bundle.getMessage('find-assets')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            ref={textFilterRef}
          />
        }
        footer={
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='AssetsAssignStickerNumButton'
                  disabled={selectedItems.length !== 1}
                  onClick={() => setShowAssignStickerNum(true)}
                  variant='normal'
                >
                  {bundle.getMessage('assign-sticker-num')}
                </Button>
                <Button
                  data-testid='AssetsRemoveSelectedButton'
                  disabled={selectedItems.length == 0}
                  onClick={deleteBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        header={
          <>
            <Header
              counter={`(${itemsCount().toString()})`}
            />
            <form onSubmit={e => e.preventDefault()}>
              <SpaceBetween direction='horizontal' size='xs'>
                <FormField label={bundle.getMessage('visitor-type')}>
                  <Select
                    disabled={visitorStickerNum != ''}
                    expandToViewport
                    options={Object.values(VisitorTypes).map(v => {
                      return(
                        {
                          label: v === VisitorTypes.Employee ? 'Associate' : v,
                          value: v
                        });
                    })}
                    onChange={({ detail }) => {
                      setVisitorType(detail.selectedOption);
                    }}
                    placeholder={bundle.getMessage('select-visitor-type')}
                    selectedOption={visitorType}
                  />
                </FormField>
                <FormField label={bundle.getMessage('username')} >
                  <Input
                    data-testid='VisitorSiteAssetsVisitorUsernameField'
                    disabled={visitorType?.value !== VisitorTypes.Employee}
                    onChange={event => {
                      setVisitorUsername(event.detail.value)
                    }}
                    ref={visitorUsernameRef}
                    value={visitorUsername}
                  />
                </FormField>
                <FormField label={bundle.getMessage('first-name')} >
                  <Input
                    data-testid='VisitorSiteAssetsVisitorFirstNameField'
                    disabled={!visitorType || visitorType?.value === VisitorTypes.Employee}
                    onChange={event => {
                      setVisitorFirstName(event.detail.value)
                    }}
                    ref={visitorFirstNameRef}
                    value={visitorFirstName}
                  />
                </FormField>
                <FormField label={bundle.getMessage('last-name')} >
                  <Input
                    data-testid='VisitorSiteAssetsVisitorLastNameField'
                    disabled={!visitorType || visitorType?.value === VisitorTypes.Employee}
                    onChange={event => {
                      setVisitorLastName(event.detail.value)
                    }}
                    value={visitorLastName}
                  />
                </FormField>
                <FormField label={bundle.getMessage('email-address')} >
                  <Input
                    data-testid='VisitorSiteAssetsVisitorEmailField'
                    disabled={!visitorType || visitorType?.value === VisitorTypes.Employee}
                    onChange={event => {
                      setVisitorEmail(event.detail.value)
                    }}
                    value={visitorEmail}
                  />
                </FormField>
                <FormField label={bundle.getMessage('sticker-num')} >
                  <Input
                    data-testid='VisitorSiteAssetsVisitorStickerField'
                    disabled={visitorType !== null}
                    onChange={event => {
                      setVisitorStickerNum(event.detail.value)
                    }}
                    value={visitorStickerNum}
                  />
                </FormField>
                <FormField label='&#8203;'>
                  <Button
                    disabled={
                      !visitorStickerNum 
                      &&
                      (!visitorType
                      ||
                      (visitorType?.value === VisitorTypes.Employee && !visitorUsername)
                      ||
                      (visitorType?.value !== VisitorTypes.Employee && ((visitorFirstName === '' || visitorLastName === '') && (visitorEmail === '')))
                      || visitorSiteAssetsQuery.isFetching
                      )
                    }
                    onClick={() => visitorSiteAssetsQuery.refetch()}
                    variant='primary'
                    loading={visitorSiteAssetsQuery.isFetching}
                  >
                    {bundle.getMessage('search')}
                  </Button>                 
                </FormField>
                <FormField label='&#8203;'>
                  <Button                                 
                    formAction='none'
                    iconName='undo'
                    key='resetBtn'
                    onClick={resetVisitor}
                  />
                </FormField>               
              </SpaceBetween>
            </form>
          </>
        }
        items={items}
        loading={visitorSiteAssetsQuery.isFetching}
        loadingText={bundle.getMessage('loading-assets')}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='id'
        variant='embedded'
      />
      {deleteVisible
      &&
      <Modal
        onDismiss={() => setDeleteVisible(false)}
        visible={deleteVisible}
        closeAriaLabel='Close'
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={deleteNoBtnClickHandler}>{bundle.getMessage('cancel')}</Button>
              <Button
                data-testid='AssetsRemoveButton'
                disabled={removingAssets}
                loading={removingAssets}
                onClick={deleteSelectedVisitorAssets}
                variant='primary'
              >
                {bundle.getMessage('remove')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={bundle.getMessage('confirm-asset-removal')}
      >
        {bundle.getMessage('please-confirm-asset-removal')}
      </Modal>
      }
      {showAssignStickerNum
      &&
      <Modal
        header={
          <Header>
            {bundle.getMessage('assign-asset-sticker-num')}
          </Header>
        }
        onDismiss={() => setShowAssignStickerNum(false)}
        size='small'
        visible={showAssignStickerNum}
      >
        <AssignStickerNum
          assignStickerNum={assignStickerNum}
          cancelAssignStickerNum={() => setShowAssignStickerNum(false)}
          errorText={errorText}
        />
      </Modal>
      }
    </>
  );
}
