import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "src/API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";
import { Checkbox } from '@amzn/awsui-components-react';
import EmployeeBadgePhoto from 'src/components/common/EmployeeBadgePhoto';

export const DelegateesColumnDefinitions: TableProps.ColumnDefinition<APIt.Delegation>[] = [
  {
    id: 'username',
    header: <TranslateHeader value={'username'} />,
    cell: item => <EmployeeBadgePhoto username={item.delegatee_username }></EmployeeBadgePhoto>,
    minWidth: 30,
    sortingField: 'delegatee_username'
  },
  {
    id: 'typeOfView',
    header: <TranslateHeader value={'delegation-type'} />,
    cell: item => item.delegation_type ?? 'empty',
    minWidth: 10,
    sortingField: 'delegation_type'
  },  
  {
    id: 'permanentFlag',
    header: <TranslateHeader value={'permanent'} />,
    cell: item => <Checkbox checked={item.permanent_flag} disabled onChange={()=>{}}/>,
    minWidth: 10,
    sortingField: 'permanent_flag'
  },
  {
    id: 'validFrom',
    header: <TranslateHeader value={'valid-from'} />,
    cell: item => item.start_date,
    minWidth: 20,
    sortingField: 'start_date'
  },
  {
    id: 'validThrough',
    header: <TranslateHeader value={'valid-through'} />,
    cell: item => item.end_date,
    minWidth: 20,
    sortingField: 'end_date'
  }  
];

interface EmptyStateProps {
  title: string
}

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.SelfService.Delegations.Delegatees.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
