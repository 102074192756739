import React from 'react';
import csvDownload from 'json-to-csv-export';
import { useBundle } from '@amzn/react-arb-tools';
import { Button, Spinner } from '@amzn/awsui-components-react';
import { auditDecorator } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

let csvDownloadWrapper = (data: any, filename: string | undefined, delimiter: string | undefined, headers: string[] | undefined) => {
  csvDownload({ data, filename, delimiter, headers });
};
csvDownloadWrapper = auditDecorator('csvDownloadWrapper', csvDownloadWrapper);

interface ICsvDownloadButton {
  data: any;
  disabled: boolean;
  filename?: string;
  delimiter?: string;
  headers?: string[];
}

const CsvDownloadButton = (props: ICsvDownloadButton) => {
  const { data, filename, delimiter, headers, disabled } = props;

  const [bundle, isBundleLoading] = useBundle('components.common.CsvDownloadButton');

  if (isBundleLoading) return <Spinner/>;

  return (
    <Button
      iconName={'download'}
      onClick={() => csvDownloadWrapper(data, filename, delimiter, headers)}
      disabled={disabled}
    >
      {bundle.getMessage('download')}
    </Button>
  );
};
export default CsvDownloadButton;