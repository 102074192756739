import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  DatePicker,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import * as APIt from 'src/API';
import * as uuid from 'uuid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createLookupTypeValue, updateLookupTypeValue } from './utils';
import { debug } from 'src/utils/commonUtils';

export interface ILookupTypeValueProps {
  closeCallback: Function;
  lookupType: APIt.LookupType;
  lookupTypeValue: APIt.LookupTypeValue | undefined;
  updateFailureCallback: Function;
  updateSuccessCallback: Function;
  addFailureCallback: Function;
  addSuccessCallback: Function;
  username: string;
}

export default function LookupTypeValue(props: ILookupTypeValueProps) {
  debug(`LookupTypeValue() props is ${JSON.stringify(props)}`);

  const defaultLookupTypeValue: APIt.LookupTypeValue = 
    {
      __typename: 'LookupTypeValue',
      id: uuid.v4(),
      lookup_type_id: props.lookupType.id,
      value: '',
      description: '',
      enabled: false,
      created: Date.now().toString(),
      updated: Date.now().toString(),
      created_by: props.username,
      updated_by: props.username,
    };

  const [lookupTypeValue, setLookupTypeValue] = useState<APIt.LookupTypeValue>(props.lookupTypeValue || defaultLookupTypeValue);
  const [startDateChanged, setStartDateChanged] = useState<boolean>(false);
  const [endDateChanged, setEndDateChanged] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.LookupTypes.LookupTypeValues.LookupTypeValue');

  const queryClient = useQueryClient();

  const createLookupTypeValueMutation = useMutation({
    mutationFn: createLookupTypeValue,
    onSuccess: async () => {
      props.addSuccessCallback();
      await queryClient.invalidateQueries({ queryKey: ['lookupTypeValues'] });
    },
    onError: () => props.addFailureCallback(),
  });

  const updateLookupTypeValueMutation = useMutation({
    mutationFn: updateLookupTypeValue,
    onSuccess: async () => {
      props.updateSuccessCallback();
      await queryClient.invalidateQueries({ queryKey: ['lookupTypeValues'] });
    },
    onError: () => props.updateFailureCallback(),
  });

  const formChanged = (): boolean => {
    return(
      JSON.stringify(props.lookupTypeValue) == JSON.stringify(lookupTypeValue)
      ||
      (!props.lookupTypeValue && (!lookupTypeValue.value || !lookupTypeValue.description))
    );
  };

  const saveForm = () => {
    props.lookupTypeValue ? updateLookupTypeValueMutation.mutate(lookupTypeValue) : createLookupTypeValueMutation.mutate(lookupTypeValue);
    props.closeCallback();
  };

  const invalidStartDate = (): boolean => {
    if (!lookupTypeValue.start_date) return false;
    const currentDate = (new Date);
    const currentDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2,'0')}-${currentDate.getDate().toString().padStart(2,'0')}`;
    return (lookupTypeValue?.start_date < currentDateString);
  };

  const invalidEndDate = (): boolean => {
    if (!lookupTypeValue.start_date) return false;
    if (!lookupTypeValue.end_date) return false;
    const currentDate = (new Date);
    const currentDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2,'0')}-${currentDate.getDate().toString().padStart(2,'0')}`;
    return (lookupTypeValue?.end_date < lookupTypeValue?.start_date || lookupTypeValue?.end_date < currentDateString);
  };

  useEffect(() => {
    setLookupTypeValue(props.lookupTypeValue || defaultLookupTypeValue);
    setStartDateChanged(false);
    setEndDateChanged(false);
  }, [props.lookupTypeValue])

  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      <Form
        actions={
          <SpaceBetween size="s" direction="horizontal">
            <Button
              key="lookupTypeValueCancelBtn"
              loading={false}
              onClick={() => {
                setLookupTypeValue(defaultLookupTypeValue);
                props.closeCallback();
              }}
            >
              {bundle.getMessage("cancel")}
            </Button>
            <Button
              disabled={
                formChanged()
                ||
                (startDateChanged && invalidStartDate())
                ||
                (endDateChanged && invalidEndDate())
              } 
              key="lookupTypeValueSaveBtn"
              loading={
                props.lookupTypeValue
                  ? updateLookupTypeValueMutation.isLoading
                  : createLookupTypeValueMutation.isLoading
              }
              onClick={saveForm}
              variant="primary"
            >
              {bundle.getMessage("save")}
            </Button>
          </SpaceBetween>
        }
      >
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}>
          <FormField label={bundle.getMessage("value")}>
            <Input
              onChange={({ detail }) =>
                setLookupTypeValue({ ...lookupTypeValue, value: detail.value })
              }
              value={lookupTypeValue?.value || ""}
            />
          </FormField>
          <FormField label={bundle.getMessage("description")}>
            <Input
              onChange={({ detail }) =>
                setLookupTypeValue({
                  ...lookupTypeValue,
                  description: detail.value,
                })
              }
              value={lookupTypeValue?.description || ""}
            />
          </FormField>
        </Grid>
        <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }, { colspan: 2 }]}>
          <FormField label={bundle.getMessage("start-date")}>
            <DatePicker
              nextMonthAriaLabel={""}
              invalid={startDateChanged && invalidStartDate()}
              onChange={({ detail }) => {
                setStartDateChanged(true);
                setLookupTypeValue({
                  ...lookupTypeValue,
                  start_date: detail.value,
                });
              }}
              previousMonthAriaLabel={""}
              todayAriaLabel={""}
              value={lookupTypeValue?.start_date || ""}
            />
          </FormField>
          <FormField label={bundle.getMessage("end-date")}>
            <DatePicker
              nextMonthAriaLabel={""}
              invalid={endDateChanged && invalidEndDate()}
              onChange={({ detail }) => {
                setEndDateChanged(true);
                setLookupTypeValue({
                  ...lookupTypeValue,
                  end_date: detail.value,
                });
              }}
              previousMonthAriaLabel={""}
              todayAriaLabel={""}
              value={lookupTypeValue?.end_date || ""}
            />
          </FormField>
          <FormField label={bundle.getMessage("enabled")}>
            <Checkbox
              checked={lookupTypeValue.enabled}
              onChange={({ detail }) =>
                setLookupTypeValue({
                  ...lookupTypeValue,
                  enabled: detail.checked,
                })
              }
            />
          </FormField>
        </Grid>
      </Form>
    </Container>
  );

}
