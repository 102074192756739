import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DateRangePicker,
  DateRangePickerProps,
  Flashbar,
  FormField,
  Grid,
  Modal,
  Pagination,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TimeInput,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import * as APIt from 'src/API';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  IQuerySupervisorReportsParams,
  isFromDateValid,
  isThroughDateValid,
  queryAccessLevelApproverGroups,
  queryAccessLevelsForSite,
  queryLookupTypeValueForTypeAndDescription,
  querySupervisorReports,
} from 'src/components/utils';
import { LookupTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import { IQueryAccessLevelsParams, queryAccessLevels } from '../../AccessLevels/utils';
import { accessLevelApproverGroupMembersExist } from '../../utils';
import AdvancedEmployeeSearch from 'src/components/common/AdvancedEmployeeSearch.tsx/TablePanel';
import { debug } from 'src/utils/commonUtils';

export interface IAccessDetailsRecord {
  id: number;
  accessLevelId: string | undefined;
  accessLevelName: string | undefined;
  permanentFlag: boolean;
  site: string | undefined;
  validFrom: string | undefined;
  validThrough: string | undefined;
}

interface IExistingAccessLevel {
  cardholderName: string;
  accessLevelName: string;
  accessLevelActivate: string | null | undefined;
  accessLevelDeactivate: string | null | undefined;
  permanent: boolean;
}

export interface IAccessDetailsTablePanelProps{
  accessLevels: IAccessDetailsRecord[];
  cardholders: APIt.EmployeeDetails[];
  clear: boolean;
  detailsClearedCallback: Function;
  setAccessLevelsCallback: Function;
  username: string;
}

interface IAccessLevelOption extends SelectProps.Option {
  noApproverGroupLinked: boolean;
  noApproverGroupMembers: boolean;
  contingentWorkerSelected: boolean;
}

const defaultValidFromTime = '08:00';
const defaultValidThroughTime = '17:00';

export default function AccessDetailsTablePanel(props: IAccessDetailsTablePanelProps) {
  debug(`AccessDetailsTablePanel() props is ${JSON.stringify(props)}`);

  const queryClient = useQueryClient();

  const [accessLevelInput, setAccessLevelInput] = useState<SelectProps.Option | null>(null);
  const [accessLevelOptions, setAccessLevelOptions] = useState<IAccessLevelOption[]>([]);
  const [adding, setAdding] = useState<boolean>(false);
  const [allItems, setAllItems] = useState<IAccessDetailsRecord[]>(props.accessLevels);
  const [checkAccessLevelApproverGroups, setCheckAccessLevelApproverGroups] = useState<boolean>(false);
  const [duplicateInputAccessLevelFound, setDuplicateInputAccessLevelFound] = useState<boolean>(false);
  const [currentEmployeeDetailsId, setCurrentEmployeeDetailsId] = useState<string | null>(() => {
    const empDetails = queryClient.getQueryData<APIt.EmployeeDetails>(['currentUserEmployeeDetails']);
    debug(`AccessDetailsTablePanel() empDetails is ${JSON.stringify(empDetails)}`);
    if (empDetails) {
      return empDetails.id;
    }
    return null;
  });
  const [nonReportIncluded, setNonReportIncluded] = useState<boolean>(false);
  const [existingInputAccessLevelFound, setExistingInputAccessLevelFound] = useState<boolean>(false);
  const [existingInputAccessLevels, setExistingInputAccessLevels] = useState<IExistingAccessLevel[]>([]);
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [id, setId] = useState<number>(1);
  const [invalidFromDate, setInvalidFromDate] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [permanentFlagInput, setPermanentFlagInput] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<IAccessDetailsRecord[]>([]);
  const [showSearchReports, setShowSearchReports] = useState<boolean>(false);
  const [siteInput, setSiteInput] = useState<SelectProps.Option | null>(null);
  const [sites, setSites] = useState<APIt.Site[]>(queryClient.getQueryData(['sites']) || []);
  const [validFromDateInput, setValidFromDateInput] = useState<any>(undefined);
  const [validFromTimeInput, setValidFromTimeInput] = useState<string>(defaultValidFromTime);
  const [validThroughDateInput, setValidThroughDateInput] = useState<string>('');
  const [validThroughTimeInput, setValidThroughTimeInput] = useState<string>(defaultValidThroughTime);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAccess.AccessDetails.TablePanel');

  const siteRef = useRef<HTMLInputElement>(null);

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const duplicateInputAccessLevel = (): boolean => {
    debug(`duplicateInputAccessLevel()`);
    const foundItem = allItems.find(i => {
      debug(`duplicateInputAccessLevel() ${i.accessLevelId} == ${accessLevelInput?.value}`);
      const result = i.accessLevelId == accessLevelInput?.value;
      debug(`duplicateInputAccessLevel() result is ${result}`);
      return result;
    });
    debug(`duplicateInputAccessLevel() foundItem is ${JSON.stringify(foundItem)}`);
    if (foundItem) return true;
    return false;
  };

  const flagExistingAccessLevels = async (addedAccessLevels: IAccessDetailsRecord[]): Promise<void> => {
    debug(`AccessDetails() flagExistingAccessLevels()`);
    const existingAccessLevels: IExistingAccessLevel[] = [];
    setExistingInputAccessLevelFound(false);
    setExistingInputAccessLevels(existingAccessLevels);
    for (let cardholder of props.cardholders) {
      debug(`AccessDetails() flagExistingAccessLevels() cardholder is ${JSON.stringify(cardholder)}`);
      const queryAccessLevelsParams: IQueryAccessLevelsParams = {
        employeeId: cardholder.id.toString(),
        Orderby: 'accesslevelname',
        OrderbyDirection: 'a',
        PageNumber: 0,
        RecordsByPage: 500
      };
      const cardholderExistingAccessLevels = await queryAccessLevels(queryAccessLevelsParams);
      debug(`AccessDetails() flagExistingAccessLevels() existingAccessLevels is ${JSON.stringify(cardholderExistingAccessLevels)}`);
      const currentDate = new Date();
      const currentDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2,'0')}-${currentDate.getDate().toString().padStart(2,'0')}`;
      for (let accessLevel of addedAccessLevels) {
        debug(`AccessDetails() flagExistingAccessLevels() accessLevel is ${JSON.stringify(accessLevel)}`);
        cardholderExistingAccessLevels.forEach(al => {
          if
            (al.ACCESSLEVEL_NAME == accessLevel.accessLevelName
            &&
              (al.ACCESSLEVEL_DEACTIVATE == undefined
              || al.ACCESSLEVEL_DEACTIVATE == null
              || al.ACCESSLEVEL_DEACTIVATE >= currentDateString))
          {
            setExistingInputAccessLevelFound(true);
            existingAccessLevels.push({
              cardholderName: `${cardholder.lastName}, ${cardholder.firstName}`,
              accessLevelName: al.ACCESSLEVEL_NAME,
              accessLevelActivate: al.ACCESSLEVEL_ACTIVATE,
              accessLevelDeactivate: al.ACCESSLEVEL_DEACTIVATE,
              permanent:
                (al.ACCESSLEVEL_ACTIVATE == undefined || al.ACCESSLEVEL_ACTIVATE == null
                && al.ACCESSLEVEL_DEACTIVATE == undefined || al.ACCESSLEVEL_DEACTIVATE == null)
            });
          }
        });
      }
      setExistingInputAccessLevels([...existingAccessLevels]);
    }
  }

  const addHandler = async () => {
    setAdding(true);
    let i = id + 1;
    setId(i);
    const newItems = [];
    let invalidDate = false;
    if (!permanentFlagInput && !isFromDateValid(new Date(`${validFromDateInput} ${validFromTimeInput}`), hoursExtFromDate)) {
      setInvalidFromDate(true);
      invalidDate = true;
    }
    if (!permanentFlagInput && !isThroughDateValid(
      new Date(`${validFromDateInput} ${validFromTimeInput}`),
      new Date(`${validThroughDateInput} ${validThroughTimeInput}`)))
    {
      setInvalidThroughDate(true);
      invalidDate = true;
    }
    if (invalidDate) {
      setAdding(false);
      return;
    }
    if (!duplicateInputAccessLevel()) {
      newItems.push(
        {
          accessLevelId: accessLevelInput?.value,
          accessLevelName: accessLevelInput?.label,
          id: i,
          permanentFlag: permanentFlagInput,
          site: siteInput?.value,
          validFrom: !permanentFlagInput ? `${validFromDateInput} ${validFromTimeInput}` : undefined,
          validThrough: !permanentFlagInput ? `${validThroughDateInput} ${validThroughTimeInput}` : undefined
        }
      );
    } else {
      setDuplicateInputAccessLevelFound(true);
    }
    props.setAccessLevelsCallback([...allItems, ...newItems]);
    setAdding(false);
  };

  const dateRangeInputChangeHandler = (value: DateRangePickerProps.Value | null) => {
    debug(`AccessDetailsTablePanel() dateRangeInputChangeHandler() value is ${JSON.stringify(value)}`);
    setVisitDateRange(value);
    if (value?.type == 'absolute') {
      setValidFromDateInput(value.startDate);
      setInvalidFromDate(!isFromDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), hoursExtFromDate));
      setValidThroughDateInput(value.endDate);
      setInvalidFromDate(!isThroughDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), new Date(`${value.endDate} ${validThroughTimeInput}`)));
    }
    if (value?.type == 'relative') {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      debug(`AccessDetailsTablePanel() dateRangeInputChangeHandler() fromDate is ${fromDate}`);
      setValidFromDateInput(`${fromDate.getFullYear()}-${(fromDate.getMonth()+1).toString().padStart(2, '0')}-${fromDate.getDate().toString().padStart(2, '0')}`);
      const throughDate = new Date();
      let factor = 1;
      switch(value.unit) {
        case('day'):
          factor = Math.abs(value.amount)*1;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('month'):
          factor = Math.abs(value.amount);
          debug(`AccessDetailsTablePanel() dateRangeInputChangeHandler() ${throughDate.getFullYear()} ${throughDate.getMonth()} ${throughDate.getDate()}`);
          const dateUTC = Date.UTC(throughDate.getFullYear(), throughDate.getMonth() + factor, throughDate.getDate());
          debug(`AccessDetailsTablePanel() dateRangeInputChangeHandler() dateUTC is ${dateUTC}`);
          throughDate.setTime(dateUTC);
          break;
        case('week'):
          factor = Math.abs(value.amount)*7;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('year'):
          factor = Math.abs(value.amount)*365;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
      }
      debug(`AccessDetailsTablePanel() dateRangeInputChangeHandler() throughDate is ${throughDate}`);
      const throughDateInput = `${throughDate.getFullYear()}-${(throughDate.getMonth()+1).toString().padStart(2, '0')}-${throughDate.getDate().toString().padStart(2, '0')}`;
      setValidThroughDateInput(throughDateInput);
      if (fromDate !== null) setInvalidFromDate(!isFromDateValid(fromDate, hoursExtFromDate));
      if (fromDate !== null && throughDate !== null) setInvalidThroughDate(throughDateInput == '' ? false : !isThroughDateValid(fromDate, throughDate));
    }
  };
 
  const fromTimeInputChangeHandler = (value: string) => {
    setValidFromTimeInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${validFromDateInput} ${value}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${value}`),
          new Date(`${validThroughDateInput} ${validThroughTimeInput}`)));
  };
 
  const throughTimeInputChangeHandler = (value: string) => {
    setValidThroughTimeInput(value)
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${validThroughDateInput} ${value}`)));
  };

  const removeSelected = async () => {
    const currentItems = allItems;
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.id == al.id));
    setSelectedItems([]);
    setAllItems(newItems);
    props.setAccessLevelsCallback(newItems);
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as IAccessDetailsRecord[]);
  };

  useEffect(() => {
    if (props.cardholders.length > 0
      && siteRef
      && !siteInput
      && props.accessLevels.length == 0
    ) {
      siteRef.current?.focus();
    }
  }, [props.cardholders, props.accessLevels, siteRef]);

  const accessLevelsForSiteQuery = useQuery({
    queryKey: ['accessLevelsForSiteRequestAccess'],
    queryFn: () => queryAccessLevelsForSite(siteInput?.value),
    onSuccess: async (data) => {
      setCheckAccessLevelApproverGroups(true);
      if (!accessLevelsForSiteQuery.data) return [];
      const options = await Promise.all(data.map(async (al): Promise<any> => {
        debug(`AccessDetailsTablePanel() accessLevelsForSiteQuery() onSuccess() al is ${JSON.stringify(al)}`);
        try {
          const accessLevelApproverGroups = (await queryAccessLevelApproverGroups(al.AccessLevelName)) || [];
          debug(`AccessDetailsTablePanel() accessLevelsForSiteQuery() onSuccess() accessLevelsApproverGroups is ${JSON.stringify(accessLevelApproverGroups)}`);
          let accessLevelNoApproverGroupMembers = false;
          if (accessLevelApproverGroups?.length > 0) accessLevelNoApproverGroupMembers = !(await accessLevelApproverGroupMembersExist(accessLevelApproverGroups));
          debug(`AccessDetailsTablePanel() accessLevelsForSiteQuery() onSuccess() accessLevelNoApproverGroupMembers is ${JSON.stringify(accessLevelNoApproverGroupMembers)}`);
          const contingentWorkerSelected = props.cardholders.find(ch => ch.jobLevel === '99') !== undefined;
          debug(`AccessDetailsTablePanel() accessLevelsForSiteQuery() onSuccess() contingentWorkerSelected is ${contingentWorkerSelected}`);
          return {
            label: al.AccessLevelName,
            value: al.AccessLevelID?.toString(),
            contingentWorkerSelected: contingentWorkerSelected,
            disabled:
              accessLevelApproverGroups.length == 0
              ||
                (props.cardholders.find(ch => ch.jobLevel === '99')
                &&
                (al.AccessLevelName.includes('-4-') || al.AccessLevelName.includes('-5-')))
              || accessLevelNoApproverGroupMembers,
            noApproverGroupLinked: accessLevelApproverGroups.length === 0,
            noApproverGroupMembers: accessLevelNoApproverGroupMembers,
          };
        } catch(error) {
          return {
            label: al.AccessLevelName,
            value: al.AccessLevelID?.toString(),
            disabled: true,
          };
        }
      }));
      debug(`AccessDetailsTablePanel() accessLevelsForSiteQuery() onSuccess() options is ${JSON.stringify(options)}`);
      setAccessLevelOptions(options);
      setCheckAccessLevelApproverGroups(false);
    },
    retry: 3
  });

  const copyAccessLevelsFromEmployees = async (employees: APIt.EmployeeDetails[]): Promise<void> => {
    setNonReportIncluded(false);
    return new Promise(async (resolve, reject) => {
      debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() employees is ${JSON.stringify(employees)}`);
      let emplId = currentEmployeeDetailsId;
      if (!emplId) {
        const empDetails = queryClient.getQueryData<APIt.EmployeeDetails>(['currentUserEmployeeDetails']);
        debug(`AccessDetailsTablePanel() empDetails is ${JSON.stringify(empDetails)}`);
        if (empDetails) {
          setCurrentEmployeeDetailsId(empDetails.id);
        }
        reject();
      }
      const querySupervisorReportsParams: IQuerySupervisorReportsParams = { emplId: emplId! };
      debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() querySupervisorReportsParams is ${JSON.stringify(querySupervisorReportsParams)}`);
      const supervisorReports = await querySupervisorReports(querySupervisorReportsParams);
      debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() supervisorReports is ${JSON.stringify(supervisorReports)}`);
      debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() supervisorReports.length is ${supervisorReports.length}`);
      let newAccessLevels: IAccessDetailsRecord[] = [];
      let i = id;
      let nonReportIncluded = false;
      for (let employee of employees) {
        if (employee.id !== emplId && !supervisorReports.find(sr => sr.FinalPSID === employee.id)) {
          debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() supervisorReports does not include ${JSON.stringify(employee)}`);
          nonReportIncluded = true;
        }
      }
      if (nonReportIncluded) {
        setNonReportIncluded(true);
      } else {
        for (let employee of employees) {
          debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() employee is ${JSON.stringify(employee)}`);
          const queryAccessLevelsParams: IQueryAccessLevelsParams = {
            employeeId: employee.id.toString(),
            Orderby: 'accesslevelname',
            OrderbyDirection: 'a',
            PageNumber: 0,
            RecordsByPage: 500
          };
          const employeeAccessLevels = await queryAccessLevels(queryAccessLevelsParams);
          debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() employeeAccessLevels is ${JSON.stringify(employeeAccessLevels)}`);
          employeeAccessLevels.map(eal => {
            newAccessLevels.push({
              accessLevelId: eal.ACCLVLID,
              accessLevelName: eal.ACCESSLEVEL_NAME,
              id: i++,
              permanentFlag: !eal.ACCESSLEVEL_ACTIVATE && !eal.ACCESSLEVEL_DEACTIVATE ? true : false,
              site: eal.ACCESSLEVEL_NAME.slice(0,eal.ACCESSLEVEL_NAME.indexOf('-')),
              validFrom: eal.ACCESSLEVEL_ACTIVATE === null ? undefined : eal.ACCESSLEVEL_ACTIVATE,
              validThrough: eal.ACCESSLEVEL_DEACTIVATE === null ? undefined : eal.ACCESSLEVEL_DEACTIVATE,
            });
          });
          const validAccessLevels = await Promise.all(newAccessLevels.map(async(al) => {
            return await isAccessLevelValid(al); 
          }));
          newAccessLevels = newAccessLevels.filter((al, index) => validAccessLevels[index]);
          debug(`AccessDetailsTablePanel() copyAccessLevelsFromEmployees() newAccessLevels is ${JSON.stringify(newAccessLevels)}`);
        }
      }
      setId(i);
      props.setAccessLevelsCallback([...allItems, ...newAccessLevels]);
      resolve();
    });
  };

  const FlashMessageDuplicateAccessLevelInput = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: true,
            content: bundle.getMessage('duplicate-access-level-input'),
            onDismiss: () => {setDuplicateInputAccessLevelFound(false)}
          },
        ]
      }
    />
  );

  const FlashMessageExistingAccessLevelInput = () => (
    <Flashbar
      items = {
        [
          {
            type: 'warning',
            dismissible: true,
            content:
              <>
                {bundle.getMessage('existing-access-level-found-message')}
                <br/>
                <table>
                  <tbody>
                    {existingInputAccessLevels.map(al => 
                      <tr key={`${al.cardholderName}${al.accessLevelName}`}>
                        <td>{al.cardholderName}</td>
                        <td>{al.accessLevelName}</td>
                        <td>{al.permanent ? `(${bundle.getMessage('permanent')})` : ''}</td>
                        <td>{al.accessLevelActivate}</td>
                        <td>{al.accessLevelDeactivate}</td>
                      </tr>)}
                  </tbody>
                </table>
              </>,
            onDismiss: () => {setExistingInputAccessLevelFound(false)}
          },
        ]
      }
    />
  );

  const isAccessLevelValid = async (accessLevel: IAccessDetailsRecord): Promise<boolean> => {
    if (!accessLevel.accessLevelName || !accessLevel.accessLevelId) return false;
    const skeletonAccessLevelRegex = new RegExp(`}.*`);
    if (skeletonAccessLevelRegex.test(accessLevel.accessLevelName)) return false;
    if (!accessLevel.permanentFlag) {
      if (!accessLevel.validFrom) return false;
      if (!accessLevel.validThrough) return false;
      const throughDate = new Date(accessLevel.validThrough);
      const currentDate = new Date();
      if (accessLevel.validThrough && throughDate < currentDate) return false;
    }
    if (allItems.find(i => i.accessLevelName === accessLevel.accessLevelName)) return false;
    if (!sites.find(site => site.SiteCode === accessLevel.site)) return false;
    try {
      const accessLevelApproverGroups = (await queryAccessLevelApproverGroups(accessLevel.accessLevelName)) || [];
      let accessLevelNoApproverGroupMembers = false;
      if (accessLevelApproverGroups?.length > 0) accessLevelNoApproverGroupMembers = !(await accessLevelApproverGroupMembersExist(accessLevelApproverGroups));
      return accessLevelApproverGroups.length > 0 && !accessLevelNoApproverGroupMembers;
    } catch (error) {
      console.error(error);
      //pessimistic handling:  assume access level is invalid rather than display on a call failure.
      return false;
    }
  }

  const siteInputHandler = (detail: SelectProps.ChangeDetail) => {
    setAccessLevelInput(null);
    setSiteInput(detail.selectedOption);
  };

  const accessLevels = () => {
    debug(`AccessDetailsTablePanel() accessLevelOptions is ${JSON.stringify(accessLevelOptions)}`);
    const values = [];
    const available = {
      label: bundle.getMessage('available-access-levels'),
      options: accessLevelOptions.filter(al => !al.disabled),
    };
    const unavailableContingentWorker = {
      label: bundle.getMessage('unavailable-contingent-worker'),
      options: accessLevelOptions.filter(al => al.disabled && (al.label?.includes('-4-') || al.label?.includes('-5')) && al.contingentWorkerSelected),
    };
    const unavailableNoApproverGroupLinked = {
      label: bundle.getMessage('unavailable-no-approver-group-linked'),
      options: accessLevelOptions.filter(al => al.disabled && al.noApproverGroupLinked),
    };
    const unavailableNoApproverGroupMembers = {
      label: bundle.getMessage('unavailable-no-approver-group-members'),
      options: accessLevelOptions.filter(al => al.disabled && al.noApproverGroupMembers),
    };
    if (available.options.length > 0) values.push(available);
    if (unavailableContingentWorker.options.length > 0) values.push(unavailableContingentWorker);
    if (unavailableNoApproverGroupLinked.options.length > 0) values.push(unavailableNoApproverGroupLinked);
    if (unavailableNoApproverGroupMembers.options.length > 0) values.push(unavailableNoApproverGroupMembers);
    return(values);
  };

  useEffect(() => {
    setAccessLevelOptions([]);
    setAccessLevelInput(null);
    accessLevelsForSiteQuery.refetch();
  }, [siteInput]);

  useEffect(() => {
    setSiteInput(null);
    setAccessLevelInput(null);
    setPermanentFlagInput(false);
    setVisitDateRange(null);
    setValidFromDateInput('');
    setValidFromTimeInput(defaultValidFromTime);
    setValidThroughDateInput('');
    setValidThroughTimeInput(defaultValidThroughTime);
    setInvalidFromDate(false);
    setInvalidThroughDate(false);
    props.detailsClearedCallback();
  }, [props.clear]);

  useEffect(() => {
    setAllItems(props.accessLevels);
    setExistingInputAccessLevelFound(false);
    flagExistingAccessLevels(props.accessLevels);
  }, [props.accessLevels]);

  useEffect(() => {
    setExistingInputAccessLevelFound(false);
    flagExistingAccessLevels(props.accessLevels);
    accessLevelsForSiteQuery.refetch();
  }, [props.cardholders]);

  useEffect(() => {
    let sites = (queryClient.getQueryData(['sites'] || []) as APIt.Site[]) || [];
    setSites(sites);
  }, [queryClient.getQueryData(['sites'])]);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.HoursExtensionFromDateFormValidation)
    .then((v) => setHoursExtFromDate(parseInt(v.value)));
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {duplicateInputAccessLevelFound && <FlashMessageDuplicateAccessLevelInput/>}
    {existingInputAccessLevelFound && <FlashMessageExistingAccessLevelInput/>}
    <div id='RequestAccessAccessDetailsTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        data-testid='RequestAccessAccessDetailsTable'
        columnDefinitions={ColumnDefinitions}
        header={
          <>
            <Header
              counter={`(${itemsCount().toString()})`}
            >
              {bundle.getMessage('access-details')}
            </Header>
            <Grid gridDefinition={[{colspan: 12}]}>
              <SpaceBetween direction='horizontal' size='s'>
                <FormField label={bundle.getMessage('site')} stretch={true}>
                  <div style={{width: 120}}>
                    <Select
                      data-testid='RequestAccessAccessDetailsSitesSelect'
                      filteringType='auto'
                      loadingText={bundle.getMessage('loading-sites')}
                      onChange={({ detail }) => siteInputHandler(detail)}
                      options={
                        sites.map(s => {
                          return {label: s.SiteCode, value: s.SiteCode};
                        })
                      }
                      placeholder={bundle.getMessage('select-a-site')}
                      ref={siteRef}
                      selectedOption={siteInput}
                      statusType={queryClient.isFetching(['sites']) ? 'loading' : 'finished'}
                    />
                  </div>
                </FormField>
                <FormField label={bundle.getMessage('access-level')} stretch={true}>
                  <div style={{width: 300}}>
                    <Select
                      data-testid='RequestAccessAccessDetailsAccessLevelSelect'
                      disabled={!siteInput}
                      filteringType='auto'
                      loadingText={bundle.getMessage('loading-site-access-levels')}
                      onChange={({ detail }) => setAccessLevelInput(detail.selectedOption)}
                      options={accessLevels()}
                      placeholder={bundle.getMessage('select-an-access-level')}
                      selectedOption={accessLevelInput}
                      statusType={
                        accessLevelsForSiteQuery.isLoading || accessLevelsForSiteQuery.isFetching || checkAccessLevelApproverGroups
                        ? 'loading'
                        : 'finished'}
                    />
                  </div>
                </FormField>
                <FormField label={bundle.getMessage('permanent')}>
                  <Checkbox
                    onChange={({ detail }) => {
                      setPermanentFlagInput(detail.checked);
                      setValidFromDateInput('');
                      setValidFromTimeInput('');
                      setValidThroughDateInput('');
                      setValidThroughTimeInput('');
                      if (!detail.checked) {
                        setValidFromTimeInput(defaultValidFromTime);
                        setValidThroughTimeInput(defaultValidThroughTime);
                      }
                    }}
                    checked={permanentFlagInput}
                  />
                </FormField>
                <FormField label={bundle.getMessage('days-site-time')}>
                  <DateRangePicker
                    data-testid='RequestAccessAccessDetailsValidDateRangePicker'
                    dateOnly
                    disabled={permanentFlagInput}
                    hideTimeOffset
                    i18nStrings={{
                      todayAriaLabel: bundle.getMessage('today'),
                      nextMonthAriaLabel: bundle.getMessage('next-month'),
                      previousMonthAriaLabel: bundle.getMessage('previous-month'),
                      customRelativeRangeDurationLabel: bundle.getMessage('duration'),
                      customRelativeRangeDurationPlaceholder: bundle.getMessage('enter-duration'),
                      customRelativeRangeOptionLabel: bundle.getMessage('custom-range'),
                      customRelativeRangeOptionDescription: bundle.getMessage('set-a-custom-range-in-the-future'),
                      customRelativeRangeUnitLabel: bundle.getMessage('unit-of-time'),
                      formatRelativeRange: (rr): string => {
                        return(
                          `${bundle.getMessage('next')} ${Math.abs(rr.amount)} ${bundle.formatMessage(rr.unit, { amount: rr.amount.toString() })}`
                        );
                      },
                      formatUnit: (e, t) => (1 === t ? e : `${bundle.formatMessage(e, { amount: t.toString() })}`),
                    dateTimeConstraintText: '',
                      relativeModeTitle: bundle.getMessage('relative-range'),
                      absoluteModeTitle: bundle.getMessage('absolute-range'),
                      relativeRangeSelectionHeading: bundle.getMessage('choose-a-range'),
                      startDateLabel: bundle.getMessage('start-date'),
                      endDateLabel: bundle.getMessage('end-date'),
                      startTimeLabel: bundle.getMessage('start-time'),
                      endTimeLabel: bundle.getMessage('end-time'),
                      clearButtonLabel: bundle.getMessage('clear-and-dismiss'),
                      cancelButtonLabel: bundle.getMessage('cancel'),
                      applyButtonLabel: bundle.getMessage('apply'),
                    }}
                    invalid={!permanentFlagInput && (invalidFromDate || invalidThroughDate)}
                    isDateEnabled={date => {
                      const [month, day, year] = [
                        (date.getMonth() + 1).toString().padStart(2, '0'),
                        date.getDate().toString().padStart(2, '0'),
                        date.getFullYear(),
                      ];
                      const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
                      return isFromDateValid(convertedDate, hoursExtFromDate);
                    }}
                    isValidRange={(value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
                      const result: DateRangePickerProps.ValidationResult = { valid: true };
                      return result;
                    }}
                    onChange={({ detail }) => dateRangeInputChangeHandler(detail.value)}
                    placeholder='YYYY/MM/DD'
                    relativeOptions={[
                      { key: 'tomorrow', amount: -1, unit: 'day', type: 'relative' },
                      { key: 'next-7-days', amount: -7, unit: 'day', type: 'relative' },
                      { key: 'next-14-days', amount: -14, unit: 'day', type: 'relative' },
                      { key: 'next-365-days', amount: -365, unit: 'day', type: 'relative' },
                    ]}
                    value={visitDateRange}
                  />
                </FormField>
                <FormField label={bundle.getMessage('from-time')}>
                  <div style={{width: 100}}>
                    <TimeInput
                      data-testid='RequestAccessAccessDetailsValidFromTimeInput'
                      disabled={permanentFlagInput}
                      format="hh:mm"
                      invalid={!permanentFlagInput && invalidFromDate}
                      onChange={event => fromTimeInputChangeHandler(event.detail.value)}
                      placeholder="hh:mm"
                      value={validFromTimeInput}
                    />
                  </div>
                </FormField>
                <FormField label={bundle.getMessage('to-time')}>
                  <div style={{width: 100}}>
                    <TimeInput
                      data-testid='RequestAccessAccessDetailsValidThroughTimeInput'
                      disabled={permanentFlagInput}
                      format="hh:mm"
                      invalid={!permanentFlagInput && invalidThroughDate}
                      onChange={event => throughTimeInputChangeHandler(event.detail.value)}
                      placeholder="hh:mm"
                      value={validThroughTimeInput}
                    />
                  </div>
                </FormField>
                <div style={{position:'relative', bottom: '-25px'}}>
                  <Button
                    data-testid='RequestAccessAccessDetailsAddButton'
                    disabled={
                      !siteInput
                      || !accessLevelInput
                      ||
                        (
                          !permanentFlagInput
                        &&
                          (
                            !validFromDateInput || !validFromTimeInput || !validThroughDateInput || !validThroughTimeInput || invalidFromDate || invalidThroughDate
                          )
                        )
                    }
                    loading={adding}
                    onClick={addHandler}
                    variant='normal'
                  >
                    {bundle.getMessage('add')}
                  </Button>
                </div>
              </SpaceBetween>
            </Grid>
          </>
        }
        footer={
          <>
          <Box float='right' margin='s'>
            <SpaceBetween direction='horizontal' size='s'>
              <Button
                onClick={() => {
                  setNonReportIncluded(false);
                  setShowSearchReports(true);
                }}
                variant='normal'
              >
                {bundle.getMessage('copy-access-details-from-your-reports')}
              </Button>
              <Button
                disabled={selectedItems.length == 0}
                onClick={removeSelected}
                variant='primary'
              >
                {bundle.getMessage('remove-selected')}
              </Button>
            </SpaceBetween>
          </Box>
          </>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
    {showSearchReports
    &&
    <Modal
      header={
        <>
          <Header
            description={bundle.getMessage('search-for-your-direct-and-indirect-reports-to-generate-a-request')}
            variant='h2'
          >
            {bundle.getMessage('search-your-reports')}
          </Header>
        </>
      }
      onDismiss={() => setShowSearchReports(false)}
      size='large'
      visible={showSearchReports}
    >
      <AdvancedEmployeeSearch
        addEmployeesCallback={copyAccessLevelsFromEmployees}
        closeCallback={() => setShowSearchReports(false)}
        nonReportIncluded={nonReportIncluded}
      />
    </Modal>
    }
    </div>
    </>
  );
}

