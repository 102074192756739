import * as APIt from "src/API";
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import React from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBundle } from "@amzn/react-arb-tools";

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.VisitorRequest>[] = [
  {
    id: 'visitorType',
    header: <TranslateHeader value={'visitor-type'} />,
    cell: item => item.visitor_type,
    sortingField: 'visitor_type'
  },
  {
    id: 'badgeNum',
    header: <TranslateHeader value={'badge'} />,
    cell: item => item.vendor_day_pass_badge_num
      ? item.vendor_day_pass_badge_num == 'unassigned'
        ? ''
        : item.vendor_day_pass_badge_num !== 'NaN'
          ? item.vendor_day_pass_badge_num
          : item.badge_id
      : item.badge_id,
    sortingComparator: (a, b) => {
      if (a.vendor_day_pass_badge_num && b.vendor_day_pass_badge_num) {
        return a.vendor_day_pass_badge_num.localeCompare(b.vendor_day_pass_badge_num)
      } else if (a.badge_id && b.badge_id) {
        return a.badge_id.localeCompare(b.badge_id)
      }
      return 0;
    }
  },
  {
    id: 'firstName',
    header: <TranslateHeader value={'first-name'} />,
    cell: item => item.first_name,
    sortingField: 'first_name'
  },
  {
    id: 'lastName',
    header: <TranslateHeader value={'last-name'} />,
    cell: item => item.last_name,
    sortingField: 'last_name'
  },
  {
    id: 'company',
    header: <TranslateHeader value={'company'} />,
    cell: item => item.company,
    sortingField: 'company'
  },
  {
    id: 'validFrom',
    header: <TranslateHeader value={'valid-from'} />,
    cell: item => item.start_date?.slice(0,16),
    sortingField: 'start_date'
  },
  {
    id: 'validThrough',
    header: <TranslateHeader value={'valid-through'} />,
    cell: item => item.end_date?.slice(0,16),
    sortingField: 'end_date'
  },
  {
    id: 'status',
    header: <TranslateHeader value={'status'} />,
    cell: item => <div data-testid='status'>{item.status}<span hidden={true} data-testid='visitorIdHidden'>{item.visitor_id}</span></div>,
    sortingField: 'status'
  },
  {
    id: 'visitor_id',
    header: <TranslateHeader value={'visitor-id'} />,
    cell: item => <div data-testid='visitorId'>{item.visitor_id}</div>,
    sortingField: 'visitor_id'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
