import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  FormField,
  Modal,
  RadioGroup,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Toggle,
  ToggleProps,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useBundle } from "@amzn/react-arb-tools";
import { ILanguage, Languages, ManagementPages, Modes, NavigationTypes, SelfServicePages } from '../../constants/Constants';
import * as APIt from '../../API';
import { createUserPreferences, queryUserPreferences, updateUserPreferences } from '../SelfService/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { debug } from 'src/utils/commonUtils';

interface IUserPreferencesProps {
  admin: boolean;
  closeUserPreferencesCallback(): void;
  dailySummaryEmails: boolean;
  darkMode: boolean;
  disableAutoApproval: boolean;
  disableNewVendorDayPassRequests: boolean | undefined;
  enableDebugLogging: boolean;
  language: ILanguage | undefined;
  liveEmails: boolean;
  mode: Modes;
  notificationTime: string | null;
  notificationTimezone: string | null;
  setAdminCallback: Function;
  setDisableAutoApprovalCallback: Function;
  setEnableDebugLoggingCallback: Function;
  username: string;
  userPrefs: APIt.UserPrefs | undefined | null,
  userPrefsIsLoading: boolean,
}

export const UserPreferences = (props: IUserPreferencesProps) => {
  debug(`UserPreferences() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.common.UserPreferences');

  const generateTimeOptions = (): string[] =>{
    const timeOptions: string[] = [];
    
    for (let hour = 0; hour < 24; hour++) {
      const formattedHour = hour.toString().padStart(2, '0');
      timeOptions.push(`${formattedHour}:00`);
    }
    
    return timeOptions;
  }

  const getDarkMode = (): boolean => {
    let darkMode: boolean = false;

    if (props.mode == Modes.MANAGEMENT
    && props.userPrefs?.management
    && JSON.parse(props.userPrefs?.management).darkMode)
      darkMode = JSON.parse(props.userPrefs?.management).darkMode;
    if (props.mode == Modes.SELF_SERVICE
    && props.userPrefs?.selfService
    && JSON.parse(props.userPrefs?.selfService).darkMode)
      darkMode = JSON.parse(props.userPrefs?.selfService).darkMode;

    return darkMode;
  };

  const getInitialPage = (): string | undefined => {
    let initialPage: string | undefined = 'Home';

    if (props.mode == Modes.MANAGEMENT
    && props.userPrefs?.management
    && JSON.parse(props.userPrefs.management).initialPage)
      initialPage = JSON.parse(props.userPrefs?.management).initialPage;

    if (props.mode == Modes.SELF_SERVICE
    && props.userPrefs?.selfService
    && JSON.parse(props.userPrefs.selfService).initialPage)
        initialPage = JSON.parse(props.userPrefs?.selfService).initialPage;

    return initialPage;
  };

  const getLanguage = (): { value: string, label: string } | null => {
    if (!props.userPrefs?.global) return {label: 'English', value: 'en-US'};
    try {
      const globalPrefs = JSON.parse(props.userPrefs.global);
      const language = Languages.find(l => l.id == globalPrefs.language.value)
      if (!language?.id || !language?.text) return null;
      return { value: language?.id, label: language?.text };
    } catch(error) {
      return null;
    }
  };

  const getNavigationType = (): NavigationTypes => {
    let navigationType: NavigationTypes = NavigationTypes.SIDE_MENU;

    if (props.mode == Modes.MANAGEMENT && props.userPrefs?.management && JSON.parse(props.userPrefs.management).navigationType) {
      navigationType = JSON.parse(props.userPrefs?.management).navigationType as NavigationTypes;
    }
    if (props.mode == Modes.SELF_SERVICE && props.userPrefs?.selfService && JSON.parse(props.userPrefs.selfService).navigationType)
      navigationType = JSON.parse(props.userPrefs?.selfService).navigationType as NavigationTypes;

    return navigationType;
  };

  const transformDailyOption = (liveEmails: boolean, dailySummaryEmails: boolean): string => {
    if(liveEmails && dailySummaryEmails)
      return '3';
    if(dailySummaryEmails)
      return '2';
    return '1';
  };

  const [enableDebugLogging, setEnableDebugLogging] = useState<boolean>(props.enableDebugLogging);
  const [dailySummaryEmails, setDailySummaryEmails] = useState<boolean>(props.dailySummaryEmails);
  const [disableAdmin, setDisableAdmin] = useState<boolean>(false);
  const [disableAutoApproval, setDisableAutoApproval] = useState<boolean>(props.disableAutoApproval);
  const [liveEmails, setLiveEmails] = useState<boolean>(props.liveEmails);
  const [selectedEmailOption, setSelectedEmailOption] = useState<string>(transformDailyOption(props.liveEmails, props.dailySummaryEmails));
  const [selectedNavigationType, setSelectedNavigationType] = useState<NavigationTypes>(getNavigationType());
  const [selectedInitialPage, setSelectedInitialPage] = useState<string | undefined>(getInitialPage());
  const [selectedDarkMode, setSelectedDarkMode] = useState<boolean>(getDarkMode());
  const [selectedLanguage, setSelectedLanguage] = useState< { value: string, label: string } | null >(getLanguage());
  const [selectedTimezone, setSelectedTimezone] = useState<SelectProps.Option | null>(null);
  const [selectedTime, setSelectedTime] = useState<SelectProps.Option | null>(props.notificationTime != null ? {value: props.notificationTime}: null);
  const [timezoneOptions, setTimezoneOptions] = useState<SelectProps.Option[]>([]);
  const [timeOptions, setTimeOptions] = useState<string[]>(generateTimeOptions());

  const queryClient = useQueryClient();

  const initialPageOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedInitialPage(detail.selectedOption.value);
  };

  const darkModeOnChangeHandler = (detail: ToggleProps.ChangeDetail) => {
    setSelectedDarkMode(detail.checked);
  };

  function formatTimezoneWithOffset(zone: string): SelectProps.Option & { sortTime: number } {
    const now = new Date();
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: zone,
      timeZoneName: 'longOffset',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
    
    const formatted = formatter.format(now);
    const offsetMatch = formatted.match(/GMT([+-]\d{2}:\d{2})/);
    const offset = offsetMatch ? offsetMatch[1] : '+0';
        
    const zoneTime = new Date(now.toLocaleString('en-US', { timeZone: zone }));
    
    return {
      value: zone,
      label: `${zone} (GMT${offset})`,
      sortTime: zoneTime.getTime()
    };
  }

  const getTimezone = (): SelectProps.Option => {
    return props.notificationTimezone != null ? formatTimezoneWithOffset(props.notificationTimezone) : formatTimezoneWithOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  
  const emailOptionChangeHandler = (optionValue: string) => {
    if (optionValue == '1') {
      setLiveEmails(true);
      setDailySummaryEmails(false);
      setSelectedTime(null);
      setSelectedTimezone(null);
    }else if (optionValue == '2') {
      setLiveEmails(false);
      setDailySummaryEmails(true);
      setSelectedTimezone(getTimezone());
      setSelectedTime(props.notificationTime != null ? {value: props.notificationTime}: null);
    }else if (optionValue == '3') {
      setLiveEmails(true);
      setDailySummaryEmails(true);
      setSelectedTimezone(getTimezone());
      setSelectedTime(props.notificationTime != null ? {value: props.notificationTime}: null);
    }
    setSelectedEmailOption(optionValue);
  };
  
  const saveUserPrefs = async () => {
    const userPrefs: APIt.UserPrefs | null = await queryUserPreferences(props.username);

    let
      managementPrefs = userPrefs?.management ? JSON.parse(userPrefs.management) : {},
      selfServicePrefs = userPrefs?.selfService ? JSON.parse(userPrefs.selfService): {};

    if (props.mode == Modes.MANAGEMENT)
      managementPrefs =
        {
          ...JSON.parse(userPrefs?.management || '{}'),
          darkMode: selectedDarkMode,
          initialPage: selectedInitialPage,
          navigationType: selectedNavigationType,
        };

    if (props.mode == Modes.SELF_SERVICE)
      selfServicePrefs =
        {
          ...JSON.parse(userPrefs?.selfService || '{}'),
          darkMode: selectedDarkMode,
          initialPage: selectedInitialPage,
          navigationType: selectedNavigationType,
        };
      const newUserPrefs: APIt.UserPrefs = {
        __typename: 'UserPrefs',
        global: JSON.stringify({
          ...JSON.parse(userPrefs?.global || '{}'),
          dailySummaryEmails: dailySummaryEmails,
          language: selectedLanguage,
          liveEmails: liveEmails,
          notificationTimezone: selectedTimezone?.value,
          notificationTime: selectedTime?.value,
        }),
        management: JSON.stringify(managementPrefs),
        selfService: JSON.stringify(selfServicePrefs),
        username: props.username,
        dailySummaryEmailTimestamp: (transformToUTCTime() || '99:99:99.999Z')
      };

      if (userPrefs) await updateUserPreferences(newUserPrefs);
      if (!userPrefs) await createUserPreferences(newUserPrefs);

    queryClient.invalidateQueries({ queryKey: ['userPrefs'] })
    props.closeUserPreferencesCallback();
  };

  const timezoneHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedTimezone(detail.selectedOption);
  };

  const timeSelectedHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedTime(detail.selectedOption);
  };

  const transformToUTCTime = (): string | null => {
    if (!selectedTimezone || !selectedTime || !selectedTime.value) {
      return null;
    }
  
    const [hours, minutes] = selectedTime.value.split(':');
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  
    const selectedDateTime = new Date(new Date().getTime() + timezoneOffset);
    selectedDateTime.setHours(parseInt(hours, 10));
    selectedDateTime.setMinutes(parseInt(minutes, 10));
    selectedDateTime.setSeconds(0);
    selectedDateTime.setMilliseconds(0);
  
    const timezoneDate = new Date(selectedDateTime.toLocaleString('en-US', { timeZone: selectedTimezone.value }));
  
    const utcTime = timezoneDate.toISOString();
    //strip off date, just leave time
    return utcTime.substring(11);
  };
  

  const saveUserPrefsMutation = useMutation({
    mutationFn: async () => {
      await saveUserPrefs();
      if (disableAdmin) props.setAdminCallback(false);
      props.setDisableAutoApprovalCallback(disableAutoApproval);
    },
  });

  useEffect(() => {
    const options = Intl.supportedValuesOf('timeZone').map(formatTimezoneWithOffset).sort((a, b) => a.sortTime - b.sortTime);
    setTimezoneOptions(options);
    setSelectedTimezone(getTimezone());
  }, []);

  if (isBundleLoading || props.userPrefsIsLoading) return <Spinner/>;

  return (
    <Modal
      visible={true}
      header={bundle.getMessage('user-preferences')}
      onDismiss={() => props.closeUserPreferencesCallback()}
      closeAriaLabel='close user preferences'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              disabled = {(selectedTimezone == null || selectedTime == null) && (selectedEmailOption == '2' || selectedEmailOption == '3')}
              variant='primary'
              onClick={() => saveUserPrefsMutation.mutate()}
            >
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size='m' direction='vertical'>
        <FormField label={bundle.getMessage('navigation-type')}>
          <Select
            placeholder={bundle.getMessage('select-navigation-type')}
            onChange={({ detail }) => setSelectedNavigationType(detail.selectedOption.value! as NavigationTypes)}
            options={
              [
                {
                  label: bundle.getMessage('side-menu'),
                  value: 'side-menu'
                },
                {
                  label: bundle.getMessage('tabs'),
                  value: 'tabs'
                },
              ]
            }
            ariaLabel={bundle.getMessage('select-navigation-type')}
            selectedAriaLabel='Selected Navigation Type'
            ariaDescribedby={selectedNavigationType}
            renderHighlightedAriaLive={option => `Select Page ${option.label}`}
            ariaRequired
            selectedOption={ { label: bundle.getMessage(selectedNavigationType || 'side-menu'), value: selectedNavigationType || 'side-menu'} }
          />
        </FormField>
        <FormField label={bundle.getMessage('initial-page')}>
          <Select
            placeholder={bundle.getMessage('select-initial-page')}
            onChange={({ detail }) => initialPageOnChangeHandler(detail)}
            options={
              props.mode == Modes.MANAGEMENT
                ? ManagementPages.filter(mp => mp.name != 'Administration')
                  .map(ssp => { return { label: bundle.getMessage(ssp.code), value: ssp.name }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)
                : SelfServicePages.filter(ssp => props.disableNewVendorDayPassRequests ? ssp.code !== 'vendor-day-pass-request' : true)
                  .map(ssp => { return { label: bundle.getMessage(ssp.code), value: ssp.name }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)
            }
            ariaLabel={bundle.getMessage('select-initial-page')}
            selectedAriaLabel='Selected Initial Page'
            ariaDescribedby={selectedInitialPage}
            renderHighlightedAriaLive={option => `Select Page ${option.label}`}
            ariaRequired
            selectedOption={{ label: selectedInitialPage, value: selectedInitialPage }}
          />
        </FormField>
        <FormField label={bundle.getMessage('initial-page')}>
          <Select
            placeholder={bundle.getMessage('select-initial-page')}
            onChange={({ detail }) => initialPageOnChangeHandler(detail)}
            options={
              props.mode == Modes.MANAGEMENT
                ? ManagementPages.filter(mp => mp.name != 'Administration')
                  .map(ssp => { return { label: bundle.getMessage(ssp.code), value: ssp.name }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)
                : SelfServicePages.filter(ssp => props.disableNewVendorDayPassRequests ? ssp.code !== 'vendor-day-pass-request' : true)
                  .map(ssp => { return { label: bundle.getMessage(ssp.code), value: ssp.name }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)
            }
            ariaLabel={bundle.getMessage('select-initial-page')}
            selectedAriaLabel='Selected Initial Page'
            ariaDescribedby={selectedInitialPage}
            renderHighlightedAriaLive={option => `Select Page ${option.label}`}
            ariaRequired
            selectedOption={{ label: selectedInitialPage, value: selectedInitialPage }}
          />
        </FormField>
        <FormField label={bundle.getMessage('dark-mode')}>
          <Toggle
            onChange={({ detail }) => darkModeOnChangeHandler(detail)}
            ariaLabel={bundle.getMessage('dark-mode')}
            checked={selectedDarkMode}
          />
        </FormField>
        <hr/>
        {bundle.getMessage('email-notifications-section')}
        <RadioGroup
          onChange={({ detail }) => emailOptionChangeHandler(detail.value)}
          value={selectedEmailOption}
          items={[
            { value: '1', label: bundle.getMessage('live-emails') },
            { value: '2', label: bundle.getMessage('daily-summary-emails') },
            { value: '3', label: bundle.getMessage('live-daily-summary-emails') }
          ]}
        />
        {(selectedEmailOption == '2' || selectedEmailOption == '3') && 
            <FormField label={bundle.getMessage('select-a-timezone')}>
              <Select
                filteringType='auto'
                loadingText={bundle.getMessage('loading-timezones')}
                onChange={({ detail }) => timezoneHandler(detail)}
                options={
                  timezoneOptions.map(tz => {
                    return { label: tz.label, value: tz.value };
                  })
                }
                placeholder={bundle.getMessage('select-a-timezone')}
                selectedOption={selectedTimezone}
              />
          </FormField>
        }
        {(selectedEmailOption == '2' || selectedEmailOption == '3') &&
          <FormField label={bundle.getMessage('select-time')}>
              <Select
                filteringType='auto'
                loadingText={bundle.getMessage('loading-times')}
                onChange={({ detail }) => timeSelectedHandler(detail)}
                options={
                  timeOptions.map(tz => {
                    return { label: tz, value: tz };
                  })
                }
                placeholder={bundle.getMessage('select-time')}
                selectedOption={selectedTime}
              />
          </FormField>
          }
      <hr/>
        <FormField label={bundle.getMessage('language')}>
          <Select
            onChange={({ detail }) => setSelectedLanguage({ value: detail.selectedOption.value!, label: detail.selectedOption.label! })}
            options={Languages.map(l => { return { label: l.text, value: l.id }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)}
            selectedOption={selectedLanguage}
          />
        </FormField>
        {props.admin
        &&
        <div>
        <hr/>
        <Alert type='warning'>
          The following settings are for testing/support, they are not saved.
        </Alert>
        <FormField>
          <Toggle
            onChange={({ detail }) => {
              setDisableAdmin(detail.checked);
            }}
            ariaLabel='disable admin'
            checked={disableAdmin}
          >
            Disable Admin
          </Toggle>
        </FormField>
        <FormField>
          <Toggle
            onChange={({ detail }) => {
              setDisableAutoApproval(detail.checked);
            }}
            ariaLabel='disable auto approval'
            checked={disableAutoApproval}
          >
            Disable Auto Approval
          </Toggle>
        </FormField>
        <FormField>
          <Toggle
            onChange={({ detail }) => {
              setEnableDebugLogging(detail.checked);
              props.setEnableDebugLoggingCallback(detail.checked);
            }}
            ariaLabel='enable debug logging'
            checked={enableDebugLogging}
          >
            Enable Debug Logging
          </Toggle>
        </FormField>
        </div>
        }
      </SpaceBetween>
    </Modal>
  );
}
