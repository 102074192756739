import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Flashbar,
  Form,
  FormField,
  Grid,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  TimeInput } from '@amzn/awsui-components-react';
import * as uuid from 'uuid';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  isFromDateValid,
  isThroughDateValid,
  getLookupTypeValueId,
  queryAccessLevelApproverGroups,
  queryAccessLevelsForSite,
  queryLookupTypeValueForTypeAndDescription,
} from 'src/components/utils';
import { ApprovalStatus, LookupTypes, VisitorTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import { addRequestAccessLevel, queryVisitorAccessLevelsForVisitor } from '../utils';
import { debug } from 'src/utils/commonUtils';

export interface IRequestAccessLevel{
  airSite: boolean;
  closeCallback: Function;
  site: string;
  username: string;
  visitorRequest: APIt.VisitorRequest;
}

const defaultValidFromTime = '08:00';
const defaultValidThroughTime = '17:00';

export default function RequestAccessLevel(props: IRequestAccessLevel) {
  debug(`RequestAccessLevel() props is ${JSON.stringify(props)}`);

  const [accessLevelInput, setAccessLevelInput] = useState<SelectProps.Option | null>(null);
  const [accessLevelOptions, setAccessLevelOptions] = useState<SelectProps.Options>();
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [invalidFromDate, setInvalidFromDate] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [permanentFlagInput, setPermanentFlagInput] = useState<boolean>(false);
  const [showFailure, setShowFailure] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [validFromDateInput, setValidFromDateInput] = useState<string>('');
  const [validFromTimeInput, setValidFromTimeInput] = useState<string>(defaultValidFromTime);
  const [validThroughDateInput, setValidThroughDateInput] = useState<string>('');
  const [validThroughTimeInput, setValidThroughTimeInput] = useState<string>(defaultValidThroughTime);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.RequestAccessLevel.index');

  const createAccessLevelOptions = async (data: APIt.AccessLevelForSite[]) => {
    const options = await Promise.all(data.map(async (al): Promise<any> => {
      debug(`accessLevelsForSiteQuery() onSuccess() al is ${JSON.stringify(al)}`);
      try {
        const accessLevelApproverGroups = await queryAccessLevelApproverGroups(al.AccessLevelName);
        const visitorAccessLevels = await queryVisitorAccessLevelsForVisitor(props.visitorRequest.visitor_id);
        debug(`accessLevelsForSiteQuery() onSuccess() accessLevelsApproverGroups is ${JSON.stringify(accessLevelApproverGroups)}`);
        return {
          label: al.AccessLevelName,
          value: al.AccessLevelID?.toString(),
          disabled:
            (!accessLevelApproverGroups || accessLevelApproverGroups.length == 0)
            ||
            (visitorAccessLevels.find(val => val.access_level_id == al.AccessLevelID?.toString())),
        };
      } catch(error) {
        return {
          label: al.AccessLevelName,
          value: al.AccessLevelID?.toString(),
          disabled: true,
        };
      }
    }));
    debug(`accessLevelsForSiteQuery() onSuccess() options is ${JSON.stringify(options)}`);
    setAccessLevelOptions(options);
  };

  const accessLevelsForSiteQuery = useQuery({
    queryKey: ['accessLevelsRequestAccessLevel'],
    queryFn: () => queryAccessLevelsForSite(props.site),
    onSuccess: async (data) => {
      if (!accessLevelsForSiteQuery.data) return [];
      createAccessLevelOptions(data);
    },
    retry: 3
  });

  const addRequestAccessLevelMutation = useMutation({
    mutationFn: addRequestAccessLevel,
    onError: () => {
      setShowFailure(true);
    },
    onSuccess: () => {
      setShowSuccess(true);
    },
  });

  const addHandler = async () => {
    let invalidDate = false;
    if (!isFromDateValid(new Date(`${validFromDateInput} ${validFromTimeInput}`), hoursExtFromDate)) {
      setInvalidFromDate(true);
      invalidDate = true;
    }
    if (!isThroughDateValid(
      new Date(`${validFromDateInput} ${validFromTimeInput}`),
      new Date(`${validThroughDateInput} ${validThroughTimeInput}`)))
    {
      setInvalidThroughDate(true);
      invalidDate = true;
    }
    if (invalidDate) {
      return;
    }
    const sourceSystem = 'PACS';
    let promises = [];
    promises.push(getLookupTypeValueId(LookupTypes.AccessLevelSourceSystem, sourceSystem));
    promises.push(getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus, ApprovalStatus.PendingApproval));
    const [ accessLevelSourceSystemId, visitorAccessLevelStatusPendingApprovalId ] = await Promise.all(promises);
    const visitorAccessLevel: APIt.CreateVisitorAccessLevelInput =
      {
        access_level_id: accessLevelInput!.value!,
        access_level_name: accessLevelInput!.label!,
        access_level_source_system_id: accessLevelSourceSystemId!,
        created_by: props.username,
        end_date: validThroughDateInput + ' ' + validThroughTimeInput,
        id: uuid.v4(),
        permanent_flag: permanentFlagInput,
        reason: '',
        site_id: props.visitorRequest.site_id,
        start_date: validFromDateInput + ' ' + validFromTimeInput,
        status_code_id: visitorAccessLevelStatusPendingApprovalId!,
        visitor_id: props.visitorRequest.visitor_id,
      };
    addRequestAccessLevelMutation.mutate(
      {
        requestId: props.visitorRequest.request_id!,
        requestor: props.username,
        visitorAccessLevel: visitorAccessLevel,
      });
  };

  const fromDateInputChangeHandler = (value: string) => {
    setValidFromDateInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${value} ${validFromTimeInput}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${value} ${validFromTimeInput}`),
          new Date(`${validFromDateInput} ${validThroughTimeInput}`)));
  };
 
  const fromTimeInputChangeHandler = (value: string) => {
    setValidFromTimeInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${validFromDateInput} ${value}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${value}`),
          new Date(`${validThroughDateInput} ${validThroughTimeInput}`)));
  };
 
  const throughDateInputChangeHandler = (value: string) => {
    setValidThroughDateInput(value)
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${value} ${validThroughTimeInput}`)));
  };
 
  const throughTimeInputChangeHandler = (value: string) => {
    setValidThroughTimeInput(value)
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${validThroughDateInput} ${value}`)));
  };

  useEffect(() => {
    setAccessLevelInput(null);
    if (accessLevelsForSiteQuery.data) createAccessLevelOptions(accessLevelsForSiteQuery.data);
  }, [accessLevelsForSiteQuery.data]);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.HoursExtensionFromDateFormValidation)
    .then((v) => setHoursExtFromDate(parseInt(v.value)));
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return(
    <form onSubmit={e => e.preventDefault()}>
    <Form
      header={
        <>
        {showSuccess
        &&
        <Flashbar
          items = {
            [
              {
                type: 'success',
                content: bundle.getMessage('request-submitted'),
                dismissible: true,
                onDismiss: () => setShowSuccess(false),
              },
            ]
          }
        />
        }
        {showFailure
        &&
        <Flashbar
          items = {
            [
              {
                type: 'error',
                content: bundle.getMessage('request-failed'),
                dismissible: true,
                onDismiss: () => setShowFailure(false),
              },
            ]
          }
        />
        }
        </>
      }
      actions={
        <SpaceBetween direction='horizontal' size='xs'>
          <Button
            disabled={addRequestAccessLevelMutation.isLoading}
            formAction='none'
            onClick={() => props.closeCallback()}
            variant='normal'
          >
            {bundle.getMessage('close')}
          </Button>
          <Button
            disabled={
              props.airSite
              || !props.site
              || !accessLevelInput
              ||
                (
                  !permanentFlagInput
                &&
                  (
                    !validFromDateInput
                    || !validFromTimeInput
                    || !validThroughDateInput
                    || !validThroughTimeInput
                    || invalidFromDate
                    || invalidThroughDate
                  )
                )
            }
            formAction='submit'
            loading={addRequestAccessLevelMutation.isLoading}
            onClick={addHandler}
            variant='primary'
          >
            {bundle.getMessage('add')}
          </Button>
        </SpaceBetween>
      }
    >
      <Grid gridDefinition={[{colspan: 4}, {colspan: 4}]}>
        <FormField label={bundle.getMessage('access-level')} stretch={true}>
          <Select
            data-testid='RequestAccessAccessDetailsAccessLevelSelect'
            disabled={!props.site}
            filteringType='auto'
            loadingText={bundle.getMessage('loading-site-access-levels')}
            onChange={({ detail }) => setAccessLevelInput(detail.selectedOption)}
            options={accessLevelOptions}
            placeholder={bundle.getMessage('select-an-access-level')}
            selectedOption={accessLevelInput}
            statusType={accessLevelsForSiteQuery.isLoading || accessLevelsForSiteQuery.isFetching? 'loading' : 'finished'}
          />
        </FormField>
        <FormField label={bundle.getMessage('permanent')}>
          <Checkbox
            onChange={({ detail }) => {
              setPermanentFlagInput(detail.checked);
              setValidFromDateInput('');
              setValidFromTimeInput('');
              setValidThroughDateInput('');
              setValidThroughTimeInput('');
              if (!detail.checked) {
                setValidFromTimeInput(defaultValidFromTime);
                setValidThroughTimeInput(defaultValidThroughTime);
              }
            }}
            checked={permanentFlagInput}
            disabled={props.visitorRequest.visitor_type !== VisitorTypes.Employee}
          />
        </FormField>
      </Grid>
      <Grid gridDefinition={[{colspan: 4}, {colspan: 2}, {colspan: 4}, {colspan: 2}]}>
        <FormField label={bundle.getMessage('valid-from')} >
          <DatePicker
            invalid={invalidFromDate}
            isDateEnabled={date => {
              const [month, day, year] = [
                (date.getMonth()+1).toString().padStart(2,'0'),
                date.getDate().toString().padStart(2,'0'),
                date.getFullYear(),
              ];
              const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
              return isFromDateValid(convertedDate, hoursExtFromDate);
            }}
            nextMonthAriaLabel={bundle.getMessage('next-month')}
            onChange={event => fromDateInputChangeHandler(event.detail.value)}
            disabled={permanentFlagInput}
            placeholder='YYYY/MM/DD'
            previousMonthAriaLabel={bundle.getMessage('previous-month')}
            todayAriaLabel={bundle.getMessage('today')}
            value={validFromDateInput}
          />
        </FormField>
        <FormField label='&zwnj;'>
          <TimeInput
            disabled={permanentFlagInput}
            invalid={invalidFromDate}
            onChange={event => fromTimeInputChangeHandler(event.detail.value)}
            format="hh:mm"
            placeholder="hh:mm"
            value={validFromTimeInput}
          />
        </FormField>
        <FormField label={bundle.getMessage('valid-through')} >
          <DatePicker
            disabled={permanentFlagInput}
            invalid={invalidThroughDate}
            isDateEnabled={date => {
              const [month, day, year] = [
                (date.getMonth()+1).toString().padStart(2,'0'),
                date.getDate().toString().padStart(2,'0'),
                date.getFullYear(),
              ];
              const throughDate = `${year}-${month}-${day} 23:59:49`;
              return isThroughDateValid(new Date(`${validFromDateInput} ${validFromTimeInput}`), new Date(throughDate));
            }}
            nextMonthAriaLabel={bundle.getMessage('next-month')}
            onChange={event => throughDateInputChangeHandler(event.detail.value)}
            placeholder="YYYY/MM/DD"
            previousMonthAriaLabel={bundle.getMessage('previous-month')}
            todayAriaLabel={bundle.getMessage('today')}
            value={validThroughDateInput}
          />
        </FormField>
        <FormField label='&zwnj;'>
          <TimeInput
            disabled={permanentFlagInput}
            format="hh:mm"
            invalid={invalidThroughDate}
            onChange={event => throughTimeInputChangeHandler(event.detail.value)}
            placeholder="hh:mm"
            value={validThroughTimeInput}
          />
        </FormField>
      </Grid>
    </Form>
    </form>
  );
}