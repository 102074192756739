import React, { useState } from 'react';
import {
  Box,
  Button,
  Form,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Textarea
} from '@amzn/awsui-components-react';
import { CancelCreateVisitorAssetInterface } from './VisitorAssetsTablePanel';
import { UseQueryResult } from '@tanstack/react-query'
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import * as uuid from 'uuid';
import { debug } from 'src/utils/commonUtils';

export interface SelectOptionInterface {
  label: string;
  value: string;
}

export interface VisitorAssetCreatePanelPropsInterface {
  assetTypeOptionsQuery: UseQueryResult<APIt.LookupTypeValue[]>;
  cancelCreateCallback: CancelCreateVisitorAssetInterface;
  addCallback: Function;
}

export const AddVisitorAssetCreatePanel = (props: VisitorAssetCreatePanelPropsInterface) => {
  debug(`AddVisitorAssetCreatePanel() props is ${JSON.stringify(props)}`);

  const [assetDescriptionValue, setAssetDescriptionValue] = useState<string | null>(null);
  const [assetEndDateValue, setAssetEndDateValue] = useState<string | null>(null);
  const [assetId, setAssetId] = useState<string>(uuid.v4());
  const [assetMakeValue, setAssetMakeValue] = useState<string | null>(null);
  const [assetModelValue, setAssetModelValue] = useState<string | null>(null);
  const [assetPermanentValue, setAssetPermanentValue] = useState<boolean>(false);
  const [assetSerialNumValue, setAssetSerialNumValue] = useState<string| null>(null);
  const [assetStartDateValue, setAssetStartDateValue] = useState<string | null>(null);
  const [assetTypeValue, setAssetTypeValue] = useState<SelectProps.Option | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.common.Visitor.AddVisitorAssetCreatePanel');

  const clearCreateForm = () => {
    setAssetTypeValue(null);
    setAssetDescriptionValue(null);
    setAssetSerialNumValue(null);
    setAssetPermanentValue(false);
    setAssetStartDateValue(null);
    setAssetEndDateValue(null);
  };

  const cancelBtnHandler = () => {
    clearCreateForm();
    props.cancelCreateCallback();
  };

  const addBtnHandler = async () => {
    const visitorAsset: APIt.VisitorAsset = {
      __typename: 'VisitorAsset',
      approval_status_id: '',
      asset_type: assetTypeValue?.label,
      asset_type_id: assetTypeValue?.value!,
      created: '',
      created_by: '',
      description: assetDescriptionValue!,
      end_date: assetEndDateValue,
      id: assetId,
      make: assetMakeValue,
      model: assetModelValue,
      permanent_flag: assetPermanentValue,
      start_date: assetStartDateValue,
      serial_num: assetSerialNumValue!,
      site_id: '',
      site_source_system_id: '',
      updated: '',
      updated_by: '',
      visitor_id: ''
    };
    props.addCallback(visitorAsset);
  };

  const visitorAssetTypeIdFieldOnChangeHandler = (detail: any) => {
    setAssetTypeValue(detail.selectedOption);
  };

  const visitorAssetDescriptionFieldOnChangeHandler = (detail: any) => {
    setAssetDescriptionValue(detail.value);
  };

  const visitorAssetSerialNumFieldOnChangeHandler = (detail: any) => {
    setAssetSerialNumValue(detail.value);
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <div id='AddVisitorAssetCreateDiv'>
      <Form
       actions={
          <Box float='right'>
            <SpaceBetween size='xs' direction='horizontal'>
              <Button onClick={cancelBtnHandler}>
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='AssetsCreateAddAssetButton'
                onClick={addBtnHandler}
                variant='primary'
                disabled={
                  (
                    !assetTypeValue
                    || !assetSerialNumValue
                    || !assetDescriptionValue
                  )
                }
              >
                {bundle.getMessage('add-asset')}
              </Button>
            </SpaceBetween>
          </Box>
       } 
       errorText={errorText}
      >
        <SpaceBetween size='s' direction='vertical'>
          <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
            <FormField label={bundle.getMessage('asset-type')}>
              <Select
                data-testid='AssetCreateTypeSelect'
                onChange={({ detail }) => visitorAssetTypeIdFieldOnChangeHandler(detail)}
                selectedOption={assetTypeValue}
                options={props.assetTypeOptionsQuery?.data ? props.assetTypeOptionsQuery.data!.map((at) => {return {label: at.value, value: at.id}}): []}
              />
            </FormField>
            <FormField label={bundle.getMessage('serial-number')}>
              <Input
                data-testid='AssetCreateSerialNumberInput'
                onChange={({ detail }) => visitorAssetSerialNumFieldOnChangeHandler(detail)}
                value={assetSerialNumValue || ''}
                placeholder=''
                inputMode='text'
              />
            </FormField>
          </Grid>
          <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
            <FormField label={bundle.getMessage('make')}>
              <Input
                data-testid='AssetCreateMakeInput'
                onChange={({ detail }) => setAssetMakeValue(detail.value)}
                value={assetMakeValue || ''}
                placeholder={bundle.getMessage('enter-make')}
                inputMode='text'
              />
            </FormField>
            <FormField label={bundle.getMessage('model')}>
              <Input
                data-testid='AssetCreateModelInput'
                onChange={({ detail }) => setAssetModelValue(detail.value)}
                value={assetModelValue || ''}
                placeholder={bundle.getMessage('enter-model')}
                inputMode='text'
              />
            </FormField>
          </Grid>
          <FormField label={bundle.getMessage('description')}>
            <Textarea
              data-testid='AssetCreateDescriptionTextarea'
              onChange={({ detail }) => visitorAssetDescriptionFieldOnChangeHandler(detail)}
              value={assetDescriptionValue || ''}
              placeholder=''
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </div>
  );
}
