import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";
import EmployeeBadgePhoto from 'src/components/common/EmployeeBadgePhoto';
import { IPendingVisitorRequest } from 'src/types';
import PendingVisitorRequestStatus from '../common/PendingVisitorStatus';

export const ColumnDefinitions: TableProps.ColumnDefinition<IPendingVisitorRequest>[] = [
  {
    id: 'siteId',
    header: <TranslateHeader value={'site'} />,
    cell: item => item.site_id,
    minWidth: 30,
    sortingField: 'site_id'
  },
  {
    id: 'visitorType',
    header: <TranslateHeader value={'visitor-type'} />,
    cell: item => item.visitor_type,
    minWidth: 30,
    sortingField: 'visitor_type'
  },
  {
    id: 'firstName',
    header: <TranslateHeader value={'first-name'} />,
    cell: item => item.first_name,
    minWidth: 30,
    sortingField: 'first_name'
  },
  {
    id: 'lastName',
    header: <TranslateHeader value={'last-name'} />,
    cell: item => item.last_name,
    minWidth: 30,
    sortingField: 'last_name'
  },
  {
    id: 'company',
    header: <TranslateHeader value={'company'} />,
    cell: item => item.company,
    minWidth: 30,
    sortingField: 'company'
  },
  {
    id: 'escort1',
    header: <TranslateHeader value={'escort'} />,
    cell: item => {
      return <>
        {item.escort1 && <EmployeeBadgePhoto username={item.escort1} />}
        {item.escort2 && <EmployeeBadgePhoto username={item.escort2} />}
        {item.escort3 && <EmployeeBadgePhoto username={item.escort3} />}
        {item.escort4 && <EmployeeBadgePhoto username={item.escort4} />}
        {item.escort5 && <EmployeeBadgePhoto username={item.escort5} />}
      </>
    },
    minWidth: 30,
    sortingField: 'escort1'
  },
  {
    id: 'startDate',
    header: <TranslateHeader value={'start-date'} />,
    cell: item => item.start_date,
    minWidth: 30,
    sortingField: 'start_date'
  },
  {
    id: 'endDate',
    header: <TranslateHeader value={'end-date'} />,
    cell: item => item.end_date,
    minWidth: 30,
    sortingField: 'end_date'
  },
  {
    id: 'status',
    header: <TranslateHeader value={'status'} />,
    cell: item => <PendingVisitorRequestStatus
      status={item.status}
      nonPendingRequest={item.nonPendingRequest}
    />,
    minWidth: 30,
    sortingField: 'status'
  },
  {
    id: 'approvals_needed',
    header: <TranslateHeader value={'approvals-needed'} />,
    cell: item => item.approvals_needed,
    minWidth: 30,
    sortingField: 'approvals_needed'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.SelfService.PendingVisitorRequests.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
