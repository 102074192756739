import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import {
  listVisitorAssetApprovalsForVisitorAsset,
  SNSPublishAssetRequestApproved,
  SNSPublishAssetRequestDeclined,
} from 'src/graphql/queries';
import {
  deleteVisitorAsset as deleteVisitorAssetMutation,
  updateVisitorAsset as updateVisitorAssetMutation,
  updateVisitorAssetApproval as updateVisitorAssetApprovalMutation,
} from 'src/graphql/mutations';
import { auditDecorator, getLookupTypeValueId, getLookupTypeValues, sqlEscapeString } from 'src/components/utils';
import { ApprovalStatus, LookupTypes } from 'src/constants/Constants';
import { VisitorAssetWithAction } from './TablePanel';
import { debug } from 'src/utils/commonUtils';

export let queryDenialReasons = async (): Promise<APIt.LookupTypeValue[]> => {
  debug('queryDenialReasons()');

  let denialReasons: APIt.LookupTypeValue[] = [];

  try {
    denialReasons = await getLookupTypeValues(LookupTypes.AssetDenial);
    debug(`queryDenialReasons() denialReasons is ${JSON.stringify(denialReasons)}`);
  } catch(error) {
    console.error(`queryDenialReasons() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return denialReasons;
};
queryDenialReasons = auditDecorator('queryDenialReasons', queryDenialReasons);

export let cancelAccessLevelRequest = async (visitorAssetId: string, updatedBy: string): Promise<APIt.VisitorAsset | null> => {
  debug(`cancelAccessLevelRequest() visitorAssetId is ${visitorAssetId} updatedBy is ${updatedBy}`);

  let visitorAsset: APIt.VisitorAsset | null = null;
  let cancelledStatusCodeId = await getLookupTypeValueId('Access Level Approval Status', 'Cancelled');
  debug(`cancelAccessLevelRequest() cancelledStatusCodeId is ${cancelledStatusCodeId}`);

  if (!visitorAssetId || !updatedBy || !cancelledStatusCodeId) return visitorAsset;

  try {
    const response = await API.graphql(graphqlOperation(deleteVisitorAssetMutation,
      {
        id: visitorAssetId,
        updated_by: updatedBy
      })) as GraphQLResult<APIt.DeleteVisitorAssetMutation>;
    debug(`cancelAccessLevelRequest() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.deleteVisitorAsset) {
      visitorAsset= response.data.deleteVisitorAsset as APIt.VisitorAsset;
    }
  } catch(error) {
    console.error(`cancelAccessLevelRequest(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`cancelAccessLevelRequest() visitorAsset is ${JSON.stringify(visitorAsset)}`);
  return(visitorAsset);
};
cancelAccessLevelRequest = auditDecorator('cancelAccessLevelRequest', cancelAccessLevelRequest);

export let queryVisitorAssetApprovals = async (visitorAssetId: string | undefined): Promise<APIt.VisitorAssetApproval[]> => {
  debug(`queryVisitorAssetApprovals() visitorAssetId is ${visitorAssetId}`);

  let visitorAssetApprovals: APIt.VisitorAssetApproval[] = [];

  if (!visitorAssetId) return visitorAssetApprovals;

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAssetApprovalsForVisitorAsset,
      {
        visitor_asset_id: visitorAssetId
      })) as GraphQLResult<APIt.ListVisitorAssetApprovalsForVisitorAssetQuery>;
    debug(`queryVisitorAssetApprovals() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listVisitorAssetApprovalsForVisitorAsset) {
      visitorAssetApprovals = response.data.listVisitorAssetApprovalsForVisitorAsset as unknown as APIt.VisitorAssetApproval[];
    }
  } catch(error) {
    console.error(`queryVisitorAssetApprovals(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryVisitorAssetApprovals() visitorAssetApprovals is ${JSON.stringify(visitorAssetApprovals)}`);
  return(visitorAssetApprovals);
};
queryVisitorAssetApprovals = auditDecorator('queryVisitorAssetApprovals', queryVisitorAssetApprovals);

export let getVisitorAssetApproval = async (visitorAssetId: string, approverId: string): Promise<APIt.VisitorAssetApproval | null> => {
  debug(`getVisitorAssetApproval() visitorAssetId is ${visitorAssetId} approverId is ${approverId}`);

  let visitorAssetApproval: APIt.VisitorAssetApproval | null = null;
  let cancelledStatusCodeId = await getLookupTypeValueId('Access Level Approval Status', 'Cancelled');
  debug(`getVisitorAssetApproval() cancelledStatusCodeId is ${cancelledStatusCodeId}`);

  if (!visitorAssetId || !approverId) return visitorAssetApproval;

  try {
    const visitorAssetApprovals = await queryVisitorAssetApprovals(visitorAssetId);
    debug(`getVisitorAssetApproval() visitorAssetApprovals is ${JSON.stringify(visitorAssetApprovals)}`);
    visitorAssetApproval = visitorAssetApprovals.filter(ala => ala.approver_id == approverId)[0];
  } catch (error) {
    console.error(`getVisitorAssetApproval(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`getVisitorAssetApproval() visitorAssetApproval is ${JSON.stringify(visitorAssetApproval)}`);
  return(visitorAssetApproval);
};
getVisitorAssetApproval = auditDecorator('getVisitorAssetApproval', getVisitorAssetApproval);

export let updateVisitorAsset = async (updateVisitorAssetInput: APIt.UpdateVisitorAssetInput): Promise<APIt.VisitorAsset | null> => {
  debug(`updateVisitorAsset() updateVisitorAssetInput is ${JSON.stringify(updateVisitorAssetInput)}`);

  let visitorAsset: APIt.VisitorAsset | null = null;

  if (!updateVisitorAssetInput) return visitorAsset;

  try {
    const response = await API.graphql(graphqlOperation(updateVisitorAssetMutation,
      {
        input: updateVisitorAssetInput,
      })) as GraphQLResult<APIt.UpdateVisitorAssetMutation>;
    debug(`updateVisitorAsset() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateVisitorAsset) {
      visitorAsset = response.data.updateVisitorAsset as APIt.VisitorAsset;
    }
  } catch (error) {
    console.error(`updateVisitorAsset(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`updateVisitorAsset() visitorAsset is ${JSON.stringify(visitorAsset)}`);
  return(visitorAsset);
};
updateVisitorAsset = auditDecorator('updateVisitorAsset', updateVisitorAsset);

export let updateVisitorAssetApproval = async (updateVisitorAssetApprovalInput: APIt.UpdateVisitorAssetApprovalInput): Promise<APIt.VisitorAssetApproval | null> => {
  debug(`updateVisitorAssetApproval() updateVisitorAssetApprovalInput is ${JSON.stringify(updateVisitorAssetApprovalInput)}`);

  let visitorAssetApproval: APIt.VisitorAssetApproval | null = null;

  if (!updateVisitorAssetApprovalInput) return visitorAssetApproval;

  updateVisitorAssetApprovalInput.notes ? updateVisitorAssetApprovalInput.notes = sqlEscapeString(updateVisitorAssetApprovalInput.notes) : null;

  try {
    const response = await API.graphql(graphqlOperation(updateVisitorAssetApprovalMutation,
      {
        input: updateVisitorAssetApprovalInput,
      })) as GraphQLResult<APIt.UpdateVisitorAssetApprovalMutation>;
    debug(`updateVisitorAssetApproval() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateVisitorAssetApproval) {
      visitorAssetApproval = response.data.updateVisitorAssetApproval as APIt.VisitorAssetApproval;
    }
  } catch (error) {
    console.error(`updateVisitorAssetApproval(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`updateVisitorAssetApproval() visitorAssetApproval is ${JSON.stringify(visitorAssetApproval)}`);
  return(visitorAssetApproval);
};
updateVisitorAssetApproval = auditDecorator('updateVisitorAssetApproval', updateVisitorAssetApproval);

const handleApproval = async (visitorAssetApproval: APIt.VisitorAssetApproval, action: VisitorAssetWithAction, approverId: string): Promise<void> => {
  debug(`handleApproval() visitorAssetApproval is ${JSON.stringify(visitorAssetApproval)}`);
  const visitorAssetApprovalStatusApprovedId = await getLookupTypeValueId(LookupTypes.AssetApprovalStatus,ApprovalStatus.Approved);
  debug(`handleApproval() visitorAssetApprovalStatusApprovedId is ${JSON.stringify(visitorAssetApprovalStatusApprovedId)}`);
  if (!visitorAssetApprovalStatusApprovedId) throw new Error('could not find request asset approval status approved id');
  visitorAssetApproval.status_code_id = visitorAssetApprovalStatusApprovedId;
  visitorAssetApproval.updated_by = approverId;
  visitorAssetApproval.notes = action.notes;
  const updateVisitorAssetApprovalInput: APIt.UpdateVisitorAssetApprovalInput = {
    approver_id: visitorAssetApproval.approver_id,
    approver_source_system_id: visitorAssetApproval.approver_source_system_id,
    id: visitorAssetApproval.id,
    notes: action.notes,
    status_code_id: visitorAssetApprovalStatusApprovedId,
    updated_by: visitorAssetApproval.approver_id,
    visitor_asset_id: visitorAssetApproval.visitor_asset_id,
  };
  debug(`handleApproval() updateVisitorAssetApprovalInput is ${JSON.stringify(updateVisitorAssetApprovalInput)}`);
  const updateVisitorAssetApprovalResponse = await updateVisitorAssetApproval(updateVisitorAssetApprovalInput);
  debug(`handleApproval() updateVisitorAssetApprovalResponse is ${JSON.stringify(updateVisitorAssetApprovalResponse)}`);
  const updateVisitorAssetInput: APIt.UpdateVisitorAssetInput = {
    approval_status_id: visitorAssetApprovalStatusApprovedId,
    asset_type_id: action.asset_type_id,
    id: action.id,
    permanent_flag: action.permanent_flag,
    site_id: action.site_id,
    site_source_system_id: action.site_source_system_id,
    updated_by: visitorAssetApproval.approver_id,
    visitor_id: action.visitor_id,
  } as APIt.UpdateVisitorAssetInput;
  debug(`handleApproval() updateVisitorAssetInput is ${JSON.stringify(updateVisitorAssetInput)}`);
  const updateVisitorAssetResponse = await updateVisitorAsset(updateVisitorAssetInput);
  debug(`handleApproval() updateVisitorAssetResponse is ${JSON.stringify(updateVisitorAssetResponse)}`);

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishAssetRequestApproved,
      {
        visitorAssetId: action!.id,
      })) as GraphQLResult<APIt.SNSPublishAssetRequestApprovedQuery>;
    if (response && response.data && response.data.SNSPublishAssetRequestApproved) {
      debug(`handleApproval() SNSPublishAssetRequestApproved response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`handleApproval() SNSPublishAssetRequestApproved error is ${JSON.stringify(error)}`);
    throw error;
  }
};

const handleDenial = async (visitorAssetApproval: APIt.VisitorAssetApproval, action: VisitorAssetWithAction, approverId: string): Promise<void> => {
  debug(`handleDenial() visitorAssetApproval is ${JSON.stringify(visitorAssetApproval)}`);
  const visitorAssetApprovalStatusDeniedId = await getLookupTypeValueId(LookupTypes.AssetApprovalStatus, ApprovalStatus.Denied);
  if (!visitorAssetApprovalStatusDeniedId) throw new Error('could not find request asset denied status Approved id');
  const visitorAssetApprovalDenialId = await getLookupTypeValueId(LookupTypes.AssetDenial, action.denial_reason!);
  if (!visitorAssetApprovalDenialId) throw new Error('could not find request asset denial reason id');
  visitorAssetApproval.status_code_id = visitorAssetApprovalStatusDeniedId;
  visitorAssetApproval.denial_reason_id = visitorAssetApprovalDenialId;
  visitorAssetApproval.updated_by = approverId;
  visitorAssetApproval.notes = action.notes;
  const updateVisitorAssetApprovalInput: APIt.UpdateVisitorAssetApprovalInput = {
    approver_id: visitorAssetApproval.approver_id,
    approver_source_system_id: visitorAssetApproval.approver_source_system_id,
    denial_reason_id: visitorAssetApprovalDenialId,
    id: visitorAssetApproval.id,
    notes: action.notes,
    status_code_id: visitorAssetApprovalStatusDeniedId,
    visitor_asset_id: visitorAssetApproval.visitor_asset_id,
    updated_by: visitorAssetApproval.approver_id,
  };
  debug(`handleDenial() updateVisitorAssetApprovalInput is ${JSON.stringify(updateVisitorAssetApprovalInput)}`);
  const updateVisitorAssetApprovalResponse = await updateVisitorAssetApproval(updateVisitorAssetApprovalInput);
  debug(`handleDenial() updateVisitorAssetApprovalResponse is ${JSON.stringify(updateVisitorAssetApprovalResponse)}`);
  let updateVisitorAssetInput: APIt.UpdateVisitorAssetInput = {
    approval_status_id: visitorAssetApprovalStatusDeniedId,
    asset_type_id: action.asset_type_id,
    id: action.id,
    permanent_flag: action.permanent_flag,
    site_id: action.site_id,
    site_source_system_id: action.site_source_system_id,
    updated_by: visitorAssetApproval.approver_id,
    visitor_id: action.visitor_id,
  };
  debug(`handleDenial() updateVisitorAssetInput is ${JSON.stringify(updateVisitorAssetInput)}`);
  const updateVisitorAssetResponse = await updateVisitorAsset(updateVisitorAssetInput);
  debug(`handleDenial() updateVisitorAssetResponse is ${JSON.stringify(updateVisitorAssetResponse)}`);

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishAssetRequestDeclined,
      {
        visitorAssetId: action!.id,
      })) as GraphQLResult<APIt.SNSPublishAssetRequestDeclinedQuery>;
    if (response && response.data && response.data.SNSPublishAssetRequestDeclined) {
      debug(`handleDenial() SNSPublishAssetRequestDeclined response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`handleDenial() SNSPublishAssetRequestDeclined error is ${JSON.stringify(error)}`);
    throw error;
  }
};

export interface SubmitVisitorAssetActionsProps {
  actions: VisitorAssetWithAction[];
  approverId: string;
}

export let submitVisitorAssetActions = async (props: SubmitVisitorAssetActionsProps): Promise<void> => {
  debug(`submitVisitorAssetActions() props is ${JSON.stringify(props)}`);

  for (let action of props.actions) {
    try {
      const visitorAssetApproval = await getVisitorAssetApproval(action.id, props.approverId);
      if (!visitorAssetApproval) throw new Error('could not find visitor asset approval');
      debug(`submitVisitorAssetActions() visitorAssetApproval is ${JSON.stringify(visitorAssetApproval)}`);
      switch(action.action?.value) {
        case 'approve':
          await handleApproval(visitorAssetApproval, action, props.approverId);
          break;
        case 'deny':
          await handleDenial(visitorAssetApproval, action, props.approverId);
          break;
      }
    } catch(error) {
      throw(error);
    }
  }

};
submitVisitorAssetActions = auditDecorator('submitVisitorAssetActions', submitVisitorAssetActions);