
import { Button, Popover, StatusIndicator } from '@amzn/awsui-components-react';
import React from 'react';

type PopoverProps = {
  title: string;
  children?: never[]; //not used but required to be present
};

export default function PopoverText(props: PopoverProps) {
  return (<>
    <Popover
      size="medium"
      triggerType="custom"
      content={
        <StatusIndicator type="info">
          {props.title}
        </StatusIndicator>
      }
    >
      <Button iconName="status-info" variant='icon'></Button>
    </Popover>
  </>);
};