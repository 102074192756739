import { AppLayout, Container, Header, Spinner, Tabs } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import React, { ReactNode, useState } from 'react';
import AccessRequestDetails from './AccessRequestDetails';
import { debug } from 'src/utils/commonUtils';

export default function Support() {
  debug(`Support()`);

  const [activeTabId, setActiveTabId] = useState<string>('Access Request Details');

  const [bundle, isBundleLoading] = useBundle('components.Management.Support.index');

  const displayTabs = (): {label: ReactNode | string, id: string, content: any }[] => {
    let tabs: { label: ReactNode | string; id: string; content: any; }[] = [];
    tabs = [
      {
        label: bundle.getMessage('access-request-details'),
        id: 'Access Request Details',
        content: <AccessRequestDetails/>
      },
    ];
    return tabs;
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <AppLayout
      ariaLabels={{ navigationClose: 'close' }}
      content={
        <Tabs
          tabs={displayTabs()}
          variant='container'
          activeTabId={activeTabId}
          onChange={e => setActiveTabId(e.detail.activeTabId)}
        />
      }
      contentType='table'
      disableContentPaddings
      headerSelector='#header'
      navigationHide
      stickyNotifications
      toolsHide={true}
    />
  );
}


