import React from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { Modes } from 'src/constants/Constants';
import VisitorAssetsTablePanel from './TablePanel';
import { queryVisitorAssetTypes } from 'src/components/utils';
import * as APIt from 'src/API';
import { debug } from '../../../utils/commonUtils';

interface IAssetsProps {
  assetQueryUser: string;
  delegatorsQuery: UseQueryResult<APIt.Delegation[] | null, unknown>;
  disableAutoApproval: boolean;
  empId: string;
  mode: Modes;
  setAssetQueryUserCallback: Function
  username: string;
  visitorAssetsQuery: UseQueryResult<APIt.VisitorAsset[] | null, unknown>;
  visitorId: string | undefined;
}

export default function Assets(props: IAssetsProps) {
  debug(`Assets() props is ${JSON.stringify(props)}`);


  const visitorAssetsTypesQuery = useQuery({
    queryKey: ['visitorAssetTypes'],
    queryFn: () => queryVisitorAssetTypes(),
    retry: 3
  });

  return(
    <VisitorAssetsTablePanel
      assetQueryUser={props.assetQueryUser}
      delegatorsQuery={props.delegatorsQuery}
      disableAutoApproval={props.disableAutoApproval}
      employeeId={props.empId}
      isTableLoading={props.visitorAssetsQuery.isFetching}
      setAssetQueryUserCallback={props.setAssetQueryUserCallback}
      username={props.username}
      visitorAssets={props.visitorAssetsQuery.data || []}
      visitorAssetsQuery={props.visitorAssetsQuery}
      visitorAssetTypes={visitorAssetsTypesQuery.data || []}
      visitorId={props.visitorId}
    />
  );
}


