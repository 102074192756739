import React, { useState, useEffect } from 'react';
import { Button, Modal, Pagination, SpaceBetween, Spinner, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './VisitorAssetsTablePanel.table-config';
import * as APIt from 'src/API';
import { AddVisitorAssetCreatePanel } from './AddVisitorAssetCreatePanel';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult } from '@tanstack/react-query';
import { debug } from 'src/utils/commonUtils';

export interface CancelCreateVisitorAssetInterface {
  (): void;
}

export interface CreatedVisitorAssetInterface {
  (): void;
}

export interface CancelUpdateVisitorAssetInterface {
  (): void;
}

export interface UpdatedVisitorAssetInterface {
  (updatedVisitorAsset: APIt.VisitorAsset): void;
}

export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export interface ManageVisitorAssetsTablePanelPropsInterface {
  isTableLoading: boolean;
  readOnly: boolean;
  setVisitorAssets: Function | undefined;
  showAssetOwner?: boolean;
  siteCode: string;
  username: string;
  visitorAssets: APIt.VisitorAsset[];
  visitorAssetTypesQuery: UseQueryResult<APIt.LookupTypeValue[]> | null;
  visitorId: string;
}

export default function VisitorAssetsTablePanel(props: ManageVisitorAssetsTablePanelPropsInterface ) {
  debug(`VisitorAssetsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAsset[]>(props.visitorAssets);
  const [columnDefinitions, setColumnDefinitions] = useState<TableProps.ColumnDefinition<APIt.VisitorAsset>[]>(ColumnDefinitions);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<APIt.VisitorAsset[]>([]);
  const [showCreateVisitorAssetPanel, setShowCreateVisitorAssetPanel] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.common.Visitor.VisitorAssetsTablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-assets-found') : 'No Assets Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const cancelCreate: CancelCreateVisitorAssetInterface = () => {
    setShowCreateVisitorAssetPanel(false);
  }

  const createBtnClickHandler = () => {
    setShowCreateVisitorAssetPanel(true);
  };

  const addVisitorAsset = (visitorAsset: APIt.VisitorAsset) => {
    allItems.push(visitorAsset);
    if (props.setVisitorAssets) props.setVisitorAssets(allItems);
    setShowCreateVisitorAssetPanel(false);
  }

  const deleteBtnClickHandler = () => {
  };

  useEffect(() => {
    if (props.visitorAssets) setAllItems(props.visitorAssets);
  }, [props.visitorAssets]);

  useEffect(() => {
    if (!props.showAssetOwner) setColumnDefinitions(ColumnDefinitions.filter(cd => cd.id !== 'person_id'));
  }, [props.showAssetOwner]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='visitorAssetsTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter Assets'
            filteringPlaceholder={bundle.getMessage('find-assets')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        footer={
          <Header
            actions={
              !props.readOnly
              &&
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='VisitorRemoveSelectedButton'
                  disabled={selectedItems.length == 0}
                  formAction='none'
                  onClick={deleteBtnClickHandler}
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
                <Button
                  data-testid='VisitorAddAssetButton'
                  formAction='none'
                  onClick={createBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('add-asset')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        items={items}
        loading={props.isTableLoading}
        loadingText={bundle.getMessage('loading-assets')}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType={props.readOnly ? undefined : 'multi'}
        stickyHeader={true}
        trackBy='id'
        variant='embedded'
      />
    </div>
    {showCreateVisitorAssetPanel && props.visitorAssetTypesQuery
    &&
      <Modal
        header={bundle.getMessage('add-asset')}
        visible={showCreateVisitorAssetPanel}
        onDismiss={() => setShowCreateVisitorAssetPanel(false)}>
        <AddVisitorAssetCreatePanel
          assetTypeOptionsQuery={props.visitorAssetTypesQuery}
          cancelCreateCallback={cancelCreate}
          addCallback={addVisitorAsset}
        />
      </Modal>
    }
    </>
  );
}
