import { AppLayout, Container, Header, Spinner, Tabs } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import React, { ReactNode, useState } from 'react';
import { queryApproverGroups } from 'src/components/utils';
import { useQuery } from '@tanstack/react-query';
import Lookups from './LookupTypes';
import ApproverGroups from './ApproverGroups';
import { debug } from 'src/utils/commonUtils';

interface IAdministrationProps {
  username: string;
}

export default function Administration(props: IAdministrationProps) {
  debug(`Administration() props is ${JSON.stringify(props)}`);

  const approverGroupsQuery = useQuery({
    queryFn: () => queryApproverGroups(),
    queryKey: ['approverGroups'],
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const [activeTabId, setActiveTabId] = useState<string>('Lookups');

  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.index');

  const displayTabs = (): {label: ReactNode | string, id: string, content: any }[] => {
    let tabs: { label: ReactNode | string; id: string; content: any; }[] = [];
    tabs = [
      {
        label: bundle.getMessage('lookups'),
        id: 'Lookups',
        content: <Lookups username={props.username} />
      },
      {
        label: bundle.getMessage('approver-groups'),
        id: 'Approver Groups',
        content: <ApproverGroups username={props.username} />
      },
    ];
    return tabs;
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <AppLayout
      ariaLabels={{ navigationClose: 'close' }}
      content={
        <Tabs
          tabs={displayTabs()}
          variant='container'
          activeTabId={activeTabId}
          onChange={e => setActiveTabId(e.detail.activeTabId)}
        />
      }
      contentType='table'
      disableContentPaddings
      headerSelector='#header'
      navigationHide
      stickyNotifications
      toolsHide={true}
    />
  );
}


