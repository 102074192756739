import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Grid,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  Textarea,
  TextFilter,
  Toggle,
  ToggleProps,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import PendingAccessRequestDetailsTablePanel from './DetailsTablePanel';
import { useQuery } from '@tanstack/react-query';
import {
  cancelAccessLevelRequest,
  queryHistoricalVisitorAccessLevels,
  queryPendingVisitorAccessLevels,
  queryVisitorAccessLevelApprovals,
  sendAccessLevelRequestReminder,
} from './utils';
import { queryLookupTypeValueForTypeAndDescription } from 'src/components/utils';
import { LookupTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import { debug } from 'src/utils/commonUtils';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import { PendingAccessRequestsTablePanelPropsInterface, VisitorAccessLevelCsv } from 'src/interfaces';
import { IPendingVisitorAccessLevel } from 'src/types';

export default function PendingAccessRequestsTablePanel(props: PendingAccessRequestsTablePanelPropsInterface ) {
  debug(`PendingAccessRequestsTablePanel() props is ${JSON.stringify(props)}`);

  const pendingVisitorAccessLevelsQuery = useQuery({
    onSuccess: (data) => { setAllItems(data || []); },
    queryKey: ['pendingVisitorAccessLevels'],
    queryFn: () => queryPendingVisitorAccessLevels(props.username, props.username),
    retry: 3,
  });
  
  const [accessLevelReminderThrottleHours, setAccessLevelReminderThrottleHours] = useState<number>(24);
  const [allItems, setAllItems] = useState<IPendingVisitorAccessLevel[]>(pendingVisitorAccessLevelsQuery.data || []);
  const [cancelAccessRequestsVisible, setCancelAccessRequestsVisible] = useState<boolean>(false);
  const [cancellingAccessRequests, setCancellingAccessRequests] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [queryAllHistorical, setQueryAllHistorical] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<APIt.VisitorAccessLevel[]>([]);
  const [sendingReminder, setSendingReminder] = useState<boolean>(false);
  const [showCancelledAccessRequestsFlashbar, setShowCancelledAccessRequestsFlashbar] = useState<boolean>(false);
  const [showNotCancelledAccessRequestsFlashbar, setShowNotCancelledAccessRequestsFlashbar] = useState<boolean>(false);
  const [showReminderSent, setShowReminderSent] = useState<boolean>(false);
  const [throttleSendReminder, setThrottleSendReminder] = useState<boolean>(false);  

  const historicalVisitorAccessLevelsQuery = useQuery({
    enabled: queryAllHistorical,
    onSuccess: (data) => { setAllItems(data || []); },
    queryKey: ['historicalVisitorAccessLevels'],
    queryFn: () => queryHistoricalVisitorAccessLevels(props.username, props.username),
    retry: 3,
  });

  const visitorAccessLevelApprovalsQuery = useQuery({
    queryKey: ['pendingAccessLevelApprovals'],
    queryFn: () => queryVisitorAccessLevelApprovals(selectedItems[0]?.id),
    retry: 3
  });  

  const refreshVisitorAccessLevels = async () => {
    if (queryAllHistorical) {
      await historicalVisitorAccessLevelsQuery.refetch();
    } else {
      await pendingVisitorAccessLevelsQuery.refetch();
    }
  };

  const refresh = async () => {
    await visitorAccessLevelApprovalsQuery.refetch();
  };

  useEffect(() => {
    visitorAccessLevelApprovalsQuery.refetch();
  }, [selectedItems]);

  useEffect(() => {
    refreshVisitorAccessLevels();
  }, [queryAllHistorical]);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.PendingAccessRequests.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={
          queryAllHistorical
            ? !isBundleLoading
              ? bundle.getMessage('no-historical-access-requests-found')
              : 'No Historical Access Requests Found'
            : !isBundleLoading
              ? bundle.getMessage('no-pending-access-requests-found')
              : 'No Pending Access Requests'
        } />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const cancelAccessRequestsBtnClickHandler = () => {
    if (selectedItems.length > 0) setCancelAccessRequestsVisible(true);
  };

  const sendReminderBtnClickHandler = async () => {
    setShowReminderSent(false);
    setSendingReminder(true);
    for (let selectedItem of selectedItems) {
      await sendAccessLevelRequestReminder(selectedItem.request_id, props.username);
    }
    setSendingReminder(false);
    setShowReminderSent(true);
    await refreshVisitorAccessLevels();
    setSelectedItems([]);
  };

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await refreshVisitorAccessLevels();
    setRefreshing(false);
    setSelectedItems([]);
  };

  const cancelAccessRequestsConfirmedHandler = async () => {
    setCancellingAccessRequests(true);
    let filteredSelectedItems: IPendingVisitorAccessLevel[] = selectedItems;
    let itemsNotUpToDate: IPendingVisitorAccessLevel[] = [];
    try {
      const refreshedItems = await queryPendingVisitorAccessLevels(props.username, props.username);
      if (!refreshedItems)
        throw new Error('Failed to refresh visitor access levels.');
      for (let si of selectedItems) {
        if (!refreshedItems.find((ri) => ri.id === si.id)) {
          itemsNotUpToDate.push(si);
        }
      }
      if (itemsNotUpToDate.length === 0) {
        for (let vr of selectedItems) {
          await cancelAccessLevelRequest(vr.id, props.username);
          filteredSelectedItems = filteredSelectedItems.filter((si) => si.id !== vr.id);
        };
        await refreshVisitorAccessLevels();
        setShowCancelledAccessRequestsFlashbar(true);
        setSelectedItems(filteredSelectedItems);
      } else {
        for (let vr of itemsNotUpToDate) {
          allItems.find((ai) => {
            if (ai.id === vr.id) {
              ai.nonPendingRequest = true;
            }
          });
        }
        setShowNotCancelledAccessRequestsFlashbar(true);
      }
      setCancelAccessRequestsVisible(false);
      setCancellingAccessRequests(false);
    } catch (error) {
      console.error(error);
    }
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const csvColumns = allPageItems.map(item => {
    return {
      access_level_name: item.access_level_name,
      company: item.company,
      created: item.created,
      created_by: item.created_by,
      email: item.email,
      end_date: item.end_date,
      first_name: item.first_name,
      last_name: item.last_name,
      last_reminder_date: item.last_reminder_date,
      person_id: item.person_id,
      permanent_flag: item.permanent_flag,
      reason: item.reason,
      request_id: item.request_id,
      requestor_id: item.requestor_id,
      site_id: item.site_id,
      start_date: item.start_date,
      status: item.status,
      updated: item.updated,
      updated_by: item.updated_by,
      visitor_id: item.visitor_id,
      visitor_type: item.visitor_type
    }
  });

  const listHistoricalToggleHandler = (detail: ToggleProps.ChangeDetail) => {
    setQueryAllHistorical(detail.checked);
    setSelectedItems([]);
  };

  const ReasonField = () => {
    return(
      <>
      <FormField label={bundle.getMessage('reason-for-access-request')} >
        <Textarea value={selectedItems[0].reason} disabled/>
      </FormField>
      <br/>
      </>
    );
  };

  useEffect(() => {
    const now = Date.now();
    let throttle = false;
    let accessLevel = selectedItems.find((si) => {
      if (si.last_reminder_date !== null && si.last_reminder_date !== undefined) {
        let lastReminderDate = new Date(si.last_reminder_date?.slice(0,19) + 'Z')
        let diff = now - lastReminderDate.getTime();
        let diffHours = diff / (1000 * 60 * 60);
        debug(`now is ${now} lastReminderDate.getTime() is ${lastReminderDate.getTime()} lastReminderDate is ${lastReminderDate} diff is ${diff} diffHours is ${diffHours} accessLevelReminderThrottleHours is ${accessLevelReminderThrottleHours}`);
        return diffHours < accessLevelReminderThrottleHours;
      }
      return false;
    });
    if (accessLevel) throttle = true;
    setThrottleSendReminder(throttle);
  }, [selectedItems]);

  useEffect(() => {
    try {
      queryLookupTypeValueForTypeAndDescription(
        LookupTypes.WelcomeApplicationSettings,
        WelcomeApplicationSettings.AccessLevelReminderThrottleHours)
      .then((v) => setAccessLevelReminderThrottleHours(parseFloat(v.value)));
    } catch(error) {
      setAccessLevelReminderThrottleHours(24);
      console.error(error);
    }
  }, []);

  const FlashMessageReminderSent = () => (
    <Flashbar
      items = {
        [
          {
            type: 'success',
            dismissible: true,
            content: bundle.getMessage('reminder-sent'),
            onDismiss: () => setShowReminderSent(false)
          },
        ]
      }
    />
  );

  const FlashMessageAccessRequestsCancelled = () => (
    <Flashbar
      items = {
        [
          {
            type: 'success',
            dismissible: true,
            content: bundle.getMessage('access-request-cancelled'),
            onDismiss: () => setShowCancelledAccessRequestsFlashbar(false)
          },
        ]
      }
    />
  );

  const FlashMessageAccessRequestsNotCancelled = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: true,
            content: bundle.getMessage('access-request-not-cancelled'),
            onDismiss: () => setShowNotCancelledAccessRequestsFlashbar(false)
          },
        ]
      }
    />
  );

  const FlashMessageSendReminderThrottle = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: `${bundle.getMessage('reminder-throttled')} (${accessLevelReminderThrottleHours} ${bundle.getMessage('hours')})`,
          },
        ]
      }
    />
  );

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showCancelledAccessRequestsFlashbar && <FlashMessageAccessRequestsCancelled/>}
    {showReminderSent && <FlashMessageReminderSent/>}
    {throttleSendReminder && <FlashMessageSendReminderThrottle/>}
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        data-testid='PendingAccessRequestsTable'
        filter={
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
            <TextFilter
              {...filterProps}
              filteringAriaLabel={queryAllHistorical ? bundle.getMessage('filter-historical-access-requests') : bundle.getMessage('filter-pending-access-requests')}
              filteringPlaceholder={queryAllHistorical ? bundle.getMessage('find-historical-access-requests') : bundle.getMessage('find-pending-access-requests')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0 : filteredItemsCount)}
            />
            <Toggle
              onChange={({ detail }) => listHistoricalToggleHandler(detail)}
              checked={queryAllHistorical}
            >
              {bundle.getMessage('list-historical-requests')}
            </Toggle>
          </Grid>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  loading={refreshing}
                  key="refreshBtn"
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                >
                  {bundle.getMessage('refresh')}
                </Button>
                <CsvDownloadButton
                  data={csvColumns}
                  delimiter={','}
                  disabled={pendingVisitorAccessLevelsQuery.isFetching || historicalVisitorAccessLevelsQuery.isFetching || items.length < 1}
                  filename={`AccessRequests-${new Date().toISOString()}`}
                />
              </SpaceBetween>
              </>
            }
          >
            {queryAllHistorical ? bundle.getMessage('historical-access-requests') : bundle.getMessage('pending-access-requests')}
          </Header>
        }
        footer={
          <>
          {showNotCancelledAccessRequestsFlashbar && <FlashMessageAccessRequestsNotCancelled/>}
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='PendingAccessRequestsSendReminderButton'
                  disabled={selectedItems.length === 0 || throttleSendReminder || queryAllHistorical}
                  onClick={sendReminderBtnClickHandler}
                  iconName='notification'
                  loading={sendingReminder}
                  variant='normal'
                >
                  {bundle.getMessage('send-reminder')}
                </Button>
                <Button
                  data-testid='PendingAccessRequestsCancelAccessRequestButton'
                  disabled={selectedItems.length === 0 || queryAllHistorical}
                  onClick={cancelAccessRequestsBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('cancel-access-request')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
          </>
        }
        items={items}
        loading={pendingVisitorAccessLevelsQuery.isFetching || historicalVisitorAccessLevelsQuery.isFetching}
        loadingText={queryAllHistorical ? bundle.getMessage('loading-historical-access-requests') : bundle.getMessage('loading-pending-access-requests')}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    {
      selectedItems.length == 1
      &&
      <Container
        key={'PendingAccessRequestDetails'}
        header={<Header>{`${bundle.getMessage('access-request-details')} for ${selectedItems[0].person_id} ${selectedItems[0].access_level_name}`}</Header>}
        children={
          <>
          <ReasonField/>
          {selectedItems.length == 1
          && visitorAccessLevelApprovalsQuery.data
          &&
            <PendingAccessRequestDetailsTablePanel
              isLoading={visitorAccessLevelApprovalsQuery.isLoading || visitorAccessLevelApprovalsQuery.isFetching}
              refreshCallback={refresh}
              visitorAccessLevelApprovals={visitorAccessLevelApprovalsQuery.data}
            />}
          </>
        }
      />
    }
    {cancelAccessRequestsVisible
    &&
    <Modal
      onDismiss={() => setCancelAccessRequestsVisible(false)}
      visible={cancelAccessRequestsVisible}
      closeAriaLabel='Close'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setCancelAccessRequestsVisible(false)}>{bundle.getMessage('no')}</Button>
            <Button
              disabled={cancellingAccessRequests}
              loading={cancellingAccessRequests}
              onClick={() => cancelAccessRequestsConfirmedHandler() }
              variant='primary'
            >
              {bundle.getMessage('yes')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('confirm-access-request-cancel')}
    >
      {bundle.getMessage('please-confirm-access-request-cancel')}
    </Modal>
    }
    </>
  );
}
