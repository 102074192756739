import React, { useState, useEffect } from 'react';
import { Box, Button, Flashbar, Modal, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { API, graphqlOperation } from '@aws-amplify/api';
import { deleteVisitorAsset as deleteVisitorAssetMutation } from 'src/graphql/mutations';
import { ManageVisitorAssetCreatePanel } from './Create';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult } from '@tanstack/react-query';
import { debug } from 'src/utils/commonUtils';

export interface CancelCreateVisitorAssetInterface {
  (): void;
}

export interface CreatedVisitorAssetInterface {
  (): void;
}

export interface CancelUpdateVisitorAssetInterface {
  (): void;
}

export interface UpdatedVisitorAssetInterface {
  (updatedVisitorAsset: APIt.VisitorAsset): void;
}

export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export interface ManageVisitorAssetsTablePanelPropsInterface {
  isTableLoading: boolean;
  readOnly: boolean;
  siteCode: string;
  username: string;
  visitorAssetsQuery: UseQueryResult<APIt.VisitorAsset[] | null, unknown>;
  visitorAssetTypesQuery: UseQueryResult<APIt.LookupTypeValue[]>;
  visitorId: string;
}

export default function ManageVisitorAssetsTablePanel(props: ManageVisitorAssetsTablePanelPropsInterface ) {
  debug(`ManageVisitorAssetsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAsset[]>(props.visitorAssetsQuery.data || []);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [removingAssets, setRemovingAssets] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<APIt.VisitorAsset[]>([]);
  const [showCreateVisitorAssetPanel, setShowCreateVisitorAssetPanel] = useState<boolean>(false);
  const [visitorAssetCreateSaved, setVisitorAssetCreateSaved] = useState<boolean>(false);
  const [visitorAssetDeleteSaved, setVisitorAssetDeleteSaved] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.Assets.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-assets-found') : 'No Assets Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const cancelCreate: CancelCreateVisitorAssetInterface = () => {
    setShowCreateVisitorAssetPanel(false);
  }

  const createBtnClickHandler = () => {
    setShowCreateVisitorAssetPanel(true);
  };

  const createdVisitorAsset: CreatedVisitorAssetInterface = () => {
    setVisitorAssetCreateSaved(true);
    setShowCreateVisitorAssetPanel(false);
    props.visitorAssetsQuery.refetch();
  }

  const resetVisitorAssetRequestCreatedSaved = () => {
    setVisitorAssetCreateSaved(false);
  };

  const FlashMessageCreateSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'success',
            dismissible: true,
            content: bundle.getMessage('asset-added'),
            onDismiss: resetVisitorAssetRequestCreatedSaved
          },
        ]
      }
    />
  );

  const deleteBtnClickHandler = () => {
    if (selectedItems.length > 0) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const deleteSelectedVisitorAssets = async () => {
    setRemovingAssets(true);
    for (let selectedItem of selectedItems) {
      try {
        await API.graphql(graphqlOperation(deleteVisitorAssetMutation,
          {
            input:
              {
                id: selectedItem.id,
                updated_by: props.username
              }
          }));
      } catch (e) {
        console.error(`deleteVisitorAssets(): exception is ${JSON.stringify(e)}`);
      }
    }
    const newAllItems = allItems!.filter( (item) => !selectedItems.find( si => si.id == item.id));
    debug(`deleteSelectedVisitorAssets() newAllItems.length is ${newAllItems.length}`);
    setAllItems(newAllItems);
    setDeleteVisible(false);
    setVisitorAssetDeleteSaved(true);
    setSelectedItems([]);
    setRemovingAssets(false);
  };

  const resetVisitorAssetDeleteSaved = () => {
    setVisitorAssetDeleteSaved(false);
  };

  const FlashMessageDeleteSaved = () => (
    <Flashbar
      items = {
        [
          {
            header: bundle.getMessage('assets-removed'),
            dismissible: true,
            dismissLabel: bundle.getMessage('dismiss'),
            onDismiss: resetVisitorAssetDeleteSaved,
            type: 'success',
          },
        ]
      }
    />);

  useEffect(() => {
    if (props.visitorAssetsQuery.data) setAllItems(props.visitorAssetsQuery.data);
  }, [props.visitorAssetsQuery]);

  useEffect(() => {
    return () => {
      setVisitorAssetCreateSaved(false);
      setVisitorAssetDeleteSaved(false);
    };
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {visitorAssetCreateSaved && <FlashMessageCreateSaved/>}
    {visitorAssetDeleteSaved && <FlashMessageDeleteSaved/>}
    <div id='visitorAssetsTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter Assets'
            filteringPlaceholder={bundle.getMessage('find-assets')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        footer={
          <Header
            actions={
              !props.readOnly
              &&
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  disabled={selectedItems.length == 0}
                  formAction='none'
                  onClick={deleteBtnClickHandler}
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
                <Button
                  formAction='none'
                  onClick={createBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('add-asset')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        items={items}
        loading={props.isTableLoading}
        loadingText={bundle.getMessage('loading-assets')}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='id'
        variant='embedded'
      />
    </div>
    {deleteVisible
    &&
    <Modal
      onDismiss={() => setDeleteVisible(false)}
      visible={deleteVisible}
      closeAriaLabel='Close'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              data-testid='AssetsRemoveCancelButton'
              variant='link'
              onClick={deleteNoBtnClickHandler}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              data-testid='AssetsRemoveButton'
              formAction='none'
              disabled={removingAssets}
              loading={removingAssets}
              onClick={deleteSelectedVisitorAssets}
              variant='primary'
            >
              {bundle.getMessage('remove')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('confirm-asset-removal')}
    >
      {bundle.getMessage('please-confirm-asset-removal')}
    </Modal>
    }
    {showCreateVisitorAssetPanel
    &&
      <Modal
        header={bundle.getMessage('add-asset')}
        visible={showCreateVisitorAssetPanel}
        onDismiss={() => setShowCreateVisitorAssetPanel(false)}>
        <ManageVisitorAssetCreatePanel
          assetTypeOptionsQuery={props.visitorAssetTypesQuery}
          cancelCreateCallback={cancelCreate}
          saveCallback={createdVisitorAsset}
          siteCode={props.siteCode}
          username={props.username}
          visitorId={props.visitorId}
        />
      </Modal>
    }
    </>
  );
}
