import React, { useEffect, useRef, useState } from 'react';
import {
  AssetApprovalStatus,
  DefaultValidFromTime,
  DefaultValidThroughTime,
  LookupTypes,
  Modes,
  URLS,
  VisitorRequestStatus,
  VisitorTypes
} from 'src/constants/Constants';
import EscortsTablePanel, { HeaderMessages } from 'src/components/common/Escorts/TablePanel';
import { IEscort, IVisitorRequest } from 'src/types';
import Visitors from './Visitors/TablePanel';
import {
  Box,
  Button,
  Container,
  DateRangePickerProps,
  Flashbar,
  FormField,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  Textarea } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { ISubmitVisitorsRequest, submitVisitorRequest } from './utils';
import * as APIt from 'src/API';
import * as uuid from 'uuid';
import { getLookupTypeValueId } from 'src/components/utils';
import { useQueryClient } from '@tanstack/react-query';
import { TVisitor } from 'src/types';
import { ICreateVisitorInputWithAssets } from 'src/interfaces';
import { debug } from 'src/utils/commonUtils';

interface IRequestVisitorAccessProps {
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  maxVisitorsPerEscort: number;
  mode: Modes;
  setCardholdersQuickLinksCallback: Function;
  setVisitDateRangeCallback: Function;
  setVisitorRequestCallback: Function;
  username: string;
  visitDateRange: DateRangePickerProps.Value | null;
  visitorRequest: IVisitorRequest | null;
}

export default function RequestVisitorAccess(props: IRequestVisitorAccessProps) {
  debug(`RequestVisitorAccess() props is ${JSON.stringify(props)}`);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [escorts, setEscorts] = useState<IEscort[]>(props.visitorRequest?.escorts || []);
  const [escortMissingActiveBadge, setEscortMissingActiveBadge] = useState<boolean | null>(null);
  const [escortMissingSiteAccess, setEscortMissingSiteAccess] = useState<boolean | null>(null);
  const [clearDetails, setClearDetails] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [reasonForVisitInput, setReasonForVisitInput] = useState<string>(props.visitorRequest?.reason || '');
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [showRequestFailedFlashbar, setShowRequestFailedFlashbar] = useState<boolean>(false);
  const [showRequestSubmittedFlashbar, setShowRequestSubmittedFlashbar] = useState<boolean>(false);
  const [showSubmitRequestAcknowledgementModal, setShowSubmitRequestAcknowledgementModal] = useState<boolean>(false);
  const [siteCode, setSiteCode] = useState<string | null>(props.visitorRequest?.siteCode || null);
  const [tooManyVisitorsPerEscortFlashbar, setTooManyVisitorsPerEscortFlashbar] = useState<boolean>(false);
  const [visitors, setVisitors] = useState<TVisitor[]>(props.visitorRequest?.visitors || []);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(props.visitDateRange || null);
  const [visitStartDate, setVisitStartDate] = useState<string | null>(props.visitorRequest?.startDate || null);
  const [visitStartTime, setVisitStartTime] = useState<string>(props.visitorRequest?.startTime || DefaultValidFromTime);
  const [visitEndDate, setVisitEndDate] = useState<string | null>(props.visitorRequest?.endDate || null);
  const [visitEndTime, setVisitEndTime] = useState<string>(props.visitorRequest?.endTime || DefaultValidThroughTime);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVisitorAccess.index');

  const reasonForVisitRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const queryClient = useQueryClient();

  const submitRequest = async () => {
    debug(`RequestVisitorAccess() submitRequest()`);

    setRequestProcessing(true);
    setShowSubmitRequestAcknowledgementModal(false);

    const sourceSystem = 'PACS';

    try {
      const siteSourceSystemId = await getLookupTypeValueId(LookupTypes.SiteSourceSystem, sourceSystem);
      if (!siteSourceSystemId) throw `unable to locate site source system id for ${sourceSystem}`;
      const requestorSourceSystemId = await getLookupTypeValueId(LookupTypes.RequestorSourceSystem, sourceSystem);
      if (!requestorSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
      const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, sourceSystem);
      if (!personSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
      const visitorRequestStatusScheduledVisit = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, VisitorRequestStatus.ScheduledVisit);
      if (!visitorRequestStatusScheduledVisit) throw `unable to locate visitor request status id for ${VisitorRequestStatus.ScheduledVisit}`;
      const assetApprovalStatusApproved = await getLookupTypeValueId(LookupTypes.AssetApprovalStatus, AssetApprovalStatus.Approved);
      if (!assetApprovalStatusApproved) throw `unable to locate asset approval status id for ${AssetApprovalStatus.Approved}`;
      const visitorTypeId = await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.Visitor);
      if (!visitorTypeId) throw `unable to locate visitor type id for ${VisitorTypes.Visitor}`;

      const requestId = uuid.v4();
      const escortsInput: APIt.CreateRequestEscortInput[] = [];
      const visitorsInput: ICreateVisitorInputWithAssets[] = [];
    
      escorts.map(e => {
        const escort: APIt.CreateRequestEscortInput =
          {
            created_by: props.username,
            escort_id: e.username,
            escort_source_system_id: personSourceSystemId,
            id: uuid.v4(),
            request_id: requestId,
          };
        escortsInput.push(escort);
      });
    
      visitors.map(v => {
        const visitorId = uuid.v4();
        const visitorInput: APIt.CreateVisitorInput =
          {
            company: v.company,
            created_by: props.username,
            email: v.emailAddress,
            first_name: v.firstName,
            id: visitorId,
            last_name: v.lastName,
            person_id: v.emailAddress || `${v.lastName}, ${v.firstName}`,
            phone_number: v.phoneNumber,
            request_id: requestId,
            status_id: visitorRequestStatusScheduledVisit,
            visitor_type_id: visitorTypeId,
          };
        const assets: APIt.VisitorAsset[] = [];
        if (v.assets && v.assets.length > 0) {
          for (let asset of v.assets) {
            const assetInput: APIt.VisitorAsset = {
              __typename: 'VisitorAsset',
              approval_status_id: assetApprovalStatusApproved,
              asset_type_id: asset.asset_type_id,
              created: '',
              created_by: props.username,
              description: asset.description,
              id: '',
              make: asset.make,
              model: asset.model,
              permanent_flag: false,
              person_id: v.emailAddress || `${v.lastName}, ${v.firstName}`,
              serial_num: asset.serial_num,
              site_id: siteCode!,
              site_source_system_id: siteSourceSystemId,
              updated: '',
              updated_by: '',
              visitor_id: visitorId,
            };
            assets.push(assetInput);
          }
        }
        visitorsInput.push(
          {
            ...visitorInput,
            assets: assets,
          });
        debug(`RequestVisitorAccess() visitorsInput is ${JSON.stringify(visitorsInput)}`)
      });
  
      const visitorsRequest: ISubmitVisitorsRequest = {
        escorts: escortsInput,
        request: {
          id: requestId,
          site_id: siteCode,
          site_source_system_id: siteSourceSystemId,
          reason: reasonForVisitInput!,
          requestor_id: props.username,
          requestor_source_system_id: requestorSourceSystemId,
          start_date: `${visitStartDate} ${visitStartTime}`,
          end_date: `${visitEndDate} ${visitEndTime}`,
          status: visitorRequestStatusScheduledVisit,
          created_by: props.username,
        },
        visitors: visitorsInput,
      };

      try {
        await submitVisitorRequest(visitorsRequest);
        setShowRequestSubmittedFlashbar(true);
        clearRequest();
        queryClient.fetchQuery({ queryKey: ['pendingVisitorRequests'] });
      } catch(error) {
        console.error(error);
        debug(`RequestVisitorAccess() submitRequest() error is ${JSON.stringify(error)}`);
        throw(error);
      }
    } catch(error) {
      console.error(error);
      setErrorMessage((error as any).message ? (error as any).message : JSON.stringify(error));
      setShowRequestFailedFlashbar(true);
    }
    setRequestProcessing(false);
  };

  const clearRequest = () => {
    setReasonForVisitInput('');
    props.setVisitorRequestCallback(null);
    setVisitDateRange(null);
    props.setVisitDateRangeCallback(null);
    setClearDetails(true);
  };

  const detailsCleared = () => {
    setClearDetails(false);
  };

  const FlashMessageRequestFailed = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: `${bundle.getMessage('request-failed')}: ${errorMessage}`,
            buttonText: bundle.getMessage('ok'),
            onButtonClick: () => {
              setErrorMessage('');
              setShowRequestFailedFlashbar(false);
            },
          },
        ]
      }
    />
  );

  const FlashMessageRequestSubmitted = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('request-submitted'),
            buttonText: bundle.getMessage('ok'),
            onButtonClick: () => setShowRequestSubmittedFlashbar(false)
          },
        ]
      }
    />
  );

  const FlashMessageTooManyVisitorsPerEscort = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: `${bundle.formatMessage('too-many-visitors-per-escorts', { maxVisitorsPerEscort: props.maxVisitorsPerEscort } )}`
          },
        ]
      }
    />
  );

  useEffect(() => {
    debug(`RequestVisitorAccess() useEffect[props.visitorRequest] props.visitorRequest is ${JSON.stringify(props.visitorRequest)}`);
    setEscorts(props.visitorRequest?.escorts || []);
    setReasonForVisitInput(props.visitorRequest?.reason || '');
    setSiteCode(props.visitorRequest?.siteCode || null);
    setVisitors(props.visitorRequest?.visitors || []);
    setVisitStartDate(props.visitorRequest?.startDate || null);
    setVisitStartTime(props.visitorRequest?.startTime || DefaultValidFromTime);
    setVisitEndDate(props.visitorRequest?.endDate || null);
    setVisitEndTime(props.visitorRequest?.endTime || DefaultValidThroughTime);
    setVisitDateRange(props.visitDateRange || null);
  }, [props.visitorRequest])

  useEffect(() => {
    if (escorts.length > 0 && visitors.length/escorts.length > props.maxVisitorsPerEscort) setTooManyVisitorsPerEscortFlashbar(true);
    if (escorts.length == 0 || visitors.length/escorts.length <= props.maxVisitorsPerEscort) setTooManyVisitorsPerEscortFlashbar(false);
  }, [escorts, visitors]);

  useEffect(() => {
    props.setVisitDateRangeCallback(visitDateRange);
  }, [visitDateRange]);

  if (isBundleLoading) return <Spinner/>

  return (
    <>
    <Container
      header={
        <Header variant='h2'>{bundle.getMessage('visitor-request')}</Header>
      }
      footer={
        <Box float='right'>
          <SpaceBetween
            key={'RequestAccessBottomButtonsSpaceBetween'}
            size='s'
            direction='horizontal'
          >
            <Button
              disabled={
                escorts.length == 0 &&
                visitors.length == 0 &&
                !reasonForVisitInput &&
                !siteCode &&
                !visitStartDate &&
                visitStartTime === DefaultValidFromTime &&
                !visitEndDate &&
                visitEndTime === DefaultValidThroughTime
              }
              onClick={clearRequest}
            >
              {bundle.getMessage('clear')}
            </Button>
            <Button
              data-testid='RequestVisitorAccessSubmitRequestButton'
              disabled={
                escorts.length == 0 ||
                visitors.length == 0 ||
                !reasonForVisitInput ||
                escortMissingActiveBadge == null ||
                escortMissingActiveBadge ||
                escortMissingSiteAccess == null ||
                escortMissingSiteAccess ||
                siteCode == null ||
                tooManyVisitorsPerEscortFlashbar ||
                visitStartDate == null ||
                visitEndDate == null ||
                invalidThroughDate
              }
              variant='primary'
              loading={requestProcessing}
              onClick={() => setShowSubmitRequestAcknowledgementModal(true)}
            >
              {bundle.getMessage('submit-request')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      variant='default'
    >
      {showRequestFailedFlashbar && <FlashMessageRequestFailed />}
      {showRequestSubmittedFlashbar && <FlashMessageRequestSubmitted />}
      <EscortsTablePanel
        autoFocus={escorts.length == 0}
        cardholdersQuickLinks={props.cardholdersQuickLinks}
        checkForMissingSiteAccess={true}
        darkMode={props.darkMode}
        escorts={escorts}
        username={props.username}
        setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
        setEscortsCallback={(escorts: IEscort[]) => {
          props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              escorts,
            }
            );
        }}
        setEscortMissingActiveBadgeCallback={setEscortMissingActiveBadge}
        setEscortMissingSiteAccessCallback={setEscortMissingSiteAccess}
        siteCode={siteCode}
        headerMessage={HeaderMessages.ESCORTS}
      />
      <Visitors
        visitors={visitors}
        escorts={escorts}
        clear={clearDetails}
        detailsClearedCallback={detailsCleared}
        setVisitorsCallback={async (visitors: TVisitor[]) => {
          await props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              visitors,
            }
          );
          if (reasonForVisitInput == '' && visitors.length > 0) reasonForVisitRef.current?.focus();
        } }
        setSiteCodeCallback={(siteCode: string) => {
          setSiteCode(siteCode);
          props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              siteCode,
            });
        } }
        setInvalidThroughDateCallback={setInvalidThroughDate}
        setVisitStartDateCallback={(visitStartDate: string) => {
          debug(`RequestVisitorAccess() setVisitStartDateCallback() visitStartDate is ${visitStartDate}`);
          setVisitStartDate(visitStartDate);
          props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              startDate: visitStartDate,
            });
        } }
        setVisitDateRangeCallback={setVisitDateRange}
        setVisitStartTimeCallback={(visitStartTime: string) => {
          setVisitStartTime(visitStartTime);
          props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              startTime: visitStartTime,
            });
        } }
        setVisitEndDateCallback={(visitEndDate: string) => {
          setVisitEndDate(visitEndDate);
          props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              endDate: visitEndDate,
            });
        } }
        setVisitEndTimeCallback={(visitEndTime: string) => {
          setVisitEndTime(visitEndTime);
          props.setVisitorRequestCallback(
            {
              ...props.visitorRequest,
              endTime: visitEndTime,
            });
        } }
        endDate={visitEndDate}
        endTime={visitEndTime}
        siteCode={siteCode}
        startDate={visitStartDate}
        startTime={visitStartTime}
        username={props.username}
        visitDateRange={visitDateRange}
      />
      {tooManyVisitorsPerEscortFlashbar && <FlashMessageTooManyVisitorsPerEscort />}
      <Container
        header={
          <Header
            variant='h2'
          >
           {bundle.getMessage('justification')}
          </Header>}
        variant='stacked'
      >
        <FormField label={bundle.getMessage('reason-for-visit')}>
          <Textarea
            data-testid='RequestVisitorAccessReasonTextarea'
            onChange={({ detail }) => {
              setReasonForVisitInput(detail.value);
              props.setVisitorRequestCallback(
                {
                  ...props.visitorRequest,
                  reason: detail.value,
                });
            }}
            placeholder={bundle.getMessage('reason-for-visit-placeholder')}
            ref={reasonForVisitRef}
            value={reasonForVisitInput}
          />
        </FormField>
      </Container>
    </Container>
    {showSubmitRequestAcknowledgementModal
    &&
    <Modal
      onDismiss={() => setShowSubmitRequestAcknowledgementModal(false)}
      visible={showSubmitRequestAcknowledgementModal}
      closeAriaLabel='Close'
      size='large'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              variant='link'
              onClick={() => setShowSubmitRequestAcknowledgementModal(false)}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              disabled={requestProcessing}
              loading={requestProcessing}
              onClick={() => submitRequest()}
              ref={submitButtonRef}
              variant='primary'
            >
              {bundle.getMessage('ok')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('submit-request-information-header')}
    >
      {`${bundle.getMessage('submit-request-information1')} `}<Link target='_blank' href={URLS.GSOVisitorStandardGlobal}>GSO - Visitors Standard - Global</Link>.
    </Modal>
    }
    </>
  );
}