import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Modal,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { Actions, VisitorAssetWithAction } from './TablePanel';
import SubmissionApproval from './SubmissionApproval';
import SubmissionDenial from './SubmissionDenial';
import * as APIt from '../../../API';
import { debug } from 'src/utils/commonUtils';

export interface ISubmitActionsProps{
  visitorAssets: VisitorAssetWithAction[];
  cancelSubmitActionsCallback: Function;
  submitActionsCallback: Function;
  submissionProcessing: boolean;
}

export default function SubmitActions(props: ISubmitActionsProps) {
  debug(`SubmitActions() props is ${JSON.stringify(props)}`);

  const [visitorAssets, setVisitorAssets] = useState<APIt.VisitorAsset[]>(props.visitorAssets);
  const [visitorAssetsWithAction, setVisitorAssetsWithAction] = useState<VisitorAssetWithAction[]>([]);
  const [errorText, setErrorText] = useState<string>();
  const [formComplete, setFormComplete] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.SubmitActions');

  const setNotesForAccessRequest = (id: string, notes: string): void => {
    debug(`SubmitActions() setNotesForAccessRequest() id is ${id} notes is ${notes}`);
    const visitorAssetWithAction = visitorAssetsWithAction.find(acl => acl.id == id);
    if (visitorAssetWithAction) {
      visitorAssetWithAction.notes = notes;
      const newVisitorAssetsWithAction = 
        [
          ...(visitorAssetsWithAction.filter(ala => ala.id != visitorAssetWithAction.id)),
          visitorAssetWithAction
        ];
      debug(`SubmitActions() setNotesForAccessRequest() newVisitorAssetsWithAction is ${JSON.stringify(newVisitorAssetsWithAction)}`);
      setVisitorAssets(newVisitorAssetsWithAction);
    }
  };

  const setDenialReasonForAccessRequest = (id: string, denialReason: string): void => {
    debug(`SubmitActions() setDenialReasonForAccessRequest() id is ${id} denialReason is ${denialReason}`);
    debug(`SubmitActions() setDenialReasonForAccessRequest() visitorAssetsWithAction is ${JSON.stringify(visitorAssetsWithAction)}`);
    const accessLevelRequestWithAction = visitorAssetsWithAction.find(acl => acl.id == id);
    if (accessLevelRequestWithAction) {
      accessLevelRequestWithAction.denial_reason = denialReason;
      debug(`SubmitActions() setDenialReasonForAccessRequest() accessLevelRequestWithAction is ${JSON.stringify(accessLevelRequestWithAction)}`);
      const otherVisitorAssetsWithAction = [...(visitorAssetsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id))];
      debug(`SubmitActions() setDenialReasonForAccessRequest() otherVisitorAssetsWithAction is ${JSON.stringify(otherVisitorAssetsWithAction)}`);
      const newVisitorAssetsWithAction = 
        [
          ...(visitorAssetsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id)),
          accessLevelRequestWithAction
        ];
      debug(`SubmitActions() setDenialReasonForAccessRequest() newVisitorAssetsWithAction is ${JSON.stringify(newVisitorAssetsWithAction)}`);
      setVisitorAssetsWithAction(newVisitorAssetsWithAction);
      checkFormComplete();
    }
  };

  const setDenialNotesForAccessRequest = (id: string, notes: string): void => {
    debug(`SubmitActions() setDenialNotesForAccessRequest() id is ${id} notes is ${notes}`);
    const accessLevelRequestWithAction = visitorAssetsWithAction.find(acl => acl.id == id);
    if (accessLevelRequestWithAction) {
      accessLevelRequestWithAction.notes = notes;
      setVisitorAssets(
        [
          ...(visitorAssetsWithAction.filter(ala => ala.id != accessLevelRequestWithAction.id)),
          accessLevelRequestWithAction
        ]);
    }
  };

  const checkFormComplete = (): void => {
    debug(`SubmitActions() checkFormComplete()`);
    debug(`SubmitActions() checkFormComplete() visitorAssetsWithAction.length is ${visitorAssetsWithAction.length}`);
    if (visitorAssetsWithAction.length == 0) {
      setFormComplete(false);
      return;
    }
    let result = true;
    for (let i = 0; i < visitorAssetsWithAction.length; i++) {
      debug(`SubmitActions() checkFormComplete() visitorAssetsWithAction[${i}] is ${JSON.stringify(visitorAssetsWithAction[i])}`);
      if (visitorAssetsWithAction[i].action?.value == Actions.deny && !visitorAssetsWithAction[i].denial_reason) {
        result = false;
        break;
      }
    }
    setFormComplete(result);
  };

  const assignActions = (): void => {
    if (visitorAssetsWithAction.length == 0) {
      setVisitorAssetsWithAction(props.visitorAssets);
      return;
    }
    for (let i = 0; i < visitorAssetsWithAction.length ;i++) {

    }
  };

  useEffect(() => {
    checkFormComplete();
  }, []);

  useEffect(() => {
    assignActions();
  }, [props.visitorAssets]);

  useEffect(() => {
    checkFormComplete();
  }, [visitorAssetsWithAction]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      header={
        <Header>
          {bundle.getMessage('confirm-actions')}
        </Header>
      }
      onDismiss={() => props.cancelSubmitActionsCallback()}
      size='large'
      visible={true}
    >
      <form onSubmit={e => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                formAction="none"
                variant="normal"
                onClick={() =>
                  {
                    setVisitorAssetsWithAction([]);
                    props.cancelSubmitActionsCallback();
                  }
                }
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                disabled={!formComplete}
                variant="primary"
                loading={props.submissionProcessing}
                onClick={async () => 
                  {
                    props.submitActionsCallback(visitorAssetsWithAction);
                  }
                }
              >
                {bundle.getMessage('confirm-actions')}
              </Button>
            </SpaceBetween>
          }
          errorText={errorText}
        >
          {
            props.visitorAssets.some(alr => alr.action?.value == Actions.approve)
            &&
            <Container
              header={<>{bundle.getMessage('approve')}</>}
            >
              {visitorAssetsWithAction.map(va => {
                if (va.action?.value == Actions.approve) {
                  return(
                    <div key={va.id}>
                      <SubmissionApproval
                        visitorAssetApproval={va}
                        setNotesCallback={setNotesForAccessRequest}
                      />
                    </div>
                  );
                };
              })}
            </Container>
          }
          {
            props.visitorAssets.some(alr => alr.action?.value == Actions.deny)
            &&
            <Container
              header={<>{bundle.getMessage('deny')}</>} 
            >
              {visitorAssetsWithAction.map(va => {
                if (va.action?.value == Actions.deny) {
                  return(
                    <div key={va.id}>
                      <SubmissionDenial
                        visitorAssetDenial={va}
                        setDenialReasonCallback={setDenialReasonForAccessRequest}
                        setDenialNotesCallback={setDenialNotesForAccessRequest}
                      />
                    </div>
                  );
                };
              })}
            </Container>
          }
        </Form>
      </form>
    </Modal>);
}