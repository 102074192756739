import React, { useState } from 'react';
import {
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { VisitorAssetWithAction } from './TablePanel';
import { useQuery } from '@tanstack/react-query';
import { queryDenialReasons } from './utils';
import { debug } from 'src/utils/commonUtils';

export interface ISubmissionDenial{
  visitorAssetDenial: VisitorAssetWithAction;
  setDenialReasonCallback: Function;
  setDenialNotesCallback: Function;
}

export default function SubmissionDenial(props: ISubmissionDenial) {
  debug(`SubmissionDenial() props is ${JSON.stringify(props)}`);

  const [denialReasonOptions, setDenialReasonOptions] = useState<SelectProps.Options>();

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.SubmissionDenial');

  const denialReasonsQuery = useQuery({
    queryKey: ['denialReasons'],
    queryFn: () => queryDenialReasons(),
    onSuccess: (data) => data ? setDenialReasonOptions(data.map(d => { return { label: d.value, value: d.value } })) : [],
    retry: 3
  });

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 6 }]} >
        {props.visitorAssetDenial.person_id}
        {`${props.visitorAssetDenial.asset_type} (${props.visitorAssetDenial.description})`}
        {props.visitorAssetDenial.permanent_flag ? bundle.getMessage('permanent') : `${props.visitorAssetDenial.start_date} ${bundle.getMessage('through')} ${props.visitorAssetDenial.end_date}`}
      </Grid>
      <FormField label={bundle.getMessage('denial-reason')}>
        <Select
          onChange={({ detail }) => props.setDenialReasonCallback(props.visitorAssetDenial.id, detail.selectedOption.value)}
          options={denialReasonOptions}
          selectedOption={{ label: props.visitorAssetDenial.denial_reason!, value: props.visitorAssetDenial.denial_reason!}}
        />
      </FormField>
      <FormField label={bundle.getMessage('denial-notes')}>
        <Input
          onChange={({detail}) => props.setDenialNotesCallback(props.visitorAssetDenial.id, detail.value)}
          value={props.visitorAssetDenial.notes || ''}
        />
      </FormField>
    </>
  );
}