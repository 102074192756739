import * as APIt from "src/API";
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import React from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBundle } from "@amzn/react-arb-tools";

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.ApproverGroup>[] = [
  {
    id: 'approverGroup',
    header: <TranslateHeader value={'approver-group'} />,
    cell: item => item.approver_group,
    sortingField: 'approver_group'
  },
  {
    id: 'accessLevelName',
    header: <TranslateHeader value={'access-level'} />,
    cell: item => item.access_level,
    sortingField: 'access_level'
  },
  {
    id: 'created',
    header: <TranslateHeader value={'created'} />,
    cell: item => item.created,
    sortingField: 'created'
  },
  {
    id: 'updated',
    header: <TranslateHeader value={'updated'} />,
    cell: item => item.updated,
    sortingField: 'updated'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Management.Administration.ApproverGroups.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
