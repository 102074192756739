import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  FormField,
  Input,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQueryClient, useIsFetching, useQuery } from '@tanstack/react-query';
import { queryAccessRequestDetails } from './utils';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import * as uuid from 'uuid';
import { debug } from 'src/utils/commonUtils';

export default function AccessRequestDetailsTablePanel() {
  debug(`RequestDetailsTablePanel()`);

  const pageSize = 15;

  const queryClient = useQueryClient();

  const isFetchingRequestDetails = useIsFetching(['accessRequestDetails']);

  const [allItems, setAllItems] = useState<APIt.AccessRequestDetails[]>(queryClient.getQueryData(['accessRequestDetails']) || []);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [accessRequestId, setAccessRequestId] = useState<string>();

  const accessRequestDetailsQuery = useQuery({
    enabled: false,
    onSuccess: (data) => {
      setAllItems(data);
    },
    queryFn: () => queryAccessRequestDetails(accessRequestId!),
    queryKey: ['accessRequestDetails'],
  });

  const accessRequestsDetailQueryState = queryClient.getQueryState(['accessRequestDetails']);

  const [bundle, isBundleLoading] = useBundle('components.Management.Support.AccessRequestDetails.TablePanel');

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-access-request-details-found') : 'No Access Request Details Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const search = async () => {
    accessRequestDetailsQuery.refetch();
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <form onSubmit={e => e.preventDefault()}>
            <SpaceBetween direction='horizontal' size='xs'>
              <FormField label='&#8203;'>
                <TextFilter
                  {...filterProps}
                  filteringAriaLabel={bundle.getMessage('filter-access-request-details')}
                  filteringPlaceholder={bundle.getMessage('find-access-request-details')}
                  countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
                />
              </FormField>
              <FormField label={bundle.getMessage('access-request-id')}>
                <Input
                  autoFocus
                  invalid={accessRequestId !== undefined && !uuid.validate(accessRequestId)}
                  onChange={({ detail }) => setAccessRequestId(detail.value) }
                  placeholder={bundle.getMessage('access-request-id')}
                  value={accessRequestId || ''}
                />
              </FormField>
              <FormField label='&#8203;'>
                <Button
                  disabled={accessRequestId == undefined || (accessRequestId !== undefined && !uuid.validate(accessRequestId))}
                  iconAlign='right'
                  iconName='search'
                  loading={accessRequestDetailsQuery?.fetchStatus == 'fetching'}
                  onClick={search}
                  variant='primary'
                >
                  {bundle.getMessage('search')}
                </Button>
              </FormField>
            </SpaceBetween>
          </form>
        }
        footer={
          <Header
            actions={
              <SpaceBetween size='s' direction='horizontal'>
              </SpaceBetween>
            }
          >
          </Header>
        }
        header={
          <>
            <Header
              counter={`(${itemsCount()})`}
              actions={
                <SpaceBetween direction='horizontal' size={'s'}>
                  <CsvDownloadButton
                    data={allPageItems}
                    delimiter={','}
                    disabled={isFetchingRequestDetails > 0}
                    filename={`WelcomeAccessRequestDetails-${accessRequestId}-${new Date().toISOString()}`}
                  />
                </SpaceBetween>
              }
            >
              {bundle.getMessage('access-request-details')}
            </Header>
          </>
        }
        items={items}
        loading={isFetchingRequestDetails > 0}
        empty={accessRequestDetailsQuery?.status == 'error'
          ?
            <Alert type='error'>
              {`${bundle.getMessage('query-failure')} (${typeof accessRequestsDetailQueryState?.error === 'object'
                 ? JSON.stringify(accessRequestsDetailQueryState.error)
                 : accessRequestsDetailQueryState?.error})`}
            </Alert>
          : bundle.getMessage('no-access-request-details-found')
        }
        loadingText={bundle.getMessage('loading-access-request-details')}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
        trackBy='track_by'
      />
    </div>
  );
}
