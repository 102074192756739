import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from '../../API';
import {
  getUserPrefs,
  getVisitorByPersonId,
  listLookupTypeValuesForTypeName,
  listVisitorAssetsByPSID,
  SNSPublishAccessLevelRequestApproved,
  SNSPublishAccessLevelRequestCreated,
  SNSPublishViewershipPrivilegeGranted,
  SNSPublishViewershipPrivilegeRemoved,
} from 'src/graphql/queries';
import {
  cardholder as cardholderMutation,
  createUserPrefs as createUserPrefsMutation,
  createRequest as createRequestMutation,
  createVisitor as createVisitorMutation,
  deleteRequest as deleteRequestMutation,
  updateVisitor as updateVisitorMutation,
} from "src/graphql/mutations";
import { ApprovalStatus, LookupTypes, UnicornPACSAPIv2Priority, VisitorTypes } from 'src/constants/Constants';
import {
  auditDecorator,
  createEscort,
  createVisitorAccessLevel,
  createVisitorAccessLevelApproval,
  getAccessLevelApproverGroups,
  getApprovers,
  getLookupTypeValueId,
  getLookupTypeValues,
  queryLookupTypeValue,
  queryLookupTypeValueForTypeAndValue,
  sqlEscapeString
} from 'src/components/utils';
import * as uuid from 'uuid';
import { submitVisitorAccessLevelActions } from '../Management/ManageAccessRequests/utils';
import { IVisitorAccessLevelWithAction } from '../Management/ManageAccessRequests/TablePanel';
import { ICreateVisitorInputWithAssets } from 'src/interfaces';
import { createVisitorAsset } from './Assets/utils';
import { AccessLevelApproverGroup } from '../../API';
import { debug } from '../../utils/commonUtils';

export let queryVisitor = async (personId: string, personSourceSystem: string): Promise<APIt.Visitor | null> => {
  debug(`queryVisitor() personId is ${personId} personSourceSystem is ${personSourceSystem}`);

  let visitor: APIt.Visitor | null = null;

  try {
    const personSourceSystemId = await getLookupTypeValueId('Person Source System', personSourceSystem);
    debug(`queryVisitor() personSourceSystemId is ${personSourceSystemId}`);

    const response = await API.graphql(graphqlOperation(getVisitorByPersonId,
    {
      person_id: personId,
      person_source_system_id: personSourceSystemId,
    }
    )) as GraphQLResult<APIt.GetVisitorByPersonIdQuery>;
    debug(`queryVisitor() response is ${JSON.stringify(response)}`);
    if (response && response.data && response.data.getVisitorByPersonId) {
      visitor = response.data.getVisitorByPersonId;
    }
  } catch(error) {
    debug(`queryVisitor() exception is ${error} JSON: ${JSON.stringify(error)}`);
    console.error(error);
    throw error;    
  }

  debug(`queryVisitor() visitor is ${JSON.stringify(visitor)}`);
  return visitor;
};
queryVisitor = auditDecorator('queryVisitor', queryVisitor);

export let createViewerOptions = (delegationType: string, data: APIt.Delegation[], username: string, employeeId: string) => {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  let delegators = data ?? [];
  let delegatorOptions = delegators.filter(at => at.delegation_type === delegationType && (at.permanent_flag || (at.start_date && at.end_date && new Date(at.start_date) <= today && new Date(at.end_date) >= today)));
  let options = delegatorOptions.map(at => { return { label: at.delegator_username!, value: at.delegator_id }; });
  options.push({ label: username, value: employeeId.toString() });
  return options;
}

export let createVisitor = async (createVisitorInput: APIt.CreateVisitorInput): Promise<APIt.Visitor | null> => {
  debug(`createVisitor() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);

  let visitor: APIt.Visitor | null = null;

  try {
    if (createVisitorInput.first_name) createVisitorInput.first_name = sqlEscapeString(createVisitorInput.first_name);
    if (createVisitorInput.last_name) createVisitorInput.last_name = sqlEscapeString(createVisitorInput.last_name);
    if (createVisitorInput.company) createVisitorInput.company = sqlEscapeString(createVisitorInput.company);
    if (createVisitorInput.email) createVisitorInput.email = sqlEscapeString(createVisitorInput.email);
    if (createVisitorInput.phone_number) createVisitorInput.phone_number = sqlEscapeString(createVisitorInput.phone_number);
    if (createVisitorInput.person_id) createVisitorInput.person_id = sqlEscapeString(createVisitorInput.person_id);
    if (createVisitorInput.title) createVisitorInput.title = sqlEscapeString(createVisitorInput.title);
    debug(`createVisitor() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
    const response = await API.graphql(graphqlOperation(createVisitorMutation,
      {
        input: createVisitorInput
      })) as GraphQLResult<APIt.CreateVisitorMutation>;
    if (response && response.data && response.data.createVisitor) {
      visitor = response.data.createVisitor;
    }
    if (!visitor) throw new Error('Unable to create visitor.');
  } catch(error) {
    debug(`createVisitor() exception is ${JSON.stringify(error)}`);
    console.error(error);
    throw error;
  }

  const unescortedVendorVisitorType = await queryLookupTypeValueForTypeAndValue(LookupTypes.VisitorType, VisitorTypes.UnescortedVendor);
  debug(`createVisitor() unescortedVendorVisitorType is ${JSON.stringify(unescortedVendorVisitorType)}`);

  // only continue to create a cardholder in the device management systems for unescorted vendors
  if (unescortedVendorVisitorType.id !== visitor.visitor_type_id) return visitor;

  let cardholderRequestResponse: APIt.CardholderRequestResponse | null = null;

  try {
    const cardholderInput = {
      methodName: 'AddCardholder',
      params: {
        Alias: visitor.person_id,
        Barcode: undefined,
        EmployeeStatus: 'A',
        EmployeeType: 10310,
        FirstName: visitor.first_name,
        LastName: visitor.last_name,
        Priority: UnicornPACSAPIv2Priority.HIGH,
        WelcomeID: visitor.id,
      }
    };
    debug(`createVisitor() cardholderInput is ${JSON.stringify(cardholderInput)}`);
    const cardholderMutationResponse = await API.graphql(graphqlOperation(cardholderMutation,
      {
        input: cardholderInput
      })) as GraphQLResult<APIt.CardholderMutation>;
    debug(`createVisitor() cardholderMutationResponse is ${JSON.stringify(cardholderMutationResponse)}`);
    if (cardholderMutationResponse.data && cardholderMutationResponse.data.cardholder) {
      cardholderRequestResponse = cardholderMutationResponse.data.cardholder as APIt.CardholderRequestResponse;
      if (cardholderRequestResponse.statusCode !== 202) throw new Error('Failure to create cardholder.');
      const updateVisitorInput = {
        ...createVisitorInput,
        created_by: undefined,
        requestUUID: cardholderRequestResponse.requestUUID,
        updated_by: createVisitorInput.created_by,
      };
      debug(`createVisitor() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
      const response = await API.graphql(graphqlOperation(updateVisitorMutation,
        {
          input: updateVisitorInput
        })) as GraphQLResult<APIt.UpdateVisitorMutation>;
      if (response && response.data && response.data.updateVisitor) {
        visitor = response.data.updateVisitor;
      }
      if (!visitor) throw new Error('Unable to update visitor.');
    }
  } catch(error) {
    debug(`createVisitor() exception is ${JSON.stringify(error)}`);
    if (visitor.request_id) await deleteRequest({id: visitor.request_id, updated_by: visitor.created_by});
    console.error(error);
    throw error;
  }

  debug(`createVisitor() visitor is ${JSON.stringify(visitor)}`);
  return visitor;
};
createVisitor = auditDecorator('createVisitor', createVisitor);

export let queryDelegationTypes = async (): Promise<APIt.LookupTypeValue[]> => {
  debug('queryDelegationTypes()');  
  let delegationTypes: APIt.LookupTypeValue[] = [];
 
  try {
    const response = await API.graphql(graphqlOperation(listLookupTypeValuesForTypeName,
      {
        input: {
          limit: 1000,
          lookup_type_name: LookupTypes.DelegationType,
          offset: 0,
        }
      })) as GraphQLResult<APIt.ListLookupTypeValuesForTypeNameQuery>;
    if (response.data && response.data.listLookupTypeValuesForTypeName) {
      delegationTypes = response.data.listLookupTypeValuesForTypeName as APIt.LookupTypeValue[];
    }
  } catch (e) {
    console.error(`queryDelegationTypes() exception is ${JSON.stringify(e)}`);
    throw e;
  }
 
  return(delegationTypes);
};

export let queryVisitorAssetsByPSID = async (
  psid: string | undefined = undefined): Promise<APIt.VisitorAsset[] | null> =>
{
  debug(`queryVisitorAssetsByPSID() psid is ${psid}`);

  let employeeAssets: APIt.VisitorAsset[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAssetsByPSID,
      {
        psid: psid
      })) as GraphQLResult<APIt.ListVisitorAssetsByPSIDQuery>;
    if (response.data && response.data.listVisitorAssetsByPSID) {
      employeeAssets = response.data.listVisitorAssetsByPSID as APIt.VisitorAsset[];
    }
  } catch (e) {
    console.error(`queryVisitorAssetsByPSID() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryVisitorAssetsByPSID() visitorAssets is ${JSON.stringify(employeeAssets)}`);

  return(employeeAssets);
};
queryVisitorAssetsByPSID = auditDecorator('queryVisitorAssetsByPSID', queryVisitorAssetsByPSID);

export let queryUserPreferences = async (username: string): Promise<APIt.UserPrefs | null> => {
  debug(`queryUserPreferences() username is ${username}`);

  let userPrefs: APIt.UserPrefs | null = null;

  try {
    const response = await API.graphql(graphqlOperation(getUserPrefs,
      {
        username: username
      })) as GraphQLResult<APIt.GetUserPrefsQuery>;
    if (response.data && response.data.getUserPrefs) {
      userPrefs = response.data.getUserPrefs as APIt.UserPrefs;
    }
  } catch(error) {
    console.error(`queryUserPreferences() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(userPrefs);
};
queryUserPreferences = auditDecorator('queryUserPreferences', queryUserPreferences);

export let createUserPreferences = async (userPrefs: APIt.UserPrefs): Promise<APIt.UserPrefs | null> => {
  debug(`createUserPreferences() userPrefs is ${JSON.stringify(userPrefs)}`);

  let newUserPrefs: APIt.UserPrefs | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createUserPrefsMutation,
      {
        input: {...userPrefs, __typename: undefined}
      })) as GraphQLResult<APIt.CreateUserPrefsMutation>;
    if (response.data && response.data.createUserPrefs) {
      newUserPrefs = response.data.createUserPrefs as APIt.UserPrefs;
    }
  } catch(error) {
    console.error(`createUserPreferences() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(newUserPrefs);
};
createUserPreferences = auditDecorator('createUserPreferences', createUserPreferences);

export let updateUserPreferences = async (userPrefs: APIt.UserPrefs): Promise<APIt.UserPrefs | null> => {
  debug(`updateUserPreferences() userPrefs is ${JSON.stringify(userPrefs)}`);

  let updatedUserPref: APIt.UserPrefs | null = await createUserPreferences(userPrefs);

  return(updatedUserPref);
};
updateUserPreferences = auditDecorator('updateUserPreferences', updateUserPreferences);

export interface ISubmitAccessLevelRequest {
  accessLevels: APIt.CreateVisitorAccessLevelInput[];
  escorts: APIt.CreateRequestEscortInput[];
  request: APIt.CreateRequestInput;
  visitors: ICreateVisitorInputWithAssets[];
}

export let submitAccessRequest = async (accessLevelRequest: ISubmitAccessLevelRequest, disableAutoApproval: boolean = false) => {
  debug(`submitAccessRequest() accessLevelRequest is ${JSON.stringify(accessLevelRequest)} disableAutoApproval is ${disableAutoApproval}`);

  let request: APIt.Request | null = null, autoApprove = false;

  try {
    request = await createRequest(accessLevelRequest.request);
    debug(`submitAccessRequest() request is ${JSON.stringify(request)}`);
    for (let visitor of accessLevelRequest.visitors) {
      const createVisitorInput = {...visitor};
      delete createVisitorInput.assets;
      debug(`submitVisitorRequest() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
      const createdVisitor = await createVisitor(createVisitorInput);
      debug(`submitAccessRequest() createdVisitor is ${JSON.stringify(createdVisitor)}`);
      if (visitor.assets) {
        for (let asset of visitor.assets) {
          const createdVisitorAsset = await createVisitorAsset(asset);
          debug(`submitAccessRequest() createdVisitorAsset is ${JSON.stringify(createdVisitorAsset)}`);
        };
      }
    }
    const approverSourceSystemId = await getLookupTypeValueId('Approver Source System', 'PACS');
    debug(`submitAccessRequest() approverSourceSystemId is ${approverSourceSystemId}`);
    if (!approverSourceSystemId) throw new Error(`Unable to locate approver source system id value for PACS`);
    const pendingApprovalAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.PendingApproval);
    debug(`submitAccessRequest() pendingApprovalAccessLevelStatusCodeId is ${pendingApprovalAccessLevelStatusCodeId}`);
    const approvedAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status',ApprovalStatus.Approved);
    debug(`submitAccessRequest() approvedAccessLevelStatusCodeId is ${approvedAccessLevelStatusCodeId}`);
    if (!pendingApprovalAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);
    if (!approvedAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.Approved}`);
    const denyReasons = await getLookupTypeValues(LookupTypes.AccessLevelDenial);
    debug(`submitAccessRequest() denyReasons is ${JSON.stringify(denyReasons)}`);
    for (let escort of accessLevelRequest.escorts) {
      debug(`submitAccessRequest() escort is ${JSON.stringify(escort)}`);
      const createdEscort = await createEscort(escort);
      if (!createdEscort) throw new Error('Unable to create escort');
    }
    let
      createVisitorAccessLevelApprovalCalls = [],
      createVisitorAccessLevelApprovedCalls = [],
      createVisitorAccessLevelRequestApprovedCalls = [];
    const createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[] = [];
    for (let accessLevel of accessLevelRequest.accessLevels) {
      autoApprove = false;
      debug(`submitAccessRequest() accessLevel is ${JSON.stringify(accessLevel)}`);
      const visitorAccessLevel = await createVisitorAccessLevel(accessLevel);
      if (!visitorAccessLevel) {
        throw new Error('Unable to create request access level');
      }
      const approverGroups = await getAccessLevelApproverGroups(accessLevel.access_level_name);
      debug(`submitAccessRequest() approverGroups is ${JSON.stringify(approverGroups)}`);
      for (let approverGroup of approverGroups) {
        debug(`submitAccessRequest() approverGroup is ${approverGroup}`);
        let approvers = await getApprovers(approverGroup);
        debug(`submitAccessRequest() approvers is ${JSON.stringify(approvers)}`);
        if (approvers.includes(accessLevelRequest.request.requestor_id) && !disableAutoApproval) {
          autoApprove = true;
          approvers = [accessLevelRequest.request.requestor_id];
        }
        for (let approver of approvers) {
          debug(`submitAccessRequest() approver is ${approver}`);
          if (createdVisitorAccessLevelApprovals.find((a) =>
            (a.approver_id == approver
            && a.approver_source_system_id == approverSourceSystemId
            && a.visitor_access_level_id == visitorAccessLevel.id)) == undefined)
          {
            const id = uuid.v4();
            const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
              approver_id: approver,
              approver_source_system_id: approverSourceSystemId,
              created_by: accessLevelRequest.request.created_by,
              id: id, 
              visitor_access_level_id: visitorAccessLevel.id,
              status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
            };
            createdVisitorAccessLevelApprovals.push(createVisitorAccessLevelApprovalInput);
            createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
          }
        }
        debug(`submitAccessRequest() autoApprove is ${autoApprove}`);
      }
      if (autoApprove) {
        const visitor = accessLevelRequest.visitors.find(v => v.id === accessLevel.visitor_id);
        debug(`submitAccessRequest() visitor is ${JSON.stringify(visitor)}`);
        let visitorType = '';
        if (visitor) visitorType = (await queryLookupTypeValue(visitor.visitor_type_id))?.value || '';
        debug(`submitAccessRequest() visitorType is ${JSON.stringify(visitorType)}`);
        const action: IVisitorAccessLevelWithAction = {
          __typename: 'VisitorAccessLevel',
          ...accessLevelRequest.request,
          ...accessLevel,
          action: { label: 'Approve', value: 'approve' },
          created: '',
          dates_updated: false,
          person_id: visitor?.person_id || '',
          request_id: accessLevelRequest.request.id,
          requestor_id: accessLevelRequest.request.requestor_id,
          requestor_source_system_id: accessLevelRequest.request.requestor_source_system_id,
          updated: '',
          updated_by: accessLevelRequest.request.requestor_id,
          visitor_type: visitorType,
        };

        try {
          createVisitorAccessLevelApprovedCalls.push(submitVisitorAccessLevelActions(
            {
              actions: [action],
              approverId: accessLevelRequest.request.requestor_id,
            }));
          createVisitorAccessLevelRequestApprovedCalls.push(() => {
            return new Promise(async (resolve, reject) => {
              try {
                const response = await API.graphql(graphqlOperation(SNSPublishAccessLevelRequestApproved,
                  {
                    requestId: request!.id
                  })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestApprovedQuery>;
                debug(`submitAccessRequest() SNSPublishAccessLevelRequestApproved response is ${JSON.stringify(response)}`);
                resolve(response);
              } catch (error) {
                reject(error);
              }
          })});
        } catch(error) {
          debug(`submitAccessRequest() SNSPublishAccessLevelRequestApproved error is ${JSON.stringify(error)}`);
          throw error;
        }
      }
    }
    await Promise.all(createVisitorAccessLevelApprovalCalls);
    await Promise.all(createVisitorAccessLevelApprovedCalls);
    await Promise.all(createVisitorAccessLevelRequestApprovedCalls);
  } catch(error) {
    if (request) await deleteRequest({id: request.id, updated_by: request.created_by});
    debug(`submitAccessRequest() error is ${error} JSON: ${JSON.stringify(error)}`);
    throw(error);
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishAccessLevelRequestCreated,
      {
        requestId: request!.id
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestCreatedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestCreated) {
      debug(`submitAccessRequest() SNSPublishAccessLevelRequestCreated response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`submitAccessRequest() SNSPublishAccessLevelRequestCreated error is ${JSON.stringify(error)}`);
    throw error;
  }
};

export const createRequest = async (requestInput: APIt.CreateRequestInput): Promise<APIt.Request | null> => {
  debug(`createRequest() requestInput is ${JSON.stringify(requestInput)}`);

  let createdVisit: APIt.Request | null = null;

  try {
    if (requestInput.reason) requestInput.reason = sqlEscapeString(requestInput.reason);
    debug(`createRequest() requestInput.reason is ${requestInput.reason}`);
    const response = await API.graphql(graphqlOperation(createRequestMutation,
      {
        input: requestInput
      })) as GraphQLResult<APIt.CreateRequestMutation>;
    if (response && response.data && response.data.createRequest) {
      createdVisit = response.data.createRequest;
    }
  } catch (error) {
    console.error(`createRequest(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdVisit;

};

export const deleteRequest = async (deleteRequestInput: APIt.DeleteRequestInput): Promise<APIt.Request | null> => {
  debug(`deleteRequest() deleteRequestInput is ${JSON.stringify(deleteRequestInput)}`);

  let deletedRequest: APIt.Request | null = null;

  try {
    const response = await API.graphql(graphqlOperation(deleteRequestMutation,
      {
        input: deleteRequestInput
      })) as GraphQLResult<APIt.DeleteRequestMutation>;
    if (response && response.data && response.data.deleteRequest) {
      deletedRequest = response.data.deleteRequest;
    }
  } catch(error) {
    console.error(`createRequest() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return deletedRequest;
};

export const accessLevelApproverGroupMembersExist = async (approverGroups: AccessLevelApproverGroup[]): Promise<boolean> => {
  let membersExist = false;
  try {
    for (let approverGroup of approverGroups) {
      let approvers = await getApprovers(approverGroup.approver_group);
      if (approvers.length > 0) {
        membersExist = true;
        break;
      }
    }
  } catch(error) {
    console.error('accessLevelApproverGroupMembers() error is ', error);
  }
  return membersExist;
};

export let viewershipAccessGrantedSendNotification = async (
delegationSNSInput: APIt.DelegationPrivilegeInput): Promise<void> =>
{
  debug(`viewershipAccessGrantedSendNotification() delegationSNSInput is ${JSON.stringify(delegationSNSInput)}`);
  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishViewershipPrivilegeGranted,
      {delegationInput: delegationSNSInput})) as GraphQLResult<APIt.SNSPublishViewershipPrivilegeGrantedQuery>;
    if (response && response.data && response.data.SNSPublishViewershipPrivilegeGranted) {
      debug(`viewershipAccessGrantedSendNotification() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`viewershipAccessGrantedSendNotification() error is ${JSON.stringify(error)}`);
    throw error;
  }
}
viewershipAccessGrantedSendNotification = auditDecorator('viewershipAccessGrantedSendNotification', viewershipAccessGrantedSendNotification);

export let viewershipAccessRemovedSendNotication = async (
  delegationSNSInput: APIt.DelegationPrivilegeInput): Promise<void> =>
{
  debug(`viewershipAccessRemovedSendNotication() delegationSNSInput is ${JSON.stringify(delegationSNSInput)}`);
  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(SNSPublishViewershipPrivilegeRemoved,
     {delegationInput: delegationSNSInput})) as GraphQLResult<APIt.SNSPublishViewershipPrivilegeRemovedQuery>;
    if (response && response.data && response.data.SNSPublishViewershipPrivilegeRemoved) {
      debug(`viewershipAccessRemovedSendNotication() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`viewershipAccessRemovedSendNotication() error is ${JSON.stringify(error)}`);
    throw error;
  }
}
viewershipAccessRemovedSendNotication = auditDecorator('viewershipAccessRemovedSendNotication', viewershipAccessRemovedSendNotication);