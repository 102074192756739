import React from 'react';
import { debug } from 'src/utils/commonUtils';
import LookupTypesTablePanel from './TablePanel';

interface ILookupsProps {
  username: string;
}

export default function Lookups(props: ILookupsProps) {
  debug(`Lookups() props is ${JSON.stringify(props)}`);

  return(
    <LookupTypesTablePanel username={props.username} />
  );
}


