import React, { useEffect, useState } from 'react';
import AuthorizeUser from './AuthorizeUser';
import { LookupTypes, URLS, WelcomeApplicationSettings } from '../constants/Constants';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { Box, Link, Spinner } from '@amzn/awsui-components-react';
import jwtDecode from 'jwt-decode';
import {
  auditDecorator,
  createUserAction,
  queryLookupTypeValueForTypeAndDescription,
  ttl,
} from './utils';
import * as uuid from 'uuid';
import { LookupTypeValue } from 'src/API';
import { debug } from '../utils/commonUtils';

export var authenticatedUsername: string = '';

const AMPLIFY_SYMBOL = ((typeof Symbol !== 'undefined' && typeof Symbol.for === 'function') ?
  Symbol.for('amplify_default') : '@@amplify_default') as Symbol;

const dispatchAuthEvent = (event:string, data:any, message:string) => {
  Hub.dispatch('auth', { event, data, message }, 'Auth', AMPLIFY_SYMBOL);
};

export default function AuthenticateUser() {
  debug('AuthenticateUser()');

  document.title = "Welcome";

  const [ authFailed, setAuthFailed ] = useState<boolean>(false);
  const [ authError, setAuthError ] = useState<string>('');
  const [ decodedTokenValue, setDecodedTokenValue ] = useState<any>();
  const [ employeeId, setEmployeeId ] = useState<number>();
  const [ admin, setAdmin ] = useState<boolean>(false);
  const [ username, setUsername ] = useState<string>('');

  Hub.listen('auth', ({ payload: { event, data }  }) => {
    debug(`AuthenticateUser() event is ${event} data is ${JSON.stringify(data)}`);
    createUserAction({
      actionId: uuid.v4(),
      actionName: 'AuthenticateUser',
      parameters: JSON.stringify(
        {
          data,
          employeeId,
          event,
          admin,
          username: username !== '' ? username : authenticatedUsername || 'unknown',
        }),
      timestamp: Date.now().toString(),
      ttl: ttl(1),
      username: authenticatedUsername || 'unknown',
    });
    switch(event) {
      case 'customOAuthState':
        window.location.replace(data);
        break;
    }
  });

  useEffect(() => {
    debug('AuthenticateUser() useEffect([])');
    setAuthFailed(false);
    Auth.currentAuthenticatedUser()
      .then(async (cognitoUserData: { username: string; signInUserSession: { idToken: { jwtToken: any; }; }; }) => {
        try {
          await createUserAction({
            actionId: uuid.v4(),
            actionName: 'AuthenticateUser',
            parameters: JSON.stringify(
              {
                data: cognitoUserData.signInUserSession,
                employeeId,
                event: 'currentAuthenticatedUser.then',
                admin,
                username: cognitoUserData.username !== '' ? cognitoUserData.username : authenticatedUsername || 'unknown',
              }),
            timestamp: Date.now().toString(),
            ttl: ttl(1),
            username: authenticatedUsername || 'unknown',
          });
        } catch(error) {
          console.error('AuthenticateUser Auth.currentAuthenticatedUser', error);
        }
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() cognitoUserData is ${JSON.stringify(cognitoUserData)}`);
        const username = cognitoUserData.username.split('_')[1];
        setUsername(username);
        debug(`AuthenticateUser() useEffect([]) currentAuthenticatedUser() username is ${username}`);
        try {
          const jwtToken = cognitoUserData.signInUserSession.idToken.jwtToken;
          const decodedTokenValue: any = jwtDecode(jwtToken);
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
          setDecodedTokenValue(decodedTokenValue);
          let employeeId  = decodedTokenValue['custom:employeeId'];
          if (username == 'svcgsowelcometesting' || username == 'svcgsowelcometest2') employeeId = -99;
          setEmployeeId(employeeId);
          setAdmin(await isAdmin(decodedTokenValue));
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() employeeId is ${employeeId}`);
        } catch(error) {
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        }
        dispatchAuthEvent('configured', null, `The Auth category has been configured successfully`);
      })
      .catch((error) => {
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${JSON.stringify(error)}`);
        setAuthError(error);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate', customState: window.location.href })
          .catch(error => {
            debug(`AuthenticateUser() useEffect([]) federatedSignIn() error is ${JSON.stringify(error)}`);
            setAuthFailed(true);
            setAuthError(error);
          });
        createUserAction({
          actionId: uuid.v4(),
          actionName: 'AuthenticateUser',
          parameters: JSON.stringify(
            {
              data: error,
              employeeId,
              event: 'currentAuthenticatedUser.error',
              admin,
              username: username !== '' ? username : authenticatedUsername || 'unknown',
            }),
          timestamp: Date.now().toString(),
          ttl: ttl(1),
          username: authenticatedUsername || 'unknown',
        });
      });
  }, []);

  let componentToRender;

  if (username && employeeId && decodedTokenValue) {
    debug(`AuthenticateUser() authentication complete, username is ${username} employeeId is ${employeeId} decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
    authenticatedUsername = username;
    createUserAction({
      actionId: uuid.v4(),
      actionName: 'authenticationSuccess',
      parameters: JSON.stringify(
        {
          employeeId,
          admin,
          username,
        }),
      timestamp: Date.now().toString(),
      ttl: ttl(1),
      username: username,
    });
    componentToRender = (
      <div>
        <AuthorizeUser admin={admin} employeeId={employeeId} username={username} />
      </div>
    );
  } else if (authFailed) {
    debug(`AuthenticateUser() authFailed`);
    createUserAction({
      actionId: uuid.v4(),
      actionName: 'authenticationFailure',
      parameters: JSON.stringify(
        {
          employeeId,
          admin,
          username,
        }),
      timestamp: Date.now().toString(),
      ttl: ttl(1),
      username: authenticatedUsername || 'unknown',
    });
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s"}}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Failed to authenticate user, please try again or contact
        <Link external href={URLS.Contact}> SIDE Support</Link>
      </Box>
    );
  } else {
    debug(`AuthenticateUser() authenticating`);
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s"}}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Authenticating User...<Spinner />
      </Box>
    );
  }

  return componentToRender;
}

let isAdmin = async (decodedTokenValue: any): Promise<boolean> => {
  debug(`isAdmin() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`); 

  let adminGroupsLookupTypeValue: LookupTypeValue, adminGroups: string[] | null = null;

  let result = false;

  try {
    adminGroupsLookupTypeValue = await queryLookupTypeValueForTypeAndDescription(LookupTypes.WelcomeApplicationSettings, WelcomeApplicationSettings.AdminGroups);
    debug(`isAdmin() adminGroupsLookupTypeValue is ${JSON.stringify(adminGroupsLookupTypeValue)}`); 
    adminGroups = JSON.parse(adminGroupsLookupTypeValue.value);
  } catch(error) {
    console.error(`isAdmin() error is ${error} JSON: ${JSON.stringify(error)}`); 
  }

  if (adminGroups) {
    debug(`isAdmin() adminGroups is ${adminGroups}`); 
    for (let adminGroup of adminGroups) {
      debug(`isAdmin() adminGroup is ${adminGroup}`); 
      debug(`isAdmin() decodedTokenValue[custom:${adminGroup}] is ${decodedTokenValue[`custom:${adminGroup}`]}`); 
      if (decodedTokenValue[`custom:${adminGroup}`] === `["${adminGroup}"]`) return true;
    }
    debug(`isAdmin() returning ${result}`); 
    return result;
  } else {
    debug(`isAdmin() using default check`); 
    const sideTeam = decodedTokenValue['custom:side-team'];
    const sigAdmin = decodedTokenValue['custom:sig-admin'];
    debug(`isAdmin() (sideTeam == '["side-team"]' || sigAdmin == '["sig-admin"]' is ${(sideTeam == '["side-team"]' || sigAdmin == '["sig-admin"]')}`); 
    return(sideTeam == '["side-team"]' || sigAdmin == '["sig-admin"]');
  }
}
isAdmin = auditDecorator('isAdmin', isAdmin);