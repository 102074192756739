import React, { useState, useEffect } from 'react';
import { Button, Flashbar, Header, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult } from '@tanstack/react-query';
import { ApprovalStatus, VisitorRequestStatus, VisitorTypes } from 'src/constants/Constants';
import { reapplyAccessLevel } from '../utils';
import { debug } from 'src/utils/commonUtils';

export interface ManageVisitorAccessLevelRequestsTablePanelPropsInterface {
  airSite: boolean;
  closeCallback: Function | undefined;
  visitorRequest: APIt.VisitorRequest;
  visitorAccessLevelsQuery: UseQueryResult<APIt.VisitorAccessLevel[] | null, unknown>;
  refreshCallback: Function;
  readOnly: boolean;
  showAddAccessLevelCallback: Function | undefined;
  vendorDayPassBadgeNum: string | null;
}

export default function VisitorAccessLevelRequestsTablePanel(props: ManageVisitorAccessLevelRequestsTablePanelPropsInterface ) {
  debug(`VisitorAccessLevelRequestsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAccessLevel[]>(props.visitorAccessLevelsQuery.data || []);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [reapplying, setReapplying] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<APIt.VisitorAccessLevel | null>(null);
  const [showReapplyRequestSubmitted, setShowReapplyRequestSubmitted] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.AccessLevels.TablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-access-levels-found') : 'No Access Levels Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    setSelectedItem(null);
    await props.refreshCallback();
    setRefreshing(false);
  };

  const reapplyBtnClickHandler = async () => {
    setReapplying(true);
    if (selectedItem) await reapplyAccessLevel(props.visitorRequest, selectedItem);
    setShowReapplyRequestSubmitted(true);
    setReapplying(false);
  };

  useEffect(() => {
    if (props.visitorAccessLevelsQuery.data) setAllItems(props.visitorAccessLevelsQuery.data);
  }, [props.visitorAccessLevelsQuery]);

  useEffect(() => {
    setSelectedItem(null);
  }, [props.visitorRequest]);

  useEffect(() => {
    props.visitorAccessLevelsQuery.refetch();
  }, [props.visitorRequest]);
  
  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='visitorAccessLevelsTableDiv' hidden={hideTable}>
      {showReapplyRequestSubmitted
      &&
      <Flashbar
        items = {
          [
            {
              type: 'success',
              content: bundle.getMessage('request-submitted'),
              dismissible: true,
              onDismiss: () => setShowReapplyRequestSubmitted(false),
            },
          ]
        }
      />
      }
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-access-levels')}
            filteringPlaceholder={bundle.getMessage('find-access-levels')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        footer={
          !props.readOnly
          &&
          <Header
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                {(props.visitorRequest.status == VisitorRequestStatus.CheckedIn)
                &&
                <Button
                  disabled={
                    props.airSite
                    || !selectedItem
                    || !([ApprovalStatus.Activated, ApprovalStatus.Approved, ApprovalStatus.Deactivated, ApprovalStatus.Submitted].find(s => s == selectedItem.status))
                    || (selectedItem.visitor_type == VisitorTypes.UnescortedVendor && props.vendorDayPassBadgeNum == null)
                  }
                  formAction='none'
                  loading={reapplying}
                  onClick={reapplyBtnClickHandler}
                  variant='normal'
                >
                  {bundle.getMessage('reapply')}
                </Button>
                }
                <Button
                  disabled={props.airSite}
                  formAction='none'
                  onClick={() => {
                    setSelectedItem(null);
                    if (props.showAddAccessLevelCallback) props.showAddAccessLevelCallback();
                    if (props.closeCallback) props.closeCallback();
                  }}
                  variant='primary'
                >
                  {bundle.getMessage('add-access-level')}
                </Button>
              </SpaceBetween>
            }
          >
          </Header>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <Button
                formAction='none'
                loading={refreshing}
                key="refreshBtn"
                onClick={refreshBtnClickHandler}
                iconName='refresh'
              >
                {bundle.getMessage('refresh')}
              </Button>
            }
          >
          </Header>
        }
        items={items}
        loading={props.visitorAccessLevelsQuery.isFetching}
        loadingText={bundle.getMessage('loading-access-levels')}
        onSelectionChange={({ detail }) => setSelectedItem(detail.selectedItems[0]) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectionType={props.readOnly ? undefined : 'single'}
        selectedItems={selectedItem ? [selectedItem] : []}
        stickyHeader={true}
        trackBy='id'
        variant='embedded'
      />
    </div>
  );
}
