import React, { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query'
import { Modes } from 'src/constants/Constants';
import ManageVisitorAssetsTablePanel, { VisitorAssetWithAction } from './TablePanel';
import { debug } from '../../../utils/commonUtils';

interface IManageAssetsProps {
  admin: boolean;
  manageAssetsQuery: UseQueryResult<VisitorAssetWithAction[] | null, unknown>;
  manageAssetsQueryAdmin: boolean;
  mode: Modes;
  pageSize: number;
  setPageSizePrefCallback: Function;
  setSplitPanelCallback: Function;
  setSplitPanelOpenCallback: Function;
  setStatusFilterCallback: Function;
  siteCode: string;
  userCanManageVisitorsForSite: boolean;
  username: string;
}

export default function ManageAssets(props: IManageAssetsProps) {
  debug(`ManageAssets() props is ${JSON.stringify(props)}`);

  const [manageAssetsQueryAdmin, setManageAssetsQueryAdmin] = useState(props.manageAssetsQueryAdmin);

  const refresh = async () => {
    await props.manageAssetsQuery.refetch();
  };

  useEffect(() => {
    setManageAssetsQueryAdmin(props.manageAssetsQueryAdmin);
  }, [props.manageAssetsQueryAdmin])

  return(
    <ManageVisitorAssetsTablePanel
      admin={props.admin}
      isTableLoading={props.manageAssetsQuery.isLoading}
      manageVisitorAssets={props.manageAssetsQuery.data || []}
      manageAssetsQueryAdmin={props.manageAssetsQueryAdmin}
      pageSize={props.pageSize}
      refreshCallback={refresh}
      setPageSizePrefCallback={props.setPageSizePrefCallback}
      setSplitPanelCallback={props.setSplitPanelCallback}
      setSplitPanelOpenCallback={props.setSplitPanelOpenCallback}
      setStatusFilterCallback={async (status: string, admin: boolean) =>
        {
          setManageAssetsQueryAdmin(admin);
          await props.setStatusFilterCallback({ approvalStatus: status, admin: admin });
          props.manageAssetsQuery.refetch();
        }
      }
      siteCode={props.siteCode}
      username={props.username}
      userCanManageVisitorsForSite={props.userCanManageVisitorsForSite}
    />
  );
}


