import React from 'react';
import { UseQueryResult } from '@tanstack/react-query'
import { Modes } from 'src/constants/Constants';
import DelegateesTablePanel from './Delegatees/TablePanel';
import { Delegation, LookupTypeValue } from 'src/API';
import { debug } from '../../../utils/commonUtils';
import DelegatorsTablePanel from './Delegators/TablePanel';

interface IDelegationProps {  
  delegationTypesQuery: UseQueryResult<LookupTypeValue[]>;
  delegatorsQuery: UseQueryResult<Delegation[] | null, unknown>;
  employeeId: string;
  mode: Modes;
  username: string;
}

export default function Delegations(props: IDelegationProps) {
  debug(`Delegation() props is ${JSON.stringify(props)}`);
  return(
    <div>
      <DelegateesTablePanel
        delegationTypesQuery={props.delegationTypesQuery}
        employeeId={props.employeeId}
        username={props.username}
      />
      <DelegatorsTablePanel
        delegatorsQuery={props.delegatorsQuery}
        delegationTypesQuery={props.delegationTypesQuery}
        employeeId={props.employeeId}
        username={props.username}
      />
    </div>
  );
}


