import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import {
  createApproverGroup as createApproverGroupMutation,
  deleteApproverGroup as deleteApproverGroupMutation,
  updateApproverGroup as updateApproverGroupMutation,
} from 'src/graphql/mutations';
import { SIGInfraAPIv2Methods } from 'src/constants/Constants';
import { auditDecorator } from 'src/components/utils';
import { listApproverGroupMembers } from 'src/graphql/queries';
import { debug } from 'src/utils/commonUtils';

export let createApproverGroup = async (approverGroup: APIt.ApproverGroup): Promise<APIt.ApproverGroup | null> => {
  debug(`createApproverGroup() approverGroup is ${JSON.stringify(approverGroup)}`);

  let createdApproverGroup: APIt.ApproverGroup | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createApproverGroupMutation, {
      input: {
	      id: approverGroup.id,
	      access_level: approverGroup.access_level,
	      approver_group: approverGroup.approver_group,
	      created_by: approverGroup.created_by
      }
    })) as GraphQLResult<APIt.CreateApproverGroupMutation>;
    debug(`createApproverGroup() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.createApproverGroup) createdApproverGroup = response.data.createApproverGroup as APIt.ApproverGroup;
  } catch (error) {
    console.error(`createApproverGroup() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(createdApproverGroup);
};
createApproverGroup = auditDecorator('createApproverGroup', createApproverGroup);

export let deleteApproverGroup = async (approverGroup: APIt.ApproverGroup): Promise<APIt.ApproverGroup | null> => {
  debug(`deleteApproverGroup() approverGroup is ${JSON.stringify(approverGroup)}`);

  let deletedApproverGroup: APIt.ApproverGroup | null = null;

  try {
    const response = await API.graphql(graphqlOperation(deleteApproverGroupMutation, {
      input: {
	      id: approverGroup.id,
	      updated_by: approverGroup.updated_by
      }
    })) as GraphQLResult<APIt.DeleteApproverGroupMutation>;
    debug(`deleteApproverGroup() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.deleteApproverGroup) deletedApproverGroup = response.data.deleteApproverGroup as APIt.ApproverGroup;
  } catch (e) {
    console.error(`deleteApproverGroup() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(deletedApproverGroup);
};
deleteApproverGroup = auditDecorator('deleteApproverGroup', deleteApproverGroup);

export let updateApproverGroup = async (approverGroup: APIt.ApproverGroup): Promise<APIt.ApproverGroup | null> => {
  debug(`updateApproverGroup() approverGroup is ${JSON.stringify(approverGroup)}`);

  let updatedApproverGroup: APIt.ApproverGroup | null = null;

  try {
    const response = await API.graphql(graphqlOperation(updateApproverGroupMutation, {
      input: {
	      id: approverGroup.id,
	      access_level: approverGroup.access_level,
	      approver_group: approverGroup.approver_group,
	      updated_by: approverGroup.updated_by
      }
    })) as GraphQLResult<APIt.UpdateApproverGroupMutation>;
    debug(`updateApproverGroup() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateApproverGroup) updatedApproverGroup = response.data.updateApproverGroup as APIt.ApproverGroup;
  } catch (e) {
    console.error(`updateApproverGroup() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(updatedApproverGroup);
};
updateApproverGroup = auditDecorator('updateApproverGroup', updateApproverGroup);

export let isApproverGroupValid = async (approverGroup: string): Promise<boolean> => {
  debug(`isApproverGroupValid() approverGroup is ${JSON.stringify(approverGroup)}`);

  type TlistApproverGroupMembersResponse = { statusCode: number, data: {result: string}[] };

  const input: any = {
    path: SIGInfraAPIv2Methods.GetADGroupsMembersIncludingNesting,
    params: [
      { group: approverGroup },
    ]
  };
  debug(`isApproverGroupValid() input is ${JSON.stringify(input)}`);

  let approvers: string[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listApproverGroupMembers,
      {
        input: JSON.stringify(input)
      })) as GraphQLResult<APIt.ListApproverGroupMembersQuery>;
    if (response && response.data && response.data.listApproverGroupMembers) {
      const listApproverGroupMembersResponse: TlistApproverGroupMembersResponse = JSON.parse(response.data.listApproverGroupMembers) as TlistApproverGroupMembersResponse;
      for (let dataElement of listApproverGroupMembersResponse.data) {
        if (dataElement && dataElement.result) {
          const cn = dataElement.result.split(',').find((value: string) => value.slice(0,2) == 'CN')?.split('=')[1];
          if (cn && !dataElement.result.split(',').find((value: string) => value == 'OU=Groups')) approvers.push(cn);
        }
      }
    }
  } catch(error) {
    console.error(`isApproverGroupValid() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`isApproverGroupValid() approvers is ${JSON.stringify(approvers)}`);
  return approvers.length > 0;
};
isApproverGroupValid = auditDecorator('isApproverGroupValid', isApproverGroupValid);