import React, { useState } from 'react';
import {
  Box,
  Button,
  Flashbar,
  Form,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Textarea
} from '@amzn/awsui-components-react';
import { CreatedVisitorAssetInterface } from './TablePanel';
import { CancelCreateVisitorAssetInterface } from './TablePanel';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import * as APIt from 'src/API';
import { createVisitorAsset } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { queryLookupTypeValueForTypeAndValue, sqlEscapeString } from 'src/components/utils';
import { ApprovalStatus, LookupTypes } from 'src/constants/Constants';
import { debug } from 'src/utils/commonUtils';
import { TVisitorAsset, TVisitorAssetWarningData, VisitorAssetWarningSchema } from 'src/types';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';

export interface SelectOptionInterface {
  label: string;
  value: string;
}

export interface VisitorAssetCreatePanelPropsInterface {
  assetTypeOptionsQuery: UseQueryResult<APIt.LookupTypeValue[]>;
  cancelCreateCallback: CancelCreateVisitorAssetInterface;
  saveCallback: CreatedVisitorAssetInterface;
  siteCode: string;
  username: string;
  visitorId: string;
}

export const ManageVisitorAssetCreatePanel = (props: VisitorAssetCreatePanelPropsInterface) => {
  debug(`ManageVisitorAssetCreatePanel() props is ${JSON.stringify(props)}`);

  const queryClient = useQueryClient();

  const [addAssetProcessing, setAddAssetProcessing] = useState<boolean>(false);
  const [assetDescriptionValue, setAssetDescriptionValue] = useState<string | null>(null);
  const [assetEndDateValue, setAssetEndDateValue] = useState<string | null>(null);
  const [assetMakeValue, setAssetMakeValue] = useState<string | null>(null);
  const [assetModelValue, setAssetModelValue] = useState<string | null>(null);
  const [assetPermanentValue, setAssetPermanentValue] = useState<boolean>(false);
  const [assetSerialNumValue, setAssetSerialNumValue] = useState<string| null>(null);
  const [assetStartDateValue, setAssetStartDateValue] = useState<string | null>(null);
  const [assetTypeValue, setAssetTypeValue] = useState<SelectProps.Option | null>(null);
  const [duplicateVisitorAssets, setDuplicateVisitorAssets] = useState<APIt.VisitorAsset[]>([]);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [warningData, setWarningData] = useState<boolean>(false);
  const [warningDataInfo, setWarningDataInfo] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Assets.Create');

  const clearCreateForm = () => {
    setAssetTypeValue(null);
    setAssetDescriptionValue(null);
    setAssetSerialNumValue(null);
    setAssetPermanentValue(false);
    setAssetStartDateValue(null);
    setAssetEndDateValue(null);
  };

  const createVisitorAssetMutationHook = useMutation({
    mutationFn: createVisitorAsset,
    onError: (error: any) => setErrorText(JSON.stringify(error)),
    onSuccess: () => {
      clearCreateForm();
      props.saveCallback();
      queryClient.invalidateQueries({ queryKey: ['manageVisitorCheckInAssets'] })
    },
  });

  const cancelBtnHandler = () => {
    clearCreateForm();
    props.cancelCreateCallback();
  };

  const saveBtnHandler = async () => {
    setAddAssetProcessing(true);
    debug(`ManageVisitorAssetCreatePanel() addVisitorAssets() warningData is ${warningData}`);
    if (!warningData) {
      const visitorAssetForWarningCheck: TVisitorAsset = 
        {
          description: assetDescriptionValue ?? '',
          make: assetMakeValue ?? '',
          model: assetModelValue ?? '',
          permanentFlag: assetPermanentValue ? 'Y' : 'N',
          serialNum: assetSerialNumValue ?? '',
          site: props.siteCode,
          type: assetTypeValue?.label ?? '',
          username: props.username,
          validFromDate: assetStartDateValue ?? '',
          validThroughDate: assetEndDateValue ?? '',
        };
      const parseWarningResults = await VisitorAssetWarningSchema.safeParseAsync(visitorAssetForWarningCheck) as TVisitorAssetWarningData;
      debug(`ManageVisitorAssetCreatePanel() addVisitorAssets() parseWarningResults is ${JSON.stringify(parseWarningResults)}`);
      if (parseWarningResults.data.length > 0) {
        for (let result of parseWarningResults.data) {
          debug(`ManageVisitorAssetCreatePanel() addVisitorAssets() result is ${JSON.stringify(result)}`);
          if (result.warningMessage === 'duplicate-visitor-asset') {
            setDuplicateVisitorAssets(result.duplicateVisitorAssets);
            setWarningData(true);
            setWarningDataInfo(bundle.getMessage(result.warningMessage));
            setAddAssetProcessing(false);
            return;
          }
        }
      }
    }
    try {
      const site_source_system_id = await queryLookupTypeValueForTypeAndValue(LookupTypes.SiteSourceSystem, 'PACS');
      const approvalStatus = await queryLookupTypeValueForTypeAndValue(LookupTypes.AssetApprovalStatus, ApprovalStatus.Approved);
      const newVisitorAsset: APIt.VisitorAsset = {
        __typename: 'VisitorAsset',
        approval_status: approvalStatus.value,
        approval_status_id: approvalStatus.id,
        asset_type_id: assetTypeValue!.value!,
        description: sqlEscapeString(assetDescriptionValue!),
        created_by: props.username,
        created: (new Date).toISOString(),
        end_date: assetEndDateValue ? `${assetEndDateValue} 00:00:00` : undefined,
        id: '',
        make: sqlEscapeString(assetMakeValue || ''),
        model: sqlEscapeString(assetModelValue || ''),
        permanent_flag: assetPermanentValue,
        person_id: '',
        serial_num: sqlEscapeString(assetSerialNumValue!),
        site_id: props.siteCode!,
        site_source_system_id: site_source_system_id.id,
        start_date: assetStartDateValue ? `${assetStartDateValue} 00:00:00` : undefined,
        updated: (new Date).toISOString(),
        updated_by: props.username,
        visitor_id: props.visitorId,
      }
      if (assetTypeValue) createVisitorAssetMutationHook.mutate(newVisitorAsset);
    } catch(error) {
      setErrorText(`${bundle.getMessage('error-encountered')}: ${errorText}`);
    }
    setAddAssetProcessing(false);
  };

  const visitorAssetTypeIdFieldOnChangeHandler = (detail: any) => {
    setAssetTypeValue(detail.selectedOption);
  };

  const visitorAssetDescriptionFieldOnChangeHandler = (detail: any) => {
    setAssetDescriptionValue(detail.value);
  };

  const visitorAssetSerialNumFieldOnChangeHandler = (detail: any) => {
    setAssetSerialNumValue(detail.value);
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <div>
      {warningData && duplicateVisitorAssets
      &&
      <>
      <Flashbar
        items = {
          [
            {
              content: warningDataInfo,
              dismissible: true,
              onDismiss: () => {
                setWarningData(false);
                setWarningDataInfo('');
              },
              type: 'warning',
            },
          ]
        }
      />
      <VisitorAssetsTablePanel
        isTableLoading={false}
        readOnly={true}
        setVisitorAssets={undefined}
        showAssetOwner={false}
        siteCode={''}
        username={''}
        visitorAssets={duplicateVisitorAssets}
        visitorAssetTypesQuery={null}
        visitorId={''}
      />
      </>}
      <Form
       actions={
          <Box float='right'>
            <SpaceBetween size='xs' direction='horizontal'>
              <Button onClick={cancelBtnHandler}>
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                onClick={saveBtnHandler}
                variant='primary'
                disabled={
                  (
                    !props.siteCode
                    || !assetTypeValue
                    || !assetSerialNumValue
                    || !assetDescriptionValue
                    || createVisitorAssetMutationHook.status == 'loading'
                  )
                }
                loading={createVisitorAssetMutationHook.status == 'loading' || addAssetProcessing}
              >
                {bundle.getMessage('add-asset')} { createVisitorAssetMutationHook.status == 'loading' && <span> <Spinner /> </span> }
              </Button>
            </SpaceBetween>
          </Box>
       } 
       errorText={errorText}
      >
        <SpaceBetween size='s' direction='vertical'>
          <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
            <FormField label={bundle.getMessage('asset-type')}>
              <Select
                onChange={({ detail }) => visitorAssetTypeIdFieldOnChangeHandler(detail)}
                selectedOption={assetTypeValue}
                options={props.assetTypeOptionsQuery?.data ? props.assetTypeOptionsQuery.data!.map((at) => {return {label: at.value, value: at.id}}): []}
              />
            </FormField>
            <FormField label={bundle.getMessage('serial-number')}>
              <Input
                onChange={({ detail }) => visitorAssetSerialNumFieldOnChangeHandler(detail)}
                value={assetSerialNumValue || ''}
                placeholder=''
                inputMode='text'
              />
            </FormField>
          </Grid>
          <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
            <FormField label={bundle.getMessage('make')}>
              <Input
                data-testid='AssetCreateSerialMakeInput'
                onChange={({ detail }) => setAssetMakeValue(detail.value)}
                value={assetMakeValue || ''}
                placeholder={bundle.getMessage('enter-make')}
                inputMode='text'
              />
            </FormField>
            <FormField label={bundle.getMessage('model')}>
              <Input
                data-testid='AssetCreateSerialModelInput'
                onChange={({ detail }) => setAssetModelValue(detail.value)}
                value={assetModelValue || ''}
                placeholder={bundle.getMessage('enter-model')}
                inputMode='text'
              />
            </FormField>
          </Grid>
          <FormField label={bundle.getMessage('description')}>
            <Textarea
              onChange={({ detail }) => visitorAssetDescriptionFieldOnChangeHandler(detail)}
              value={assetDescriptionValue || ''}
              placeholder=''
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </div>
  );
}
