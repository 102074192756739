import React, { useState } from 'react';
import { Box, Button, Container, FormField, Grid, Input, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { IVisitorAccessLevelWithAction } from './TablePanel';
import { useBundle } from '@amzn/react-arb-tools';
import { ActionFromLinkStatus } from 'src/constants/Constants';
import { debug } from '../../../utils/commonUtils';

interface IActionFromLinkProps {
  error: string;
  okCallback: Function;
  status: ActionFromLinkStatus;
  errorMessage: string | null;
  showSubmissionDenialNotes: boolean;
  setSubmissionDenialNotesCallback: Function;
  visitorAccessLevelApprovalId: string;
  visitorAccessLevelWithAction: IVisitorAccessLevelWithAction | undefined;
}

export default function ActionFromLink(props: IActionFromLinkProps) {
  debug(`ActionFromLink() props() is ${JSON.stringify(props)}`);

  const [ notes, setNotes ] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.ActionFromLink');

  const handleOKButton = () => {
    debug(`ActionFromLink() handleOKButton() notes ${notes}`);
    props.setSubmissionDenialNotesCallback(notes);
    setNotes('');
    props.okCallback(notes);
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <Container
      footer=
        <Box float='right'>
          <SpaceBetween direction='horizontal' size={'s'} >
            <Button
              disabled={props.status == ActionFromLinkStatus.Checking || props.status == ActionFromLinkStatus.InProgress}
              onClick={handleOKButton}
              variant='primary'
            >
              {bundle.getMessage('ok')}
            </Button>
          </SpaceBetween>
        </Box>
    >
      {
        props.visitorAccessLevelWithAction
        &&
        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]} >
          {props.visitorAccessLevelWithAction.person_id}
          {props.visitorAccessLevelWithAction.access_level_name}
          {props.visitorAccessLevelWithAction.permanent_flag ? bundle.getMessage('permanent') : `${props.visitorAccessLevelWithAction.start_date} ${bundle.getMessage('through')} ${props.visitorAccessLevelWithAction.end_date}`}
        </Grid>
      }
      {bundle.getMessage('status')}: {bundle.getMessage(props.status)} {props.error}{props.status == ActionFromLinkStatus.Checking || props.status == ActionFromLinkStatus.InProgress ? <Spinner/> : null}
      {
        props.showSubmissionDenialNotes
        &&
        <FormField label={bundle.getMessage('denial-notes')}>
          <Input
            autoFocus
            data-testid='ManageAccessRequestsSubmissionDenialNotesInput'
            onChange={({detail}) => setNotes(detail.value)}
            value={notes}
          />
        </FormField>
      }
      {
        (props.error !== '')
        &&
        props.error
      }
    </Container>
  );
}


