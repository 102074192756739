import React, { useEffect, useRef, useState } from 'react';
import { useBundle } from '@amzn/react-arb-tools';
import Papa from 'papaparse';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Input,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@amzn/awsui-components-react';
import { createVisitorAsset } from '../Assets/utils';
import {
  TVisitorAsset,
  TVisitorAssetWarningData,
  VisitorAssetSchema,
  VisitorAssetWarningSchema,
} from 'src/types';
import { VisitorAsset } from 'src/API';
import {
  sqlEscapeString,
  queryLookupTypeValueForTypeAndValue,
  getLookupTypeValueId,
} from 'src/components/utils';
import {
  ApprovalStatus,
  LookupTypes,
} from 'src/constants/Constants';
import * as APIt from 'src/API';
import { queryVisitorAssetTypes } from 'src/components/utils';
import { queryVisitor } from '../utils';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { debug } from 'src/utils/commonUtils';

interface IAssetBulkAddParams {
  addVendor: boolean;
  closeCallback: Function;
  disableAutoApproval: boolean;
  id: number;
  username: string;
}

export default function VisitorAssetBulkAdd(props: IAssetBulkAddParams) {
  debug(`VisitorAssetBulkAdd() props is ${JSON.stringify(props)}`);

  const [visitorAssetTypes, setVisitorAssetTypes] = useState<APIt.LookupTypeValue[]>([]);
  const [csvData, setCSVData] = useState('');
  const [duplicateVisitorAssets, setDuplicateVisitorAssets] = useState<APIt.VisitorAsset[]>([]);
  const [invalidData, setInvalidData] = useState<boolean>(false);
  const [loadingAssets, setLoadingAssets] = useState<boolean>(false);
  const [invalidDataInfo, setInvalidDataInfo] = useState<string>('');
  const [warningData, setWarningData] = useState<boolean>(false);
  const [warningDataInfo, setWarningDataInfo] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Assets.VisitorAssetBulkAdd');

  const fileChooserRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (result: any) => {
        setCSVData(result.data.join('\n'));
      },
      delimiter: ',',
      error: () => setInvalidData(true),
      escapeChar: '\\',
      quotes: true,
      quoteChar: '"',
      skipEmptyLines: true,
    });
    setInvalidData(false);
    setInvalidDataInfo('');
    setWarningData(false);
    setWarningDataInfo('');
  };

  const duplicateAssets = async (visitorAssetData: any): Promise<boolean> => {
    const duplicates: VisitorAsset[] = [];
    for (let i = 0; i < visitorAssetData.length; i++) {
      debug(`VisitorAssetBulkAdd() addVisitorAssets() visitorAssetData[${i}] is ${JSON.stringify(visitorAssetData[i])}`);
      if (JSON.stringify(visitorAssetData[i]) == '["username","site","type","serial","make","model","description","permanent (Y/N)","valid from","valid through"]') continue;
      if (visitorAssetData[i][0] == 'example') continue;
      const visitorAsset: TVisitorAsset = 
        {
          description: visitorAssetData[i][6],
          make: visitorAssetData[i][4],
          model: visitorAssetData[i][5],
          permanentFlag: visitorAssetData[i][7].toUpperCase(),
          serialNum: visitorAssetData[i][3],
          site: visitorAssetData[i][1].toUpperCase(),
          type: visitorAssetData[i][2],
          username: visitorAssetData[i][0].trim().toLowerCase(),
          validFromDate: visitorAssetData[i][8],
          validThroughDate: visitorAssetData[i][9],
        };
      debug(`VisitorAssetBulkAdd() addVisitorAssets() warningData is ${warningData}`);
      if (!warningData) {
        const parseWarningResults = await VisitorAssetWarningSchema.safeParseAsync(visitorAsset) as TVisitorAssetWarningData;
        debug(`VisitorAssetBulkAdd() addVisitorAssets() parseWarningResults is ${JSON.stringify(parseWarningResults)}`);
        if (parseWarningResults.data.length > 0) {
          for (let result of parseWarningResults.data) {
            debug(`VisitorAssetBulkAdd() addVisitorAssets() result is ${JSON.stringify(result)}`);
            if (result.warningMessage === 'duplicate-visitor-asset') {
              duplicates.push(...result.duplicateVisitorAssets);
            }
          }
        }
      }
    }
    if (duplicates.length > 0) {
      setDuplicateVisitorAssets(duplicates);
      return true;
    }
    return false;
  }

  const addVisitorAssets = async () => {
    setLoadingAssets(true);
    const visitorAssets: TVisitorAsset[] = [];
    try {
      const config = {
        delimiter: ',',
        error: () => setInvalidData(true),
        escapeChar: '\\',
        quotes: true,
        quoteChar: '"',
        skipEmptyLines: true,
      };
      const visitorAssetData = Papa.parse(csvData, config).data;
      debug(`VisitorAssetBulkAdd() addVisitorAssets() visitorAssetData is ${JSON.stringify(visitorAssetData)}`);
      let id = props.id;
      for (let i = 0; i < visitorAssetData.length; i++, id++) {
        debug(`VisitorAssetBulkAdd() addVisitorAssets() visitorAssetData[${i}] is ${JSON.stringify(visitorAssetData[i])}`);
        if (JSON.stringify(visitorAssetData[i]) == '["username","site","type","serial","make","model","description","permanent (Y/N)","valid from","valid through"]') continue;
        if (visitorAssetData[i][0] == 'example') continue;
        const visitorAsset: TVisitorAsset = 
          {
            description: visitorAssetData[i][6],
            make: visitorAssetData[i][4],
            model: visitorAssetData[i][5],
            permanentFlag: visitorAssetData[i][7].toUpperCase(),
            serialNum: visitorAssetData[i][3],
            site: visitorAssetData[i][1].toUpperCase(),
            type: visitorAssetData[i][2],
            username: visitorAssetData[i][0].trim().toLowerCase(),
            validFromDate: visitorAssetData[i][8],
            validThroughDate: visitorAssetData[i][9],
          };
        debug(`VisitorAssetBulkAdd() addVisitorAssets() warningData is ${warningData}`);
        if (!warningData && (await duplicateAssets(visitorAssetData))) {
          const parseWarningResults = await VisitorAssetWarningSchema.safeParseAsync(visitorAsset) as TVisitorAssetWarningData;
          debug(`VisitorAssetBulkAdd() addVisitorAssets() parseWarningResults is ${JSON.stringify(parseWarningResults)}`);
          if (parseWarningResults.data.length > 0) {
            for (let result of parseWarningResults.data) {
              debug(`VisitorAssetBulkAdd() addVisitorAssets() result is ${JSON.stringify(result)}`);
              if (result.warningMessage === 'duplicate-visitor-asset') {
                setWarningData(true);
                setWarningDataInfo(bundle.getMessage(result.warningMessage));
                setLoadingAssets(false);
                return;
              }
            }
          }
        }
        const parseResults = await VisitorAssetSchema.safeParseAsync(visitorAsset);
        debug(`VisitorAssetBulkAdd() addVisitorAssets() parseResults is ${JSON.stringify(parseResults)}`);
        if (!parseResults.success) {
          let errors = '';
          debug(`VisitorAssetBulkAdd() addVisitorAssets() parseResults.error.issues is ${JSON.stringify(parseResults.error.issues)}`);
          for (let issue of parseResults.error.issues) {
            debug(`VisitorAssetBulkAdd() addVisitorAssets() issue is ${JSON.stringify(issue)}`);
            errors += `${bundle.getMessage(issue.message)}, `;
          }
          debug(`VisitorAssetBulkAdd() addVisitorAssets() errors is ${errors}`);
          setInvalidDataInfo(`Record ${i+1}: ${errors.slice(0,-1)}`);
          throw new Error('visitor parse error');
        }
        visitorAssets.push(visitorAsset);
      }
    } catch(error) {
      debug(`VisitorAssetBulkAdd() addVisitorAssets() error is ${error} ${JSON.stringify(error)}`);
      setInvalidData(true);
      setLoadingAssets(false);
      return;
    }
    try {
      const siteSourceSystem = await queryLookupTypeValueForTypeAndValue(LookupTypes.SiteSourceSystem, 'PACS');
      const personSourceSystem = await queryLookupTypeValueForTypeAndValue(LookupTypes.PersonSourceSystem, 'PACS');
      const defaultApprovalStatus = await queryLookupTypeValueForTypeAndValue(LookupTypes.AssetApprovalStatus, ApprovalStatus.PendingApproval);
      let approvalStatus = defaultApprovalStatus;
      debug(`VisitorAssetBulkAdd() addVisitorAssets() visitorAssets is ${JSON.stringify(visitorAssets)}`);
      const createVisitorAssetPromises = [];
      for (let visitorAsset of visitorAssets) {
        debug(`VisitorAssetBulkAdd() addVisitorAssets() visitorAsset is ${JSON.stringify(visitorAsset)}`);
        const assetTypeId = await getLookupTypeValueId(LookupTypes.AssetType, visitorAsset.type, true);
        const visitor = await queryVisitor(visitorAsset.username, personSourceSystem.value);
        const newVisitorAsset: VisitorAsset = {
          __typename: 'VisitorAsset',
          approval_status: approvalStatus.value,
          approval_status_id: approvalStatus.id,
          asset_type_id: assetTypeId!,
          description: sqlEscapeString(visitorAsset.description),
          created_by: props.username,
          created: (new Date).toISOString(),
          end_date: visitorAsset.validThroughDate ? `${visitorAsset.validThroughDate} 00:00:00` : undefined,
          id: '',
          make: sqlEscapeString(visitorAsset.make || ''),
          model: sqlEscapeString(visitorAsset.model || ''),
          permanent_flag: visitorAsset.permanentFlag === 'Y',
          person_id: visitorAsset.username,
          serial_num: sqlEscapeString(visitorAsset.serialNum),
          site_id: visitorAsset.site,
          site_source_system_id: siteSourceSystem.id,
          start_date: visitorAsset.validFromDate ? `${visitorAsset.validFromDate} 00:00:00` : undefined,
          updated: (new Date).toISOString(),
          updated_by: props.username,
          visitor_id: visitor?.id!,
        }
        debug(`VisitorAssetBulkAdd() addVisitorAssets() newVisitorAsset is ${JSON.stringify(newVisitorAsset)}`);
        createVisitorAssetPromises.push(createVisitorAsset(newVisitorAsset, props.disableAutoApproval));
      }
      debug(`VisitorAssetBulkAdd() addVisitorAssets() createVisitorAssetPromises.length is ${createVisitorAssetPromises.length}`);
      await Promise.all(createVisitorAssetPromises);
    } catch(error) {
      debug(`VisitorAssetBulkAdd() addVisitorAssets() error is ${error} ${JSON.stringify(error)}`);
      setInvalidData(true);
      setLoadingAssets(false);
      return;
    }
    setCSVData('');
    setInvalidData(false);
    setInvalidDataInfo('');
    setWarningData(false);
    setWarningDataInfo('');
    if (fileChooserRef?.current) fileChooserRef.current.value = '';
    setLoadingAssets(false);
    props.closeCallback();
  };

  const assetTemplateFileName = 'asset_template.csv';

  const assetTemplateHref = 'data:text/plain;charset=utf-8,username%2Csite%2Ctype%2Cserial%2Cmake%2Cmodel%2Cdescription%2Cpermanent (Y/N)%2Cvalid from%2Cvalid through' +
    '%0Aexample%2CAUS2%2COther%2C1234567890%2CAmazon Kindle%2CFire 12%2CKindle Fire%2CN%2C"=""2024-01-01"""%2C"=""2024-01-12"""';

  useEffect(() => {
    queryVisitorAssetTypes().then(setVisitorAssetTypes);
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return (
    <Container
      header={
        <>
          {invalidData
          &&
          <Flashbar
            items = {
              [
                {
                  content: `${bundle.getMessage('invalid-data')}: ${invalidDataInfo}`,
                  dismissible: true,
                  onDismiss: () => {
                    setInvalidData(false);
                    setInvalidDataInfo('');
                  },
                  type: 'error',
                },
              ]
            }
          />}
          {warningData && duplicateVisitorAssets
          &&
          <>
          <Flashbar
            items = {
              [
                {
                  content: warningDataInfo,
                  dismissible: true,
                  onDismiss: () => {
                    setWarningData(false);
                    setWarningDataInfo('');
                  },
                  type: 'warning',
                },
              ]
            }
          />
          <VisitorAssetsTablePanel
            isTableLoading={false}
            readOnly={true}
            setVisitorAssets={undefined}
            showAssetOwner={true}
            siteCode={''}
            username={''}
            visitorAssets={duplicateVisitorAssets}
            visitorAssetTypesQuery={null}
            visitorId={''}
          />
          </>}
        </>
      }
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button
              onClick={() => {
                setCSVData('');
                setInvalidData(false);
                setInvalidDataInfo('');
                setWarningData(false);
                setWarningDataInfo('');
                if (fileChooserRef?.current) fileChooserRef.current.value = '';
                props.closeCallback();
              }}
              variant='normal'
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              disabled={!csvData}
              loading={loadingAssets}
              onClick={async () => await addVisitorAssets()}
              variant='primary'
            >
              {bundle.getMessage('add')}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction='vertical' size='s'>
        <FormField label={bundle.getMessage('upload-csv-file')}>
          <input ref={fileChooserRef} type='file' onChange={handleFileUpload} />
        </FormField>
        <a href={assetTemplateHref} download={assetTemplateFileName}>{assetTemplateFileName}</a>
        <FormField label='CSV file contents' stretch>
          <Textarea
            onChange={({ detail }) => {
              setCSVData(detail.value);
              setInvalidData(false);
              setInvalidDataInfo('');
              setWarningData(false);
              setWarningDataInfo('');
            }}
            placeholder={`username,site,type,serial,make,model,description,permanent (Y/N),valid from,valid through\njdoe,AUS2,Other,Z7684E3267823,Amazon Kindle,Kindle Fire 12,Amazon Kindle Fire,N,2024-01-01,2024-01-31`}
            rows={15}
            value={csvData}
          />
        </FormField>
        <FormField label='Allowed Types' stretch>
          <Input
            disabled
            placeholder='...'
            readOnly
            value={visitorAssetTypes.sort().map(vat => ` ${vat.value}`).toString()}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}