import React, { useEffect, useState } from 'react';
import { Button, Flashbar, Form, FormField, Grid, Header, Input, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from "@amzn/react-arb-tools";
import { useQuery } from '@tanstack/react-query';
import { queryEmployees } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';

export interface IAdvancedEmployeeSearchProps {
  closeCallback: Function;
  addEmployeesCallback: Function;
  nonReportIncluded?: boolean;
}

export default function AdvancedEmployeeSearch(props: IAdvancedEmployeeSearchProps) {
  debug(`AdvancedEmployeeSearch() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.EmployeeDetails[]>([]);
  const [added, setAdded] = useState<boolean>(false);
  const [adding, setAdding] = useState<boolean>(false);
  const [emplId, setEmplId] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [nonReportIncluded, setNonReportIncluded] = useState<boolean>(props.nonReportIncluded || false);
  const [selectedItems, setSelectedItems] = useState<APIt.EmployeeDetails[]>([]);
  const [username, setUsername] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.common.AdvancedEmployeeSearch.TablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-records-found') : 'No Records Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'username' },
    }
  );

  const listEmployeesQuery = useQuery({
    onError: (error: any) => setErrorText(JSON.stringify(error)),
    onSuccess: (data) => setAllItems(data),
    queryKey: ['cardholdersEmployeeAdvancedSearch'],
    queryFn: () => queryEmployees(username, firstName, lastName, emplId),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as APIt.EmployeeDetails[]);
  };

  const reset = () => {
    setAdded(false);
    setSelectedItems([]);
    setAllItems([]);
    setEmplId('');
    setFirstName('');
    setLastName('');
    setUsername('');
    setNonReportIncluded(false);
  };

  useEffect(() => {
    if (props.nonReportIncluded) {
      setAdded(false);
      setNonReportIncluded(true);
    } else {
      setNonReportIncluded(false);
    }
  }, [props.nonReportIncluded]);

  if (isBundleLoading) return <Spinner/>

  return(
    <div id='visitorAccessLevelsTableDiv' hidden={hideTable}>
      {(added && !nonReportIncluded)
      &&
      <Flashbar
        items = {
          [
            {
              type: 'success',
              dismissible: true,
              onDismiss: () => setAdded(false),
              content: bundle.getMessage('added')
            },
          ]
        }
      />
      }
      {nonReportIncluded
      &&
      <Flashbar
        items = {
          [
            {
              type: 'warning',
              dismissible: true,
              onDismiss: () => {
                setAdded(false);
                setNonReportIncluded(false);
              },
              content: bundle.getMessage('you-have-selected-someone-who-does-not-report-to-you')
            },
          ]
        }
      />
      }
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        footer={
          <Header
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  disabled={adding}
                  onClick={() => {
                    reset();
                    props.closeCallback();
                  }}
                  variant='normal'
                >
                  {bundle.getMessage('done')}
                </Button>
                <Button
                  disabled={selectedItems.length == 0}
                  loading={adding}
                  onClick={async () => {
                    setNonReportIncluded(false);
                    setAdded(false);
                    setAdding(true);
                    await props.addEmployeesCallback(selectedItems);
                    setAdding(false);
                    setAdded(true);
                  }}
                  variant='primary'
                >
                  {bundle.getMessage('add')}
                </Button>
              </SpaceBetween>
            }
          >
          </Header>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <form
                onSubmit={e => {
                  e.preventDefault();
                  setNonReportIncluded(false);
                  setAdded(false);
                  setAdding(false);
                  setSelectedItems([]);
                  listEmployeesQuery.refetch();
                }}
              >
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        disabled={!(firstName !== '' || lastName !== '' || emplId !== '' || username !== '' || listEmployeesQuery.isFetching)}
                        formAction='none'
                        iconName='undo'
                        key='resetBtn'
                        onClick={reset}
                      />
                      <Button
                        disabled={!(firstName.length > 2 || lastName.length > 2 || emplId !== '' || username.length > 2 )}
                        iconName='search'
                        key='searchBtn'
                        loading={listEmployeesQuery.isFetching}
                        variant='primary'
                      />
                    </SpaceBetween>
                  }
                  errorText={errorText}
                >
                <Grid gridDefinition={[
                  {colspan: 3},
                  {colspan: 3},
                  {colspan: 2},
                  {colspan: 2},
                  {colspan: 1},
                  {colspan: 1}
                ]}>
                  <FormField label={bundle.getMessage('first-name')}>
                    <Input
                      autoFocus
                      onChange={({detail}) => {setFirstName(detail.value)}}
                      value={firstName}
                    />
                  </FormField>
                  <FormField label={bundle.getMessage('last-name')}>
                    <Input
                      onChange={({detail}) => {setLastName(detail.value)}}
                      value={lastName}
                    />
                  </FormField>
                  <FormField label={bundle.getMessage('username')}>
                    <Input
                      onChange={({detail}) => {setUsername(detail.value)}}
                      value={username}
                    />
                  </FormField>
                  <FormField label={bundle.getMessage('employee-id')}>
                    <Input
                      onChange={({detail}) => {setEmplId(detail.value)}}
                      value={emplId}
                    />
                  </FormField>
                </Grid>
                </Form>
              </form>
            }
          >
          </Header>
        }
        items={items}
        loading={listEmployeesQuery.isFetching}
        loadingText={bundle.getMessage('searching')}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectionType='multi'
        selectedItems={selectedItems}
        stickyHeader={true}
        trackBy='id'
        variant='embedded'
      />
    </div>
  );
}
