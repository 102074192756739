import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "src/API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useBundle } from "@amzn/react-arb-tools";
import EmployeeBadgePhoto from 'src/components/common/EmployeeBadgePhoto';
import { Spinner } from '@amzn/awsui-components-react';

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.EmployeeDetails>[] = [
  {
    id: 'lastname',
    header: <TranslateHeader value={'last-name'} />,
    cell: item => item.lastName,
    sortingField: 'lastName'
  },
  {
    id: 'firstname',
    header: <TranslateHeader value={'first-name'} />,
    cell: item => item.firstName,
    sortingField: 'firstName',
  },
  {
    id: 'username',
    header: <TranslateHeader value={'username'} />,
    cell: item => <EmployeeBadgePhoto employeeId={item.id} username={item.username} firstName={item.firstName} lastName={item.lastName} />,
    sortingField: 'username'
  },
  {
    id: 'title',
    header: <TranslateHeader value={'title'} />,
    cell: item => item.title,
    sortingField: 'title'
  },
];

interface EmptyStateProps {
  title: string
}

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.common.AdvancedEmployeeSearch.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
