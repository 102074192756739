import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DateRangePicker,
  DateRangePickerProps,
  Flashbar,
  Form,
  FormField,  
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@amzn/awsui-components-react';
import { CreatedDelegationInterface } from './TablePanel';
import { CancelCreateDelegationInterface } from './TablePanel';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as APIt from 'src/API';
import { addDelegate, queryDelegatees, removeDelegations, transformDelegation } from '../utils';
import { useBundle } from '@amzn/react-arb-tools';
import { isFromDateValid, isThroughDateValid, queryEmployeeDetails } from 'src/components/utils';
import { debug } from 'src/utils/commonUtils';
import * as uuid from 'uuid';
import { viewershipAccessGrantedSendNotification } from '../../utils';

interface SelectOptionInterface {
  label: string;
  value: string;
}

export interface DelegationCreatePanelPropsInterface {  
  cancelCreateCallback: CancelCreateDelegationInterface; 
  saveCallback: CreatedDelegationInterface;  
  delegatorId: string; 
  username: string;
  delegationTypeOptions: SelectOptionInterface[];
}

export const DelegationCreatePanel = (props: DelegationCreatePanelPropsInterface) => {
  debug(`DelegationCreatePanel() props is ${JSON.stringify(props)}`);

  const queryClient = useQueryClient();

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Delegations.Delegatees.Create');
  const [currentUserEmployeeDetails, setCurrentUserEmployeeDetails] =
    useState<APIt.EmployeeDetails | undefined>(queryClient.getQueryData(['currentUserEmployeeDetails'])); 
  const [delegationJustificationValue, setDelegationJustification] = useState<string>('');
  const [delegationPermanentValue, setDelegationPermanentValue] = useState<boolean>(false);
  const [delegationStartDateValue, setDelegationStartDateValue] = useState<string | null>(null);
  const [delegationEndDateValue, setDelegationEndDateValue] = useState<string | null>(null);
  const [delegationTypeValue, setDelegationTypeValue] = useState<SelectProps.Option | null>(null);
  const [employeeDetails, setEmployeeDetails] = useState<APIt.EmployeeDetails | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [invalidFromDate, setInvalidFromDate] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [showEmailNotBeingSent, setShowEmailNotBeingSent] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [usernameIsInvalid, setUsernameIsInvalid] = React.useState<boolean>(false);
  const [validFromDateInput, setValidFromDateInput] = useState<any>(undefined);
  const [validThroughDateInput, setValidThroughDateInput] = useState<string>('');
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(null);
  const [warningData, setWarningData] = useState<boolean>(false);
  const [warningDataInfo, setWarningDataInfo] = useState<string>('');

  const cancelBtnHandler = () => {
    clearCreateForm();
    setErrorText(null);
    props.cancelCreateCallback();
  };

  const clearCreateForm = () => {
    setDelegationPermanentValue(false);
    setDelegationStartDateValue(null);
    setDelegationEndDateValue(null);
    setErrorText(null);
    setWarningData(false);
    setWarningDataInfo('');
    setShowEmailNotBeingSent(false);
  };

  const dateRangeInputChangeHandler = (value: DateRangePickerProps.Value | null) => {
    debug(`DelegationCreatePanel() dateRangeInputChangeHandler() value is ${JSON.stringify(value)}`);
    setVisitDateRange(value);
    if (value?.type == 'absolute') {
      setValidFromDateInput(value.startDate);
      setInvalidFromDate(!isFromDateValid(new Date(`${value.startDate} 00:00:00`), hoursExtFromDate));
      setValidThroughDateInput(value.endDate);
      setInvalidFromDate(!isThroughDateValid(new Date(`${value.startDate} 00:00:00`), new Date(`${value.endDate} 00:00:00`)));
    }
    if (value?.type == 'relative') {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      debug(`DelegationCreatePanel() dateRangeInputChangeHandler() fromDate is ${fromDate}`);
      setValidFromDateInput(`${fromDate.getFullYear()}-${(fromDate.getMonth() + 1).toString().padStart(2, '0')}-${fromDate.getDate().toString().padStart(2, '0')}`);
      const throughDate = new Date();
      let factor = 1;
      switch (value.unit) {
        case ('day'):
          factor = Math.abs(value.amount) * 1;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case ('month'):
          factor = Math.abs(value.amount);
          debug(`DelegationCreatePanel() dateRangeInputChangeHandler() ${throughDate.getFullYear()} ${throughDate.getMonth()} ${throughDate.getDate()}`);
          const dateUTC = Date.UTC(throughDate.getFullYear(), throughDate.getMonth() + factor, throughDate.getDate());
          debug(`DelegationCreatePanel() dateRangeInputChangeHandler() dateUTC is ${dateUTC}`);
          throughDate.setTime(dateUTC);
          break;
        case ('week'):
          factor = Math.abs(value.amount) * 7;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case ('year'):
          factor = Math.abs(value.amount) * 365;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
      }
      debug(`DelegationCreatePanel() dateRangeInputChangeHandler() throughDate is ${throughDate}`);
      const throughDateInput = `${throughDate.getFullYear()}-${(throughDate.getMonth() + 1).toString().padStart(2, '0')}-${throughDate.getDate().toString().padStart(2, '0')}`;
      setValidThroughDateInput(throughDateInput);
      if (fromDate !== null) setInvalidFromDate(!isFromDateValid(fromDate, hoursExtFromDate));
      if (fromDate !== null && throughDate !== null) setInvalidThroughDate(throughDateInput == '' ? false : !isThroughDateValid(fromDate, throughDate));
    }
  };

  const delegateesQuery = useQuery({
    queryKey: ['listDelegationsByDelegatorID'],
    queryFn: () => queryDelegatees(props.delegatorId),
    retry: 3
  });

  const getDelegatesToPurge = (delegatee_id: string | null |undefined): APIt.Delegation[] | undefined =>{
  return delegateesQuery?.data?.filter((delegation: APIt.Delegation) => delegation.delegator_id == props.delegatorId && delegation.delegatee_id ==  delegatee_id
   && delegation.delegation_type_id == delegationTypeValue?.value! &&
  (delegation.permanent_flag != delegationPermanentValue || delegation.permanent_flag && delegationPermanentValue || delegation.start_date == validFromDateInput && delegation.end_date == validThroughDateInput));
}
  const createDelegationMutation = useMutation({
    mutationFn: async (delegation: APIt.CreateDelegationInput) => {
      let delegationsToPurge = getDelegatesToPurge(delegation.delegatee_id);
      await removeDelegations(delegationsToPurge ?? []);
      let responseData = await addDelegate(delegation);
      if (responseData?.createDelegation) {
        let delegationPrivilegeInput = await transformDelegation(responseData?.createDelegation);       
        if (delegationPrivilegeInput != null)
          viewershipAccessGrantedSendNotification(delegationPrivilegeInput);
        else{
          debug(`Email on remove delegation has not been sent delegation is ${JSON.stringify(delegation)}`);
          setShowEmailNotBeingSent(true);
        }
      }
    },
    onError: (error: any) => setErrorText(JSON.stringify(error)),
    onSuccess: () => {
      clearCreateForm();
      queryClient.invalidateQueries({ queryKey: ['listDelegationsByDelegateeID'] })
      props.saveCallback(showEmailNotBeingSent);
    },
  });

  const continueBtnHandler = async () => {
    let empDetails: APIt.EmployeeDetails | undefined;
    try {
      if (username == props.username) {
        setUsernameIsInvalid(true);
        return;
      }
      empDetails = await queryEmployeeDetails(username);
      if (empDetails) setEmployeeDetails(empDetails);
      if (!empDetails) throw new Error('invalid username');
    } catch (error) {
      setUsernameIsInvalid(true);
      return;
    }
    try {
      saveDelegation(empDetails.id);
      setWarningData(false);
      setWarningDataInfo('');
    } catch (error) {
      setErrorText(`${bundle.getMessage('error-encountered')}: ${errorText}`);
    }
  }

  const saveBtnHandler = async () => {
    let empDetails: APIt.EmployeeDetails | undefined;
    try {
      if (username == props.username) {
        setUsernameIsInvalid(true);
        return;
      }
      empDetails = await queryEmployeeDetails(username);
      if (empDetails) setEmployeeDetails(empDetails);
      if (!empDetails) throw new Error('invalid username');
    } catch (error) {
      setUsernameIsInvalid(true);
      return;
    }
    try {
      let delegationsToPurge = getDelegatesToPurge(empDetails.id)
      if (delegationsToPurge != null && delegationsToPurge.length > 0) {
        setWarningData(true);
        setWarningDataInfo(bundle.getMessage('item-overriden'));
        return;
      }
      saveDelegation(empDetails.id);
    } catch (error) {
      setErrorText(`${bundle.getMessage('error-encountered')}: ${errorText}`);
    }
  };

  const saveDelegation = (employeeId: string) =>{
    const newDelegation: APIt.CreateDelegationInput = { 
      created_by: props.username,        
      delegatee_id: employeeId,
      delegator_id: props.delegatorId,
      delegation_type_id: delegationTypeValue?.value!,
      end_date: !delegationPermanentValue ? `${validThroughDateInput} 00:00:00` : undefined ,        
      justification: delegationJustificationValue,
      id: uuid.v4(),
      permanent_flag: delegationPermanentValue,
      start_date: !delegationPermanentValue ? `${validFromDateInput} 00:00:00` : undefined,            
      updated_by: props.username
    }
    createDelegationMutation.mutate(newDelegation);
  }

  const visitorDelegationPermanentFieldOnChangeHandler = (detail: any) => {
    setDelegationPermanentValue(detail.checked);
    setDelegationStartDateValue(null);
    setDelegationEndDateValue(null);
  };  

  useEffect(() => {
    setCurrentUserEmployeeDetails(queryClient.getQueryData(['currentUserEmployeeDetails']));
  }, [queryClient.getQueryData(['currentUserEmployeeDetails'])]);  

  if (isBundleLoading) return <Spinner/>;  
  
  return (
    <div id='DelegationsCreateDiv'>
      <form onSubmit={e => {
        e.preventDefault();
        saveBtnHandler();
      }}>
        <Form
          actions={
            <Box float='right'>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='DelegationsCancelButton'
                  formAction='none'
                  onClick={cancelBtnHandler}
                >
                  {bundle.getMessage('cancel')}
                </Button>
                {
                  !warningData &&
                  <Button
                    data-testid='DelegationsCreateAddDelegationButton'
                    disabled={
                      (
                        (
                          invalidFromDate
                          || invalidThroughDate
                          || usernameIsInvalid
                        )
                        ||
                        (
                          !delegationPermanentValue
                          && (validFromDateInput == null || validThroughDateInput == null)
                        )
                      )
                    }
                    loading={createDelegationMutation.status == 'loading'}
                    variant='primary'
                  >
                    {bundle.getMessage('add-viewer')}
                  </Button>
                }
                {
                  warningData &&
                  <Button
                    data-testid='DelegationsContinueAddDelegationButton'
                    disabled={
                      (
                        (
                          invalidFromDate
                          || invalidThroughDate
                          || usernameIsInvalid
                        )
                        ||
                        (
                          !delegationPermanentValue
                          && (validFromDateInput == null || validThroughDateInput == null)
                        )
                      )
                    }
                    loading={createDelegationMutation.status == 'loading'}
                    onClick={continueBtnHandler}
                    variant='primary'
                  >
                    {bundle.getMessage('continue')}
                  </Button>

                }

              </SpaceBetween>
            </Box>
          }
          errorText={errorText}
        >
          <SpaceBetween size='s' direction='vertical'>
            <FormField label={bundle.getMessage('username')}>
              <Input
                data-testid='DelegationCreateUsername'
                onChange={event => {
                  setUsernameIsInvalid(false);
                  setUsername(event.detail.value);
                }}
                inputMode='text'
                invalid={usernameIsInvalid}
                value={username}
              />
            </FormField>
            <FormField label={bundle.getMessage('delegation-type')}>
              <Select
                data-testid='DelegationCreateTypeSelect'
                onChange={({ detail }) => setDelegationTypeValue(detail.selectedOption)}
                options={props.delegationTypeOptions}
                placeholder={bundle.getMessage('select-delegation-type')}
                selectedOption={delegationTypeValue}
              />
            </FormField>
            <FormField label={bundle.getMessage('justification')}>
              <Textarea
                data-testid='DelegationCreateJustificationTextarea'
                onChange={({ detail }) => setDelegationJustification(detail.value)}
                value={delegationJustificationValue || ''}
                placeholder={bundle.getMessage('enter-justification')}
              />
            </FormField>
            <SpaceBetween size='xs' direction='horizontal'>
              <FormField label={bundle.getMessage('permanent')}>
                <Checkbox
                  data-testid='DelegationCreatePermanentCheckbox'
                  onChange={({ detail }) => visitorDelegationPermanentFieldOnChangeHandler(detail)}
                  checked={delegationPermanentValue}
                />
              </FormField>
              <FormField label={bundle.getMessage('days-site-time')}>
                <DateRangePicker
                  data-testid='DelegationDetailsValidDateRangePicker'
                  dateOnly
                  disabled={delegationPermanentValue}
                  hideTimeOffset
                  i18nStrings={{
                    todayAriaLabel: bundle.getMessage('today'),
                    nextMonthAriaLabel: bundle.getMessage('next-month'),
                    previousMonthAriaLabel: bundle.getMessage('previous-month'),
                    customRelativeRangeDurationLabel: bundle.getMessage('duration'),
                    customRelativeRangeDurationPlaceholder: bundle.getMessage('enter-duration'),
                    customRelativeRangeOptionLabel: bundle.getMessage('custom-range'),
                    customRelativeRangeOptionDescription: bundle.getMessage('set-a-custom-range-in-the-future'),
                    customRelativeRangeUnitLabel: bundle.getMessage('unit-of-time'),
                    formatRelativeRange: (rr): string => {
                      return (
                        `${bundle.getMessage('next')} ${Math.abs(rr.amount)} ${bundle.formatMessage(rr.unit, { amount: rr.amount.toString() })}`
                      );
                    },
                    formatUnit: (e, t) => (1 === t ? e : `${bundle.formatMessage(e, { amount: t.toString() })}`),
                    dateTimeConstraintText: '',
                    relativeModeTitle: bundle.getMessage('relative-range'),
                    absoluteModeTitle: bundle.getMessage('absolute-range'),
                    relativeRangeSelectionHeading: bundle.getMessage('choose-a-range'),
                    startDateLabel: bundle.getMessage('start-date'),
                    endDateLabel: bundle.getMessage('end-date'),
                    startTimeLabel: bundle.getMessage('start-time'),
                    endTimeLabel: bundle.getMessage('end-time'),
                    clearButtonLabel: bundle.getMessage('clear-and-dismiss'),
                    cancelButtonLabel: bundle.getMessage('cancel'),
                    applyButtonLabel: bundle.getMessage('apply'),
                  }}
                  invalid={!delegationPermanentValue && (invalidFromDate || invalidThroughDate)}
                  isDateEnabled={date => {
                    const [month, day, year] = [
                      (date.getMonth() + 1).toString().padStart(2, '0'),
                      date.getDate().toString().padStart(2, '0'),
                      date.getFullYear(),
                    ];
                    const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
                    return isFromDateValid(convertedDate, hoursExtFromDate);
                  }}
                  isValidRange={(value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
                    const result: DateRangePickerProps.ValidationResult = { valid: true };
                    return result;
                  }}
                  onChange={({ detail }) => dateRangeInputChangeHandler(detail.value)}
                  placeholder='YYYY/MM/DD'
                  relativeOptions={[
                    { key: 'tomorrow', amount: -1, unit: 'day', type: 'relative' },
                    { key: 'next-7-days', amount: -7, unit: 'day', type: 'relative' },
                    { key: 'next-14-days', amount: -14, unit: 'day', type: 'relative' },
                    { key: 'next-365-days', amount: -365, unit: 'day', type: 'relative' },
                  ]}
                  value={visitDateRange}
                />
              </FormField>
            </SpaceBetween>
            {warningData
              &&
              <>
                <Flashbar
                  items={
                    [
                      {
                        content: warningDataInfo,
                        dismissible: true,
                        onDismiss: () => {
                          setWarningData(false);
                          setWarningDataInfo('');
                        },
                        type: 'warning',
                      },
                    ]
                  }
                />
              </>
            }
          </SpaceBetween>
        </Form>
      </form>
    </div>
  );
}
