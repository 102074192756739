import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  DateRangePicker,
  DateRangePickerProps,
  Flashbar,
  FormField,
  Pagination,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TimeInput } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery } from '@tanstack/react-query'
import {
  isFromDateValid,
  isThroughDateValid,
  queryAccessLevelApproverGroups,
  queryAccessLevelsForSite,
  queryLookupTypeValueForTypeAndDescription,
  querySites
} from 'src/components/utils';
import { TVisitor } from 'src/types';
import { LookupTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import * as APIt from 'src/API';
import { accessLevelApproverGroupMembersExist } from '../../utils';
import { debug } from '../../../../utils/commonUtils';

export interface IVendorAccessDetailsRecord {
  id: number;
  accessLevelId: string | undefined;
  accessLevelName: string | undefined;
  site: string | undefined;
  validFrom: string | undefined;
  validThrough: string | undefined;
}

interface IAccessLevelOption extends SelectProps.Option {
  noApproverGroupLinked: boolean;
  noApproverGroupMembers: boolean;
  contingentWorkerSelected: boolean;
}

const defaultValidFromTime = '08:00';
const defaultValidThroughTime = '17:00';

export interface IVendorAccessDetailsTablePanelProps{
  accessLevels: IVendorAccessDetailsRecord[];
  vendors: TVisitor[];
  clear: boolean;
  escorts: APIt.EmployeeDetails[];
  detailsClearedCallback: Function;
  setAccessLevelsCallback: Function;
  username: string;
  visitorId: string | undefined;
}

export default function VendorAccessDetailsTablePanel(props: IVendorAccessDetailsTablePanelProps) {
  debug(`AccessDetailsTablePanel() props is ${JSON.stringify(props)}`);

  const [accessLevelInput, setAccessLevelInput] = useState<SelectProps.Option | null>(null);
  const [accessLevelOptions, setAccessLevelOptions] = useState<IAccessLevelOption[]>([]);
  const [adding, setAdding] = useState<boolean>(false);
  const [allItems, setAllItems] = useState<IVendorAccessDetailsRecord[]>(props.accessLevels);
  const [checkAccessLevelApproverGroups, setCheckAccessLevelApproverGroups] = useState<boolean>(false);
  const [duplicateInputAccessLevelFound, setDuplicateInputAccessLevelFound] = useState<boolean>(false);
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [id, setId] = useState<number>(1);
  const [invalidFromDate, setInvalidFromDate] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [siteInput, setSiteInput] = useState<SelectProps.Option | null>(null);
  const [selectedItems, setSelectedItems] = useState<IVendorAccessDetailsRecord[]>([]);
  const [validFromDateInput, setValidFromDateInput] = useState<string>('');
  const [validFromTimeInput, setValidFromTimeInput] = useState<string>(defaultValidFromTime);
  const [validThroughDateInput, setValidThroughDateInput] = useState<string>('');
  const [validThroughTimeInput, setValidThroughTimeInput] = useState<string>(defaultValidThroughTime);
  const [vendorDayPassMaxDays, setVendorDayPassMaxDays] = React.useState<number>(7);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVendorAccess.AccessDetails.TablePanel');

  const siteRef = useRef<HTMLInputElement>(null);

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const duplicateInputAccessLevel = (): boolean => {
    debug(`duplicateInputAccessLevel()`);
    const foundItem = allItems.find(i => {
      debug(`duplicateInputAccessLevel() ${i.accessLevelId} == ${accessLevelInput?.value}`);
      const result = i.accessLevelId == accessLevelInput?.value;
      debug(`duplicateInputAccessLevel() result is ${result}`);
      return result;
    });
    debug(`duplicateInputAccessLevel() foundItem is ${JSON.stringify(foundItem)}`);
    if (foundItem) return true;
    return false;
  };

  const addHandler = () => {
    setAdding(true);
    let i = id + 1;
    setId(i);
    const newItems = [];
    let invalidDate = false;
    if (!isFromDateValid(new Date(`${validFromDateInput} ${validFromTimeInput}`), hoursExtFromDate)) {
      setInvalidFromDate(true);
      invalidDate = true;
    }
    if (!isThroughDateValid(
        new Date(`${validFromDateInput} ${validFromTimeInput}`),
        new Date(`${validThroughDateInput} ${validThroughTimeInput}`),
        vendorDayPassMaxDays))
    {
      setInvalidThroughDate(true);
      invalidDate = true;
    }
    if (invalidDate) {
      setAdding(false);
      return;
    }
    if (!duplicateInputAccessLevel())
      newItems.push(
        {
          accessLevelId: accessLevelInput?.value,
          accessLevelName: accessLevelInput?.label,
          id: i,
          site: siteInput?.value,
          validFrom: `${validFromDateInput} ${validFromTimeInput}`,
          validThrough: `${validThroughDateInput} ${validThroughTimeInput}`
        }
      );
    else
      setDuplicateInputAccessLevelFound(true);
    props.setAccessLevelsCallback([...allItems, ...newItems]);
    setAdding(false);
  };

  const dateRangeInputChangeHandler = (value: DateRangePickerProps.Value | null) => {
    debug(`dateRangeInputChangeHandler() value is ${JSON.stringify(value)}`);
    setVisitDateRange(value);
    if (value?.type == 'absolute') {
      setValidFromDateInput(value.startDate);
      setInvalidFromDate(!isFromDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), hoursExtFromDate));
      setValidThroughDateInput(value.endDate);
      setInvalidFromDate(!isThroughDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), new Date(`${value.endDate} ${validThroughTimeInput}`)));
    }
    if (value?.type == 'relative') {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      debug(`dateRangeInputChangeHandler() fromDate is ${fromDate}`);
      setValidFromDateInput(`${fromDate.getFullYear()}-${(fromDate.getMonth()+1).toString().padStart(2, '0')}-${fromDate.getDate().toString().padStart(2, '0')}`);
      const throughDate = new Date();
      let factor = 1;
      switch(value.unit) {
        case('day'):
          factor = Math.abs(value.amount)*1;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('month'):
          factor = Math.abs(value.amount);
          debug(`dateRangeInputChangeHandler() ${throughDate.getFullYear()} ${throughDate.getMonth()} ${throughDate.getDate()}`);
          const dateUTC = Date.UTC(throughDate.getFullYear(), throughDate.getMonth() + factor, throughDate.getDate());
          debug(`dateRangeInputChangeHandler() dateUTC is ${dateUTC}`);
          throughDate.setTime(dateUTC);
          break;
        case('week'):
          factor = Math.abs(value.amount)*7;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('year'):
          factor = Math.abs(value.amount)*365;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
      }
      debug(`dateRangeInputChangeHandler() throughDate is ${throughDate}`);
      const throughDateInput = `${throughDate.getFullYear()}-${(throughDate.getMonth()+1).toString().padStart(2, '0')}-${throughDate.getDate().toString().padStart(2, '0')}`;
      setValidThroughDateInput(throughDateInput);
      if (fromDate !== null) setInvalidFromDate(!isFromDateValid(fromDate, hoursExtFromDate));
      if (fromDate !== null && throughDate !== null) setInvalidThroughDate(throughDateInput == '' ? false : !isThroughDateValid(fromDate, throughDate));
    }
  };

  const fromDateInputChangeHandler = (value: string) => {
    setValidFromDateInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${value} ${validFromTimeInput}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${value} ${validFromTimeInput}`),
          new Date(`${validThroughDateInput} ${validThroughTimeInput}`)));
  };

  const fromTimeInputChangeHandler = (value: string) => {
    setValidFromTimeInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${validFromDateInput} ${value}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${value}`),
          new Date(`${validThroughDateInput} ${validThroughTimeInput}`)));
  };

  const throughDateInputChangeHandler = (value: string) => {
    setValidThroughDateInput(value)
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${value} ${validThroughTimeInput}`)));
  };

  const throughTimeInputChangeHandler = (value: string) => {
    setValidThroughTimeInput(value)
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${validThroughDateInput} ${value}`)));
  };

  const removeSelected = () => {
    const currentItems = allItems;
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.id == al.id));
    setSelectedItems([]);
    setAllItems(newItems);
    props.setAccessLevelsCallback(newItems);
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as IVendorAccessDetailsRecord[]);
  };

  const sitesQuery = useQuery({
    queryKey: ['sites'],
    queryFn: () => querySites(true),
    retry: 3
  });

  const accessLevelsForSiteQuery = useQuery({
    queryKey: ['accessLevelsForSiteRequestVendorAccess'],
    queryFn: () => queryAccessLevelsForSite(siteInput?.value),
    onSuccess: async (data) => {
      if (!accessLevelsForSiteQuery.data) return [];
      setCheckAccessLevelApproverGroups(true);
      const options = await Promise.all(data.map(async (al): Promise<any> => {
        debug(`accessLevelsForSiteQuery() onSuccess() al is ${JSON.stringify(al)}`);
        try {
          const accessLevelApproverGroups = (await queryAccessLevelApproverGroups(al.AccessLevelName)) || [];
          debug(`accessLevelsForSiteQuery() onSuccess() accessLevelsApproverGroups is ${JSON.stringify(accessLevelApproverGroups)}`);
          let accessLevelNoApproverGroupMembers = false;
          if (accessLevelApproverGroups?.length > 0) accessLevelNoApproverGroupMembers = !(await accessLevelApproverGroupMembersExist(accessLevelApproverGroups));
          debug(`accessLevelsForSiteQuery() onSuccess() accessLevelNoApproverGroupMembers is ${JSON.stringify(accessLevelNoApproverGroupMembers)}`);
          const contingentWorkerSelected = true;
          debug(`accessLevelsForSiteQuery() onSuccess() contingentWorkerSelected is ${contingentWorkerSelected}`);
          return {
            label: al.AccessLevelName,
            value: al.AccessLevelID?.toString(),
            contingentWorkerSelected: contingentWorkerSelected,
            disabled:
              accessLevelApproverGroups.length == 0
              || al.AccessLevelName.includes('-4-')
              || al.AccessLevelName.includes('-5-')
              || accessLevelNoApproverGroupMembers,
            noApproverGroupLinked: accessLevelApproverGroups.length === 0,
            noApproverGroupMembers: accessLevelNoApproverGroupMembers,
          };
        } catch(error) {
          return {
            label: al.AccessLevelName,
            value: al.AccessLevelID?.toString(),
            disabled: true,
          };
        }
      }));
      debug(`accessLevelsForSiteQuery() onSuccess() options is ${JSON.stringify(options)}`);
      setAccessLevelOptions(options);
      setCheckAccessLevelApproverGroups(false);
    },
    retry: 3
  });

  const accessLevels = () => {
    debug(`AccessDetailsTablePanel() accessLevelOptions is ${JSON.stringify(accessLevelOptions)}`);
    const values = [];
    const available = {
      label: bundle.getMessage('available-access-levels'),
      options: accessLevelOptions.filter(al => !al.disabled),
    };
    const unavailableContingentWorker = {
      label: bundle.getMessage('unavailable-contingent-worker'),
      options: accessLevelOptions.filter(al => al.disabled && (al.label?.includes('-4-') || al.label?.includes('-5')) && al.contingentWorkerSelected),
    };
    const unavailableNoApproverGroupLinked = {
      label: bundle.getMessage('unavailable-no-approver-group-linked'),
      options: accessLevelOptions.filter(al => al.disabled && al.noApproverGroupLinked),
    };
    const unavailableNoApproverGroupMembers = {
      label: bundle.getMessage('unavailable-no-approver-group-members'),
      options: accessLevelOptions.filter(al => al.disabled && al.noApproverGroupMembers),
    };
    if (available.options.length > 0) values.push(available);
    if (unavailableContingentWorker.options.length > 0) values.push(unavailableContingentWorker);
    if (unavailableNoApproverGroupLinked.options.length > 0) values.push(unavailableNoApproverGroupLinked);
    if (unavailableNoApproverGroupMembers.options.length > 0) values.push(unavailableNoApproverGroupMembers);
    return(values);
  };

  useEffect(() => {
    setAccessLevelOptions([]);
    setAccessLevelInput(null);
    accessLevelsForSiteQuery.refetch();
  }, [siteInput])

  useEffect(() => {
    setSiteInput(null);
    setAccessLevelInput(null);
    setVisitDateRange(null);
    setValidFromDateInput('');
    setValidFromTimeInput(defaultValidFromTime);
    setValidThroughDateInput('');
    setValidThroughTimeInput(defaultValidThroughTime);
    setInvalidFromDate(false);
    setInvalidThroughDate(false);
    props.detailsClearedCallback();
  }, [props.clear])

  const siteInputHandler = (detail: SelectProps.ChangeDetail) => {
    setAccessLevelInput(null);
    setSiteInput(detail.selectedOption);
  };

  const FlashMessageDuplicateAccessLevelInput = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: true,
            content: bundle.getMessage('duplicate-access-level-input'),
            onDismiss: () => {setDuplicateInputAccessLevelFound(false)}
          },
        ]
      }
    />
  );

  useEffect(() => {
    setAllItems(props.accessLevels);
  }, [props.accessLevels])

  useEffect(() => {
    accessLevelsForSiteQuery.refetch();
  }, [props.escorts]);

  useEffect(() => {
    if (props.escorts.length > 0
      && siteRef
      && !siteInput
      && props.accessLevels.length == 0
    ) {
      siteRef.current?.focus();
    }
  }, [props.escorts, props.accessLevels, siteRef]);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.VendorDayPassMaxDays)
    .then((v) => setVendorDayPassMaxDays(parseInt(v.value)));
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.HoursExtensionFromDateFormValidation)
    .then((v) => setHoursExtFromDate(parseInt(v.value)));
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {duplicateInputAccessLevelFound && <FlashMessageDuplicateAccessLevelInput/>}
    <div id='RequestVendorAccessAccessDetailsTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        header={
          <>
            <Header
              counter={`(${itemsCount().toString()})`}
            >
              {bundle.getMessage('access-details')}
            </Header>
            <Box float='left' margin='s'>
              <SpaceBetween direction='horizontal' size='s'>
                <FormField label={bundle.getMessage('site')} stretch={true}>
                  <div style={{width: 120}}>
                    <Select
                      data-testid='RequestVendorAccessAccessDetailsSitesSelect'
                      filteringType='auto'
                      loadingText={bundle.getMessage('loading-sites')}
                      onChange={({ detail }) => siteInputHandler(detail)}
                      options={
                        sitesQuery.data?.map(s => {
                          return {label: s.SiteCode, value: s.SiteCode};
                        })
                      }
                      placeholder={bundle.getMessage('select-a-site')}
                      ref={siteRef}
                      selectedOption={siteInput}
                      statusType={sitesQuery.isLoading || sitesQuery.isFetching? 'loading' : 'finished'}
                    />
                  </div>
                </FormField>
                <FormField label={bundle.getMessage('access-level')} stretch={true}>
                  <div style={{width: 300}}>
                    <Select
                      data-testid='RequestVendorAccessAccessDetailsAccessLevelSelect'
                      disabled={!siteInput}
                      filteringType='auto'
                      loadingText={bundle.getMessage('loading-site-access-levels')}
                      onChange={({ detail }) => setAccessLevelInput(detail.selectedOption)}
                      options={accessLevels()}
                      placeholder={bundle.getMessage('select-an-access-level')}
                      selectedOption={accessLevelInput}
                      statusType={
                        accessLevelsForSiteQuery.isLoading || accessLevelsForSiteQuery.isFetching || checkAccessLevelApproverGroups
                        ? 'loading'
                        : 'finished'}
                    />
                  </div>
                </FormField>
                <FormField label={bundle.getMessage('days-site-time')}>
                  <DateRangePicker
                    data-testid='RequestVendorAccessAccessDetailsValidDateRangePicker'
                    dateOnly
                    hideTimeOffset
                    i18nStrings={{
                      todayAriaLabel: bundle.getMessage('today'),
                      nextMonthAriaLabel: bundle.getMessage('next-month'),
                      previousMonthAriaLabel: bundle.getMessage('previous-month'),
                      customRelativeRangeDurationLabel: bundle.getMessage('duration'),
                      customRelativeRangeDurationPlaceholder: bundle.getMessage('enter-duration'),
                      customRelativeRangeOptionLabel: bundle.getMessage('custom-range'),
                      customRelativeRangeOptionDescription: bundle.getMessage('set-a-custom-range-in-the-future'),
                      customRelativeRangeUnitLabel: bundle.getMessage('unit-of-time'),
                      formatRelativeRange: (rr): string => {
                        return(
                          `${bundle.getMessage('next')} ${Math.abs(rr.amount)} ${bundle.formatMessage(rr.unit, { amount: rr.amount.toString() })}`
                        );
                      },
                      formatUnit: (e, t) => (1 === t ? e : `${bundle.formatMessage(e, { amount: t.toString() })}`),
                    dateTimeConstraintText: '',
                      relativeModeTitle: bundle.getMessage('relative-range'),
                      absoluteModeTitle: bundle.getMessage('absolute-range'),
                      relativeRangeSelectionHeading: bundle.getMessage('choose-a-range'),
                      startDateLabel: bundle.getMessage('start-date'),
                      endDateLabel: bundle.getMessage('end-date'),
                      startTimeLabel: bundle.getMessage('start-time'),
                      endTimeLabel: bundle.getMessage('end-time'),
                      clearButtonLabel: bundle.getMessage('clear-and-dismiss'),
                      cancelButtonLabel: bundle.getMessage('cancel'),
                      applyButtonLabel: bundle.getMessage('apply'),
                    }}
                    invalid={invalidFromDate || invalidThroughDate}
                    isDateEnabled={date => {
                      const [month, day, year] = [
                        (date.getMonth() + 1).toString().padStart(2, '0'),
                        date.getDate().toString().padStart(2, '0'),
                        date.getFullYear(),
                      ];
                      const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
                      return isFromDateValid(convertedDate, hoursExtFromDate);
                    }}
                    isValidRange={(value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
                      const result: DateRangePickerProps.ValidationResult = { valid: true };
                      return result;
                    }}
                    onChange={({ detail }) => dateRangeInputChangeHandler(detail.value)}
                    placeholder='YYYY/MM/DD'
                    rangeSelectorMode='absolute-only'
                    relativeOptions={[
                      { key: 'tomorrow', amount: -1, unit: 'day', type: 'relative' },
                      { key: 'next-7-days', amount: -7, unit: 'day', type: 'relative' },
                      { key: 'next-14-days', amount: -14, unit: 'day', type: 'relative' },
                      { key: 'next-365-days', amount: -365, unit: 'day', type: 'relative' },
                    ]}
                    value={visitDateRange}
                  />
                </FormField>
                <FormField label={bundle.getMessage('from-time')}>
                  <div style={{width: 100}}>
                    <TimeInput
                      data-testid='RequestVendorAccessAccessDetailsDateFromTimeInput'
                      format='hh:mm'
                      onChange={event => fromTimeInputChangeHandler(event.detail.value)}
                      invalid={invalidFromDate}
                      placeholder='hh:mm'
                      value={validFromTimeInput}
                    />
                  </div>
                </FormField>
                <FormField label={bundle.getMessage('to-time')}>
                  <div style={{width: 100}}>
                    <TimeInput
                      data-testid='RequestVendorAccessAccessDetailsDateThroughTimeInput'
                      format='hh:mm'
                      invalid={invalidThroughDate}
                      onChange={event => throughTimeInputChangeHandler(event.detail.value)}
                      placeholder='hh:mm'
                      value={validThroughTimeInput}
                    />
                  </div>
                </FormField>
                <div style={{position:'relative', bottom: '-25px'}}>
                  <Button
                    data-testid='RequestVendorAccessAccessDetailsAddButton'
                    disabled={
                      !siteInput
                      || !accessLevelInput
                      || !validFromDateInput || !validFromTimeInput || !validThroughDateInput || !validThroughTimeInput || invalidFromDate || invalidThroughDate
                    }
                    loading={adding}
                    onClick={addHandler}
                    variant='normal'
                  >
                    {bundle.getMessage('add')}
                  </Button>
                </div>
              </SpaceBetween>
            </Box>
          </>
        }
        footer={
          <>
          <SpaceBetween direction='vertical' size='xs'>
            <Box float='right'>
              <Button
                disabled={selectedItems.length == 0}
                onClick={removeSelected}
                variant='primary'
              >
                {bundle.getMessage('remove-selected')}
              </Button>
            </Box>
          </SpaceBetween>
          </>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
    </div>
    </>
  );
}
