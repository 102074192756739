import React from 'react';
import ApproverGroupsTablePanel from './TablePanel';
import { debug } from 'src/utils/commonUtils';

interface IApproverGroupsProps {
  username: string;
}

export default function ApproverGroups(props: IApproverGroupsProps) {
  debug(`ApproverGroups() props is ${JSON.stringify(props)}`);

  return(
    <ApproverGroupsTablePanel username={props.username} />
  );
}


