import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "src/API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.VisitorAction>[] = [
  {
    id: 'actionType',
    header: <TranslateHeader value={'action'} />,
    cell: item => item.action_type,
    sortingField: 'action_type'
  },
  {
    id: 'badgeNum',
    header: <TranslateHeader value={'badge-num'} />,
    cell: item => {
      let additionalInfo: any = {};
      try {
        additionalInfo = JSON.parse(JSON.parse(item.additional_info));
      } catch(error) {
        console.error(error);
      }
      return additionalInfo?.badge_num || '';
    },
    sortingField: 'additional_info'
  },
  {
    id: 'time',
    header: <TranslateHeader value={'time'} extraText='(UTC)' />,
    cell: item => item.created?.slice(0,16),
    sortingField: 'created'
  },
  {
    id: 'local-time',
    header: <TranslateHeader value={'local-time'} />,
    cell: item => {
      let dt = new Date(item.created?.slice(0,16) + 'Z')
      return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
    },
    sortingField: 'created'
  },
];

function TranslateHeader({ value, extraText }: { value: string, extraText?: string | undefined }) {
  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.VisitorActions.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)} {extraText}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
