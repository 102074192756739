import React, { useState, useEffect } from 'react';
import { Header, Pagination, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult } from '@tanstack/react-query';
import { debug } from 'src/utils/commonUtils';

export interface VisitorActionsTablePanelProps {
  visitorActionsQuery: UseQueryResult<APIt.VisitorAction[]>;
}

export default function VisitorActionsTablePanel(props: VisitorActionsTablePanelProps ) {
  debug(`VisitorActionsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAction[]>(props.visitorActionsQuery.data || []);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.VisitorActions.TablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-actions-found') : 'No Actions Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  useEffect(() => {
    if (props.visitorActionsQuery.data) setAllItems(props.visitorActionsQuery.data);
  }, [props.visitorActionsQuery.data]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='visitorActionsTableDiv'>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-actions')}
            filteringPlaceholder={bundle.getMessage('find-actions')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header counter={`(${itemsCount().toString()})`}>
            {bundle.getMessage('actions')}
          </Header>
        }
        items={items}
        loading={props.visitorActionsQuery.isLoading}
        loadingText={bundle.getMessage('loading-actions')}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
        trackBy='id'
        variant='embedded'
      />
    </div>
  );
}
