import * as APIt from "src/API";
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import React from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import { useBundle } from "@amzn/react-arb-tools";

const columns = [
  'request_id',
  'request_site_id',
  'request_reason',
  'requestor_id',
  'request_start_date',
  'request_end_date',
  'request_status',
  'request_created',
  'request_created_by',
  'request_updated',
  'request_updated_by',
  'request_escort_id',
  'request_escort_person_id',
  'request_escort_created',
  'request_escort_created_by',
  'request_escort_updated',
  'request_escort_updated_by',
  'visitor_person_id',
  'visitor_id',
  'visitor_type',
  'visitor_first_name',
  'visitor_last_name',
  'visitor_company',
  'visitor_email',
  'visitor_phone_number',
  'visitor_status',
  'visitor_created',
  'visitor_created_by',
  'visitor_updated',
  'visitor_updated_by',
  'visitor_access_level_id',
  'visitor_access_level_name',
  'visitor_access_level_start_date',
  'visitor_access_level_end_date',
  'visitor_access_level_status',
  'visitor_access_level_created',
  'visitor_access_level_created_by',
  'visitor_access_level_updated',
  'visitor_access_level_updated_by',
  'visitor_access_level_approval_id',
  'visitor_access_level_approval_person_id',
  'visitor_access_level_approval_status',
  'visitor_access_level_denial_reason',
  'visitor_access_level_approval_created',
  'visitor_access_level_approval_created_by',
  'visitor_access_level_approval_updated',
  'visitor_access_level_approval_updated_by',
] as const;

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.AccessRequestDetails>[] = columns.map((c, i) => {
  return(
    {
      id: c,
      header: <TranslateHeader value={c.replace(/_/g,'-')} />,
      cell: (item: APIt.AccessRequestDetails) => item[c],
      sortingField: c
    });
});

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Management.Support.AccessRequestDetails.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
