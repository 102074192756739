import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  Flashbar,
  Form,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Textarea
} from '@amzn/awsui-components-react';
import { CreatedVisitorAssetInterface } from './TablePanel';
import { CancelCreateVisitorAssetInterface } from './TablePanel';
import { useMutation, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import * as APIt from '../../../API';
import { createVisitorAsset } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { queryEmployeeDetails, queryLookupTypeValueForTypeAndDescription, queryLookupTypeValueForTypeAndValue, sqlEscapeString } from 'src/components/utils';
import { ApprovalStatus, LookupTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { TVisitorAsset, TVisitorAssetWarningData, VisitorAssetWarningSchema } from 'src/types';
import { queryVisitor } from '../utils';
import { debug } from 'src/utils/commonUtils';

interface SelectOptionInterface {
  label: string;
  value: string;
}

export interface VisitorAssetCreatePanelPropsInterface {
  assetTypeOptions: SelectOptionInterface[];
  cancelCreateCallback: CancelCreateVisitorAssetInterface;
  disableAutoApproval: boolean;
  saveCallback: CreatedVisitorAssetInterface;
  sitesQuery: UseQueryResult<APIt.Site[] | null, unknown>;
  username: string;
  visitorId: string | undefined;
}

export const VisitorAssetCreatePanel = (props: VisitorAssetCreatePanelPropsInterface) => {
  debug(`VisitorAssetCreatePanel() props is ${JSON.stringify(props)}`);

  const queryClient = useQueryClient();

  const [addAssetProcessing, setAddAssetProcessing] = useState<boolean>(false);
  const [assetTypeValue, setAssetTypeValue] = useState<SelectProps.Option | null>(null);
  const [assetDescriptionValue, setAssetDescriptionValue] = useState<string | null>(null);
  const [assetEndDateValue, setAssetEndDateValue] = useState<string | null>(null);
  const [assetMakeValue, setAssetMakeValue] = useState<string | null>(null);
  const [assetModelValue, setAssetModelValue] = useState<string | null>(null);
  const [assetPermanentValue, setAssetPermanentValue] = useState<boolean>(false);
  const [assetSerialNumValue, setAssetSerialNumValue] = useState<string| null>(null);
  const [assetStartDateValue, setAssetStartDateValue] = useState<string | null>(null);
  const [currentUserEmployeeDetails, setCurrentUserEmployeeDetails] =
    useState<APIt.EmployeeDetails | undefined>(queryClient.getQueryData(['currentUserEmployeeDetails']));
  const [duplicateVisitorAssets, setDuplicateVisitorAssets] = useState<APIt.VisitorAsset[]>([]);
  const [employeeDetails, setEmployeeDetails] = useState<APIt.EmployeeDetails | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [minJobLevelForAutoApproval, setMinJobLevelForAutoApproval] = React.useState<number | null>(null);
  const [excludedJobLevelsForAutoApproval, setExcludedJobLevelsForAutoApproval] = React.useState<number[]>([]);
  const [selectedSiteName, setSelectedSiteName] = React.useState<string | null>(null);
  const [selectedSiteNameIsInvalid, setSelectedSiteNameIsInvalid] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>(props.username || '');
  const [usernameIsInvalid, setUsernameIsInvalid] = React.useState<boolean>(false);
  const [warningData, setWarningData] = useState<boolean>(false);
  const [warningDataInfo, setWarningDataInfo] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Assets.Create');

  const clearCreateForm = () => {
    setAssetTypeValue(null);
    setAssetDescriptionValue(null);
    setAssetSerialNumValue(null);
    setAssetPermanentValue(false);
    setAssetStartDateValue(null);
    setAssetEndDateValue(null);
    setErrorText(null);
    setSelectedSiteName(null);
    setUsername(props.username);
    setWarningData(false);
    setWarningDataInfo('');
  };

  const createVisitorAssetMutation = useMutation({
    mutationFn: async (params: { visitorAsset: APIt.VisitorAsset, disableAutoApproval: boolean }) => {
      await createVisitorAsset(params.visitorAsset, params.disableAutoApproval);
    },
    onError: (error: any) => setErrorText(JSON.stringify(error)),
    onSuccess: () => {
      clearCreateForm();
      queryClient.invalidateQueries({ queryKey: ['visitorAssets'] })
      props.saveCallback();
    },
  });

  const cancelBtnHandler = () => {
    clearCreateForm();
    setErrorText(null);
    props.cancelCreateCallback();
  };

  const saveBtnHandler = async () => {
    setAddAssetProcessing(true);
    debug(`VisitorAssetCreatePanel() addVisitorAssets() warningData is ${warningData}`);
    if (!warningData) {
      const visitorAssetForWarningCheck: TVisitorAsset = 
        {
          description: assetDescriptionValue ?? '',
          make: assetMakeValue ?? '',
          model: assetModelValue ?? '',
          permanentFlag: assetPermanentValue ? 'Y' : 'N',
          serialNum: assetSerialNumValue ?? '',
          site: selectedSiteName ?? '',
          type: assetTypeValue?.label ?? '',
          username: username,
          validFromDate: assetStartDateValue ?? '',
          validThroughDate: assetEndDateValue ?? '',
        };
      const parseWarningResults = await VisitorAssetWarningSchema.safeParseAsync(visitorAssetForWarningCheck) as TVisitorAssetWarningData;
      debug(`VisitorAssetCreatePanel() addVisitorAssets() parseWarningResults is ${JSON.stringify(parseWarningResults)}`);
      if (parseWarningResults.data.length > 0) {
        for (let result of parseWarningResults.data) {
          debug(`VisitorAssetCreatePanel() addVisitorAssets() result is ${JSON.stringify(result)}`);
          if (result.warningMessage === 'duplicate-visitor-asset') {
            setDuplicateVisitorAssets(result.duplicateVisitorAssets);
            setWarningData(true);
            setWarningDataInfo(bundle.getMessage(result.warningMessage));
            setAddAssetProcessing(false);
            return;
          }
        }
      }
    }
    let empDetails: APIt.EmployeeDetails | undefined;
    try {
      empDetails = await queryEmployeeDetails(username.trim());
      debug(`VisitorAssetCreatePanel() addVisitorAssets() emplDetails is ${JSON.stringify(empDetails)}`);
      if (empDetails) setEmployeeDetails(empDetails);
      if (!empDetails) throw new Error('invalid username');
    } catch(error) {
      setUsernameIsInvalid(true);
      setAddAssetProcessing(false);
      return;
    }
    if (empDetails.jobLevel
    && minJobLevelForAutoApproval
    && (parseInt(empDetails.jobLevel) < minJobLevelForAutoApproval || excludedJobLevelsForAutoApproval.includes(parseInt(empDetails.jobLevel)))
    && selectedSiteName == 'All') {
      setSelectedSiteNameIsInvalid(true);
      setAddAssetProcessing(false);
      return;
    }
    try {
      const site_source_system_id = await queryLookupTypeValueForTypeAndValue(LookupTypes.SiteSourceSystem, 'PACS');
      const defaultApprovalStatus = await queryLookupTypeValueForTypeAndValue(LookupTypes.AssetApprovalStatus, ApprovalStatus.PendingApproval);
      let approvalStatus = defaultApprovalStatus;
      if (empDetails
        && empDetails.jobLevel
        && minJobLevelForAutoApproval
        && parseInt(empDetails?.jobLevel) >= minJobLevelForAutoApproval
        && (!excludedJobLevelsForAutoApproval.includes(parseInt(empDetails.jobLevel))))
      {
        const approvedApprovalStatus = await queryLookupTypeValueForTypeAndValue(LookupTypes.AssetApprovalStatus, ApprovalStatus.Approved);
        if (approvedApprovalStatus) approvalStatus = approvedApprovalStatus;
      } else if (empDetails
        && empDetails.jobLevel
        && minJobLevelForAutoApproval
        && parseInt(empDetails?.jobLevel) < minJobLevelForAutoApproval
        && (!excludedJobLevelsForAutoApproval.includes(parseInt(empDetails.jobLevel)))
        && selectedSiteName == 'All') {
          setSelectedSiteNameIsInvalid(true);
          setAddAssetProcessing(false);
          return;
      }
      let visitorId = '';
      if (props.username === username) visitorId = props.visitorId || '';
      if (props.username !== username) {
        const employeeDetails = await queryEmployeeDetails(username);
        if (employeeDetails) visitorId = (await queryVisitor(employeeDetails.id, 'PACS'))?.id || '';
      }
      const newVisitorAsset: APIt.VisitorAsset = {
        __typename: 'VisitorAsset',
        approval_status: approvalStatus.value,
        approval_status_id: approvalStatus.id,
        asset_type_id: assetTypeValue!.value!,
        description: sqlEscapeString(assetDescriptionValue!),
        created_by: props.username,
        created: (new Date).toISOString(),
        end_date: assetEndDateValue ? `${assetEndDateValue} 00:00:00` : undefined,
        id: '',
        make: sqlEscapeString(assetMakeValue || ''),
        model: sqlEscapeString(assetModelValue || ''),
        permanent_flag: assetPermanentValue,
        person_id: empDetails.id,
        serial_num: sqlEscapeString(assetSerialNumValue!),
        site_id: selectedSiteName!,
        site_source_system_id: site_source_system_id.id,
        start_date: assetStartDateValue ? `${assetStartDateValue} 00:00:00` : undefined,
        updated: (new Date).toISOString(),
        updated_by: props.username,
        visitor_id: visitorId,
      }
      debug(`VisitorAssetCreatePanel() addVisitorAssets() empDetails is ${JSON.stringify(empDetails)}`);
      debug(`VisitorAssetCreatePanel() addVisitorAssets() newVisitorAsset is ${JSON.stringify(newVisitorAsset)}`);
      if (assetTypeValue) createVisitorAssetMutation.mutate({ visitorAsset: newVisitorAsset, disableAutoApproval: props.disableAutoApproval });
    } catch(error) {
      setErrorText(`${bundle.getMessage('error-encountered')}: ${errorText}`);
    }
    setAddAssetProcessing(false);
  };

  const visitorAssetTypeIdFieldOnChangeHandler = (detail: any) => {
    setAssetTypeValue(detail.selectedOption);
    setWarningData(false);
    setWarningDataInfo('');
    setDuplicateVisitorAssets([]);
  };

  const visitorAssetDescriptionFieldOnChangeHandler = (detail: any) => {
    setAssetDescriptionValue(detail.value);
  };

  const visitorAssetSerialNumFieldOnChangeHandler = (detail: any) => {
    setAssetSerialNumValue(detail.value);
    setWarningData(false);
    setWarningDataInfo('');
    setDuplicateVisitorAssets([]);
  };

  const visitorAssetPermanentFieldOnChangeHandler = (detail: any) => {
    setAssetPermanentValue(detail.checked);
    setAssetStartDateValue(null);
    setAssetEndDateValue(null);
  };

  const visitorAssetStartDateFieldOnChangeHandler = (detail: any) => {
    setAssetStartDateValue(detail.value);
  };

  const visitorAssetEndDateFieldOnChangeHandler = (detail: any) => {
    setAssetEndDateValue(detail.value);
  };

  const invalidFromDate = (): boolean => {
    const currentDate = (new Date);
    const currentDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2,'0')}-${currentDate.getDate().toString().padStart(2,'0')}`;
    return (assetStartDateValue !== null && assetStartDateValue < currentDateString);
  };

  const invalidThroughDate = (): boolean => {
    return (assetEndDateValue !== null && assetStartDateValue !== null && assetEndDateValue! < assetStartDateValue!);
  };

  const siteFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedSiteName(detail.selectedOption.label || null);
  };

  const siteOptions = (): SelectProps.Options => {
    debug(`VisitorAssetCreatePanel() siteOptions() currentUserEmployeeDetails is ${JSON.stringify(currentUserEmployeeDetails)}`);
    const options = [];
    if (currentUserEmployeeDetails
    && minJobLevelForAutoApproval
    && currentUserEmployeeDetails.jobLevel
    && parseInt(currentUserEmployeeDetails?.jobLevel) >= minJobLevelForAutoApproval
    && !excludedJobLevelsForAutoApproval.includes(parseInt(currentUserEmployeeDetails.jobLevel)))
      options.push({ label: 'All', value: 'All' });
    options.push(
      ...(props.sitesQuery.data?.map(s => { return { label: s.SiteCode, value: s.SiteCode }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)) || []);
    return options as SelectProps.Options;
  };

  useEffect(() => {
    setCurrentUserEmployeeDetails(queryClient.getQueryData(['currentUserEmployeeDetails']));
  }, [queryClient.getQueryData(['currentUserEmployeeDetails'])]);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.AssetAutoApprovalMinimumJobLevel)
    .then((v) => setMinJobLevelForAutoApproval(parseInt(v.value)));
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.AssetAutoApprovalExcludedJobLevels)
    .then((v) => setExcludedJobLevelsForAutoApproval(v.value.split(',').map(s => parseInt(s))));
  }, []);

  if (isBundleLoading) return <Spinner/>;

  return (
    <div id='AssetsCreateDiv'>
      {selectedSiteNameIsInvalid && selectedSiteName == 'All'
      &&
      <Flashbar
        items = {
          [
            {
              type: 'error',
              dismissible: false,
              content: bundle.getMessage('invalid-site-all')
            },
          ]
        }
      />}
      {warningData && duplicateVisitorAssets
      &&
      <>
      <Flashbar
        items = {
          [
            {
              content: warningDataInfo,
              dismissible: true,
              onDismiss: () => {
                setWarningData(false);
                setWarningDataInfo('');
              },
              type: 'warning',
            },
          ]
        }
      />
      <VisitorAssetsTablePanel
        isTableLoading={false}
        readOnly={true}
        setVisitorAssets={undefined}
        showAssetOwner={false}
        siteCode={''}
        username={''}
        visitorAssets={duplicateVisitorAssets}
        visitorAssetTypesQuery={null}
        visitorId={''}
      />
      </>}
      <form onSubmit={e => e.preventDefault()}>
      <Form
       actions={
          <Box float='right'>
            <SpaceBetween size='xs' direction='horizontal'>
              <Button
                data-testid='AssetsCancelButton'
                formAction='none'
                onClick={cancelBtnHandler}
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                data-testid='AssetsCreateAddAssetButton'
                disabled={
                  (
                    (
                      !selectedSiteName
                      || !assetTypeValue
                      || !assetSerialNumValue
                      || !assetDescriptionValue
                      || createVisitorAssetMutation.status == 'loading'
                      || invalidFromDate()
                      || invalidThroughDate()
                      || addAssetProcessing
                      || selectedSiteNameIsInvalid
                      || !username
                      || usernameIsInvalid
                    )
                    ||
                    (
                      !assetPermanentValue
                      && (assetStartDateValue == null || assetEndDateValue == null)
                    )
                  )
                }
                loading={addAssetProcessing || createVisitorAssetMutation.status == 'loading'}
                onClick={saveBtnHandler}
                variant='primary'
              >
                {bundle.getMessage('add-asset')}
              </Button>
            </SpaceBetween>
          </Box>
       } 
       errorText={errorText}
      >
        <SpaceBetween size='s' direction='vertical'>
          <Grid gridDefinition={[{colspan: 4}, {colspan: 4}]}>
            <FormField label={bundle.getMessage('username')}>
              <Input
                data-testid='AssetCreateUsername'
                onChange={event => {
                  setUsernameIsInvalid(false);
                  setUsername(event.detail.value);
                  if (event.detail.value !== props.username) setSelectedSiteName('');
                  setWarningData(false);
                  setWarningDataInfo('');
                }}
                inputMode='text'
                invalid={usernameIsInvalid}
                value={username}
              />
            </FormField>
            <FormField label={bundle.getMessage('site')} >
              <Select
                ariaDescribedby={selectedSiteName || undefined}
                ariaLabel='sites'
                ariaRequired
                data-testid='AssetCreateSiteSelect'
                empty={bundle.getMessage('no-sites-found')}
                filteringPlaceholder={bundle.getMessage('sites-placeholder')}
                filteringType='auto'
                invalid={selectedSiteNameIsInvalid}
                loadingText={bundle.getMessage('loading-sites')}
                onChange={({ detail }) => {
                  setSelectedSiteNameIsInvalid(false);
                  siteFieldOnChangeHandler(detail);
                }}
                options={siteOptions()}
                placeholder={bundle.getMessage('select-site')}
                renderHighlightedAriaLive={option => `Select Site ${option.label}`}
                selectedAriaLabel='Selected User Site'
                selectedOption={selectedSiteName ? { label: selectedSiteName, value: selectedSiteName } : null}
                statusType={props.sitesQuery.isLoading ? 'loading' : 'finished'}
              />
            </FormField>
          </Grid>
          <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
            <FormField label={bundle.getMessage('asset-type')}>
              <Select
                data-testid='AssetCreateTypeSelect'
                onChange={({ detail }) => visitorAssetTypeIdFieldOnChangeHandler(detail)}
                options={props.assetTypeOptions}
                placeholder={bundle.getMessage('select-asset-type')}
                selectedOption={assetTypeValue}
              />
            </FormField>
            <FormField label={bundle.getMessage('serial-number')}>
              <Input
                data-testid='AssetCreateSerialNumberInput'
                onChange={({ detail }) => visitorAssetSerialNumFieldOnChangeHandler(detail)}
                value={assetSerialNumValue || ''}
                placeholder={bundle.getMessage('enter-serial-number')}
                inputMode='text'
              />
            </FormField>
          </Grid>
          <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
            <FormField label={bundle.getMessage('make')}>
              <Input
                data-testid='AssetCreateSerialMakeInput'
                onChange={({ detail }) => setAssetMakeValue(detail.value)}
                value={assetMakeValue || ''}
                placeholder={bundle.getMessage('enter-make')}
                inputMode='text'
              />
            </FormField>
            <FormField label={bundle.getMessage('model')}>
              <Input
                data-testid='AssetCreateSerialModelInput'
                onChange={({ detail }) => setAssetModelValue(detail.value)}
                value={assetModelValue || ''}
                placeholder={bundle.getMessage('enter-model')}
                inputMode='text'
              />
            </FormField>
          </Grid>
          <FormField label={bundle.getMessage('description')}>
            <Textarea
              data-testid='AssetCreateDescriptionTextarea'
              onChange={({ detail }) => visitorAssetDescriptionFieldOnChangeHandler(detail)}
              value={assetDescriptionValue || ''}
              placeholder={bundle.getMessage('enter-description')}
            />
          </FormField>
          <SpaceBetween size='xs' direction='horizontal'>
            <FormField label={bundle.getMessage('permanent')}>
              <Checkbox
                data-testid='AssetCreatePermanentCheckbox'
                onChange={({ detail }) => visitorAssetPermanentFieldOnChangeHandler(detail)}
                checked={assetPermanentValue}
              />
            </FormField>
            <FormField label={bundle.getMessage('valid-from')}>
              <DatePicker
                data-testid='AssetCreateFromDatePicker'
                disabled={assetPermanentValue}
                invalid={invalidFromDate()}
                nextMonthAriaLabel={''}
                onChange={({ detail }) => visitorAssetStartDateFieldOnChangeHandler(detail)}
                previousMonthAriaLabel={''}
                todayAriaLabel={''}
                value={assetStartDateValue || ''}
              />
            </FormField>
            <FormField label={bundle.getMessage('valid-through')}>
              <DatePicker
                data-testid='AssetCreateThroughDatePicker'
                disabled={assetPermanentValue}
                invalid={invalidThroughDate()}
                nextMonthAriaLabel={''}
                onChange={({ detail }) => visitorAssetEndDateFieldOnChangeHandler(detail)}
                previousMonthAriaLabel={''}
                todayAriaLabel={''}
                value={assetEndDateValue || ''}
              />
              { invalidThroughDate() && <span style={{ color: 'red' }}>{bundle.getMessage('invalid-value')}</span> }
            </FormField>
          </SpaceBetween>
        </SpaceBetween>
      </Form>
      </form>
    </div>
  );
}
