import React, { useState } from 'react';
import {
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { IVisitorAccessLevelWithAction } from './TablePanel';
import { useQuery } from '@tanstack/react-query';
import { queryDenialReasons } from './utils';
import { debug } from 'src/utils/commonUtils';

export interface ISubmissionDenial{
  accessLevelDenial: IVisitorAccessLevelWithAction;
  setDenialReasonCallback: Function;
  setDenialNotesCallback: Function;
}

export default function SubmissionDenial(props: ISubmissionDenial) {
  debug(`SubmissionDenial() props is ${JSON.stringify(props)}`);

  const [denialReasonOptions, setDenialReasonOptions] = useState<SelectProps.Options>();

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.SubmissionDenial');

  const denialReasonsQuery = useQuery({
    queryKey: ['denialReasons'],
    queryFn: () => queryDenialReasons(),
    onSuccess: (data) => {
      data
      ? setDenialReasonOptions(data.filter(d => {
          if (d.enabled && d.start_date && d.end_date) {
            const currentDate = (new Date).toISOString();
            const startDate = (new Date(d.start_date)).toISOString();
            const endDate = (new Date(d.end_date)).toISOString();
            if (currentDate >= startDate && currentDate <= endDate) return true;
            return false;
          } else if (d.enabled && !d.start_date && !d.end_date) {
            return true;
          }
          return false;
        }).map(d => { return { label: d.value, value: d.value } }))
      : [];
    },
    retry: 3
  });

  if (isBundleLoading) return <Spinner/>;

  return(
    <div key={`SubmissionDenial${props.accessLevelDenial.id}`}>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]} >
        {props.accessLevelDenial.person_id}
        {props.accessLevelDenial.access_level_name}
        {props.accessLevelDenial.permanent_flag ? bundle.getMessage('permanent') : `${props.accessLevelDenial.start_date} ${bundle.getMessage('through')} ${props.accessLevelDenial.end_date}`}
      </Grid>
      <FormField label={bundle.getMessage('denial-reason')}>
        <Select
          data-testid='ManageAccessRequestsSubmissionDenialOptionsSelect'
          onChange={({ detail }) => props.setDenialReasonCallback(props.accessLevelDenial.id, detail.selectedOption.value)}
          options={denialReasonOptions}
          selectedOption={{ label: props.accessLevelDenial.denialReason, value: props.accessLevelDenial.denialReason}}
        />
      </FormField>
      <FormField label={bundle.getMessage('denial-notes')}>
        <Input
          data-testid='ManageAccessRequestsSubmissionDenialNotesInput'
          onChange={({detail}) => props.setDenialNotesCallback(props.accessLevelDenial.id, detail.value)}
          value={props.accessLevelDenial.notes || ''}
        />
      </FormField>
    </div>
  );
}