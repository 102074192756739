import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "../../../API";
import {
  listDelegationsByDelegateeID,
  listDelegationsByDelegatorID
} from 'src/graphql/queries';
import {
  createDelegation,
  deleteDelegation,
  deleteVisitorAccessLevel as deleteVisitorAccessLevelMutation  
} from 'src/graphql/mutations';
import { auditDecorator, queryEmployeeDetails } from 'src/components/utils';
import { debug } from '../../../utils/commonUtils';

export let addDelegate = async (delegation: APIt.CreateDelegationInput): Promise<APIt.CreateDelegationMutation | undefined> => {
  debug(`addDelegate has been called ${JSON.stringify(delegation)}`);
  try {
    const response = await API.graphql(graphqlOperation(createDelegation,
      {
        input: delegation
      })) as GraphQLResult<APIt.CreateDelegationMutation>;
    debug(`addDelegate() response is ${JSON.stringify(response)}`);
    return response.data;
  } catch (error) {
    console.error(`addDelegate() error is ${JSON.stringify(error)}`);
    throw error;
  }
};

addDelegate = auditDecorator('AddDelegates', addDelegate);

export const decorateDelegations = async (dels: APIt.Delegation[]): Promise<APIt.Delegation[]> => {
  debug(`updateDelegationType() delegations is ${JSON.stringify(dels)}`);
  let delegations: APIt.Delegation[] = [];
  dels.forEach(del => {
    let delegation: APIt.Delegation = {
      __typename: 'Delegation',
      created: del.created,
      created_by: del.created_by,
      delegatee_id: del.delegatee_id,
      delegator_id: del.delegator_id,
      delegation_type: del.delegation_type,
      delegation_type_id: del.delegation_type_id,
      end_date: del.end_date,
      id: del.id,
      justification: del.justification,
      permanent_flag: del.permanent_flag,
      start_date: del.start_date,
      updated: del.updated,
      updated_by: del.updated_by
    }
    delegations.push(delegation);
  });
  let delegatee_id_to_username = new Map<string, string>();
  let delegator_id_to_username = new Map<string, string>();
  await Promise.all(delegations.map(async delegation => {
    if (!delegatee_id_to_username.has(delegation.delegatee_id)) {
      let delegateeEmpDetails = await queryEmployeeDetails(delegation.delegatee_id);
      if (delegateeEmpDetails == null || delegateeEmpDetails.username == null || delegateeEmpDetails.username.length == 0) {
        debug("emp details is not valid. empDetails is " + JSON.stringify(delegateeEmpDetails));
      } else
        delegatee_id_to_username.set(delegation.delegatee_id, delegateeEmpDetails.username);
    }
    if (!delegator_id_to_username.has(delegation.delegator_id)) {
      let delegatorEmpDetails = await queryEmployeeDetails(delegation.delegator_id);
      if (delegatorEmpDetails == null || delegatorEmpDetails.username == null || delegatorEmpDetails.username.length == 0) {
        debug("emp details is not valid. empDetails is " + JSON.stringify(delegatorEmpDetails));
      } else
        delegator_id_to_username.set(delegation.delegator_id, delegatorEmpDetails.username);
    }
    delegation.delegatee_username = delegatee_id_to_username.get(delegation.delegatee_id);
    delegation.delegator_username = delegator_id_to_username.get(delegation.delegator_id);
  }));
  debug(`decorateDelegations() delegations is ${JSON.stringify(delegations)}`);
  return (delegations);
};

export let queryDelegatees = async (delegator_id: string): Promise<APIt.Delegation[]> => {
  debug(`queryDelegatees() delegator_id is ${delegator_id}`);
  let delegatees: APIt.Delegation[] = [];
  try {
    const response = await API.graphql(graphqlOperation(listDelegationsByDelegatorID,
      {
        delegator_id: delegator_id
      })) as GraphQLResult<APIt.ListDelegationsByDelegatorIDQuery>;
    if (response.data && response.data.listDelegationsByDelegatorID) {
      let dels = response.data.listDelegationsByDelegatorID as unknown as APIt.Delegation[];
      delegatees = await decorateDelegations(dels);
    }
  } catch (e) {
    console.error(`queryDelegatees(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryDelegatees() delegations is ${JSON.stringify(delegatees)}`);
  return (delegatees);
};
queryDelegatees = auditDecorator('queryDelegatees', queryDelegatees);

export let queryDelegators = async (delegatee_id: string): Promise<APIt.Delegation[]> => {
  debug(`queryDelegatees() delegatee_id is ${delegatee_id}`);
  let delegations: APIt.Delegation[] = [];
  try {
    const response = await API.graphql(graphqlOperation(listDelegationsByDelegateeID,
      {
        delegatee_id: delegatee_id
      })) as GraphQLResult<APIt.ListDelegationsByDelegateeIDQuery>;
    if (response.data && response.data.listDelegationsByDelegateeID) {
      debug(`queryDelegatees() response.data.listDelegationsByDelegateeID is ${JSON.stringify(response.data.listDelegationsByDelegateeID)}`);
      let dels = response.data.listDelegationsByDelegateeID as unknown as APIt.Delegation[];
      delegations = await decorateDelegations(dels);
    }
  } catch (e) {
    console.error(`queryDelegations(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  debug(`queryDelegations() delegations is ${JSON.stringify(delegations)}`);
  return (delegations);
};

queryDelegators = auditDecorator('queryDelegators', queryDelegators);

export const transformDelegation = async (d: APIt.Delegation): Promise<APIt.DelegationPrivilegeInput | null> => {
  let delegateeEmpDetails = await queryEmployeeDetails(d.delegatee_id);
  let delegatorEmpDetails = await queryEmployeeDetails(d.delegator_id);
  if (delegateeEmpDetails == null || delegateeEmpDetails.username == null || delegateeEmpDetails.username.length == 0
    || delegatorEmpDetails == null || delegatorEmpDetails.username == null || delegatorEmpDetails.username.length == 0
    || d.delegation_type == null || d.delegation_type.length == 0
  ) {
    debug("required details for delegation notification are not valid. delegateeEmpDetails is " + JSON.stringify(delegateeEmpDetails) + " delegatorEmpDetails is " + JSON.stringify(delegatorEmpDetails) + " delegation type is " + d.delegation_type);
    return null;
  }
  let delegationInput: APIt.DelegationPrivilegeInput = {
    delegationType: d.delegation_type,
    end_date: d.end_date,
    fromEmailAddress: delegatorEmpDetails.email ?? delegatorEmpDetails?.username + '@amazon.com',
    fromUsername: delegatorEmpDetails.username,
    justification: d.justification,
    permanent_flag: d.permanent_flag,
    start_date: d.start_date,
    toUsername: delegateeEmpDetails.username,
    toEmailAddress: delegateeEmpDetails.email ?? delegateeEmpDetails?.username + '@amazon.com',
  }
  return (delegationInput);
}

export const removeDelegations = async (delegations: APIt.Delegation[]): Promise<APIt.Delegation[] | undefined> => {
  debug(`deleteDelegation has been called ${JSON.stringify(delegations)}`);
  try {
    let removedDelegations: APIt.Delegation[] = [];
    for (let delegation of delegations) {
      const response = await API.graphql(graphqlOperation(deleteDelegation, {
        input: {
          id: delegation.id,
          updated_by: delegation.updated_by
        }
      })) as GraphQLResult<APIt.DeleteDelegationMutation>;
      debug(`deleteDelegation() response is ${JSON.stringify(response)}`);
      let removedDelegation = response.data?.deleteDelegation;
      if (removedDelegation) {
        removedDelegations.push(removedDelegation);
      }
    }
    return removedDelegations;
  } catch (error) {
    console.error(`deleteDelegation() error is ${JSON.stringify(error)}`);
    throw error;
  }
}