import React, { useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query'
import { Modes } from 'src/constants/Constants';
import PendingAccessRequestsTablePanel from './TablePanel';
import { VisitorAccessLevel } from 'src/API';
import { debug } from '../../../utils/commonUtils';

interface IPendingAccessRequestsProps {
  mode: Modes;
  pendingVisitorAccessLevelsQuery: UseQueryResult<VisitorAccessLevel[] | null, unknown>;
  username: string;
}

export default function PendingAccessRequests(props: IPendingAccessRequestsProps) {
  debug(`PendingAccessRequests() props is ${JSON.stringify(props)}`);

  return (
    <PendingAccessRequestsTablePanel
      username={props.username}
    />
  );
}


