import React, { useState, useEffect, useContext } from 'react';
import { 
  Box,
  Button,
  Container,
  Flashbar,
  Modal,
  Pagination,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../API';
import { API, graphqlOperation } from '@aws-amplify/api';
import { deleteVisitorAsset as deleteVisitorAssetMutation } from '../../../graphql/mutations';
import { VisitorAssetCreatePanel } from './Create';
import { useBundle } from '@amzn/react-arb-tools';
import { querySites } from 'src/components/utils';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import AssetDetailsTablePanel from './DetailsTablePanel';
import { queryVisitorAssetApprovals } from './utils';
import VisitorAssetBulkAdd from '../common/VisitorAssetBulkAdd';
import { debug } from '../../../utils/commonUtils';
import { createViewerOptions } from '../utils';

export interface CancelCreateVisitorAssetInterface {
  (): void;
}

export interface CreatedVisitorAssetInterface {
  (): void;
}

export interface CancelUpdateVisitorAssetInterface {
  (): void;
}

export interface UpdatedVisitorAssetInterface {
  (updatedVisitorAsset: APIt.VisitorAsset): void;
}

export interface VisitorAssetsTablePanelPropsInterface {
  assetQueryUser: string;
  disableAutoApproval: boolean;
  delegatorsQuery: UseQueryResult<APIt.Delegation[] | null, unknown>;
  employeeId: string;
  isTableLoading: boolean;
  setAssetQueryUserCallback: Function;
  username: string;
  visitorAssets: APIt.VisitorAsset[];
  visitorAssetsQuery: UseQueryResult<APIt.VisitorAsset[] | null, unknown>;
  visitorAssetTypes: APIt.LookupTypeValue[];
  visitorId: string | undefined;
}

export default function VisitorAssetsTablePanel(props: VisitorAssetsTablePanelPropsInterface ) {
  debug(`VisitorAssetsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAsset[]>(props.visitorAssets);
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [id, setId] = useState<number>(1);
  const [removingAssets, setRemovingAssets] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<APIt.VisitorAsset[]>([]);
  const [showAssetBulkLoad, setShowAssetBulkLoad] = useState<boolean>(false);
  const [showCreateVisitorAssetPanel, setShowCreateVisitorAssetPanel] = useState<boolean>(false);
  const [viewerOptions, setViewerOptions] = useState<SelectProps.Option[]>();
  const [viewerSelect, setViewerSelect] = useState<SelectProps.Option | null>({ label: props.username, value: props.employeeId });
  const [visitorAssetCreateSaved, setVisitorAssetCreateSaved] = useState<boolean>(false);
  const [visitorAssetDeleteSaved, setVisitorAssetDeleteSaved] = useState<boolean>(false);
  const [visitorAssetEditSaved, setVisitorAssetEditSaved] = useState<boolean>(false);

  const assetApprovalsQuery = useQuery({
    enabled: selectedItems.length == 1,
    queryKey: ['assetApprovals'],
    queryFn: () => queryVisitorAssetApprovals(selectedItems[0]?.id),
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const sitesQuery = useQuery({
    queryFn: () => querySites(false),
    queryKey: ['assetSites'],
    refetchOnWindowFocus: false,
  });

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Assets.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-assets-found') : 'No Assets Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  useEffect(() => {
    props.visitorAssetsQuery.refetch();
  }, [selectedItems]);
  
  useEffect(() => {    
    setAllItems(props.visitorAssetsQuery.data ?? []);
  }, [props.visitorAssetTypes]);  

  useEffect(() => {
    if (viewerSelect?.value) {
      props.setAssetQueryUserCallback(viewerSelect.value);
    }
  }, [viewerSelect]);

  useEffect(() => {
    setAllItems(props.visitorAssets);
  }, [props.visitorAssets]);

  useEffect(() => {
    let options = createViewerOptions('View Assets', props.delegatorsQuery.data ?? [], props.username, props.employeeId);
    let option = options.find(option => option.value == props.assetQueryUser);
    if (option) {
      setViewerSelect(option);
    }    
    setViewerOptions(options); 
  },[props.delegatorsQuery.data]);

  const cancelCreate: CancelCreateVisitorAssetInterface = () => {
    setShowCreateVisitorAssetPanel(false);
  }

  const createBtnClickHandler = () => {
    setShowCreateVisitorAssetPanel(true);
  };

  const createdVisitorAsset: CreatedVisitorAssetInterface = () => {
    setVisitorAssetCreateSaved(true);
    setShowCreateVisitorAssetPanel(false);
    props.visitorAssetsQuery.refetch();
  }

  const resetVisitorAssetRequestCreatedSaved = () => {
    setVisitorAssetCreateSaved(false);
  };

  const FlashMessageCreateSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'success',
            dismissible: true,
            content: bundle.getMessage('asset-added'),
            onDismiss: resetVisitorAssetRequestCreatedSaved
          },
        ]
      }
    />
  );

  const resetVisitorAssetEditSaved = () => {
    setVisitorAssetEditSaved(false);
  };

  const FlashMessageEditSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('asset-updated'),
            buttonText: bundle.getMessage('ok'),
            onButtonClick: resetVisitorAssetEditSaved
          },
        ]
      }
    />
  );

  const deleteBtnClickHandler = () => {
    if (selectedItems.length > 0) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const deleteSelectedVisitorAssets = async () => {
    setRemovingAssets(true);
    for (let selectedItem of selectedItems) {
      try {
        await API.graphql(graphqlOperation(deleteVisitorAssetMutation,
          {
            input:
              {
                id: selectedItem.id,
                updated_by: props.username
              }
          }));
      } catch (e) {
        console.error(`deleteVisitorAssets(): exception is ${JSON.stringify(e)}`);
      }
    }
    const newAllItems = allItems!.filter( (item) => !selectedItems.find( si => si.id == item.id));
    debug(`deleteSelectedVisitorAssets() newAllItems.length is ${newAllItems.length}`);
    setAllItems(newAllItems);
    setDeleteVisible(false);
    setVisitorAssetDeleteSaved(true);
    setSelectedItems([]);
    setRemovingAssets(false);
    props.visitorAssetsQuery.refetch();
  };

  const resetVisitorAssetDeleteSaved = () => {
    setVisitorAssetDeleteSaved(false);
  };

  const FlashMessageDeleteSaved = () => (
    <Flashbar
      items = {
        [
          {
            header: bundle.getMessage('assets-removed'),
            dismissible: true,
            dismissLabel: bundle.getMessage('dismiss'),
            onDismiss: resetVisitorAssetDeleteSaved,
            type: 'success',
          },
        ]
      }
    />);

  const refreshBtnClickHandler = async () => {
    props.visitorAssetsQuery.refetch();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {visitorAssetEditSaved && <FlashMessageEditSaved/>}
    {visitorAssetCreateSaved && <FlashMessageCreateSaved/>}
    {visitorAssetDeleteSaved && <FlashMessageDeleteSaved/>}
    <div id='AssetsTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter Assets'
            filteringPlaceholder='Find Assets'
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Select
                  key='selectViewerAssets'
                  data-testid='ViewerSelect'
                  onChange={({ detail }) => setViewerSelect({ label: detail.selectedOption.label!, value: detail.selectedOption.value! })}
                  options={viewerOptions}
                  selectedOption={viewerSelect}
                  disabled={props.visitorAssetsQuery.isLoading || props.visitorAssetsQuery.isFetching || props.delegatorsQuery.isLoading || props.delegatorsQuery.isFetching}                                                
                />
                <Button
                  data-testid='AssetsRefreshButton'
                  iconName='refresh'
                  key='refreshBtn'
                  loading={props.visitorAssetsQuery.isFetching}
                  onClick={refreshBtnClickHandler}
                >
                  {bundle.getMessage('refresh')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
            {bundle.getMessage('assets')}
          </Header>
        }
        footer={
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='AssetsRemoveSelectedButton'
                  onClick={deleteBtnClickHandler}
                  disabled={selectedItems.length == 0}
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
                <Button
                  data-testid='AssetsAddAssetButton'
                  onClick={createBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('add-asset')}
                </Button>
                <Button
                  data-testid='AssetsBulkAddAssetButton'
                  onClick={() => setShowAssetBulkLoad(true)}
                  variant='normal'
                >
                  {bundle.getMessage('bulk-add-assets')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        items={items}
        loading={props.isTableLoading}
        loadingText={bundle.getMessage('loading-assets')}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    {
      selectedItems.length == 1
      &&
      <Container
        key={'AssetDetails'}
        header={<Header>{`${bundle.getMessage('asset-details')} for ${selectedItems[0].asset_type} ${selectedItems[0].serial_num}`}</Header>}
        children={
          <AssetDetailsTablePanel
            isLoading={assetApprovalsQuery.isLoading || assetApprovalsQuery.isFetching}
            refreshCallback={() => assetApprovalsQuery.refetch()}
            visitorAssetApprovals={assetApprovalsQuery.data || []}
          />
        }
      />
    }
    {deleteVisible
    &&
    <Modal
      onDismiss={() => setDeleteVisible(false)}
      visible={deleteVisible}
      closeAriaLabel='Close'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={deleteNoBtnClickHandler}>{bundle.getMessage('cancel')}</Button>
            <Button
              data-testid='AssetsRemoveButton'
              disabled={removingAssets}
              loading={removingAssets}
              onClick={deleteSelectedVisitorAssets}
              variant='primary'
            >
              {bundle.getMessage('remove')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('confirm-asset-removal')}
    >
      {bundle.getMessage('please-confirm-asset-removal')}
    </Modal>
    }
    {showCreateVisitorAssetPanel
    &&
    <Modal
      header={bundle.getMessage('add-asset')}
      visible={showCreateVisitorAssetPanel}
      onDismiss={() => setShowCreateVisitorAssetPanel(false)}
      size='medium'
    >
      <VisitorAssetCreatePanel
        assetTypeOptions={props.visitorAssetTypes.map(at => { return {label: at.value, value: at.id} })}
        cancelCreateCallback={cancelCreate}
        disableAutoApproval={props.disableAutoApproval}
        saveCallback={createdVisitorAsset}
        username={props.username}
        visitorId={props.visitorId}
        sitesQuery={sitesQuery}
      />
    </Modal>}
    {showAssetBulkLoad
    &&
    <Modal
      header={bundle.getMessage('bulk-add-assets')}
      onDismiss={() => setShowAssetBulkLoad(false)}
      size='large'
      visible={showAssetBulkLoad}
    >
      <VisitorAssetBulkAdd
        addVendor={true}
        closeCallback={() => {
          setShowAssetBulkLoad(false);
          props.visitorAssetsQuery.refetch();
        }}
        disableAutoApproval={props.disableAutoApproval}
        id={id}
        username={props.username}
      />
    </Modal>
    }
    </>
  );
}
