import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import * as APIt from "src/API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";
import { Checkbox } from '@amzn/awsui-components-react';

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.VisitorAsset>[] = [
  {
    id: 'person_id',
    header: <TranslateHeader value={'visitor'} />,
    cell: item => item.person_id,
    sortingField: 'person_id'
  },
  {
    id: 'asset_type',
    header: <TranslateHeader value={'asset-type'} />,
    cell: item => item.asset_type,
    sortingField: 'asset_type'
  },
  {
    id: 'description',
    header: <TranslateHeader value={'description'} />,
    cell: item => item.description,
    sortingField: 'description',
  },
  {
    id: 'make',
    header: <TranslateHeader value={'make'} />,
    cell: item => item.make,
    sortingField: 'make'
  },
  {
    id: 'model',
    header: <TranslateHeader value={'model'} />,
    cell: item => item.model,
    sortingField: 'model'
  },
  {
    id: 'serial_num',
    header: <TranslateHeader value={'serial-number'} />,
    cell: item => item.serial_num,
    sortingField: 'serial_num'
  },
  {
    id: 'permanent_flag',
    header: <TranslateHeader value={'permanent'} />,
    cell: item => <Checkbox checked={item.permanent_flag} onChange={()=>{}} disabled />,
    sortingField: 'permanent_flag'
  },
  {
    id: 'start_date',
    header: <TranslateHeader value={'valid-from'} />,
    cell: (item) => { return item.start_date ? item.start_date.slice(0,10) : '' } ,
    sortingField: 'start_date'
  },
  {
    id: 'end_date',
    header: <TranslateHeader value={'valid-through'} />,
    cell: (item) => { return item.end_date ? item.end_date.slice(0,10) : '' } ,
    sortingField: 'end_date'
  },
  {
    id: 'approval_status',
    header: <TranslateHeader value={'approval-status'} />,
    cell: item => item.approval_status,
    sortingField: 'approval_status'
  },
  {
    id: 'notes',
    header: <TranslateHeader value={'notes'} />,
    cell: item => item.notes,
    sortingField: 'notes'
  },
  {
    id: 'denial_reason',
    header: <TranslateHeader value={'denial-reason'} />,
    cell: item => item.denial_reason,
    sortingField: 'denial_reason'
  },
  {
    id: 'sticker_num',
    header: <TranslateHeader value={'sticker-num'} />,
    cell: item => item.sticker_num,
    sortingField: 'sticker_num'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAssets.VisitorSiteAssets.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
