import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  Textarea,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { DelegateesColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import {  
  queryDelegatees,
  removeDelegations,
  transformDelegation,
} from '../utils';
import { debug } from 'src/utils/commonUtils';
import { DelegationCreatePanel } from './Create';
import { viewershipAccessRemovedSendNotication } from '../../utils';

export interface CancelCreateDelegationInterface {
  (): void;
}

export interface CancelUpdateDelegationInterface {
  (): void;
}

export interface CreatedDelegationInterface {
  (emailSent: boolean): void;
}

export interface DelegateesTablePanelPropsInterface {
  employeeId: string;
  username: string;
  delegationTypesQuery: UseQueryResult<APIt.LookupTypeValue[]>;
}

export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export default function DelegateesTablePanel(props: DelegateesTablePanelPropsInterface ) {
  debug(`DelegationsTablePanel() props is ${JSON.stringify(props)}`);

  const [addDelegationVisible, setAddDelegationVisible] = useState<boolean>(false);
  const [delegateeSelectedItems, setDelegateeSelectedItems] = useState<APIt.Delegation[]>([]);
  const [removeDelegateeVisible, setRemoveDelegateeVisible] = useState<boolean>(false);
  const [refreshingDelegatees, setRefreshingDelegatees] = useState<boolean>(false);
  const [showEmailNotBeingSent, setShowEmailNotBeingSent] = useState<boolean>(false);

  const delegateesQuery = useQuery({
    queryKey: ['listDelegationsByDelegatorID'],
    queryFn: () => queryDelegatees(props.employeeId),
    retry: 3
  });

  const cancelCreate: CancelCreateDelegationInterface = () => {
    setAddDelegationVisible(false);
  }

  const createdDelegation: CreatedDelegationInterface = async (emailSent: boolean) => {
    setAddDelegationVisible(false);
    setRefreshingDelegatees(true);
    await delegateesQuery.refetch();
    setRefreshingDelegatees(false);
  }

  const removeDelegateeBtnClickHandler = () => {
    if (delegateeSelectedItems.length > 0) setRemoveDelegateeVisible(true);
  };

  const addDelegateClickHandler = async () => {
    setAddDelegationVisible(true);
  };

  const delegateesItemsCount = (): number => {
    if (delegateesQuery?.data) return delegateesQuery?.data.length;
    return 0;
  };

  const delegateeRefreshBtnClickHandler = async () => {
    setRefreshingDelegatees(true);
    await delegateesQuery.refetch();
    setRefreshingDelegatees(false);
  };

  const removeDelegateeHandler = async () => {
    setRemoveDelegateeVisible(true);
    let filteredSelectedItems: APIt.Delegation[] = delegateeSelectedItems;
    setDelegateeSelectedItems(filteredSelectedItems);
    const removedDelegations = await removeDelegations(filteredSelectedItems);
    if (removedDelegations && removedDelegations.length > 0) {
      for (let delegation of removedDelegations) {
        let delegationPrivilegeInput = await transformDelegation(delegation);
        if (delegationPrivilegeInput != null)
          viewershipAccessRemovedSendNotication(delegationPrivilegeInput);
        else {
          debug(`Email on remove delegation has not been sent delegation is ${JSON.stringify(delegation)}`);
          setShowEmailNotBeingSent(true);
        }
      }
    }
    setRemoveDelegateeVisible(false);
    setDelegateeSelectedItems([]);
    await delegateesQuery.refetch();
  };

  const delegationTypes = (): APIt.LookupTypeValue[] => {
    if (props.delegationTypesQuery.data) return props.delegationTypesQuery.data;
    return [];
  }

  const DelegateesReasonField = () => {
    return(
      <>
      <FormField label={bundle.getMessage('justification-for-delegation')} >
        <Textarea value={delegateeSelectedItems[0].justification} disabled/>
      </FormField>
      <br/>
      </>
    );
  };

  const resetEmailNotBeingSent = () => {
    setShowEmailNotBeingSent(false);
  };

  const FlashMessageEmailNotSent = () => (
    <Flashbar
      items={
        [
          {
            header: bundle.getMessage('email-not-sent'),
            dismissible: true,
            dismissLabel: bundle.getMessage('dismiss'),
            onDismiss: resetEmailNotBeingSent,
            type: 'warning',
          },
        ]
      }
    />);

  useEffect(() => {
    setRefreshingDelegatees(true);
    delegateesQuery.refetch();
    setRefreshingDelegatees(false);
  }, [delegateeSelectedItems]);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Delegations.Delegatees.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    delegateesQuery?.data || [],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-delegatees-found') : 'No Delegated Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showEmailNotBeingSent && <FlashMessageEmailNotSent />}
    <div id='tableDelegateesDiv'>
      <Table
        {...collectionProps}
        columnDefinitions={DelegateesColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('delegatees-filter')}
            filteringPlaceholder={bundle.getMessage('delegatees-find')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        data-testid='DelegateesTable'        
        header={
          <Header
            actions={
              <>
                <SpaceBetween size='xs' direction='horizontal'>
                  <Button
                    loading={refreshingDelegatees}
                    key="refreshBtn"
                    onClick={delegateeRefreshBtnClickHandler}
                    iconName='refresh'
                  >
                    {bundle.getMessage('refresh')}
                  </Button>
                </SpaceBetween>
              </>
            }
            counter={`(${delegateesItemsCount().toString()})`}
            description={
              <Box float='left'>{bundle.getMessage('delegatees-description')}</Box>
            }
          >
            {bundle.getMessage('delegatees')}
          </Header>
        }
        footer={
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='DelegationsAddButton'
                  onClick={addDelegateClickHandler}
                  variant='normal'
                >
                  {bundle.getMessage('add')}
                </Button>
                <Button
                  data-testid='DelegateesRemoveButton'
                  disabled={delegateeSelectedItems.length == 0}
                  onClick={removeDelegateeBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('remove')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        items={items}
        loading={delegateesQuery.isLoading}
        loadingText={bundle.getMessage('loading-delegatees')}
        onSelectionChange={({ detail }) => setDelegateeSelectedItems(detail.selectedItems) }
        pagination={
          (delegateesQuery?.data?.length ?? 0) > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={delegateeSelectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    {
      delegateeSelectedItems.length == 1
      &&
      <Container
        key={'DelegateeDetails'}
        header={<Header>{`${bundle.getMessage('delegatee-details')} for ${delegateeSelectedItems[0].delegatee_username} `}</Header>}
        children={
          <>
          <DelegateesReasonField/>
          </>
        }
      />
    }
    {removeDelegateeVisible
    &&
    <Modal
      onDismiss={() => setRemoveDelegateeVisible(true)}
      visible={removeDelegateeVisible}
      closeAriaLabel='Close'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setRemoveDelegateeVisible(false)}>{bundle.getMessage('no')}</Button>
            <Button
              data-testid='ConfirmDelegateesDeleteButton'
              disabled={!removeDelegateeVisible}
              loading={!removeDelegateeVisible}
              onClick={() => removeDelegateeHandler() }
              variant='primary'
            >
              {bundle.getMessage('yes')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('confirm-delegatee-deletion')}
    >
      {bundle.getMessage('please-confirm-delegatee-deletion')}
    </Modal>
    }
    {addDelegationVisible
    &&
    <Modal
      header={bundle.getMessage('add-viewer')}
      visible={addDelegationVisible}
      onDismiss={() => setAddDelegationVisible(false)}
      size='medium'
    >
      <DelegationCreatePanel
        cancelCreateCallback={cancelCreate}
        saveCallback={createdDelegation}
        delegatorId={props.employeeId}
        username={props.username}
        delegationTypeOptions={delegationTypes().map(at => { return {label: at.value, value: at.id} })}
      />
    </Modal>}
    </>
  );
}
