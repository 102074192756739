import React from 'react';
import {
  FormField,
  Grid,
  Input,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { VisitorAssetWithAction } from './TablePanel';
import { debug } from 'src/utils/commonUtils';

export interface ISubmissionApproval{
  visitorAssetApproval: VisitorAssetWithAction;
  setNotesCallback: Function;
}

export default function SubmissionApproval(props: ISubmissionApproval) {
  debug(`SubmissionApproval() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.SubmissionApproval');

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 6 }]} >
        {props.visitorAssetApproval.person_id}
        {`${props.visitorAssetApproval.asset_type} (${props.visitorAssetApproval.description})`}
        {props.visitorAssetApproval.permanent_flag ? bundle.getMessage('permanent') : `${props.visitorAssetApproval.start_date} ${bundle.getMessage('through')} ${props.visitorAssetApproval.end_date}`}
      </Grid>
      <FormField label={bundle.getMessage('approval-notes')}>
        <Input
          onChange={({detail}) => props.setNotesCallback(props.visitorAssetApproval.id, detail.value) }
          value={props.visitorAssetApproval.notes || ''}
        />
      </FormField>
    </>
  );
}