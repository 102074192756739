/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const accessControlRequestStatus = /* GraphQL */ `mutation AccessControlRequestStatus($input: AccessControlRequestStatusInput!) {
  accessControlRequestStatus(input: $input) {
    error
    message
    requestUUID
    statusCode
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AccessControlRequestStatusMutationVariables,
  APITypes.AccessControlRequestStatusMutation
>;
export const badgeAccessLevel = /* GraphQL */ `mutation BadgeAccessLevel($input: BadgeAccessLevelRequestInput!) {
  badgeAccessLevel(input: $input) {
    statusCode
    error
    message
    requestUUID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BadgeAccessLevelMutationVariables,
  APITypes.BadgeAccessLevelMutation
>;
export const badgeAssignment = /* GraphQL */ `mutation BadgeAssignment($input: BadgeAssignmentInput!) {
  badgeAssignment(input: $input) {
    statusCode
    error
    message
    requestUUID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BadgeAssignmentMutationVariables,
  APITypes.BadgeAssignmentMutation
>;
export const cardholder = /* GraphQL */ `mutation Cardholder($input: CardholderRequestInput!) {
  cardholder(input: $input) {
    statusCode
    error
    message
    requestUUID
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CardholderMutationVariables,
  APITypes.CardholderMutation
>;
export const createApproverGroup = /* GraphQL */ `mutation CreateApproverGroup($input: CreateApproverGroupInput!) {
  createApproverGroup(input: $input) {
    id
    access_level
    approver_group
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateApproverGroupMutationVariables,
  APITypes.CreateApproverGroupMutation
>;
export const createDelegation = /* GraphQL */ `mutation CreateDelegation($input: CreateDelegationInput!) {
  createDelegation(input: $input) {
    created
    created_by
    delegator_id
    delegator_username
    delegatee_id
    delegatee_username
    delegation_type_id
    delegation_type
    end_date
    justification
    id
    permanent_flag
    start_date
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDelegationMutationVariables,
  APITypes.CreateDelegationMutation
>;
export const createLookupType = /* GraphQL */ `mutation CreateLookupType($input: CreateLookupTypeInput!) {
  createLookupType(input: $input) {
    id
    name
    description
    enabled
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLookupTypeMutationVariables,
  APITypes.CreateLookupTypeMutation
>;
export const createLookupTypeValue = /* GraphQL */ `mutation CreateLookupTypeValue($input: CreateLookupTypeValueInput!) {
  createLookupTypeValue(input: $input) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLookupTypeValueMutationVariables,
  APITypes.CreateLookupTypeValueMutation
>;
export const createUserAction = /* GraphQL */ `mutation CreateUserAction($input: UserActionInput!) {
  createUserAction(input: $input) {
    actionId
    actionName
    parameters
    timestamp
    ttl
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActionMutationVariables,
  APITypes.CreateUserActionMutation
>;
export const createUserPrefs = /* GraphQL */ `mutation CreateUserPrefs($input: CreateUserPrefsInput!) {
  createUserPrefs(input: $input) {
    dailySummaryEmailTimestamp
    username
    global
    selfService
    management
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPrefsMutationVariables,
  APITypes.CreateUserPrefsMutation
>;
export const createRequest = /* GraphQL */ `mutation CreateRequest($input: CreateRequestInput!) {
  createRequest(input: $input) {
    created
    created_by
    end_date
    id
    last_reminder_date
    reason
    requestor_id
    requestor_source_system_id
    site_id
    site_source_system
    site_source_system_id
    start_date
    status
    status_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRequestMutationVariables,
  APITypes.CreateRequestMutation
>;
export const createVisitorAccessLevel = /* GraphQL */ `mutation CreateVisitorAccessLevel($input: CreateVisitorAccessLevelInput!) {
  createVisitorAccessLevel(input: $input) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorAccessLevelMutationVariables,
  APITypes.CreateVisitorAccessLevelMutation
>;
export const createVisitorAccessLevelApproval = /* GraphQL */ `mutation CreateVisitorAccessLevelApproval(
  $input: CreateVisitorAccessLevelApprovalInput!
) {
  createVisitorAccessLevelApproval(input: $input) {
    approver_id
    approver_email_address
    approver_source_system_id
    created
    created_by
    denial_reason_id
    denial_reason
    id
    notes
    status
    status_code_id
    updated
    updated_by
    visitor_access_level_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorAccessLevelApprovalMutationVariables,
  APITypes.CreateVisitorAccessLevelApprovalMutation
>;
export const createVisitorAction = /* GraphQL */ `mutation CreateVisitorAction($input: CreateVisitorActionInput!) {
  createVisitorAction(input: $input) {
    id
    visitor_id
    action_type
    action_type_id
    additional_info
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorActionMutationVariables,
  APITypes.CreateVisitorActionMutation
>;
export const createRequestEscort = /* GraphQL */ `mutation CreateRequestEscort($input: CreateRequestEscortInput!) {
  createRequestEscort(input: $input) {
    id
    request_id
    escort_id
    escort_source_system_id
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRequestEscortMutationVariables,
  APITypes.CreateRequestEscortMutation
>;
export const createVisitor = /* GraphQL */ `mutation CreateVisitor($input: CreateVisitorInput!) {
  createVisitor(input: $input) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    ttl
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorMutationVariables,
  APITypes.CreateVisitorMutation
>;
export const createVisitorAsset = /* GraphQL */ `mutation CreateVisitorAsset($input: CreateVisitorAssetInput!) {
  createVisitorAsset(input: $input) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorAssetMutationVariables,
  APITypes.CreateVisitorAssetMutation
>;
export const createVisitorAssetApproval = /* GraphQL */ `mutation CreateVisitorAssetApproval($input: CreateVisitorAssetApprovalInput!) {
  createVisitorAssetApproval(input: $input) {
    id
    visitor_asset_id
    approver_id
    approver_email_address
    approver_source_system_id
    status_code_id
    status
    denial_reason_id
    denial_reason
    notes
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorAssetApprovalMutationVariables,
  APITypes.CreateVisitorAssetApprovalMutation
>;
export const createVisitorPass = /* GraphQL */ `mutation CreateVisitorPass($input: CreateVisitorPassInput!) {
  createVisitorPass(input: $input) {
    id
    badge_id
    badge_source_system_id
    visitor_pass_assignment_id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorPassMutationVariables,
  APITypes.CreateVisitorPassMutation
>;
export const createVisitorPassAssignment = /* GraphQL */ `mutation CreateVisitorPassAssignment(
  $input: CreateVisitorPassAssignmentInput!
) {
  createVisitorPassAssignment(input: $input) {
    id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateVisitorPassAssignmentMutationVariables,
  APITypes.CreateVisitorPassAssignmentMutation
>;
export const deleteApproverGroup = /* GraphQL */ `mutation DeleteApproverGroup($input: DeleteApproverGroupInput!) {
  deleteApproverGroup(input: $input) {
    id
    access_level
    approver_group
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteApproverGroupMutationVariables,
  APITypes.DeleteApproverGroupMutation
>;
export const deleteDelegation = /* GraphQL */ `mutation DeleteDelegation($input: DeleteDelegationInput!) {
  deleteDelegation(input: $input) {
    created
    created_by
    delegator_id
    delegator_username
    delegatee_id
    delegatee_username
    delegation_type_id
    delegation_type
    end_date
    justification
    id
    permanent_flag
    start_date
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDelegationMutationVariables,
  APITypes.DeleteDelegationMutation
>;
export const deleteUserPrefs = /* GraphQL */ `mutation DeleteUserPrefs($input: DeleteUserPrefsInput!) {
  deleteUserPrefs(input: $input) {
    dailySummaryEmailTimestamp
    username
    global
    selfService
    management
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPrefsMutationVariables,
  APITypes.DeleteUserPrefsMutation
>;
export const deleteRequest = /* GraphQL */ `mutation DeleteRequest($input: DeleteRequestInput!) {
  deleteRequest(input: $input) {
    created
    created_by
    end_date
    id
    last_reminder_date
    reason
    requestor_id
    requestor_source_system_id
    site_id
    site_source_system
    site_source_system_id
    start_date
    status
    status_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRequestMutationVariables,
  APITypes.DeleteRequestMutation
>;
export const deleteVisitorAccessLevel = /* GraphQL */ `mutation DeleteVisitorAccessLevel($input: DeleteVisitorAccessLevelInput!) {
  deleteVisitorAccessLevel(input: $input) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisitorAccessLevelMutationVariables,
  APITypes.DeleteVisitorAccessLevelMutation
>;
export const deleteVisitorAccessLevelApproval = /* GraphQL */ `mutation DeleteVisitorAccessLevelApproval(
  $input: DeleteVisitorAccessLevelApprovalInput!
) {
  deleteVisitorAccessLevelApproval(input: $input) {
    approver_id
    approver_email_address
    approver_source_system_id
    created
    created_by
    denial_reason_id
    denial_reason
    id
    notes
    status
    status_code_id
    updated
    updated_by
    visitor_access_level_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisitorAccessLevelApprovalMutationVariables,
  APITypes.DeleteVisitorAccessLevelApprovalMutation
>;
export const deleteRequestEscort = /* GraphQL */ `mutation DeleteRequestEscort($input: DeleteRequestEscortInput!) {
  deleteRequestEscort(input: $input) {
    id
    request_id
    escort_id
    escort_source_system_id
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRequestEscortMutationVariables,
  APITypes.DeleteRequestEscortMutation
>;
export const deleteVisitor = /* GraphQL */ `mutation DeleteVisitor($input: DeleteVisitorInput!) {
  deleteVisitor(input: $input) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    ttl
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisitorMutationVariables,
  APITypes.DeleteVisitorMutation
>;
export const deleteVisitorAsset = /* GraphQL */ `mutation DeleteVisitorAsset($input: DeleteVisitorAssetInput!) {
  deleteVisitorAsset(input: $input) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisitorAssetMutationVariables,
  APITypes.DeleteVisitorAssetMutation
>;
export const deleteVisitorPass = /* GraphQL */ `mutation DeleteVisitorPass($input: DeleteVisitorPassInput!) {
  deleteVisitorPass(input: $input) {
    id
    badge_id
    badge_source_system_id
    visitor_pass_assignment_id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisitorPassMutationVariables,
  APITypes.DeleteVisitorPassMutation
>;
export const deleteVisitorPassAssignment = /* GraphQL */ `mutation DeleteVisitorPassAssignment(
  $input: DeleteVisitorPassAssignmentInput!
) {
  deleteVisitorPassAssignment(input: $input) {
    id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteVisitorPassAssignmentMutationVariables,
  APITypes.DeleteVisitorPassAssignmentMutation
>;
export const markEmployeeDataTTL = /* GraphQL */ `mutation MarkEmployeeDataTTL($psid: ID!, $employeeStatus: String) {
  markEmployeeDataTTL(psid: $psid, employeeStatus: $employeeStatus) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    ttl
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.MarkEmployeeDataTTLMutationVariables,
  APITypes.MarkEmployeeDataTTLMutation
>;
export const updateApproverGroup = /* GraphQL */ `mutation UpdateApproverGroup($input: UpdateApproverGroupInput!) {
  updateApproverGroup(input: $input) {
    id
    access_level
    approver_group
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateApproverGroupMutationVariables,
  APITypes.UpdateApproverGroupMutation
>;
export const updateLookupType = /* GraphQL */ `mutation UpdateLookupType($input: UpdateLookupTypeInput!) {
  updateLookupType(input: $input) {
    id
    name
    description
    enabled
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLookupTypeMutationVariables,
  APITypes.UpdateLookupTypeMutation
>;
export const updateLookupTypeValue = /* GraphQL */ `mutation UpdateLookupTypeValue($input: UpdateLookupTypeValueInput!) {
  updateLookupTypeValue(input: $input) {
    id
    lookup_type_id
    value
    description
    enabled
    start_date
    end_date
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLookupTypeValueMutationVariables,
  APITypes.UpdateLookupTypeValueMutation
>;
export const updateUserPrefs = /* GraphQL */ `mutation UpdateUserPrefs($input: UpdateUserPrefsInput!) {
  updateUserPrefs(input: $input) {
    dailySummaryEmailTimestamp
    username
    global
    selfService
    management
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPrefsMutationVariables,
  APITypes.UpdateUserPrefsMutation
>;
export const updateRequest = /* GraphQL */ `mutation UpdateRequest($input: UpdateRequestInput!) {
  updateRequest(input: $input) {
    created
    created_by
    end_date
    id
    last_reminder_date
    reason
    requestor_id
    requestor_source_system_id
    site_id
    site_source_system
    site_source_system_id
    start_date
    status
    status_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRequestMutationVariables,
  APITypes.UpdateRequestMutation
>;
export const updateVisitorAccessLevel = /* GraphQL */ `mutation UpdateVisitorAccessLevel($input: UpdateVisitorAccessLevelInput!) {
  updateVisitorAccessLevel(input: $input) {
    access_level_id
    access_level_name
    access_level_source_system_id
    company
    created
    created_by
    dates_updated
    email
    end_date
    first_name
    id
    last_name
    last_reminder_date
    person_id
    person_source_system_id
    permanent_flag
    reason
    request_id
    requestor_id
    requestor_source_system_id
    requestuuid
    site_id
    start_date
    status
    status_code_id
    updated
    updated_by
    visitor_id
    visitor_type
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisitorAccessLevelMutationVariables,
  APITypes.UpdateVisitorAccessLevelMutation
>;
export const updateVisitorAccessLevelApproval = /* GraphQL */ `mutation UpdateVisitorAccessLevelApproval(
  $input: UpdateVisitorAccessLevelApprovalInput!
) {
  updateVisitorAccessLevelApproval(input: $input) {
    approver_id
    approver_email_address
    approver_source_system_id
    created
    created_by
    denial_reason_id
    denial_reason
    id
    notes
    status
    status_code_id
    updated
    updated_by
    visitor_access_level_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisitorAccessLevelApprovalMutationVariables,
  APITypes.UpdateVisitorAccessLevelApprovalMutation
>;
export const updateVisitorAssetApproval = /* GraphQL */ `mutation UpdateVisitorAssetApproval($input: UpdateVisitorAssetApprovalInput!) {
  updateVisitorAssetApproval(input: $input) {
    id
    visitor_asset_id
    approver_id
    approver_email_address
    approver_source_system_id
    status_code_id
    status
    denial_reason_id
    denial_reason
    notes
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisitorAssetApprovalMutationVariables,
  APITypes.UpdateVisitorAssetApprovalMutation
>;
export const updateRequestEscort = /* GraphQL */ `mutation UpdateRequestEscort($input: UpdateRequestEscortInput!) {
  updateRequestEscort(input: $input) {
    id
    request_id
    escort_id
    escort_source_system_id
    created
    created_by
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRequestEscortMutationVariables,
  APITypes.UpdateRequestEscortMutation
>;
export const updateVisitor = /* GraphQL */ `mutation UpdateVisitor($input: UpdateVisitorInput!) {
  updateVisitor(input: $input) {
    badge_id
    badge_requestUUID
    company
    created
    created_by
    dob_month
    dob_day
    email
    first_name
    id
    last_name
    person_id
    person_source_system_id
    phone_number
    request_id
    requestUUID
    status_id
    title
    ttl
    vendor_day_pass_badge_num
    visitor_type_id
    visitor_pass_assignment_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisitorMutationVariables,
  APITypes.UpdateVisitorMutation
>;
export const updateVisitorAsset = /* GraphQL */ `mutation UpdateVisitorAsset($input: UpdateVisitorAssetInput!) {
  updateVisitorAsset(input: $input) {
    approval_status
    approval_status_id
    asset_type_id
    asset_type
    created
    created_by
    denial_reason
    denial_reason_id
    description
    end_date
    id
    make
    model
    notes
    permanent_flag
    person_id
    serial_num
    site_id
    site_source_system_id
    start_date
    sticker_num
    updated
    updated_by
    visitor_id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisitorAssetMutationVariables,
  APITypes.UpdateVisitorAssetMutation
>;
export const updateVisitorPass = /* GraphQL */ `mutation UpdateVisitorPass($input: UpdateVisitorPassInput!) {
  updateVisitorPass(input: $input) {
    id
    badge_id
    badge_source_system_id
    visitor_pass_assignment_id
    created
    updated
    created_by
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateVisitorPassMutationVariables,
  APITypes.UpdateVisitorPassMutation
>;
