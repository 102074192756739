import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  DateRangePicker,
  DateRangePickerProps,
  FormField,
  Grid,
  Modal,
  Pagination,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TimeInput } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import * as APIt from 'src/API';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels } from './table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery } from '@tanstack/react-query'
import {
  isFromDateValid,
  isThroughDateValid,
  queryLookupTypeValueForTypeAndDescription,
  querySites,
} from 'src/components/utils';
import AddVisitor from 'src/components/common/Visitor/AddVisitor';
import EditVisitor from 'src/components/common/Visitor/EditVisitor';
import { TVisitor } from 'src/types';
import { DefaultValidFromTime, DefaultValidThroughTime, LookupTypes, WelcomeApplicationSettings } from 'src/constants/Constants';
import VisitorBulkLoad from '../../common/VisitorBulkAdd';
import VisitorAssetsTablePanel from 'src/components/common/Visitor/VisitorAssetsTablePanel';
import { debug } from 'src/utils/commonUtils';

export interface IVisitorsTablePanelProps{
  clear: boolean;
  detailsClearedCallback: Function;
  endDate: string | null;
  endTime: string | null;
  escorts: APIt.EmployeeDetails[];
  setSiteCodeCallback: Function;
  setVisitEndDateCallback: Function;
  setVisitEndTimeCallback: Function;
  setVisitorsCallback: Function;
  setVisitDateRangeCallback: Function;
  setVisitStartDateCallback: Function;
  setVisitStartTimeCallback: Function;
  setInvalidThroughDateCallback: Function;
  siteCode: string | null;
  startDate: string | null;
  startTime: string | null;
  username: string;
  visitDateRange: DateRangePickerProps.Value | null;
  visitors: TVisitor[];
}

export default function VisitorsTablePanel(props: IVisitorsTablePanelProps) {
  debug(`VisitorsTablePanel() props is ${JSON.stringify(props)}`);

  const [adding, setAdding] = useState<boolean>(false);
  const [allItems, setAllItems] = useState<TVisitor[]>(props.visitors);
  const [editing, setEditing] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [hoursExtFromDate, setHoursExtFromDate] = React.useState<number>(0);
  const [id, setId] = useState<number>(1);
  const [invalidFromDate, setInvalidFromDate] = useState<boolean>(false);
  const [invalidThroughDate, setInvalidThroughDate] = useState<boolean>(false);
  const [siteInput, setSiteInput] = useState<SelectProps.Option | null>(props.siteCode ? {label: props.siteCode, value: props.siteCode} : null);
  const [selectedItems, setSelectedItems] = useState<TVisitor[]>([]);
  const [showAddVisitor, setShowAddVisitor] = useState<boolean>(false);
  const [showEditVisitor, setShowEditVisitor] = useState<boolean>(false);
  const [showVisitorBulkLoad, setShowVisitorBulkLoad] = useState<boolean>(false);
  const [validFromDateInput, setValidFromDateInput] = useState<string>(props.startDate || '');
  const [validFromTimeInput, setValidFromTimeInput] = useState<string>(props.startTime || DefaultValidFromTime);
  const [validThroughDateInput, setValidThroughDateInput] = useState<string>(props.endDate || '');
  const [validThroughTimeInput, setValidThroughTimeInput] = useState<string>(props.endTime || DefaultValidThroughTime);
  const [visitDateRange, setVisitDateRange] = useState<DateRangePickerProps.Value | null>(props.visitDateRange || null);
  const [visitMaxDays, setVisitMaxDays] = React.useState<number>(7);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestVisitorAccess.Visitors.TablePanel');

  const siteInputRef = useRef<HTMLInputElement>(null);

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const addVisitorButtonHandler = () => {
    setShowAddVisitor(true);
  };

  const addVisitor = (visitor: TVisitor) => {
    debug(`VisitorsTablePanel() addVisitor() visitor is ${JSON.stringify(visitor)}`);
    setAdding(true);
    let invalidDate = false;
    if (!isFromDateValid(new Date(`${validFromDateInput} ${validFromTimeInput}`), hoursExtFromDate)) {
      setInvalidFromDate(true);
      invalidDate = true;
    }
    if (!isThroughDateValid(
      new Date(`${validFromDateInput} ${validFromTimeInput}`),
      new Date(`${validThroughDateInput} ${validThroughTimeInput}`),
      visitMaxDays))
    {
      setInvalidThroughDate(true);
      invalidDate = true;
    }
    if (invalidDate) {
      setAdding(false);
      return;
    }
    let i = id + 1;
    setId(i);
    props.setVisitorsCallback([...allItems, visitor]);
    setShowAddVisitor(false);
    setAdding(false);
  };

  const editVisitor = (visitor: TVisitor) => {
    debug(`VisitorsTablePanel() editVisitor() visitor is ${JSON.stringify(visitor)}`);
    setEditing(true);
    let invalidDate = false;
    if (!isFromDateValid(new Date(`${validFromDateInput} ${validFromTimeInput}`), hoursExtFromDate)) {
      setInvalidFromDate(true);
      invalidDate = true;
    }
    if (!isThroughDateValid(
      new Date(`${validFromDateInput} ${validFromTimeInput}`),
      new Date(`${validThroughDateInput} ${validThroughTimeInput}`),
      visitMaxDays))
    {
      setInvalidThroughDate(true);
      invalidDate = true;
    }
    if (invalidDate) {
      setEditing(false);
      return;
    }
    props.setVisitorsCallback([...(allItems.filter(v => v.id !== visitor.id)), visitor]);
    setShowEditVisitor(false);
    setEditing(false);
  };

  const dateRangeInputChangeHandler = async (value: DateRangePickerProps.Value | null) => {
    debug(`dateRangeInputChangeHandler() value is ${JSON.stringify(value)}`);
    setVisitDateRange(value);
    await props.setVisitDateRangeCallback(value);
    if (value?.type == 'absolute') {
      setValidFromDateInput(value.startDate);
      setInvalidFromDate(!isFromDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), hoursExtFromDate));
      setValidThroughDateInput(value.endDate);
      setInvalidFromDate(!isThroughDateValid(new Date(`${value.startDate} ${validFromTimeInput}`), new Date(`${value.endDate} ${validThroughTimeInput}`)));
      props.setVisitStartDateCallback(value.startDate);
      props.setVisitEndDateCallback(value.endDate);
    }
    if (value?.type == 'relative') {
      const fromDate = new Date();
      fromDate.setDate(fromDate.getDate() + 1);
      debug(`dateRangeInputChangeHandler() fromDate is ${fromDate}`);
      const fromDateInput = `${fromDate.getFullYear()}-${(fromDate.getMonth()+1).toString().padStart(2, '0')}-${fromDate.getDate().toString().padStart(2, '0')}`;
      debug(`dateRangeInputChangeHandler() fromDateInput is ${fromDateInput}`);
      setValidFromDateInput(fromDateInput);
      const throughDate = new Date();
      let factor = 1;
      switch(value.unit) {
        case('day'):
          factor = Math.abs(value.amount)*1;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('month'):
          factor = Math.abs(value.amount);
          debug(`dateRangeInputChangeHandler() ${throughDate.getFullYear()} ${throughDate.getMonth()} ${throughDate.getDate()}`);
          const dateUTC = Date.UTC(throughDate.getFullYear(), throughDate.getMonth() + factor, throughDate.getDate());
          debug(`dateRangeInputChangeHandler() dateUTC is ${dateUTC}`);
          throughDate.setTime(dateUTC);
          break;
        case('week'):
          factor = Math.abs(value.amount)*7;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
        case('year'):
          factor = Math.abs(value.amount)*365;
          throughDate.setDate(throughDate.getDate() + factor);
          break;
      }
      debug(`dateRangeInputChangeHandler() throughDate is ${throughDate}`);
      const throughDateInput = `${throughDate.getFullYear()}-${(throughDate.getMonth()+1).toString().padStart(2, '0')}-${throughDate.getDate().toString().padStart(2, '0')}`;
      debug(`dateRangeInputChangeHandler() throughDateInput is ${throughDateInput}`);
      setValidThroughDateInput(throughDateInput);
      if (fromDate !== null) setInvalidFromDate(!isFromDateValid(fromDate, hoursExtFromDate));
      if (fromDate !== null && throughDate !== null) setInvalidThroughDate(throughDateInput == '' ? false : !isThroughDateValid(fromDate, throughDate));
      await props.setVisitStartDateCallback(fromDateInput);
      await props.setVisitEndDateCallback(throughDateInput);
    }
  };

  const fromDateInputChangeHandler = (value: string) => {
    setValidFromDateInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${value} ${validFromTimeInput}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${value} ${validFromTimeInput}`),
          new Date(`${validFromDateInput} ${validThroughTimeInput}`),
          visitMaxDays));
    props.setVisitStartDateCallback(value);
  };
 
  const fromTimeInputChangeHandler = (value: string) => {
    setValidFromTimeInput(value);
    setInvalidFromDate(!isFromDateValid(new Date(`${validFromDateInput} ${value}`), hoursExtFromDate));
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${value}`),
          new Date(`${validThroughDateInput} ${validThroughTimeInput}`),
          visitMaxDays));
    props.setVisitStartTimeCallback(value);
  };
 
  const throughDateInputChangeHandler = (value: string) => {
    setValidThroughDateInput(value);
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${value} ${validThroughTimeInput}`),
          visitMaxDays));
    props.setVisitEndDateCallback(value);
  };
 
  const throughTimeInputChangeHandler = (value: string) => {
    setValidThroughTimeInput(value);
    setInvalidThroughDate(
      validThroughDateInput == ''
        ? false 
        : !isThroughDateValid(
          new Date(`${validFromDateInput} ${validFromTimeInput}`),
          new Date(`${validThroughDateInput} ${value}`),
          visitMaxDays));
    props.setVisitEndTimeCallback(value);
  };

  const removeSelected = () => {
    const currentItems = allItems;
    const newItems = currentItems.filter(al => !selectedItems.find(i => i.id == al.id));
    setSelectedItems([]);
    setAllItems(newItems);
    props.setVisitorsCallback(newItems);
  };

  const onSelectionChangeHandler = (detail: { selectedItems: React.SetStateAction<any[]> }) => {
    setSelectedItems(detail.selectedItems as TVisitor[]);
  };

  const sitesQuery = useQuery({
    queryKey: ['sites'],
    queryFn: () => querySites(true),
    retry: 3
  });

  const siteInputHandler = (detail: SelectProps.ChangeDetail) => {
    setSiteInput(detail.selectedOption);
    props.setSiteCodeCallback(detail.selectedOption.value);
  };

  const addBulkVisitors = (visitors: TVisitor[]) => {
    debug(`VisitorsTablePanel() addBulkVisitors() visitors is ${JSON.stringify(visitors)}`);
    props.setVisitorsCallback([...allItems, ...visitors]);
    setId(visitors[visitors.length-1].id + 1);
  };

  useEffect(() => {
    if (props.escorts.length > 0
      && siteInputRef
      && !siteInput
      && props.visitors.length == 0
    ) {
      siteInputRef.current?.focus();
    }
  }, [props.escorts, props.visitors, siteInputRef]);

  useEffect(() => {
    debug(`VisitorsTablePanel() useEffect()[props.clear]`);
    setSiteInput(null);
    setValidFromDateInput('');
    setValidFromTimeInput(DefaultValidFromTime);
    setValidThroughDateInput('');
    setValidThroughTimeInput(DefaultValidThroughTime);
    setInvalidFromDate(false);
    setInvalidThroughDate(false);
    setVisitDateRange(null);
    props.setVisitDateRangeCallback(null);
    props.detailsClearedCallback();
  }, [props.clear])

  useEffect(() => {
    setSelectedItems([]);
    setAllItems(props.visitors);
  }, [props.visitors]);

  useEffect(() => {
    setSiteInput(props.siteCode ? {label: props.siteCode, value: props.siteCode} : null);
  }, [props.siteCode]);

  useEffect(() => {
    if (props.startDate) fromDateInputChangeHandler(props.startDate);
  }, [props.startDate]);

  useEffect(() => {
    if (props.startTime && props.startTime !== DefaultValidFromTime) fromTimeInputChangeHandler(props.startTime);
  }, [props.startTime]);

  useEffect(() => {
    if (props.endDate) throughDateInputChangeHandler(props.endDate);
  }, [props.endDate]);

  useEffect(() => {
    if (props.endTime && props.endTime !== DefaultValidThroughTime) throughTimeInputChangeHandler(props.endTime);
  }, [props.startTime]);

  useEffect(() => {
    setVisitDateRange(props.visitDateRange);
  }, [props.visitDateRange]);

  useEffect(() => {
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.VisitMaxDays)
    .then((v) => setVisitMaxDays(parseInt(v.value)));
    queryLookupTypeValueForTypeAndDescription(
      LookupTypes.WelcomeApplicationSettings,
      WelcomeApplicationSettings.HoursExtensionFromDateFormValidation)
    .then((v) => setHoursExtFromDate(parseInt(v.value)));
  }, []);

  useEffect(() => {
    props.setInvalidThroughDateCallback(invalidThroughDate);
  }, [invalidThroughDate]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='RequestVisitorAccessTableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        data-testid='RequestVisitorAccessTable'
        columnDefinitions={ColumnDefinitions}
        header={
          <>
          <Header
            counter={`(${itemsCount().toString()})`}
          >
            {bundle.getMessage('visit-details')}
          </Header>
          <Grid gridDefinition={[{colspan: 12}]}>
            <div>
            <SpaceBetween direction='horizontal' size='m'>
              <FormField label={bundle.getMessage('site')} stretch={true}>
                <div style={{width: 120}}>
                  <Select
                    data-testid='VisitorsSiteSelect'
                    filteringType='auto'
                    loadingText={bundle.getMessage('loading-sites')}
                    onChange={({ detail }) => siteInputHandler(detail)}
                    options={
                      sitesQuery.data?.map(s => {
                        return {label: s.SiteCode, value: s.SiteCode};
                      })
                    }
                    placeholder={bundle.getMessage('select-a-site')}
                    ref={siteInputRef}
                    selectedOption={siteInput}
                    statusType={sitesQuery.isLoading || sitesQuery.isFetching? 'loading' : 'finished'}
                  />
                </div>
              </FormField>
              <FormField label={bundle.getMessage('days-site-time')}>
                <DateRangePicker
                  data-testid='VisitorsDateRangePicker'
                  dateOnly
                  hideTimeOffset
                  i18nStrings={{
                    todayAriaLabel: bundle.getMessage('today'),
                    nextMonthAriaLabel: bundle.getMessage('next-month'),
                    previousMonthAriaLabel: bundle.getMessage('previous-month'),
                    customRelativeRangeDurationLabel: bundle.getMessage('duration'),
                    customRelativeRangeDurationPlaceholder: bundle.getMessage('enter-duration'),
                    customRelativeRangeOptionLabel: bundle.getMessage('custom-range'),
                    customRelativeRangeOptionDescription: bundle.getMessage('set-a-custom-range-in-the-future'),
                    customRelativeRangeUnitLabel: bundle.getMessage('unit-of-time'),
                    formatRelativeRange: (rr): string => {
                      return(
                        `${bundle.getMessage('next')} ${Math.abs(rr.amount)} ${bundle.formatMessage(rr.unit, { amount: rr.amount.toString() })}`
                      );
                    },
                    formatUnit: (e, t) => (1 === t ? e : `${bundle.formatMessage(e, { amount: t.toString() })}`),
                  dateTimeConstraintText: '',
                    relativeModeTitle: bundle.getMessage('relative-range'),
                    absoluteModeTitle: bundle.getMessage('absolute-range'),
                    relativeRangeSelectionHeading: bundle.getMessage('choose-a-range'),
                    startDateLabel: bundle.getMessage('start-date'),
                    endDateLabel: bundle.getMessage('end-date'),
                    startTimeLabel: bundle.getMessage('start-time'),
                    endTimeLabel: bundle.getMessage('end-time'),
                    clearButtonLabel: bundle.getMessage('clear-and-dismiss'),
                    cancelButtonLabel: bundle.getMessage('cancel'),
                    applyButtonLabel: bundle.getMessage('apply'),
                  }}
                  invalid={invalidFromDate || invalidThroughDate}
                  isDateEnabled={date => {
                    const [month, day, year] = [
                      (date.getMonth() + 1).toString().padStart(2, '0'),
                      date.getDate().toString().padStart(2, '0'),
                      date.getFullYear(),
                    ];
                    const convertedDate = new Date(`${year}-${month}-${day} 23:59:59`);
                    return isFromDateValid(convertedDate, hoursExtFromDate);
                  }}
                  isValidRange={(value: DateRangePickerProps.Value | null): DateRangePickerProps.ValidationResult => {
                    const result: DateRangePickerProps.ValidationResult = { valid: true };
                    return result;
                  }}
                  onChange={({ detail }) => dateRangeInputChangeHandler(detail.value)}
                  placeholder='YYYY/MM/DD'
                  rangeSelectorMode='absolute-only'
                  relativeOptions={[
                    { key: 'tomorrow', amount: -1, unit: 'day', type: 'relative' },
                    { key: 'next-7-days', amount: -7, unit: 'day', type: 'relative' },
                    { key: 'next-14-days', amount: -14, unit: 'day', type: 'relative' },
                    { key: 'next-365-days', amount: -365, unit: 'day', type: 'relative' },
                  ]}
                  value={visitDateRange}
                />
              </FormField>
              <FormField label={bundle.getMessage('from-time')}>
                <div style={{width: 100}}>
                  <TimeInput
                    data-testid='VisitorsFromTimeInput'
                    format="hh:mm"
                    invalid={invalidFromDate}
                    onChange={event => fromTimeInputChangeHandler(event.detail.value)}
                    placeholder="hh:mm"
                    value={validFromTimeInput}
                  />
                </div>
              </FormField>
              <FormField label={bundle.getMessage('to-time')}>
                <div style={{width: 100}}>
                  <TimeInput
                    data-testid='VisitorsThroughTimeInput'
                    format="hh:mm"
                    invalid={invalidThroughDate}
                    onChange={event => throughTimeInputChangeHandler(event.detail.value)}
                    placeholder="hh:mm"
                    value={validThroughTimeInput}
                  />
                </div>
              </FormField>
              <div style={{position:'relative', bottom: '-25px'}}>
                <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  data-testid='VisitorsAddVisitorButton'
                  disabled={
                    !siteInput
                    || !validFromDateInput
                    || !validFromTimeInput
                    || !validThroughDateInput
                    || !validThroughTimeInput
                    || invalidFromDate
                    || invalidThroughDate
                  }
                  loading={adding || editing}
                  onClick={addVisitorButtonHandler}
                  variant='normal'
                >
                  {bundle.getMessage('add-visitor')}
                </Button>
                <Button
                  disabled={
                    !siteInput
                    || !validFromDateInput
                    || !validFromTimeInput
                    || !validThroughDateInput
                    || !validThroughTimeInput
                    || invalidFromDate
                    || invalidThroughDate
                  }
                  loading={adding || editing}
                  onClick={() => setShowVisitorBulkLoad(true)}
                  variant='normal'
                >
                  {bundle.getMessage('bulk-add-visitors')}
                </Button>
                </SpaceBetween>
              </div>
            </SpaceBetween>
            </div>
          </Grid>
          </>
        }
        footer={
          <SpaceBetween direction='vertical' size='xs'>
            <Box float='right'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                  disabled={selectedItems.length !== 1}
                  onClick={() => setShowEditVisitor(true)}
                  variant='normal'
                >
                  {bundle.getMessage('edit')}
                </Button>
                <Button
                  disabled={selectedItems.length == 0}
                  onClick={removeSelected}
                  variant='primary'
                >
                  {bundle.getMessage('remove-selected')}
                </Button>
              </SpaceBetween>
            </Box>
          </SpaceBetween>
        }
        items={items}
        selectionType='multi'
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) =>
          onSelectionChangeHandler(detail)
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        trackBy='id'
      />
      {selectedItems.length === 1 && selectedItems[0].assets && selectedItems[0].assets?.length > 0
      &&
      <Container header={bundle.getMessage('assets')}>
        <VisitorAssetsTablePanel
          isTableLoading={false}
          readOnly={true}
          siteCode=''
          setVisitorAssets={undefined}
          username={props.username}
          visitorAssets={selectedItems[0].assets || []}
          visitorAssetTypesQuery={null}
          visitorId=''
        />
      </Container>
      }
    </div>
    {showAddVisitor
    &&
    <AddVisitor
      addVendor={false}
      addVisitorCallback={addVisitor}
      cancelAddVisitorCallback={() => setShowAddVisitor(false)}
      id={id}
    />}
    {showVisitorBulkLoad
    &&
    <Modal
      header={bundle.getMessage('bulk-add-visitors')}
      onDismiss={() => setShowVisitorBulkLoad(false)}
      size='large'
      visible={showVisitorBulkLoad}
    >
      <VisitorBulkLoad
        addBulkVisitorsCallback={addBulkVisitors}
        addVendor={false}
        closeCallback={() => setShowVisitorBulkLoad(false)}
        id={id}
      />
    </Modal>
    }
    {showEditVisitor
    &&
    <EditVisitor
      editVendor={false}
      editVisitorCallback={editVisitor}
      cancelEditVisitorCallback={() => setShowEditVisitor(false)}
      id={id}
      visitor={selectedItems[0]}
      visitorAssets={selectedItems[0]?.assets || []}
    />}
    </>
  );
}
