import React, { useEffect, useRef, useState } from 'react';
import { ApprovalStatus, LookupTypes, Modes, VisitorRequestStatus, VisitorTypes } from 'src/constants/Constants';
import CardHolders from './CardHolders/TablePanel';
import AccessDetails, { IAccessDetailsRecord } from './AccessDetails/TablePanel';
import { Box, Button, Container, Flashbar, FormField, Grid, Header, SpaceBetween, Spinner, Textarea } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { ISubmitAccessLevelRequest, submitAccessRequest } from 'src/components/SelfService/utils';
import * as APIt from 'src/API';
import * as uuid from 'uuid';
import { getLookupTypeValueId } from 'src/components/utils';
import { useQueryClient } from '@tanstack/react-query';
import { debug } from 'src/utils/commonUtils';

interface IRequestAccessProps {
  accessLevels: IAccessDetailsRecord[];
  disableAutoApproval: boolean;
  cardholders: APIt.EmployeeDetails[];
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  mode: Modes;
  reasonForAccessRequest: string;
  setAccessLevelsCallback: Function;
  setCardholdersCallback: Function;
  setCardholdersQuickLinksCallback: Function;
  setReasonForAccessRequestCallback: Function;
  username: string;
}

export default function RequestAccess(props: IRequestAccessProps) {
  debug(`RequestAccess() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAccess.index');

  const [accessLevels, setAccessLevels] = useState<IAccessDetailsRecord[]>(props.accessLevels);
  const [cardholders, setCardholders] = useState<APIt.EmployeeDetails[]>(props.cardholders);
  const [cardholdersMissingActiveBadge, setCardholdersMissingActiveBadge] = useState<boolean | null>(null);
  const [clearDetails, setClearDetails] = useState<boolean>(false);
  const [invalidAccessLevelForContractor, setInvalidAccessLevelForContractor] = useState<boolean>(false);
  const [reasonForAccessRequestInput, setReasonForAccessRequestInput] = useState<string>(props.reasonForAccessRequest);
  const [requestProcessing, setRequestProcessing] = useState<boolean>(false);
  const [showRequestFailedFlashbar, setShowRequestFailedFlashbar] = useState<boolean>(false);
  const [showRequestSubmittedFlashbar, setShowRequestSubmittedFlashbar] = useState<boolean>(false);

  const reasonForAccessRequestRef = useRef<HTMLInputElement>(null);

  const queryClient = useQueryClient();

  const submitRequest = async () => {
    debug(`RequestAccess() submitRequest()`);

    setRequestProcessing(true);

    const sourceSystem = 'PACS';
    const lookupPromises = [];
    let
      visitorTypeEmployee: string | undefined,
      accessLevelSourceSystemId: string | undefined,
      requestorSourceSystemId: string | undefined,
      personSourceSystemId: string | undefined,
      visitorStatusPendingApprovalId: string | undefined,
      visitorAccessLevelStatusPendingApprovalId: string | undefined;

    lookupPromises.push(getLookupTypeValueId(LookupTypes.VisitorType,VisitorTypes.Employee)
      .then(id => visitorTypeEmployee = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.AccessLevelSourceSystem,sourceSystem)
      .then(id => accessLevelSourceSystemId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.RequestorSourceSystem,sourceSystem)
      .then(id => requestorSourceSystemId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.PersonSourceSystem,sourceSystem)
      .then(id => personSourceSystemId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.VisitorRequestStatus,VisitorRequestStatus.PendingApproval)
      .then(id => visitorStatusPendingApprovalId = id));
    lookupPromises.push(getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus,ApprovalStatus.PendingApproval)
      .then(id => visitorAccessLevelStatusPendingApprovalId = id));

    await Promise.all(lookupPromises);

    if (!visitorTypeEmployee) throw `unable to locate visitor type id for ${VisitorTypes.Employee}`;
    if (!accessLevelSourceSystemId) throw `unable to locate access level source system id for ${sourceSystem}`;
    if (!requestorSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    if (!personSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    if (!visitorStatusPendingApprovalId) throw `unable to locate visitor request status id for ${VisitorRequestStatus.PendingApproval}`;
    if (!visitorAccessLevelStatusPendingApprovalId) throw `unable to locate visitor access level status id for ${ApprovalStatus.PendingApproval}`;

    const requestId = uuid.v4();
    const visitorsInput: APIt.CreateVisitorInput[] = [];
    const accessLevelsInput: APIt.CreateVisitorAccessLevelInput[] = [];
    
    cardholders.forEach(cardholder => {
      const visitorId = uuid.v4();
      const visitorInput: APIt.CreateVisitorInput = {
        created_by: props.username,
        email: cardholder.email,
        first_name: cardholder.firstName,
        id: visitorId,
        last_name: cardholder.lastName,
        person_id: cardholder.id,
        person_source_system_id: personSourceSystemId,
        request_id: requestId,
        status_id: visitorStatusPendingApprovalId,
        title: cardholder.title,
        visitor_type_id: visitorTypeEmployee!,
      };
      visitorsInput.push(visitorInput);
      accessLevels.map(al => {
        const visitorAccessLevel: APIt.CreateVisitorAccessLevelInput =
          {
            access_level_id: al.accessLevelId!,
            access_level_name: al.accessLevelName!,
            access_level_source_system_id: accessLevelSourceSystemId!,
            created_by: props.username,
            end_date: al.validThrough,
            id: uuid.v4(),
            permanent_flag: al.permanentFlag,
            reason: reasonForAccessRequestInput,
            site_id: al.site,
            start_date: al.validFrom,
            status_code_id: visitorAccessLevelStatusPendingApprovalId!,
            visitor_id: visitorId,
          };
        accessLevelsInput.push(visitorAccessLevel);
      })
    });

    const accessLevelRequest: ISubmitAccessLevelRequest = {
      accessLevels: accessLevelsInput,
      escorts: [],
      request: {
        id: requestId,
        site_id: undefined,
        site_source_system_id: undefined,
        reason: reasonForAccessRequestInput,
        requestor_id: props.username,
        requestor_source_system_id: requestorSourceSystemId,
        start_date: undefined,
        end_date: undefined,
        status: undefined,
        created_by: props.username,
      },
      visitors: visitorsInput,
    };
    try {
      await submitAccessRequest(accessLevelRequest, props.disableAutoApproval);
      setShowRequestSubmittedFlashbar(true);
      clearRequest();
      queryClient.fetchQuery({ queryKey: ['pendingVisitorAccessLevels'] });
    } catch(error) {
      console.error(error);
      debug(`RequestAccess() submitRequest() error is ${JSON.stringify(error)}`);
      setShowRequestFailedFlashbar(true);
    }
    setRequestProcessing(false);
  };

  const clearRequest = async () => {
    setReasonForAccessRequestInput('');
    await Promise.all(
      [
        props.setAccessLevelsCallback([]),
        props.setCardholdersCallback([]),
        props.setReasonForAccessRequestCallback('')
      ]);
    setClearDetails(true);
  };

  const detailsCleared = () => {
    setClearDetails(false);
  };

  useEffect(() => {
    setCardholders(props.cardholders);
    setAccessLevels(props.accessLevels);
    setInvalidAccessLevelForContractor(false);
    if (props.cardholders.find(ch => ch.jobLevel === '99')
    && props.accessLevels.find(al => al.accessLevelName?.includes('-4') || al.accessLevelName?.includes('-5-'))) {
      setInvalidAccessLevelForContractor(true);
    }
  }, [props.accessLevels, props.cardholders])

  const resetShowRequestSubmittedFlashbar = () => {
    setShowRequestSubmittedFlashbar(false);
  };

  const resetShowRequestFailedFlashbar = () => {
    setShowRequestFailedFlashbar(false);
  };

  const FlashMessageRequestSubmitted = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('request-submitted'),
            buttonText: bundle.getMessage('ok'),
            onButtonClick: resetShowRequestSubmittedFlashbar
          },
        ]
      }
    />
  );

  const FlashMessageRequestFailed = () => (
    <Flashbar
      items = {
        [
          {
            type: 'error',
            dismissible: false,
            content: bundle.getMessage('request-failed'),
            buttonText: bundle.getMessage('ok'),
            onButtonClick: resetShowRequestFailedFlashbar
          },
        ]
      }
    />
  );

  if (isBundleLoading) return <Spinner/>

  return(
    <Container
      header={
        <Header
          variant='h2'
        >
         {bundle.getMessage('access-request')}
        </Header>}
      footer={
        <Box float={'right'}>
          <SpaceBetween key={'RequestAccessBottomButtonsSpaceBetween'} size='s' direction='horizontal'>
            <Button
              onClick={clearRequest}
              disabled={cardholders.length == 0 && accessLevels.length == 0 && !reasonForAccessRequestInput}
            >
              {bundle.getMessage('clear')}
            </Button>
            <Button
              data-testid='RequestAccessSubmitRequestButton'
              disabled={
                accessLevels.length == 0
                || cardholders.length == 0
                || cardholdersMissingActiveBadge == null
                || cardholdersMissingActiveBadge
                || invalidAccessLevelForContractor
                || !reasonForAccessRequestInput
              }
              loading={requestProcessing}
              onClick={submitRequest}
              variant='primary'
            >
              {bundle.getMessage('submit-request')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      variant='default'
    >
      {showRequestSubmittedFlashbar && <FlashMessageRequestSubmitted/>}
      {showRequestFailedFlashbar && <FlashMessageRequestFailed/>}
      <CardHolders
        cardholders={cardholders}
        cardholdersQuickLinks={props.cardholdersQuickLinks}
        darkMode={props.darkMode}
        setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
        username={props.username}
        setCardholdersCallback={props.setCardholdersCallback}
        setCardholderMissingActiveBadgeCallback={setCardholdersMissingActiveBadge}
      />
      <AccessDetails
        accessLevels={accessLevels}
        cardholders={cardholders}
        clear={clearDetails}
        detailsClearedCallback={detailsCleared}
        setAccessLevelsCallback={(accessLevels: IAccessDetailsRecord[]) => {
          props.setAccessLevelsCallback(accessLevels);
          if (!reasonForAccessRequestInput && accessLevels.length > 0) reasonForAccessRequestRef.current?.focus();
        }}
        username={props.username}
      />
      <Container
        header={
          <Header
            variant='h2'
          >
           {bundle.getMessage('justification')}
          </Header>}
        variant='stacked'
      >
        <FormField label={bundle.getMessage('reason-for-access-request')}>
          <Textarea
            data-testid='RequestAccessReasonTextarea'
            onChange={({ detail }) => {
              setReasonForAccessRequestInput(detail.value);
              props.setReasonForAccessRequestCallback(detail.value);
            }}
            placeholder={bundle.getMessage('reason-for-access-request-placeholder')}
            ref={reasonForAccessRequestRef}
            value={reasonForAccessRequestInput}
          />
        </FormField>
      </Container>
    </Container>
  );
}