import React, { useEffect, useState } from 'react';
import {
  Flashbar,
  FormField,
  Grid,
  Input,
  Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { IVisitorAccessLevelWithAction } from './TablePanel';
import { IQueryEmployeeActiveBadgesParams, queryEmployeeActiveBadges, queryEmployeeDetails } from 'src/components/utils';
import { VisitorTypes } from 'src/constants/Constants';
import { debug } from 'src/utils/commonUtils';

export interface ISubmissionApproval {
  accessLevelApproval: IVisitorAccessLevelWithAction;
  setMissingActiveBadgeCallback: Function;
  setNotesCallback: Function;
}

export default function SubmissionApproval(props: ISubmissionApproval) {
  debug(`SubmissionApproval() props is ${JSON.stringify(props)}`);

  const [missingActiveBadge, setMissingActiveBadge] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAccessRequests.SubmissionApproval');

  useEffect(() => {
    debug(`SubmissionApproval() useEffect[props.accessLevelApproval] props.accessLevelApproval.person_id is ${props.accessLevelApproval.person_id}`);

    const updateMissingActiveBadge = async () => {
      let employeeDetails;
      try {
        employeeDetails = await queryEmployeeDetails(props.accessLevelApproval.person_id);
        debug(`SubmissionApproval() useEffect[props.accessLevelApproval] updateMissingActiveBadge() employeeDetails is ${JSON.stringify(employeeDetails)}`);
      } catch(error) {
        debug(`SubmissionApproval() useEffect[props.accessLevelApproval] queryEmployeeDetails error is ${error} JSON: ${JSON.stringify(error)}`);
        setMissingActiveBadge(true);
        props.setMissingActiveBadgeCallback(true, props.accessLevelApproval.id);
      } 
      try {
        if (!employeeDetails) return;
        const queryEmployeeActiveBadgeParams: IQueryEmployeeActiveBadgesParams = {
          emplId: employeeDetails.id.toString(),
          region: employeeDetails.region || 'AMER'
        };
        const activeBadges = await queryEmployeeActiveBadges(queryEmployeeActiveBadgeParams);
        debug(`SubmissionApproval() useEffect[props.accessLevelApproval] updateMissingActiveBadge() activeBadges is ${JSON.stringify(activeBadges)}`);
        setMissingActiveBadge(activeBadges.length == 0);
        props.setMissingActiveBadgeCallback(activeBadges.length == 0, props.accessLevelApproval.id);
      } catch(error) {
        debug(`SubmissionApproval() useEffect[props.accessLevelApproval] queryEmployeeActiveBadges error is ${error} JSON: ${JSON.stringify(error)}`);
        setMissingActiveBadge(true);
        props.setMissingActiveBadgeCallback(true, props.accessLevelApproval.id);
      }
    };

    if (props.accessLevelApproval.visitor_type == VisitorTypes.Employee) {
      updateMissingActiveBadge();
    } else {
      props.setMissingActiveBadgeCallback(false, props.accessLevelApproval.id);
    }
  
    return () => {
      setMissingActiveBadge(false);
    };
  }, [props.accessLevelApproval]);
  
  if (isBundleLoading) return <Spinner/>;

  return(
    <div key={`SubmissionApproval${props.accessLevelApproval.id}`}>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 6 }]} >
        {props.accessLevelApproval.person_id}
        {props.accessLevelApproval.access_level_name}
        {props.accessLevelApproval.permanent_flag ? bundle.getMessage('permanent') : `${props.accessLevelApproval.start_date} ${bundle.getMessage('through')} ${props.accessLevelApproval.end_date}`}
      </Grid>
      <FormField label={bundle.getMessage('approval-notes')}>
        <Input
          onChange={({detail}) => props.setNotesCallback(props.accessLevelApproval.id, detail.value) }
          value={props.accessLevelApproval.notes || ''}
        />
      </FormField>
      {missingActiveBadge
      &&
      <Flashbar
        items = {
          [
            {
              type: 'error',
              dismissible: false,
              content: bundle.getMessage('cardholder-missing-active-badge')
            },
          ]
        }
      />}
    </div>
  );
}