import React, { useEffect, useState } from 'react';
import { Button, Pagination, Select, SelectProps, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult } from '@tanstack/react-query'
import { debug } from '../../../utils/commonUtils';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import { createViewerOptions } from '../utils';
import * as APIt from '../../../API';


const PAGE_SIZE = 10;

export interface RefreshCallBackInterface {
  (pageNumber: number): void;
}

export interface AccessLevelsTablePanelPropsInterface {
  accessLevelsQuery: UseQueryResult<APIt.AccessLevel[] | null, unknown>;
  accessLevelQueryUser: string;
  delegatorsQuery: UseQueryResult<APIt.Delegation[] | null, unknown>;
  employeeId: number;
  setAccessLevelsCallback: Function;
  username: string;    
}

export default function AccessLevelsTablePanel(props: AccessLevelsTablePanelPropsInterface ) {
  debug(`AccessLevelsTablePanel() props is ${JSON.stringify(props)}`);


  const [bundle, isBundleLoading] = useBundle('components.SelfService.AccessLevels.TablePanel');
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [viewerSelect, setViewerSelect] = useState<SelectProps.Option>({ label: props.username, value: props.employeeId.toString() });
  const [viewerOptions, setViewerOptions] = useState<SelectProps.Option[]>(); 
  const pageSize = PAGE_SIZE;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    props.accessLevelsQuery?.data || [],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-access-levels-found') : 'No Assets Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCount = (): number => {
    if (props.accessLevelsQuery.data?.length) return props.accessLevelsQuery.data.length;
    return 0;
  };

  useEffect(() => {
    if (viewerSelect?.value) {
      props.setAccessLevelsCallback(viewerSelect?.value);
    }
  }, [viewerSelect]);

  useEffect(() => {    
    let options = createViewerOptions('View Access', props.delegatorsQuery.data ?? [], props.username, props.employeeId.toString());
    let option = options.find(option => option.value == props.accessLevelQueryUser);
    if (option) {
      setViewerSelect(option);
    }    
    setViewerOptions(options);
  }, [props.delegatorsQuery.data]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel='Filter Access Levels'
            filteringPlaceholder='Find Access Levels'
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>              
                <Select
                  key="selectViewerAccessLevels"
                  data-testid='ViewerSelect'
                  onChange={({ detail }) => setViewerSelect(
                    {
                      label: detail.selectedOption.label!,
                      value: detail.selectedOption.value!
                    })
                  }
                  options={viewerOptions}                                    
                  selectedOption={viewerSelect}
                  disabled={props.accessLevelsQuery.isLoading || props.accessLevelsQuery.isFetching}                
                />
                <CsvDownloadButton
                  data={allPageItems}
                  delimiter={','}
                  disabled={props.accessLevelsQuery.isFetching || items.length < 1}
                  filename={`${props.username !== viewerSelect.label ? viewerSelect.label : ''}AccessLevels-${new Date().toISOString()}`}
                />
                <Button
                  loading={props.accessLevelsQuery.isFetching}
                  key="refreshBtn"
                  onClick={async () => {
                    await props.accessLevelsQuery.refetch()
                  }}
                  iconName='refresh'>{bundle.getMessage('refresh')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
            {bundle.getMessage('access-levels')}
          </Header>
        }
        footer={<></>}
        items={items}
        loading={props.accessLevelsQuery.isLoading || props.accessLevelsQuery.isFetching}
        loadingText={bundle.getMessage('loading-access-levels')}
        pagination={
          props.accessLevelsQuery && props.accessLevelsQuery.data && props.accessLevelsQuery.data.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
      />
    </div>
    </>
  );
}
