import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import { listVisitorAssetsForSiteAndNameOrEmail, listVisitorAssetsForSiteAndPerson, listVisitorAssetsForSiteAndSticker } from 'src/graphql/queries';
import { auditDecorator } from 'src/components/utils';
import { VisitorTypes } from 'src/constants/Constants';
import { debug } from 'src/utils/commonUtils';

export interface IQueryVisitorSiteAssetsParams {
  email: string,
  firstName: string;
  lastName: string;
  personId: string;
  siteId: string;
  stickerNum: string;
  visitorType: VisitorTypes;  
  
}

export let queryVisitorSiteAssets = async (params: IQueryVisitorSiteAssetsParams): Promise<APIt.VisitorAsset[] | null> => {
  debug(`queryVisitorSiteAssets() params is ${JSON.stringify(params)}`);  
  if (!params.siteId) return null;

  let pendingVisitorAssetsForRequestor: APIt.VisitorAsset[] = [];

  if (params.stickerNum !== '') {
    try {
      const response = await API.graphql(graphqlOperation(listVisitorAssetsForSiteAndSticker,
        {
          site_id: params.siteId,
          sticker_num: params.stickerNum !== '' ? params.stickerNum : undefined                   
        })) as GraphQLResult<APIt.ListVisitorAssetsForSiteAndStickerQuery>;
      debug(`queryVisitorSiteAssets() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listVisitorAssetsForSiteAndSticker) {
        pendingVisitorAssetsForRequestor = response.data.listVisitorAssetsForSiteAndSticker as APIt.VisitorAsset[];
      }
    } catch(error) {
      console.error(`queryVisitorSiteAssets(): error is ${JSON.stringify(error)}`);
      throw error;
    }
  }else if (params.personId && params.personId !== '') {
    try {
      const response = await API.graphql(graphqlOperation(listVisitorAssetsForSiteAndPerson,
        {
          person_id: params.personId,
          site_id: params.siteId,
        })) as GraphQLResult<APIt.ListVisitorAssetsForSiteAndPersonQuery>;
      debug(`queryVisitorSiteAssets() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listVisitorAssetsForSiteAndPerson) {
        pendingVisitorAssetsForRequestor = response.data.listVisitorAssetsForSiteAndPerson as APIt.VisitorAsset[];
      }
    } catch(error) {
      console.error(`queryVisitorSiteAssets(): error is ${JSON.stringify(error)}`);
      throw error;
    }
  } else if (params.visitorType.valueOf() !== '' && params.email !== '' || params.firstName !== '' || params.lastName !== '') {
    try {
      const response = await API.graphql(graphqlOperation(listVisitorAssetsForSiteAndNameOrEmail,
        {
          email: params.email !== '' ? params.email : undefined,
          first_name: params.firstName !== '' ? params.firstName : undefined,
          last_name: params.lastName !== '' ? params.lastName : undefined,
          site_id: params.siteId,
          visitor_type: params.visitorType,
        })) as GraphQLResult<APIt.ListVisitorAssetsForSiteAndNameOrEmailQuery>;
      debug(`queryVisitorSiteAssets() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.listVisitorAssetsForSiteAndNameOrEmail) {
        pendingVisitorAssetsForRequestor = response.data.listVisitorAssetsForSiteAndNameOrEmail as APIt.VisitorAsset[];
      }
    } catch(error) {
      console.error(`queryVisitorSiteAssets(): error is ${JSON.stringify(error)}`);
      throw error;
    }
  }

  return(pendingVisitorAssetsForRequestor);
};
queryVisitorSiteAssets = auditDecorator('queryVisitorSiteAssets', queryVisitorSiteAssets);