import React from 'react';
import AccessLevelsTablePanel from './TablePanel';
import { debug } from '../../../utils/commonUtils';
import * as APIt from 'src/API';
import { UseQueryResult } from '@tanstack/react-query';

interface IAppProps {
  accessLevelsQuery: UseQueryResult<APIt.AccessLevel[] | null, unknown>;
  accessLevelQueryUser: string;
  delegatorsQuery: UseQueryResult<APIt.Delegation[] | null, unknown>;
  employeeId: number;
  setAccessLevelsCallback: Function;
  username: string; 
}

export interface SelectOptionInterface {
  label: string;
  value: string;
}

export default function AccessLevels(props: IAppProps) {
  debug(`AccessLevels() props is ${JSON.stringify(props)}`);

  return(
    <AccessLevelsTablePanel
      accessLevelQueryUser={props.accessLevelQueryUser}
      delegatorsQuery={props.delegatorsQuery}
      employeeId={props.employeeId}
      username={props.username} 
      accessLevelsQuery={props.accessLevelsQuery}
      setAccessLevelsCallback={props.setAccessLevelsCallback}
    />
  );
}