import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Flashbar,
  FormField,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  Textarea,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { DelegatorColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { UseQueryResult } from '@tanstack/react-query';
import { removeDelegations, transformDelegation } from '../utils';
import { debug } from 'src/utils/commonUtils';
import { viewershipAccessRemovedSendNotication } from '../../utils';

export interface CancelCreateDelegationInterface {
  (): void;
}

export interface CancelUpdateDelegationInterface {
  (): void;
}

export interface CreatedDelegationInterface {
  (): void;
}

export interface DelegatorsTablePanelPropsInterface {
  delegatorsQuery: UseQueryResult<APIt.Delegation[] | null, unknown>;
  employeeId: string;
  username: string;
  delegationTypesQuery: UseQueryResult<APIt.LookupTypeValue[]>;
}

export interface RefreshCallBackInterface {
  (): Promise<void>;
}

export default function DelegatorsTablePanel(props: DelegatorsTablePanelPropsInterface ) {
  debug(`DelegationsTablePanel() props is ${JSON.stringify(props)}`);
  
  const [delegatorSelectedItems, setDelegatorSelectedItems] = useState<APIt.Delegation[]>([]);
  const [removeDelegatorsVisible, setRemoveDelegatorsVisible] = useState<boolean>(false);
  const [refreshingDelegators, setRefreshingDelegators] = useState<boolean>(false);
  const [showEmailNotBeingSent, setShowEmailNotBeingSent] = useState<boolean>(false);

  const removeDelegatorBtnClickHandler = () => {
    if (delegatorSelectedItems.length > 0) setRemoveDelegatorsVisible(true);
  };

  const delegatorsItemsCount = (): number => {
    if (props.delegatorsQuery?.data) return props.delegatorsQuery?.data.length;
    return 0;
  };

  const delegatorRefreshBtnClickHandler = async () => {
    setRefreshingDelegators(true);
    await props.delegatorsQuery.refetch();
    setRefreshingDelegators(false);
  };

  const removeDelegatorHandler = async () => {
    setRemoveDelegatorsVisible(true);
    let filteredSelectedItems: APIt.Delegation[] = delegatorSelectedItems;
    setDelegatorSelectedItems(filteredSelectedItems);
    const removedDelegations = await removeDelegations(filteredSelectedItems);
    if (removedDelegations && removedDelegations.length > 0) {
      for (let delegation of removedDelegations) {
        let delegationPrivilegeInput = await transformDelegation(delegation);
        if (delegationPrivilegeInput != null)
          viewershipAccessRemovedSendNotication(delegationPrivilegeInput);
        else {
          debug(`Email on remove delegation has not been sent delegation is ${JSON.stringify(delegation)}`);
          setShowEmailNotBeingSent(true);
        }
      }
    }
    setRemoveDelegatorsVisible(false);
    setDelegatorSelectedItems([]);
    await props.delegatorsQuery.refetch();
  };

  const DelegatorsReasonField = () => {
    return(
      <>
      <FormField label={bundle.getMessage('justification-for-delegation')} >
        <Textarea value={delegatorSelectedItems[0].justification} disabled/>
      </FormField>
      <br/>
      </>
    );
  };

  const resetEmailNotBeingSent = () => {
    setShowEmailNotBeingSent(false);
  };

  const FlashMessageEmailNotSent = () => (
    <Flashbar
      items={
        [
          {
            header: bundle.getMessage('email-not-sent'),
            dismissible: true,
            dismissLabel: bundle.getMessage('dismiss'),
            onDismiss: resetEmailNotBeingSent,
            type: 'warning',
          },
        ]
      }
    />);

  useEffect(() => {
    setRefreshingDelegators(true);
    props.delegatorsQuery.refetch();
    setRefreshingDelegators(false);
  }, [delegatorSelectedItems]);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Delegations.Delegators.TablePanel');

  const pageSize = 10;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    props.delegatorsQuery?.data || [],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-delegators-found') : 'No Delegated To Me Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );
 
  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showEmailNotBeingSent && <FlashMessageEmailNotSent />}
    <div id='tableDelegatorsDiv'>
      <Table
        {...collectionProps}
        columnDefinitions={DelegatorColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('delegators-filter')}
            filteringPlaceholder={bundle.getMessage('delegators-find')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        data-testid='DelegatorsTable'
        header={
          <Header
            counter={`(${delegatorsItemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  loading={refreshingDelegators}
                  key="refreshBtn"
                  onClick={delegatorRefreshBtnClickHandler}
                  iconName='refresh'
                >
                  {bundle.getMessage('refresh')}
                </Button>
              </SpaceBetween>
              </>
            }
            description={
              <Box float='left'>{bundle.getMessage('delegators-description')}</Box>
            }
          >
            {bundle.getMessage('delegators')}
          </Header>
        }
        footer={
          <Header
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button
                  data-testid='DelegatorsRemoveButton'
                  disabled={delegatorSelectedItems.length == 0}
                  onClick={removeDelegatorBtnClickHandler}
                  variant='primary'
                >
                  {bundle.getMessage('remove')}
                </Button>
              </SpaceBetween>
              </>
            }
          >
          </Header>
        }
        items={items}
        loading={props.delegatorsQuery.isLoading}
        loadingText={bundle.getMessage('loading-delegators')}
        onSelectionChange={({ detail }) => setDelegatorSelectedItems(detail.selectedItems) }
        pagination={
          (props.delegatorsQuery?.data?.length ?? 0)  > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        selectedItems={delegatorSelectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    {
      delegatorSelectedItems.length == 1
      &&
      <Container
        key={'DelegationDetails'}
        header={<Header>{`${bundle.getMessage('delegator-details')} for ${delegatorSelectedItems[0].delegator_username} `}</Header>}
        children={
          <>
          <DelegatorsReasonField/>
          </>
        }
      />
    }
    {removeDelegatorsVisible
    &&
    <Modal
      onDismiss={() => setRemoveDelegatorsVisible(true)}
      visible={removeDelegatorsVisible}
      closeAriaLabel='Close'
      size='medium'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={() => setRemoveDelegatorsVisible(false)}>{bundle.getMessage('no')}</Button>
            <Button
              disabled={!removeDelegatorsVisible}
              loading={!removeDelegatorsVisible}
              onClick={() => removeDelegatorHandler() }
              variant='primary'
            >
              {bundle.getMessage('yes')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('confirm-delegator-deletion')}
    >
      {bundle.getMessage('please-confirm-delegator-deletion')}
    </Modal>
    }
    </>
  );
}
