import React, { useEffect, useState } from 'react';
import { Button, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './details-table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { RefreshCallBackInterface } from './TablePanel';
import { debug } from 'src/utils/commonUtils';

export interface IManageVisitorAssetDetailsTablePanelProps {
  isLoading: boolean;
  refreshCallback: RefreshCallBackInterface;
  visitorAssetApprovals: APIt.VisitorAssetApproval[];
}

export default function ManageVisitorAssetDetailsTablePanel(props: IManageVisitorAssetDetailsTablePanelProps) {
  debug(`ManageVisitorAssetDetailsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.VisitorAssetApproval[]>(props.visitorAssetApprovals);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageAssets.DetailsTablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading && !props.isLoading ? bundle.getMessage('no-asset-approvals-found') : 'No Asset Approvals Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const [hideTable, setHideTable] = useState<boolean>(false);

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await props.refreshCallback();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  useEffect(() => {
    setAllItems(props.visitorAssetApprovals);
  }, [props.visitorAssetApprovals]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-asset-approvals')}
            filteringPlaceholder={bundle.getMessage('find-asset-approvals')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button loading={refreshing} key="refreshBtn" onClick={refreshBtnClickHandler} iconName='refresh'>{bundle.getMessage('refresh')}</Button>
              </SpaceBetween>
              </>
            }
          >
            {bundle.getMessage('asset-approvals')}
          </Header>
        }
        items={items}
        loading={props.isLoading}
        loadingText={bundle.getMessage('loading-asset-approvals')}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
        trackBy='id'
      />
    </div>
  );
}
