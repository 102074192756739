import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from "@amzn/react-arb-tools";
import { IAccessDetailsRecord } from './TablePanel';
import { Toggle } from '@amzn/awsui-components-react';

export const ColumnDefinitions: TableProps.ColumnDefinition<IAccessDetailsRecord>[] = [
  {
    id: 'site',
    header: <TranslateHeader value={'site'} />,
    cell: item => item.site,
    minWidth: 10,
    sortingField: 'site'
  },
  {
    id: 'accessLevel',
    header: <TranslateHeader value={'access-level'} />,
    cell: item => item.accessLevelName,
    minWidth: 30,
    sortingField: 'accessLevelName',
  },
  {
    id: 'permanentFlag',
    header: <TranslateHeader value={'permanent'} />,
    cell: (item) => <Toggle checked={item.permanentFlag} onChange={()=>{}} disabled/>,
    sortingField: 'permanentFlag',
  },
  {
    id: 'validFrom',
    header: <TranslateHeader value={'valid-from'} />,
    cell: item => item.validFrom,
    minWidth: 20,
    sortingField: 'validFrom'
  },
  {
    id: 'validThrough',
    header: <TranslateHeader value={'valid-through'} />,
    cell: item => item.validThrough,
    minWidth: 20,
    sortingField: 'validThrough'
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.SelfService.RequestAccess.AccessDetails.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
  return<>value</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
